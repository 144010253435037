import React from "react";
import { Modal, Spinner } from "react-bootstrap";
//import "../../../assets/sass/admin/Coupon/DeleteCoupon.scss";
import "./VendorBrandConfigurations.scss"

const DeleteConfiguration = ({
  ShowDeleteModel,
  CloseDeleteModel,
  HandleDeleteConfig,
  DisabledButton
}) => {
  return (
    <Modal
      className=""
      show={ShowDeleteModel}
      onHide={CloseDeleteModel}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure, you want to delete this Brand Configuration?
      </Modal.Body>
      <Modal.Footer>
        {/* <div className="btn-container">
          <button
            className="btn btn-primary delete"
          // onClick={() => props.deleteBrandCoupon(props.state.brandCouponId)}
          >
            Yes
          </button>
          <button
            className="btn btn-primary delete"
          // onClick={props.closeDeleteModal}
          >
            Cancel
          </button>
        </div> */}
        <div className="row"
          style={
            {
              // border: "1px solid red",
              width: "100%",
            }
          }
        >
          <div className="col col-md-6" >

          </div>
          <div className="col col-md-3 pr-0"
          >

            <button
              disabled={DisabledButton}
              className="model-btn btn-danger"
              onClick={CloseDeleteModel}
            >
              Cancel</button>
          </div>
          <div className="col col-md-3 pr-0" >

            <button
              disabled={DisabledButton}
              className="model-btn btn-secondary"
              onClick={HandleDeleteConfig}
            >
              {
                DisabledButton ?
                  <>
                    {""}
                    <Spinner animation="border" size="sm" />
                  </>
                  :
                  "Okay"

              }
            </button>
          </div>

        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfiguration;
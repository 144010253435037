import React, { Component } from "react";
import Header from "./Common/Header";
import FooterMain from "./Common/FooterMain";
import cookie from "react-cookies";
import { logout } from "../utills/API";
import { Row, Col } from "react-bootstrap";
import ourearth from "../assets/images/gainon/ourearth.svg";
import ourvision from "../assets/images/gainon/ourvision.svg";
import gainonscreen from "../assets/images/gainon/gainon-screen.svg";
import excellence from "../assets/images/gainon/excellence-icon.svg";
import easyicon from "../assets/images/gainon/easy-icon.svg";
import trusticon from "../assets/images/gainon/trust-icon.svg";
import favicon from "../assets/images/gainon/fav-icon.svg";
import Carousel from "react-bootstrap/Carousel";
import "../assets/scss/components/_about.scss";
import bgmask1 from "../assets/images/gainon/bg-thankyou.png";
import bgmasksm1 from "../assets/images/gainon/bg-thankyou-sm.png";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    this.props.history.push("/");
  };

  render() {
    // New UI
    return (
      <>
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <section className="page page-about">
          <div className="container">
            <div className="about-info">
              <Row>
                <Col md={6}>
                  <h1 className="about-title">
                    About <span>Gainon</span>
                  </h1>
                  <h3>One Richly Rewarding Program</h3>
                  <img
                    className="gainon-mob-image d-block d-sm-none mb-5"
                    src={gainonscreen}
                    alt="gainon screen mobile"
                  />
                  <p>
                  Gainon is a members-only rewards program that offers Instant Loyalty Cashback on every payment.
At Gainon we recognise loyalty differently – we want to eliminate the frustration of the expired,
overly-complicated and fragmented loyalty points. There are just too many programs to remember
and understand, right?
                  </p>

                  <p>
                  Instead, Gainon rewards you with what we all love most, cash! Members have the opportunity to
use the Gainon Cash back at any of the 100+ brands or they may extract it back into a bank account.
                  </p>

                  <p>Gainon is free to join – start earning now.</p>
                </Col>
                <Col md={6}>
                  <div className="image-wrap d-flex align-items-center justify-content-end">
                    <img
                      className="d-none d-sm-block"
                      src={gainonscreen}
                      alt="gainon screen"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="go-about">
              <Row className="align-items-center">
                <Col md={7} className="left-sec">
                  <h2>Our Vision</h2>
                  <p className="mb-0">
                    An open and multicultural Rewards Program, appreciated and
                    respected around the world for its achievements and ability
                    to exceed expectations of its members.
                  </p>
                </Col>
                <Col md={5} className="right-sec">
                  <img
                    className="about-image"
                    src={ourvision}
                    alt="Our Vision"
                  />
                </Col>
              </Row>
            </div>
            <div className="go-about d-block d-sm-none -values">
              <Row className="align-items-center">
                <Col>
                  <h2>Our Values</h2>
                  <p className="mb-0">
                    Gainon is aware of the high social repercussions of
                    transportation, and for that reason, dedicates resources for
                    charitable projects both in India and abroad.
                  </p>
                </Col>
              </Row>
            </div>
            <section className="go-about value-section">
              <div className="d-none d-sm-block">
                <h2 className="text-center">Our Values</h2>
                <div className="values-info d-flex">
                  <div className="values-content text-center">
                    <img
                      className="value-icon"
                      src={excellence}
                      alt="Excellence icon"
                    />
                    <h4>Excellence</h4>
                    <p>
                      We aim to deliver the greatest joy and satisfaction to our
                      members.
                    </p>
                  </div>
                  <div className="values-content text-center">
                    <img
                      className="value-icon"
                      src={easyicon}
                      alt="Easy icon"
                    />
                    <h4>Easy</h4>
                    <p>
                      Through our portal we aim to provide a simple and
                      frustration-free experience.
                    </p>
                  </div>
                  <div className="values-content text-center">
                    <img
                      className="value-icon"
                      src={trusticon}
                      alt="Trust icon"
                    />
                    <h4>Trust</h4>
                    <p>
                      Through our portal we aim to provide a simple and
                      frustration-free experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-block d-sm-none">
                <Carousel>
                  <Carousel.Item>
                    <div className="values-content text-center">
                      <img
                        className="value-icon"
                        src={excellence}
                        alt="Excellence icon"
                      />
                      <h4>Excellence</h4>
                      <p>
                        We aim to deliver the greatest joy and satisfaction to
                        our members.
                      </p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="values-content text-center">
                      <img
                        className="value-icon"
                        src={easyicon}
                        alt="Easy icon"
                      />

                      <h4>Easy</h4>
                      <p>
                        Through our portal we aim to provide a simple and
                        frustration-free experience.
                      </p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="values-content text-center">
                      <img
                        className="value-icon"
                        src={trusticon}
                        alt="Trust icon"
                      />
                      <h4>Trust</h4>
                      <p>
                        Through our portal we aim to provide a simple and
                        frustration-free experience.
                      </p>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </section>
          </div>
          <section className="section-thankyou">
          <img
            src={bgmask1}
            className="bgmask d-none d-xl-block d-lg-block"
            alt="bg"
          />
          <img
            src={bgmasksm1}
            className="bgmask d-block d-lg-none"
            alt="bg"
            />
            <div className="container">
              <div className="thankyou-content">
            <img className="fav-icon" src={favicon} alt="Fav icon" />
            <h4 className="text-center">
                  Thank you for supporting us in our startup journey.
            </h4>
              </div>
              </div>
          </section>
        </section>

        <FooterMain />
      </>
    );
  }
}

export default About;

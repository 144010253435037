import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/CouponCategory.scss";
import { get, Delete } from "../../../utills/API";
import { Link } from "react-router-dom";
import DeleteRedeemBrand from "./DeleteRedeemBrand";
import "../../../assets/sass/admin/Category/ShowCategory.scss";

class ShowCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "S.No.",
          sortable: true,
          selector: "sr_no"
        },
        {
          name: "Brand Name",
          selector: "name",
          sortable: true
        },
        {
          name: "Edit",
          button: true,
          cell: row => (
            <Link to={`/admin/redeemBrand/${row.id}/edit`}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          )
        },
        {
          name: "Delete",
          button: true,
          cell: row => (
            <a onClick={() => this.showDeleteModal(row.id)}>
              <i class="far fa-trash-alt"></i>
            </a>
          )
        }
      ],
      filterText: "",
      resetPaginationToggle: false,
      brandsData: [],
      showDeleteModal: false,
      redeemBrandId: "",
      isLoad: false
    };
  }

  showDeleteModal = id => {
    this.setState({
      showDeleteModal: true,
      redeemBrandId: id
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoad !== this.state.isLoad) {
      try {
        const { data } = await get("reedem_brands");
        var brandsData = data.data.reedem_brands.map((data, i) => ({ ...data, sr_no: ++i }));
      } catch (e) {
        console.log("error", e);
      }

      this.setState({ brandsData: brandsData });
    }
  }

  deleteCategory = async Id => {
    try {
      const { data } = await Delete(`reedem_brands/${Id}`);
      this.setState({ showDeleteModal: false, isLoad: !this.state.isLoad });
    } catch (e) {
      console.log("error", e);
    }
  };

  async componentDidMount() {
    try {
      const { data } = await get("reedem_brands");
      var brandsData = data.data.reedem_brands.map((data, i) => ({ ...data, sr_no: ++i }));
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ brandsData: brandsData });
  }

  searchHeader = e => {
    console.log("event", e);
  };

  onFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const { brandsData, columns, filterText } = this.state;
    const filteredItems = brandsData.filter(item =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <div className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div
              className="navbar navbar-inner block-header"
              style={{ padding: "0px 15px" }}
            >
              <div className="muted pull-left">Show Redeem Brands</div>
              <div className="muted pull-right">
                <Link className="btn btn-primary delete" to={"redeemBrand/new"}>
                  Add Redeem Brand
                </Link>
              </div>
            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <FilterComponent
                        onFilter={this.onFilter}
                        onClear={this.onClear}
                        filterText={filterText}
                      />
                    }
                    columns={columns}
                    data={filteredItems}
                    pagination={true}
                    // persistTableHead
                    defaultSortField
                  />
                  <DeleteRedeemBrand
                    state={this.state}
                    showDeleteModal={this.showDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteCategory={this.deleteCategory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

export default ShowCategory;

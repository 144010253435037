import React from "react";
import { Modal, Card } from "react-bootstrap";
// import "../../../assets/sass/admin/Category/DeleteCategory.scss";
import renderHTML from "react-render-html";

const RewardTerms = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.closeReward}
      className="delete-category-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="card-brand-type mt-3">
          <Card.Body>
            {renderHTML(
              `${props.reward && props.reward.about ? props.reward.about : ""}`
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-container">
          <button
            className="btn btn-primary delete"
            onClick={() => props.closeReward()}
          >
            Close
          </button>
        </div>{" "}
      </Modal.Footer>
    </Modal>
  );
};

export default RewardTerms;

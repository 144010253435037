import React, { Component } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
// import "../../assets/sass/Voucher.scss";
import { get } from "../../utills/API";
import VoucherCategory from "./VoucherCategory";
import BrandVoucherContainer from "./BrandVoucherContainer";
import { logout } from "../../utills/API";
import cookie from "react-cookies";
import Loader from "../Loader";
import { InputGroup, FormControl } from "react-bootstrap";
import AllCategories from "../MAllCategories";
import { decrypt } from "../../utills/function";
import { urlLinks } from "../../utills/Constants";

// import "../../assets/sass/newDesign/voucher.scss";

class Voucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      currCategoryId: null,
      responsiveCatBar: false,
      isLoading: false,
      brands: [],
      allBrands: [],
      redeemBrands: [],
      allRedeemBrands: [],
      easyDineVendor: null,
      isLoggedIn: false,
      modalAllCategories: false,
      selectedVoucherKey: '',
      navExtendedLink:''
    };
  }

  setCurrCategoryId = (id, name, baseLink) => {
    window.scroll(0, 0);
    this.setState({
      currCategoryId: id,
      selectedVoucherKey:name,
      navExtendedLink:baseLink,
      responsiveCatBar: false,
      easyDineVendor: null,
    });
  };

  showResponsiveCatBar = () => {
    this.setState({ responsiveCatBar: true });
  };

  hideResponsiveCatBar = () => {
    this.setState({ responsiveCatBar: false });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { data } = await get("ngp/categories");
      var categories = data.data.categories;
      const location = window.location.href
      const locationPaths = location.split('/')
      let locationId = Object.keys(urlLinks).find(key => urlLinks[key] === locationPaths[locationPaths.length-1]);

      let matchedCategoryIndex = categories.findIndex((category)=>{
        return locationId==category.id
      })
      if(matchedCategoryIndex !=-1){
      this.setCurrCategoryId(categories[matchedCategoryIndex].id, categories[matchedCategoryIndex].name, locationPaths[locationPaths.length-1]!=='redeem' ? locationPaths[locationPaths.length-1]:'')
    }
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("ngp/all_brands");
      var allBrands = decrypt(data.data.brands);
      var brands = allBrands.filter((brand) => brand.is_redeem === false);
      var redeemBrands = allBrands.filter(
        (brand) =>
          brand.is_redeem === true || brand.is_available_for_redeem === true
      );
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      categories,
      isLoading: false,
      brands,
      redeemBrands,
      allBrands: brands,
      allRedeemBrands: redeemBrands
    });
    window.scrollTo(0, 0);
  }

  isCurrentUser = () => {
    let isLogin = !!cookie.load("accessToken");
    return isLogin;
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
  };

  setEasyDine = (vendorId) => {
    this.setState({
      currCategoryId: null,
      easyDineVendor: vendorId,
      responsiveCatBar: false,
    });
  };

  setModalAllCategories = (opt) => {
    this.setState({
      modalAllCategories: opt,
    });
  };


  filterBrandByName = (value, isredeem) => {
    let filteredBrand = [];
    if ((value && this.state.allBrands.length > 0) || (value && this.state.allRedeemBrands.length > 0)) {
      let selectedTypeBrand = isredeem === "redeem" ? this.state.allRedeemBrands : this.state.allBrands
      filteredBrand = selectedTypeBrand.filter((eachBrand) => {
        return (eachBrand.name).toLowerCase().includes(value.toLowerCase());
      });
    } else {
      filteredBrand = isredeem === "redeem" ? this.state.allRedeemBrands : this.state.allBrands;
    }
    this.setState({ [isredeem === "redeem" ? 'redeemBrands' : 'brands']: filteredBrand });
  };

  render() {
    const { isredeem } = this.props.match.params;
    const {
      categories,
      currCategoryId,
      isLoading,
      brands,
      redeemBrands,
      easyDineVendor
    } = this.state;

    return (
      <>
        {isLoading ? <Loader /> : null}
        <Header
          logout={this.logout}
          isCurrentUser={this.isCurrentUser()}
          isLoggedIn={this.state.isLoggedIn}
          navExtendedLink={this.state.navExtendedLink}
        />
        <section className="page page-voucher">
          <div className="container">
            <div className="flex-xl-nowrap row">
              <div className="col-auto d-none d-lg-flex flex-column">
                <VoucherCategory
                  currCategoryId={currCategoryId}
                  categories={categories}
                  setCurrCategoryId={this.setCurrCategoryId}
                  hideResponsiveCatBar={this.hideResponsiveCatBar}
                  responsiveCatBar={this.state.responsiveCatBar}
                  easyDineVendor={easyDineVendor}
                  setEasyDine={this.setEasyDine}
                  selectedVoucherKey={this.state.selectedVoucherKey}
                  isredeem={isredeem ? isredeem :''}
                />
              </div>
              <div className="col">
                <div>
                  {!isredeem &&
                    <p className="shop-txt d-block d-lg-none">
                      <i className="icon-shop" /> Shop
                  </p>
                  }
                  {isredeem === "redeem" && <p className="shop-txt d-block d-lg-none">
                    <i className="icon-redeem" /> Redeem
                  </p>}
                  <div className="d-flex mb-3">
                    <h1 className="font-weight-bolder text-16 d-none d-lg-block ">
                      All Categories
                    </h1>
                    <div className="ml-0 ml-lg-auto search-brand">
                      <InputGroup className="mb-lg-0 mb-4">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1" className="pr-0 border-right-0">
                            <i
                              className="icon-search"
                              style={{ color: "#989998" }}
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Search for your favourite brands"
                          aria-label="Search for your favourite brands"
                          aria-describedby="basic-addon1"
                          onChange={(e) => {
                            this.filterBrandByName(e.target.value, isredeem);
                          }}
                        />
                      </InputGroup>
                      <button
                        className="categories-link d-block d-lg-none"
                        onClick={() => this.setModalAllCategories(true)}
                      >
                        All Categories
                        <i className="icon-angle-down ml-2" />
                      </button>
                    </div>
                  </div>
                  {isredeem === "redeem" ? (
                    <BrandVoucherContainer
                      currCategoryId={currCategoryId}
                      showResponsiveCatBar={this.showResponsiveCatBar}
                      brands={redeemBrands}
                      redeemTab={true}
                      redirectUrl={"redeemBrand"}
                    />
                  ) : (
                      <BrandVoucherContainer
                        currCategoryId={currCategoryId}
                        showResponsiveCatBar={this.showResponsiveCatBar}
                        brands={brands}
                        easyDineVendor={easyDineVendor}
                        redeemTab={false}
                        props={this.props}
                        redirectUrl={"brand"}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <AllCategories
          currCategoryId={currCategoryId}
          categories={categories}
          show={this.state.modalAllCategories}
          onHide={() => this.setModalAllCategories(false, "")}
          setCurrCategoryId={this.setCurrCategoryId}
          easyDineVendor={easyDineVendor}
          setEasyDine={this.setEasyDine}
          selectedVoucherKey={this.state.selectedVoucherKey}
          isredeem={isredeem ? isredeem :''}
        />
        {/* OLD HTML */}
        {/* <div className="voucher evoucher">
          <div class="container voucher-box">
            <Tabs defaultActiveKey="pay" id="uncontrolled-tab-example">
              <Tab eventKey="pay" title="Shop">
                <div class="row-masnory row-tb-20  clearfix">
                  <div class="book-now"></div>
                  <VoucherCategory
                    currCategoryId={currCategoryId}
                    categories={categories}
                    setCurrCategoryId={this.setCurrCategoryId}
                    hideResponsiveCatBar={this.hideResponsiveCatBar}
                    responsiveCatBar={this.state.responsiveCatBar}
                    easyDineVendor={easyDineVendor}
                    setEasyDine={this.setEasyDine}
                  />
                  <BrandVoucherContainer
                    currCategoryId={currCategoryId}
                    showResponsiveCatBar={this.showResponsiveCatBar}
                    brands={brands}
                    easyDineVendor={easyDineVendor}
                    redeemTab={false}
                    props={this.props}
                    redirectUrl={"brand"}
                  />
                </div>
              </Tab>
              <Tab eventKey="redeem" title="Redeem">
                <div class="row-masnory row-tb-20  clearfix">
                  <div class="book-now"></div>
                  <VoucherCategory
                    currCategoryId={currCategoryId}
                    categories={categories}
                    setCurrCategoryId={this.setCurrCategoryId}
                    hideResponsiveCatBar={this.hideResponsiveCatBar}
                    responsiveCatBar={this.state.responsiveCatBar}
                  />
                  <BrandVoucherContainer
                    currCategoryId={currCategoryId}
                    showResponsiveCatBar={this.showResponsiveCatBar}
                    brands={redeemBrands}
                    redeemTab={true}
                    redirectUrl={"redeemBrand"}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div> */}
      </>
    );
  }
}

export default Voucher;

import axios from "axios";
import cookie from "react-cookies";
import { DECODE_KEY } from "./Constants";
const CryptoJS = require("crypto-js");

export const loginCookie = (token, roleId) => {
  cookie.save("accessToken", token, {
    path: "/",
    maxAge: 28440,
  });
  cookie.save("roleId", roleId, {
    path: "/",
    maxAge: 28440,
  });
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
};
export const loggedUser = (user) => {
  cookie.save("userId", user.id, {
    path: "/",
    maxAge: 28440,
  });
};

export const isCurrentUser = () => {
  return !!cookie.load("accessToken");
};

export const isSubscribeModal = () => {
  return cookie.load("isSubscribe");
};

export const subscribeForm = (value) => {
  cookie.save("isSubscribe", value, {
    path: "/"
  });
};


export const encrypt = (data) =>{
  return CryptoJS.AES.encrypt(JSON.stringify(data), DECODE_KEY).toString();
};

export const decrypt = (data) => {
  var decryptData = CryptoJS.AES.decrypt(data, DECODE_KEY);
  return JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
};

export const getUrlParams = (param, url) => {
  const urlParams = new URLSearchParams(url);
  return urlParams.get(param);
};

export const downloadXML = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    fileName
  );
  document.body.appendChild(link);
  link.click();
};

import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

class Donation extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="clearfix"></div>

        <div className="container cou-wids min-heigs main-donate">
          <div className="row">
            <div className="fli-intl-rhs">
              <div className="make_relative">
                <p className="cpns-header">
                  <span className="LatoBold">Enter an Amount to donate</span>
                </p>
                <div className="rvw-sctn append_bottom15 rvw-cpn-sctn">
                  <form
                    action="https://nowgoplaces.com/donationpayment.php"
                    method="post"
                    name="frmdonation"
                    id="frmdonation"
                  >
                    <p className="make_relative append_bottom10">
                      <input
                        type="text"
                        placeholder="Amount"
                        name="amt"
                        id="amt"
                      />
                    </p>

                    <p className="make_relative append_bottom10">
                      <input
                        type="checkbox"
                        name="terms_check"
                        id="terms_check"
                      />
                      <span>Give in honour for someone</span>
                    </p>
                    <p
                      className="make_relative append_bottom10"
                      id="show"
                      // style={{ display: "none" }}
                    >
                      <input
                        type="text"
                        placeholder="Name"
                        name="Name"
                        id="Name"
                      />
                    </p>
                    {/* <!-- <button className="placement">donate</button>--> */}
                    <input
                      type="submit"
                      id="submit"
                      className="placement"
                      name="submit"
                      value="Donate"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Donation;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Card, Col, Container, Modal, Row, Breadcrumb, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { post, put } from "../../../utills/API";

import "./Partners.scss"

const FloatBalanceModal = ({
    ShowModel,
    CloseModel,
    PartnerDetails,
    PartnerBalaceLogs,
    RefetchBalanceLogs,
}) => {

    const [float_amount, setFloat_amount] = useState(0)
    const [float_description, setfloat_description] = useState("")
    const [is_buttonDisabled, setIs_buttonDisabled] = useState(false)

    const columns = [
        {
            name: "Sr.No.",
            selector: "sr_no",
            sortable: true
        },
        {
            name: "Transaction Amount",
            selector: "amount",
            sortable: true,
            width: "160px",
            center: true,
        },
        {
            name: "Transaction Date",
            selector: row => moment(row.createdAt).format("DD-MM-YYYY"),
            sortable: true,
            width: "160px",
            center: true,
        },
        {
            name: "Cashfloat Description",
            selector: "description",
            sortable: true,
            width: "180px",
            center: true,
        },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIs_buttonDisabled(true)

        try {
            const AddFloat = await put(`partners/cashfloat/add/${PartnerDetails["id"]}`, {
                amount: parseInt(float_amount),
                cashfloat_description: float_description
            })
            RefetchBalanceLogs();
            setFloat_amount(0);
            setfloat_description("")
            setIs_buttonDisabled(false);

            toast.success(<p>Float balance added successfully &#128522;</p>)

        } catch (error) {
            console.log(error);
            toast.error(<p>Failed to add float balance &#128549;</p>)

        }
    }

    const BalanceTable = PartnerBalaceLogs
        .sort((a, b) => (b.id > a.id) ? 1 : -1)
        .map((logs, i) => ({ ...logs, sr_no: i + 1 }))
        .filter(logs => logs.sr_no <= 5)
    console.log(PartnerBalaceLogs)
    return (
        <div>
            <Modal show={ShowModel} size="xl" scrollable>
                <Modal.Header
                    className="bg-dark"
                >
                    <Modal.Title
                        className="text-light"
                    >
                        Float Balance
                    </Modal.Title>
                    <button
                        className="zing-btn-sm zing-btn-width-small btn-danger"
                        onClick={CloseModel}
                    >
                        Close
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            PartnerDetails["id"]
                                ?
                                <Row>
                                    <Col md={6}>
                                        <Card>
                                            <Card.Header className="bg-dark text-light" >
                                                Add Float Balance
                                            </Card.Header>
                                            <Card.Body className="show-grid">
                                                <Form onSubmit={handleSubmit} >
                                                    {/* <Container> */}
                                                    <Row>
                                                        <Col md={12} >
                                                            <Breadcrumb>
                                                                <Breadcrumb.Item>Current Balance: INR. {PartnerDetails["cash_float"]}</Breadcrumb.Item>
                                                            </Breadcrumb>
                                                        </Col>
                                                        <Col md={6}>
                                                            <label className="zing-form-label" >Enter Amount</label>
                                                            <input type="number"
                                                                disabled={is_buttonDisabled}
                                                                className="form-control zing-form-control"
                                                                value={float_amount}
                                                                onChange={(e) => setFloat_amount(e.target.value)}
                                                                min="1"
                                                                // max={10}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label className="zing-form-label" >Add Float Description</label>
                                                            <input type="text"
                                                                disabled={is_buttonDisabled}
                                                                className="form-control zing-form-control"
                                                                value={float_description}
                                                                onChange={(e) => setfloat_description(e.target.value)}
                                                                // min="1"
                                                                // max={10}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label className="zing-form-label" >&nbsp;</label>
                                                            <button
                                                                className="zing-btn btn-danger"
                                                                disabled={is_buttonDisabled}
                                                            >
                                                                {
                                                                    is_buttonDisabled ?
                                                                        <>
                                                                            {""}
                                                                            <Spinner animation="border" size="sm" />
                                                                        </>
                                                                        :
                                                                        "Add"
                                                                }
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    {/* </Container> */}
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card>
                                            <Card.Header className="bg-dark text-light" >
                                                Float Balance Logs
                                            </Card.Header>
                                            <Card.Body>
                                                <div>
                                                    <DataTable
                                                        data={BalanceTable}
                                                        columns={columns}
                                                    />
                                                </div>
                                            </Card.Body>
                                            <Card.Footer
                                                className="text-center"
                                            >
                                                <Link to={`partners/balancelogs/${PartnerDetails.id}`} >
                                                    View more
                                                </Link>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                                :
                                <center>
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                    />
                                    <br />
                                    <span>Please wait...</span>
                                </center>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default FloatBalanceModal;
import React from "react";
import renderHTML from "react-render-html";
import htr1 from "../../../assets/img/htr3.jpg";
import htr2 from "../../../assets/img/htr1.jpg";
import htr3 from "../../../assets/img/htr2.jpg";
import htr4 from "../../../assets/img/htr4.jpg";
import htr5 from "../../../assets/img/htr5.jpg";

const shopping = [
  {
    id: 1,
    img: htr1,
    text: "Visit the outlet/store nearby you that accepts the e-voucher.",
  },
  { id: 2, img: htr3, text: "Shop as usual." },
  {
    id: 3,
    img: htr2,
    text:
      "Share your e-voucher at the time of billing & if required, pay any remaining amount by cash or card.",
  },
];
const restaurant = [
  {
    id: 1,
    img: htr4,
    text:
      "Visit the restaurant & inform the cashier about the e-voucher before ordering food.",
  },
  { id: 2, img: htr5, text: "Order food as usual" },
  {
    id: 3,
    img: htr2,
    text:
      "Provide the e-voucher to the cashier at the time of billing & if required, pay any remaining amount by cash or card.",
  },
];

const ConditionRender = ({ category, brandInfo }) => {
  switch (category) {
    case "Apparels & Accessories":
      return (
        <>
          {shopping.map((ele, i) => (
            <StepBox stepNumber={ele.id} img={ele.img} text={ele.text} />
          ))}
        </>
      );
    case "Grocery & Home Needs":
      return (
        <>
          {shopping.map((ele, i) => (
            <StepBox stepNumber={ele.id} img={ele.img} text={ele.text} />
          ))}
        </>
      );
    case "Food & Beverages":
      return (
        <>
          {restaurant.map((ele, i) => (
            <StepBox stepNumber={ele.id} img={ele.img} text={ele.text} />
          ))}
        </>
      );
    case "Luxury":
      return (
        <>
          {shopping.map((ele, i) => (
            <StepBox stepNumber={ele.id} img={ele.img} text={ele.text} />
          ))}
        </>
      );
    default:
      return <>{renderHTML(`${brandInfo ? brandInfo.how_to_redeem : ""}`)}</>;
  }
};

const HowToRedeem = (props) => {
  let videoLink = props.brandInfo ? props.brandInfo.video_link : null;
  if (videoLink) {
    return (
      <>
        <a href={videoLink}>{videoLink}</a>
      </>
    );
  } else {
    return (
      <div className="how-to-redeem-step-container">
        <ConditionRender
          category={props.category}
          brandInfo={props.brandInfo}
        />
      </div>
    );
  }
};

const StepBox = (props) => {
  return (
    <div className="step-box">
      <div className="heading">Step</div>
      <div className="step-number-box">
        <div className="inner-box">{props.stepNumber}</div>
      </div>
      <div className="box-card">
        <div className="image-box">
          <img src={props.img} alt="" />
        </div>
        <div className="text-box">{props.text}</div>
      </div>
    </div>
  );
};

export default HowToRedeem;

import React from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import "../../../assets/sass/admin/vouchersListing.scss";

const VoucherModal = props => {
  return (
    <>
      <Modal
        className="voucher-show-listing"
        show={props.show}
        onHide={props.hideVoucher}
      >
        <Modal.Header closeButton></Modal.Header>
        <DataTable
          columns={columns}
          data={props.voucherData}
          pagination={true}
          defaultSortField
          highlightOnHover
        />
      </Modal>
    </>
  );
};

const columns = [
  {
    name: "Brand Name",
    sortable: true,
    selector: "brand_name",
    width: "150px"
  },
  {
    name: "Voucher No.",
    sortable: true,
    selector: "number",
    width: "170px"
  },
  {
    name: "Voucher Code",
    sortable: true,
    selector: "code",
    width: "170px"
  },
  {
    name: "Pin",
    sortable: true,
    selector: "pin"
  },
  {
    name: "Voucher Value",
    selector: "value",
    sortable: true,
    cell: row => (
      <span>
        <i class="fa fa-inr"></i>&nbsp;{row.value}
      </span>
    ),
    width: "90px"
  },
  {
    name: "Earning",
    selector: "earn",
    sortable: true,
    cell: row => (
      <span>
        <i class="fa fa-inr"></i>&nbsp;{row.earn}
      </span>
    ),
    width: "90px"
  },
  {
    name: "End Date",
    sortable: true,
    selector: "end_date",
    width: "130px"
  }
];
export default VoucherModal;

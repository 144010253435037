import React, { Component } from "react";
// import "../../assets/sass/newDesign/landing.scss";
// import { Link } from "react-router-dom";
// import { isCurrentUser } from "../../utills/function";
// import LandingAnimation from "./LandingAnimation";
import { Container, InputGroup, FormControl } from "react-bootstrap";
import brandLogo from "../../assets/images/gainon/brand.svg";
import { post } from "../../utills/API";
import OutsideSearch from "./OutsideSearch"
import onClickOutside from "react-onclickoutside";

class ParallaxBg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchDropdown: false,
      search: "",
      searchDataArr: [],
    };
  }

  handleSearchOnChange = async (e) => {
    const { value } = e.target;
    const searchData = {
      q: value,
    };
    let searchedBrand = [];
    try {
      var { data } = await post("ngp/pay_brand_search", searchData);
      searchedBrand = data.data.brands;
    } catch (e) {
      console.log("error", e);
    }
    // setSearchDataArr([...searchedBrand]);
    this.setState({ searchDataArr: searchedBrand, isSearchDropdown: true });
  };
  render() {
    const { search, searchDataArr, isSearchDropdown } = this.state;
    return (
      <>
        <section className="section-banner">
          <Container className="position-relative">
            <div className="heading">
              <h2>instant rewards every time.</h2>
              <h1>
                Spend <span>smart</span>,<br />
                Earn more
              </h1>
            </div>
            <InputGroup className="input-group-search">
              <InputGroup.Prepend>
                <InputGroup.Text className="pr-0">
                  <i className="home-page-icon icon-search"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <OutsideSearch />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i className="home-page-icon icon-arrow-right"></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <img src={brandLogo} alt="Brand Images" className="brands-group" />
          </Container>
        </section>
      </>
    );
  }
}

export default ParallaxBg;

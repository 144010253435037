import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import {
  Card,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';
import { get, logout, post } from '../../utills/API';
import { useHistory } from 'react-router';

import { withRouter, useLocation } from 'react-router-dom';
import comingsoonimg from '../../assets/images/gainon/coming-soon.png';
import tooltipicon from '../../assets/images/gainon/tooltip-icon.svg';
import { decrypt } from '../../utills/function';
import { Toast } from "react-bootstrap";
import { Alert } from "react-bootstrap";


const Wallet = (props) => {
  const [is_applied, setIs_applied] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [promoCode, setPromoCode] = useState("")
  const [responseData, setResponseData] = useState({})
  const [isSuccess, setIsSuccess] = useState(false)
  const [SwiggyMessage, setSwiggyMessage] = useState({
    message: '',
    type: ''
  })
  const [isMessageFromSwiggy, setIsMessageFromSwiggy] = useState(false)

  let walletBalance = localStorage.getItem('walletBalance');
  let rewardBalance = localStorage.getItem('rewardBalance');
  let goPointsBalance = localStorage.getItem('goPointsBalance')

  const history = useHistory();
  const location = useLocation();

  const isCurrentUser = () => {
    return !!cookie.load('accessToken');
  };

  const logoutUser = async () => {
    await logout();
    history.push({
      pathname: '/',
    });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await get("users/detail");
        var userInfo = decrypt(data.data.user);
        setUserDetails(userInfo)
        localStorage.setItem('walletBalance', userInfo.wallet);
        localStorage.setItem('rewardBalance', userInfo.ngp_gift_discount);
        localStorage.setItem('goPointsBalance', userInfo.go_points)
      } catch (e) {
        console.log("error", e);
      }
    }
    fetchUserDetails();
  }, [isSuccess])

  //  below function written by Pratik Sonar

  // const HandleApplyPromocode = async (data) => {
  //   try {
  //     const promo_resp = await post("promo_codes/apply_Invite_code", data)
  //     setResponseData(promo_resp.data)
  //     if (promo_resp.data.status !== 200) {
  //       setIs_applied(false);
  //     } else {
  //       // setResponseData();
  //       setIsSuccess(e => !e)
  //       setPromoCode("")
  //       setIs_applied(false);
  //     }
  //   } catch (err) {
  //     console.log(err.response.status)
  //     setResponseData({
  //       status: err.response.status,
  //     });
  //     setIs_applied(false);
  //     // setPromoCode("");
  //   }
  // }


  // Message Handling Swiggy

  useEffect(() => {
    if (props.location.state && props.location.state.message) {
      let response = props.location.state ? props.location.state : {}
      console.log("Message State: ", response)
      setSwiggyMessage({
        message: props.location.state.message,
        type: props.location.state.type
      })
      setIsMessageFromSwiggy(true)
    } else {
      setSwiggyMessage({})
      setIsMessageFromSwiggy(false)
    }
  }, [props.location.state])

  // Below Function wriiten by Shreyas Bhumkar
  const HandleApplyPromocode = async (data) => {
    try {
      const is_valid_promo_code = await post("promo_codes/apply_invite_code_Validaton", data)
      setResponseData(is_valid_promo_code.data)
      if (is_valid_promo_code.data.status === 200) {
        const promo_resp = await post("promo_codes/apply_invite_code_applying", data)
        setResponseData(promo_resp.data)
        if (promo_resp.data.status !== 200) {
          setIs_applied(false);
        } else {
          // setResponseData();
          setIsSuccess(e => !e)
          setPromoCode("")
          setIs_applied(false);
        }
      }
    } catch (err) {
      console.log(err.response.status)
      setResponseData({
        status: err.response.status,
      });
      setIs_applied(false);
      // setPromoCode("");
    }
  }

  return (
    <>
      <Header
        logout={logoutUser}
        isCurrentUser={isCurrentUser()}
        changedAmount={userDetails.wallet}
      />

      <section className="page page-wallet pt-4 pt-lg-5">
        {isMessageFromSwiggy && SwiggyMessage.message ? (
          <Alert variant={SwiggyMessage.type} className="container">
            <div className="d-flex justify-content-between">
              <p>{SwiggyMessage.message}</p>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  setIsMessageFromSwiggy(false);
                  setSwiggyMessage({});
                }}
                style={{
                  border: "none",
                  background: "none",
                  marginTop: "-13px",
                  boxShadow: "none",
                }}
              >
                X
              </button>
            </div>
          </Alert>
        ) : null}
        <Container>
          <p className="text-14 font-weight-bold d-block d-lg-none">Wallet</p>
          <Card className="card-balance">
            <Card.Body className="py-4 pl-3 pl-lg-4 pr-lg-5 pr-3">
              <div className="gainon-ballance-wrap d-flex align-items-center pb-4 pb-lg-0">
                <div className="wallet-wrap text-center">
                  <i className="icon-wallet" />
                </div>
                <div className="balance-txt d-lg-flex d-block justify-content-between align-items-center w-100 ml-4 pb-lg-4 pb-0">
                  <h6 className="text-uppercase font-weight-bold mb-0">
                    Gainon Balance
                  </h6>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 14 14"
                    >
                      <g transform="translate(-1173 -24)">
                        <g transform="translate(1147 12)">
                          <g transform="translate(0 9)">
                            <g transform="translate(26 1)">
                              <g transform="translate(0 2)">
                                <rect width="14" height="14" fill="none" />
                                <path
                                  d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                  fill="#333"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h2 className="mb-0">
                      {/* {userDetails.wallet ? Number(userDetails.wallet) + Number(rewardBalance) : Number(walletBalance) + Number(rewardBalance)} */}
                      {/* {
                        userDetails.wallet && userDetails.go_points ? 
                          Number(userDetails.wallet) + Number(userDetails.go_points) + Number(rewardBalance)
                        : 
                        userDetails.wallet && !userDetails.go_points ? 
                          Number(userDetails.wallet) + Number(rewardBalance)
                        :
                        Number(walletBalance) + Number(rewardBalance)
                      } */}

                      {userDetails.wallet && userDetails.go_points
                        ? Number(Math.floor(userDetails.wallet)) +
                        Number(Math.floor(userDetails.go_points))
                        : userDetails.wallet && !userDetails.go_points
                          ? Number(userDetails.wallet)
                          : Number(walletBalance)}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="balance-wrap">
                <div className="d-flex justify-content-between mb-3">
                  <p className="text-uppercase text-14 font-weight-bold mb-0 d-flex align-items-center">
                    Wallet Balance
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Amount earned as Loyalty Cashback on payments, you
                          can redeem this amount as a discount on your next payment
                        </Tooltip>
                      }
                    >
                      <img
                        src={tooltipicon}
                        alt="Tooltip icon"
                        className="tooltip-img ml-1"
                      ></img>
                    </OverlayTrigger>
                  </p>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 14 14"
                    >
                      <g transform="translate(-1173 -24)">
                        <g transform="translate(1147 12)">
                          <g transform="translate(0 9)">
                            <g transform="translate(26 1)">
                              <g transform="translate(0 2)">
                                <rect width="14" height="14" fill="none" />
                                <path
                                  d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                  fill="#333"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h3 className="rupee-txt mb-0 font-weight-bold">
                      {userDetails.wallet
                        ? Math.floor(userDetails.wallet)
                        : Math.floor(walletBalance)}
                    </h3>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between mb-3">
                  <p className="text-uppercase text-14 font-weight-bold mb-0 d-flex align-items-center">
                    Reward Discount
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Amount earned through Flipkart purchases and scratch
                          cards. You can redeem this balance against your next
                          payment but cannot withdraw it to your bank
                          account/UPI.
                        </Tooltip>
                      }
                    >
                      <img
                        src={tooltipicon}
                        alt="Tooltip icon"
                        className="tooltip-img ml-1"
                      ></img>
                    </OverlayTrigger>
                  </p>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 14 14"
                    >
                      <g transform="translate(-1173 -24)">
                        <g transform="translate(1147 12)">
                          <g transform="translate(0 9)">
                            <g transform="translate(26 1)">
                              <g transform="translate(0 2)">
                                <rect width="14" height="14" fill="none" />
                                <path
                                  d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                  fill="#333"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h3 className="rupee-txt mb-0 font-weight-bold">
                      {rewardBalance}
                    </h3>
                  </div>
                </div> */}
                <div className="d-flex justify-content-between mb-3">
                  <p className="text-uppercase text-14 font-weight-bold mb-0 d-flex align-items-center">
                    Go Points Balance
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Amount earned as GO Points can be applied as a
                          discount on your next payment up to the Loyalty
                          Cashback that is being offered on the payment
                        </Tooltip>
                      }
                    >
                      <img
                        src={tooltipicon}
                        alt="Tooltip icon"
                        className="tooltip-img ml-1"
                      ></img>
                    </OverlayTrigger>
                  </p>
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 14 14"
                    >
                      <g transform="translate(-1173 -24)">
                        <g transform="translate(1147 12)">
                          <g transform="translate(0 9)">
                            <g transform="translate(26 1)">
                              <g transform="translate(0 2)">
                                <rect width="14" height="14" fill="none" />
                                <path
                                  d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                  fill="#333"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h3 className="rupee-txt mb-0 font-weight-bold">
                      {userDetails.go_points !== null
                        ? Math.floor(goPointsBalance)
                        : 0}
                    </h3>
                  </div>
                </div>
              </div>
              {/*  */}

              <div className="row ml-lg-2">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-8">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        {/* <label for="usr">Name:</label> */}
                        <input
                          type="text"
                          className="form-control"
                          id="usr"
                          placeholder="Enter promocode"
                          value={promoCode}
                          onChange={(e) => {
                            setPromoCode(e.target.value);
                            // localStorage.setItem(
                            //   "walletBalance",
                            //   e.target.value
                            // );
                          }}
                          onFocus={() => setResponseData({})}
                          disabled={is_applied}
                          style={{ overflow: "hidden" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            width: "100px",
                            overflow: "hidden",
                            borderRadius: "4px",
                          }}
                        >
                          <Button
                            style={{
                              // marginLeft: "-40px",
                              float: "right",
                              // width: "100px !important",
                              border: "none",
                            }}
                            block
                            disabled={is_applied || !promoCode.length}
                            onClick={() => {
                              const { id } = userDetails;
                              setIs_applied(true);
                              HandleApplyPromocode({
                                promo_code: promoCode,
                                user_id: id,
                              });
                              // props.history.push('/voucher/redeem');
                            }}
                            variant={
                              promoCode.length ? "danger" : "outline-danger"
                            }
                          // block
                          >
                            {is_applied ? (
                              <>
                                {""}
                                <Spinner animation="border" size="sm" />
                              </>
                            ) : (
                              "Apply"
                            )}
                          </Button>
                        </div>

                        {responseData.status && responseData.status !== 200 ? (
                          <span style={{ color: "red" }}>
                            {responseData.errors
                              ? responseData.errors
                              : "Invalid promocode!"}
                          </span>
                        ) : (
                          <span style={{ color: "green" }}>
                            {responseData.message && responseData.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className='col-lg-4 mb-3' >
                      <Button
                        disabled={is_applied || !promoCode.length}
                        onClick={() => {
                          const { id } = userDetails;
                          setIs_applied(true)
                          HandleApplyPromocode({
                            promo_code: promoCode,
                            user_id: id,
                          });
                          // props.history.push('/voucher/redeem');
                        }}
                        variant="outline-danger"
                        block
                      >
                        {
                          is_applied ?
                            <>
                              {""}
                              <Spinner animation="border" size="sm" />
                            </>
                            :
                            "Apply"

                        }
                       
                      </Button>
                    </div> */}
                  </div>
                </div>
                {/* <div className='col-lg-4' ></div> */}

                <div className="col-lg-4">
                  <Button
                    onClick={() => {
                      props.history.push("/voucher/");
                    }}
                    variant="outline-primary"
                    block
                  >
                    Redeem against a purchase
                  </Button>
                </div>
              </div>

              {/* <div className="btn-wrap">
                  <Button
                    onClick={() => {
                      props.history.push('/voucher/redeem');
                    }}
                    variant="outline-primary"
                    block
                  >
                    Redeem against a purchase
                  </Button>
                  <Button variant="primary">Withdraw Cash</Button>
                </div> */}
            </Card.Body>
          </Card>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default withRouter(Wallet);

import React, { Component } from "react";
import "../../assets/sass/admin/RegisteredUser.scss";
import { get } from "../../utills/API";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import VoucherModal from "./AllUsers/VoucherModal";
import moment from "moment";

class UserTransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "Order Id",
          sortable: true,
          selector: "uuid",
          width: "200px",
        },
        {
          name: "Total Amount",
          selector: "total_amount",
          sortable: true,
          cell: (row) => (
            <span>
              <i class="fa fa-inr"></i>&nbsp;{row.total_amount}
            </span>
          ),
        },
        {
          name: "Gift Discount",
          selector: "ngp_discount",
          sortable: true,
          cell: (row) => (
            <span>
              <i class="fa fa-inr"></i>&nbsp;{row.ngp_discount}
            </span>
          ),
        },
        {
          name: "Order Status",
          selector: "status",
          sortable: true,
          cell: (row) => {
            var status;
            var color;
            if (row.status === "order_completed") {
              status = "ORDER COMPLETED";
              color = "green";
            } else if (row.status === "payment_failure") {
              status = "PAYMENT FAILURE";
              color = "red";
            } else if (row.status === "payment_completed") {
              status = "PAYMENT COMPLETED";
              color = "green";
            }

            return <span style={{ fontWeight: "bold", color }}>{status}</span>;
          },
        },
        {
          name: "Vouchers",
          button: true,
          cell: (row) => (
            <a onClick={() => this.showVoucherComponent(row.id)}>
              <i class="fa fa-gift"></i>
            </a>
          ),
        },
        {
          name: "Created Date",
          selector: "createdAt",
          sortable: true,
          cell: (row) => (
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          ),
        },
      ],
      columnVoucher: [
        {
          name: "Order Id",
          sortable: true,
          selector: "external_order_id",
          width: "200px",
        },
        {
          name: "Quantity",
          sortable: true,
          selector: "quantity",
          width: "100px",
        },
        {
          name: "Status",
          sortable: true,
          selector: "status",
          cell: (row) => {
            var status;
            var color;
            if (row.status === "success") {
              status = "SUCCESS";
              color = "green";
            } else if (row.status === "fail") {
              status = "FAILURE";
              color = "red";
            } else {
              status = row.status.toUpperCase();
              color = "blue";
            }

            return <span style={{ fontWeight: "bold", color }}>{status}</span>;
          },
          width: "100px",
        },
        {
          name: "Amount",
          sortable: true,
          selector: "amount",
          cell: (row) => (
            <span>
              <i class="fa fa-inr"></i>&nbsp;{row.amount}
            </span>
          ),
          width: "100px",
        },
        {
          name: "GUID",
          sortable: true,
          selector: "guid",
          width: "240px",
        },
        {
          name: "Gift Vouchers",
          button: true,
          cell: (row) => (
            <a onClick={() => this.showVoucherModal(row.id)}>
              <i class="fa fa-gift"></i>
            </a>
          ),
        },
      ],
      orderData: [],
      filterText: "",
      isShowVoucher: false,
      voucherData: [],
      isTransactionTableShow: true,
      userVoucherId: null,
      userVoucherData: [],
    };
  }

  showVoucherComponent = async (id) => {
    try {
      const { data } = await get(`orders/${id}/user_vouchers`);
      var userVoucherData = data.data.user_vouchers;
    } catch (e) {
      console.log("error", e);
    }
    this.setState({
      isTransactionTableShow: false,
      userVoucherId: id,
      userVoucherData,
    });
  };

  showVoucherModal = async (id) => {
    try {
      const { data } = await get(`vouchers/orders/user_vouchers/${id}`);
      var voucherData = data.data.vouchers;
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isShowVoucher: true, voucherData });
  };

  hideVoucherModal = () => {
    this.setState({ isShowVoucher: false });
  };
  hideVoucherComponent = () => {
    this.setState({ isTransactionTableShow: true });
  };

  async componentDidMount() {
    const { Id } = this.props.match.params;

    try {
      const { data } = await get(`orders/user/${Id}`);
      var orderData = data.data.orders;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ orderData });
  }

  onFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const {
      orderData,
      columns,
      filterText,
      // voucherData,
      userVoucherData,
      columnVoucher,
    } = this.state;
    const filteredItems = orderData.filter((item) =>
      item.uuid.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <div class="row-fluid admin-registered-user">
        <div id="content" class="span9">
          <div class="row-fluid">
            <div class="block">
              <div class="navbar navbar-inner block-header">
                <div class="muted pull-left">Transaction History</div>
                <div className="muted pull-right">
                  {this.state.isTransactionTableShow ? (
                    <Link
                      className="btn btn-primary delete"
                      to={"/admin/registeredUser"}
                    >
                      Back
                    </Link>
                  ) : (
                    <a
                      className="btn btn-primary delete"
                      onClick={this.hideVoucherComponent}
                    >
                      Back
                    </a>
                  )}
                </div>
              </div>
              <div class="block-content  in">
                <div class="span12">
                  {this.state.isTransactionTableShow ? (
                    <DataTable
                      subHeader
                      subHeaderComponent={
                        <FilterComponent
                          onFilter={this.onFilter}
                          onClear={this.onClear}
                          filterText={filterText}
                        />
                      }
                      columns={columns}
                      data={filteredItems}
                      pagination={true}
                      defaultSortField
                      highlightOnHover
                      expandableRows
                      expandableRowsComponent={<SampleExpandedComponent />}
                    />
                  ) : (
                    <DataTable
                      columns={columnVoucher}
                      data={userVoucherData}
                      pagination={true}
                      defaultSortField
                      highlightOnHover
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <VoucherModal
          hideVoucher={this.hideVoucherModal}
          show={this.state.isShowVoucher}
          voucherData={this.state.voucherData}
        />
      </div>
    );
  }
}

const FilterComponent = (props) => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Brand Name"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

const SampleExpandedComponent = ({ data }) => {
  return (
    <table className="table">
      <tr>
        <th scope="row">Order Id</th>
        <td>{data.uuid}</td>
      </tr>
      <tr>
        <th scope="row">Transaction Id</th>
        <td>{data.txn_id}</td>
      </tr>
      <tr>
        <th scope="row">Bank Transaction Id</th>
        <td>{data.bank_txn_id}</td>
      </tr>
      <tr>
        <th scope="row">Bank Name</th>
        <td>{data.bank_name}</td>
      </tr>
      <tr>
        <th scope="row">Payment Status</th>
        <td>{data.payment_status}</td>
      </tr>
      <tr>
        <th scope="row">Date</th>
        <td>{moment(data.createdAt).format("DD-MM-YYYY")}</td>
      </tr>
    </table>
  );
};

export default UserTransactionHistory;

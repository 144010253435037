import React, { Component } from "react";
import { Modal, Button, Spinner, Row, Col } from "react-bootstrap";
import { required, validateMobile } from "../utills/Validation";
import { login, post } from "../utills/API";
import { ToastContainer, toast } from "react-toastify";
// import OtpModal from "./Signup/OtpModal";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import MobileModal from "./Signup/MobileModal";
// import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../utills/Constants";
import { loginCookie } from "../utills/function";
import { withRouter } from "react-router-dom";
import AlertDismissible from "../components/Common/Alert";
import ReactTelephoneInput from "react-telephone-input";
import OtpInput from "react-otp-input";
import { Toast, Alert } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      mobile: "",
      countryCode: "+91",
      inviteCode: "",
      errors: {
        emailError: null,
        passwordError: null,
      },
      showOtpModal: false,
      setShowOtpModal: false,
      showMobileModal: false,
      setShowMobileModal: false,
      authUser: {
        email: null,
        provider: null,
        token: null,
        provider_id: null,
        provider_img: null,
      },
      isProcess: false,
      showError: false,
      errorMessage: "",
      loginWithOTP: false,
      isLoginWithOTPVisible: true,
      loginWithPassword: false,
      isLoginWithPasswordVisible: true,
      getOTPButton: false,
      isGetOTPClicked: false,
      showMobileError: {
        status: false,
        message: ''
      },
      enterdMobileNumber: '',
      enterdCountryCode: '',
      isOTPModalVisible: false,
      setOTP: '',
      setOTPError: '',
      isVerifyOTPClicked: false,
      verifyOTPButton: false,
      resendOTPClicked: false,
      successToastForResendOTP: false,
      handleloginForPendingUser: false
    };
  }

  validateAllInputs = () => {
    const errors = {
      emailError: null,
      passwordError: null,
    };
    const { email, password } = this.state;
    errors.emailError = required(email);
    errors.passwordError = required(password);
    this.setState({ errors });
  };

  toggleError = (value) => {
    this.setState({ showError: value });
  };

  redirectUrl = (url) => {
    this.props.history.push(url);
  };

  validityCheck = () => {
    const { email, password } = this.state;
    return (
      email &&
      password &&
      email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  login = async (e) => {
    this.setState({ isProcess: true });
    e.preventDefault();
    this.validateAllInputs();
    if (this.validityCheck()) {
      const { email, password } = this.state;
      const loginData = {
        user: {
          email: email,
          password: password
        },
        webUser: true
      };

      try {
        const { data } = await login(loginData);
        if (data.status === 200 && data.data.user.ngp_name === "ngp_admin") {
          loginCookie(data.data.token, data.data.user.ngp_name);
          this.props.history.push("/admin/dashboard");
          this.props.getUserDetails()
          console.log("data: ", data)
        } else if (data.status === 200 && data.data.user.ngp_name === "user") {
          loginCookie(data.data.token, data.data.user.ngp_name);
          this.props.getUserDetails()
          console.log("data: ", data)
          // if (localStorage.getItem("checkoutData")) {
          //   let redirectPath = localStorage.getItem("checkoutPath");
          //   this.props.history.push(redirectPath);
          // } else {
          // this.props.history.push("/voucher");
          this.props.onHide();
          //}
        } else if (data.status === 400) {
          console.log("data: ", data)
          this.setState({
            showError: true,
            errorMessage: "Invalid Email or Password",
          });
        } else if (
          data.status === 422 &&
          data.message === `Mobile number not register, otp sent on your no ${data.data.user.mobile}`
        ) {
          console.log("data: ", data)
          this.setState({
            handleloginForPendingUser: true,
            isOTPModalVisible: true,
            loginWithPassword: false,
            loginWithOTP: true,
            isGetOTPClicked: false,
            getOTPButton: false,
            verifyOTPButton: true,
            enterdCountryCode: data.data.user.country_code,
            enterdMobileNumber: data.data.user.mobile
          })
          // this.setState({
          //   showOtpModal: true,
          //   showMobileModal: false,
          //   mobile: data.data.user.mobile,
          //   countryCode: data.data.user.country_code,
          //   showError: true,
          //   errorMessage: "Mobile number not register",
          // });
        }
      } catch (e) {
        this.setState({
          showError: true,
          errorMessage: "Invalid Email or Password",
        });
      }
    } else {
      console.log("Enter valid Details");
    }
    this.setState({ isProcess: false });
  };

  // Mobile no. validation
  handleMobileNumberValidation = () => {
    const { enterdMobileNumber } = this.state
    if (enterdMobileNumber !== "") {
      if (enterdMobileNumber.length < 10) {
        return {
          status: true,
          message: "*Mobile number should be 10 digits."
        };
      } else if (enterdMobileNumber.length > 10) {
        return {
          status: true,
          message: "*Mobile number should be 10 digits."
        };
      } else {
        // setIs_buttonDisabled(true);
        return {
          status: false,
          message: ""
        };
      }
    } else {
      return {
        status: true,
        message: "*Please enter mobile number."
      };
    }
  }

  // Mobile number input
  handleMobileNumberInput = (telNumber, selectedCountry) => {
    let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
    let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
    this.setState({ enterdCountryCode: `+${selectedCountry.dialCode}`, enterdMobileNumber: mobile });
  };

  // SEND OTP function by shreyas bhumkar
  handleSendOTP = async (e) => {
    e.preventDefault();
    const { enterdMobileNumber, enterdCountryCode, isGetOTPClicked, resendOTPClicked } = this.state
    try {
      let isMobileNumbervalid = this.handleMobileNumberValidation()
      if (!isMobileNumbervalid.status) {
        this.setState({ isGetOTPClicked: true })
        console.log("no: ", enterdMobileNumber)

        const dataForGetOTP = {
          user: {
            country_code: `+${enterdCountryCode}`,
            mobile: `${enterdMobileNumber}`,
            app_signature_id: "pb"
          },
          isOTP: true,
          webUser: true
        }
        if (!resendOTPClicked) {
          try {
            const { data } = await post("ngp/login", dataForGetOTP)
            if (data.status === 200) {
              this.setState({
                isOTPModalVisible: true,
                isGetOTPClicked: false,
                getOTPButton: false,
                verifyOTPButton: true,
              })
            } else if (data.status === 400) {
              this.setState({
                isGetOTPClicked: false,
                showMobileError: {
                  status: true,
                  message: data.message
                }
              })
            }
          } catch (error) {
            this.setState({
              isGetOTPClicked: false,
              showMobileError: {
                status: true,
                message: "There's network issue or something went wrong. Please check and try again.!"
              }
            })
            console.log("Error: ", error)
          }
        } else {
          try {
            const { data } = await post("ngp/login", dataForGetOTP)
            if (data.status === 200) {
              this.setState({
                successToastForResendOTP: true,
                isOTPModalVisible: true,
                isGetOTPClicked: false,
                getOTPButton: false,
                verifyOTPButton: true,
                resendOTPClicked: false
              })
            } else if (data.status === 400) {
              this.setState({
                isGetOTPClicked: false,
                resendOTPClicked: false,
                showMobileError: {
                  status: true,
                  message: data.message
                }
              })
            }
          } catch (error) {
            this.setState({
              isGetOTPClicked: false,
              setOTPError: "There's network issue or something went wrong. Please check and try again.!"
            })
            console.log("Error: ", error)
          }
        }

      } else {
        this.setState({
          isGetOTPClicked: false,
          showMobileError: isMobileNumbervalid
        })
      }
    } catch (e) {
      this.setState({ isGetOTPClicked: false })
      console.log("Error: ", e)
    }
  }


  handleVerifyOTP = async (e) => {
    e.preventDefault();

    const { enterdMobileNumber, enterdCountryCode, setOTP } = this.state

    if (this.state.setOTP !== "") {
      if (this.state.setOTP.length === 4) {
        this.setState({ isVerifyOTPClicked: true })
        const dataToVerifyOTP = {
          user: {
            mobile: `${enterdMobileNumber}`,
            country_code: `${enterdCountryCode}`
          },
          otp: `${setOTP}`
        }
        console.log("dataToVerifyOTP: ", dataToVerifyOTP)
        try {
          const data = await post("ngp/verify-login", dataToVerifyOTP)
          if (data.data.status === 200 && data.data.data.user.ngp_name === "ngp_admin") {
            console.log("Data: ", data)
            this.setState({ isVerifyOTPClicked: false })
            this.resetAllStates()
            loginCookie(data.data.data.token, data.data.data.user.ngp_name)
            this.props.history.push("/admin/dashboard");
            this.props.getUserDetails()
            this.props.onHide()

          } else if (data.data.status === 200 && data.data.data.user.ngp_name === "user") {

            this.setState({ isVerifyOTPClicked: false })
            this.resetAllStates()
            console.log("data: ", data)
            loginCookie(data.data.data.token, data.data.data.user.ngp_name)
            this.props.getUserDetails()
            this.props.onHide()

          } else if (data.data.status === 400) {
            console.log("Data: ", data)
            this.setState({
              isVerifyOTPClicked: false,
              setOTPError: data.data.message
            })
          }
        } catch (error) {
          this.setState({
            isVerifyOTPClicked: false,
            setOTPError: "There's network issue or something went wrong. Please check and try again.!"
          })
        }
      } else {
        this.setState({
          setOTPError: "*Please check your OTP"
        })
      }
    } else {
      this.setState({
        setOTPError: "*Please enter OTP"
      })
    }
  }

  responseGoogle = (response) => {
    this.signupOAuth(response, "google");
  };

  responseFacebook = (response) => {
    this.signupOAuth(response, "facebook");
  };

  errorGoogle = (error) => {
    console.log(error);
  };

  signupOAuth = async (resp, type) => {
    let signupOAuthData;
    let res = resp ? resp : null;
    if (type === "google" && res.w3.U3) {
      signupOAuthData = {
        user: {
          email: res.w3.U3,
          provider: type,
          token: res.accessToken,
          provider_id: res.googleId,
          provider_img: res.profileObj.imageUrl,
        },
      };
    } else if (type === "facebook" && res.email) {
      signupOAuthData = {
        user: {
          email: res.email,
          provider: type,
          token: res.accessToken,
          provider_id: res.id,
          provider_img: res.picture.data.url,
        },
      };
    }

    try {
      const { data } = await post("outh-signin", signupOAuthData);
      if (data.status === 200 && data.data.user.ngp_name === "ngp_admin") {
        loginCookie(data.data.token, data.data.user.ngp_name);
        this.props.history.push("/admin/dashboard");
      } else if (data.status === 200 && data.data.user.ngp_name === "user") {
        loginCookie(data.data.token, data.data.user.ngp_name);
        if (localStorage.getItem("checkoutData")) {
          let redirectPath = localStorage.getItem("checkoutPath");
          this.props.history.push(redirectPath);
        } else {
          this.props.history.push("/voucher");
        }
      } else if (data.status === 422) {
        this.setState({
          showError: true,
          errorMessage: "Invalid Email or Password",
        });
      } else if (
        data.status === 422 &&
        data.message === "Mobile number not register"
      ) {
        this.setState({
          showOtpModal: true,
          showMobileModal: false,
          mobile: data.data.user.mobile,
          countryCode: data.data.user.country_code,
        });
      } else if (
        data.status === 200 &&
        data.message === "Mobile number not register"
      ) {
        this.setState({
          showMobileModal: true,
          authUser: signupOAuthData.user,
          email: signupOAuthData.user.email,
        });
      } else if (data.status === 200 && data.data.user.mobile === null) {
        this.setState({
          showMobileModal: true,
          authUser: signupOAuthData.user,
          email: signupOAuthData.user.email,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  validateMobileModal = () => {
    const errors = {
      mobileError: null,
      countryCodeError: null,
    };
    const { countryCode, mobile } = this.state;
    errors.mobileError = validateMobile(mobile);
    errors.countryCodeError = required(countryCode);
    this.setState({ errors });
  };

  registerMobile = async () => {
    this.validateMobileModal();
    if (
      this.state.mobile &&
      this.state.mobile.length === 10 &&
      this.state.countryCode
    ) {
      const { authUser, mobile, countryCode, inviteCode } = this.state;
      const registerMobileData = {
        user: {
          email: authUser.email,
          provider_id: authUser.provider_id,
          provider: authUser.provider,
        },
        mobile: mobile,
        country_code: countryCode,
        invite_code: inviteCode,
      };

      try {
        const { data } = await post("register-mobile", registerMobileData);
        if (data.status === 200) {
          this.setState({ showOtpModal: true, showMobileModal: false });
        } else if (data.status === 422) {
          this.setState({
            showError: true,
            errorMessage: "Invalid Invite Code",
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      console.log("Enter valid Details");
    }
  };

  handleInputChange = (telNumber, selectedCountry) => {
    let mobileNumber = telNumber.split(" ")[1];
    let mobile = mobileNumber.split("-").join("");

    this.setState({ countryCode: `+${selectedCountry.dialCode}`, mobile });
  };

  resetAllStates = () => {
    this.setState({
      email: '',
      password: '',
      loginWithOTP: false,
      isLoginWithOTPVisible: true,
      loginWithPassword: false,
      isLoginWithPasswordVisible: true,
      getOTPButton: false,
      isGetOTPClicked: false,
      showMobileError: {
        status: false,
        message: ''
      },
      enterdMobileNumber: '',
      enterdCountryCode: '',
      isOTPModalVisible: false,
      setOTP: '',
      setOTPError: '',
      isVerifyOTPClicked: false,
      verifyOTPButton: false,
      resendOTPClicked: false,
      successToastForResendOTP: false,
      handleloginForPendingUser: false
    })
  }

  render() {
    const { props } = this;
    const {
      email,
      password,
      errors,
      enterdMobileNumber,
      enterdCountryCode,
      isOTPModalVisible,
      setOTP,
      setOTPError
    } = this.state;
    return (
      <>
        <Modal
          show={props.show}
          onHide={() => {
            props.onHide()
            this.resetAllStates()
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <form onSubmit={this.state.loginWithOTP && !this.state.isOTPModalVisible || this.state.resendOTPClicked ? this.handleSendOTP : this.state.loginWithOTP && this.state.isOTPModalVisible ? this.handleVerifyOTP : this.login}>
            <Modal.Header
              className="pb-0 border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="pt-0">
              {/* <ToastContainer position={toast.POSITION.TOP_CENTER} /> */}
              <AlertDismissible
                toggleError={this.toggleError}
                errorMessage={this.state.errorMessage}
                show={this.state.showError}
              />

              <div className="pl-4 pr-4">
                {
                  this.state.loginWithOTP || this.state.loginWithPassword ?
                    <div className="d-flex d-lg-block">
                      <i className="fa fa-arrow-left d-lg-block"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.resetAllStates()
                        }}
                      ></i>
                    </div>
                    :
                    null
                }
                <h1 className="font-weight-bolder text-center text-24 mb-5">
                  {
                    this.state.verifyOTPButton ? "Verify OTP" : "Log in"
                  }
                </h1>

                {
                  // this.state.successToastForResendOTP &&
                  <Alert
                    show={this.state.successToastForResendOTP}
                    variant="success"
                  >
                    <div className="d-flex justify-content-between">
                      <p>
                        OTP sent successfully
                      </p>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                          this.setState({
                            successToastForResendOTP: false
                          })
                        }}
                        style={{ border: 'none', background: 'none', marginTop: '-13px', boxShadow: 'none' }}>
                        X
                      </button>
                    </div>
                  </Alert>
                }

                {
                  this.state.loginWithPassword &&
                  <div className="form-group">
                    <label>
                      Email Address <span>*</span>
                    </label>
                    <input
                      placeholder="Email ID"
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    {errors.emailError ? (
                      <div className="error-warning">{errors.emailError}</div>
                    ) : null}
                  </div>
                }
                {
                  this.state.loginWithOTP && !this.state.isOTPModalVisible &&
                  <div className="form-group">
                    <label className='text-16'>Enter mobile number</label>
                    <PhoneInput
                      onChange={this.handleMobileNumberInput}
                      country={"in"}
                      inputStyle={{
                        fontWeight: 'normal'
                      }}
                      onEnterKeyPress={this.handleSendOTP}
                      className="mb-2"
                      // flagsImagePath="/flags.723494a4.png"
                      onFocus={() => this.setState({ showMobileError: {} })}
                    />

                    {this.state.showMobileError.status &&
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                        }}
                      >{this.state.showMobileError.message}</span>
                    }
                  </div>
                }
                {
                  this.state.loginWithPassword &&
                  <div className="form-group">
                    <label>
                      Password <span>*</span>
                    </label>
                    <input
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    {errors.passwordError ? (
                      <div className="error-warning">{errors.passwordError}</div>
                    ) : null}
                  </div>
                }

                {
                  isOTPModalVisible &&
                  <div className="justify-content-center text-center">
                    {
                      this.state.handleloginForPendingUser &&
                      <p className='text-dark text-center text-18 font-weight-bolder'>
                        Your mobile number is not registered
                      </p>
                    }
                    <p className='pt-2 text-muted text-center text-14'>
                      {`A 4 digit OTP has been sent to ${enterdCountryCode} - ${enterdMobileNumber}`}
                    </p>
                    <Row className="justify-content-center text-center">
                      <p className='pb-2 text-muted text-center text-14'>

                      </p>
                      <OtpInput
                        onChange={(otp) => {
                          console.log(otp)
                          this.setState({
                            setOTP: otp,
                            setOTPError: ''
                          })
                        }}
                        numInputs={4}
                        inputStyle={{
                          width: '60px',
                          height: '50px',
                          margin: '5px 10px 10px 10px',
                        }}

                        value={setOTP}
                        isInputNum={false}
                        // hasErrored={OTPError}
                        shouldAutoFocus={true}
                      // containerStyle="box-otp"
                      />
                    </Row>
                    {
                      setOTPError && <span className="text-center"
                        style={{
                          color: "red",
                          fontSize: "15px",
                        }}
                      >{setOTPError}</span>
                    }
                  </div>
                }

                {
                  this.state.isLoginWithOTPVisible && this.state.isLoginWithPasswordVisible &&
                  <div className="row">
                    <div className="col col-sm-6">
                      <Button
                        variant="primary"
                        block
                        className="text-12 my-2"
                        type="button"
                        onClick={() => {
                          this.setState({ loginWithOTP: true })
                          this.setState({ isLoginWithOTPVisible: false })
                          this.setState({ getOTPButton: true })
                        }}
                      // disabled={this.state.isProcess}
                      >
                        Login with OTP
                      </Button>
                    </div>
                    <div className="col col-md-6">
                      <Button
                        variant="primary"
                        block
                        className="text-12 my-2"
                        type="button"
                        onClick={() => {
                          this.setState({ loginWithPassword: true })
                          this.setState({ isLoginWithPasswordVisible: false })
                        }}
                      // disabled={this.state.isProcess}
                      >
                        Login with password
                      </Button>
                    </div>
                  </div>
                }

                <div
                  className="text-center pt-2 mb-3 justify-content-center row"
                >
                  {
                    !this.state.verifyOTPButton && !this.state.getOTPButton &&
                    <div className="text-center w-100">
                      <Button
                        onClick={() => this.props.setModalPassword(true)}
                        style={{ color: "#4886FF" }}
                        className="font-weight-bold text-capitalize btn-link-secondory m-2"
                        variant="link"
                      >
                        Forgot Password?
                      </Button>
                    </div>
                  }

                  {
                    this.state.loginWithPassword ?
                      <div className="d-flex w-100">

                        <Button
                          variant="primary"
                          block
                          type="submit"
                          disabled={this.state.isProcess}
                        >
                          {this.state.isProcess ? (
                            <Spinner animation="border" size="sm" />
                          ) : "Login"}
                        </Button>

                      </div>
                      :
                      this.state.getOTPButton ?
                        <div className="w-100 justify-content-center row">
                          <div className="col-md-3"></div>
                          <div className="col-md-6">
                            <Button
                              variant="primary"
                              block
                              type="submit"
                              disabled={this.state.isGetOTPClicked}
                            >
                              {this.state.isGetOTPClicked ? (
                                <Spinner animation="border" size="sm" />
                              ) : "SEND OTP"}
                            </Button>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                        :
                        this.state.verifyOTPButton ?
                          <div className="w-100 justify-content-center row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                              <Button
                                variant="primary"
                                block
                                type="submit"
                                disabled={this.state.isVerifyOTPClicked}
                              >
                                {this.state.isVerifyOTPClicked ? (
                                  <Spinner animation="border" size="sm" />
                                ) : "Verify OTP"}
                              </Button>

                              <button
                                type='submit'
                                className="btn btn-link mt-2 text-12"
                                onClick={() => {
                                  this.setState({
                                    resendOTPClicked: true
                                  })
                                  // setToastForResendOTP(false)
                                }}
                              >
                                Resend OTP
                              </button>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                          :
                          null
                  }

                  {
                    !this.state.verifyOTPButton && !this.state.getOTPButton &&
                    <div className="w-100 text-center pt-2">
                      Don't have an account?{" "}
                      <a
                        style={{ color: "#4886FF", cursor: 'pointer' }}
                        className="font-weight-bold"
                        onClick={() => this.props.openloginCloseSignup(true)}
                      >
                        Signup
                      </a>
                    </div>
                  }
                </div>

              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top-0">
              {/* <div className="d-flex flex-column w-100 pl-4 pr-4">
                {
                  this.state.loginWithPassword &&
                  <Button
                    variant="primary"
                    block
                    type="submit"
                    disabled={this.state.isProcess}
                  >
                    {this.state.isProcess ? (
                      <Spinner animation="border" size="sm" />
                    ) : "Login"}
                  </Button>
                }
                
              </div> */}
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}

export default withRouter(Login);

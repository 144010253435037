import cookie from "react-cookies";

export const AUTH_TOKEN = cookie.load("accessToken");
export const ROLE_ID = cookie.load("roleId");
export const SERVICE_URL = process.env.REACT_APP_API_ENDPOINT;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const PAYMENT_REDIRECT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL;
export const REZOR_PAY_KEY = process.env.REACT_APP_REZOR_PAY_KEY;
export const DECODE_KEY = process.env.REACT_APP_DECODE_KEY;
export const STUDENT_CLIENT_ID = process.env.REACT_APP_STUDENT_CLIENT_ID;
export const MAILCHIMP_ACTION_URL = process.env.REACT_APP_MAILCHIMP_ACTION_URL
export const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

export const urlLinks = {
  1: "apparels-accessories",
  3: "e-com-online",
  7: "entertainment-magazines",
  4: "food-beverages",
  2: "gifting",
  5: "grocery-home-needs",
  6: "health-wellness",
  8: "mobile-electronics",
};

export const httpResponses = {
  HTTP_SUCCESS: 200,
  HTTP_BAD_REQUEST: 400,
  HTTP_UNAUTHORIZED: 401,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,
  HTTP_CONFLICT: 409,
  HTTP_PARAMS_MISSING: 422,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_NETWORK_AUTHENTICATION_REQUIRED: 511,
};

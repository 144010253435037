import React from "react";
import { Modal } from "react-bootstrap";
import { post } from "../utills/API";
import thankyouImg from "../assets/images/gainon/thankyou-img.svg";

const SuccessForgotPassword = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="go-modal"
      >
        <Modal.Header
          className="pb-0 border-bottom-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pt-4">
          <div className="pt-5 pb-5 text-center">
            <img src={thankyouImg} alt="Thankyou-img" />
            <h1 className="font-weight-bold text-22 mb-2 mt-3">Thank you</h1>
            <p>
              A temporary password has been sent to{" "}
              <span>{props.changePasswordemail}</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessForgotPassword;

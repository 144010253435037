import React from "react";

const VoucherIcon = props => (
    <svg viewBox="0 0 376 106" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <defs>
            <linearGradient x1="20.7592046%" y1="48.0827482%" x2="79.7038133%" y2="52.5445517%" id="linearGradient-1">
              <stop stopColor="#3C5FF4" offset="0%"></stop>
              <stop stopColor="#3C98F4" offset="100%"></stop>
            </linearGradient>
              <filter x="-2.6%" y="-10.0%" width="105.1%" height="120.0%" filterUnits="objectBoundingBox" id="filter-2">
                <feGaussianBlur stdDeviation="3" in="SourceGraphic"></feGaussianBlur>
              </filter>
              <linearGradient x1="20.7592046%" y1="48.1877829%" x2="79.7038133%" y2="52.4051511%" id="linearGradient-3">
                <stop stopColor="#3C5FF4" offset="0%"></stop>
                <stop stopColor="#3C98F4" offset="100%"></stop>
      </linearGradient>
      
              {/* gradient code for purple svg
            <linearGradient x1="20.7592046%" y1="48.1877829%" x2="79.7038133%" y2="52.4051511%" id="linearGradient-1">
            <stop stop-color="#7B3CF4" offset="0%"></stop>
            <stop stop-color="#DC59E5" offset="100%"></stop>
        </linearGradient> */}
       
        <path d="M209,0 C209,4.418278 212.581722,8 217,8 C221.418278,8 225,4.418278 225,0 L352,0 C356.418278,-8.11624501e-16 360,3.581722 360,8 L360,16 L360,16 C357.238576,16 355,18.2385763 355,21 C355,23.7614237 357.238576,26 360,26 L360,40 L360,40 C357.238576,40 355,42.2385763 355,45 C355,47.7614237 357.238576,50 360,50 L360,68 L360,68 C357.238576,68 355,70.2385763 355,73 C355,75.7614237 357.238576,78 360,78 L360,82 C360,86.418278 356.418278,90 352,90 L225,90 L225,90 C225,85.581722 221.418278,82 217,82 C212.581722,82 209,85.581722 209,90 L8,90 C3.581722,90 5.41083001e-16,86.418278 0,82 L0,73 L0,73 C2.76142375,73 5,70.7614237 5,68 C5,65.2385763 2.76142375,63 0,63 L0,50 L0,50 C2.76142375,50 5,47.7614237 5,45 C5,42.2385763 2.76142375,40 0,40 L0,26 L0,26 C2.76142375,26 5,23.7614237 5,21 C5,18.2385763 2.76142375,16 0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 L209,0 L209,0 Z" id="path-4"></path>
        <filter x="-3.9%" y="-11.1%" width="107.8%" height="131.1%" filterUnits="objectBoundingBox" id="filter-6">
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0431326486 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <path d="M217,0 L362,0 C366.418278,-8.11624501e-16 370,3.581722 370,8 L370,82 C370,86.418278 366.418278,90 362,90 L217,90 L217,90 L217,0 Z" id="path-7"></path>
        <filter x="-9.2%" y="-11.1%" width="118.3%" height="131.1%" filterUnits="objectBoundingBox" id="filter-8">
          <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0431326486 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g id="Final-Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3.3" transform="translate(-92.000000, -296.000000)">
          <g id="Group-10-Copy-11" transform="translate(40.000000, 101.000000)">
            <g id="Group-56" transform="translate(60.000000, 199.000000)">
              <g id="Group-9">
                <path d="M208.194444,4 C208.194444,8.418278 211.676674,12 215.972222,12 C220.26777,12 223.75,8.418278 223.75,4 L347,4 C351.418278,4 355,7.581722 355,12 L355,20 L355,20 C352.315282,20 350.138889,22.2385763 350.138889,25 C350.138889,27.7614237 352.315282,30 355,30 L355,44 L355,44 C352.315282,44 350.138889,46.2385763 350.138889,49 C350.138889,51.7614237 352.315282,54 355,54 L355,72 L355,72 C352.315282,72 350.138889,74.2385763 350.138889,77 C350.138889,79.7614237 352.315282,82 355,82 L355,86 C355,90.418278 351.418278,94 347,94 L223.75,94 L223.75,94 C223.75,89.581722 220.26777,86 215.972222,86 C211.676674,86 208.194444,89.581722 208.194444,94 L13,94 C8.581722,94 5,90.418278 5,86 L5,77 L5,77 C7.68471753,77 9.86111111,74.7614237 9.86111111,72 C9.86111111,69.2385763 7.68471753,67 5,67 L5,54 L5,54 C7.68471753,54 9.86111111,51.7614237 9.86111111,49 C9.86111111,46.2385763 7.68471753,44 5,44 L5,30 L5,30 C7.68471753,30 9.86111111,27.7614237 9.86111111,25 C9.86111111,22.2385763 7.68471753,20 5,20 L5,12 C5,7.581722 8.581722,4 13,4 L208.194444,4 L208.194444,4 Z" id="Combined-Shape-Copy-3" fill="url(#linearGradient-1)" opacity="0.40234375" filter="url(#filter-2)"></path>
                <g id="Group-54-Copy-5">
                  <g id="Group-53">
                    <g id="Group-49-Copy-8">
                      <mask id="mask-5" fill="white">
                        <use href="#path-4"></use>
                      </mask>
                      <g id="Combined-Shape-Copy-3">
                        <use fill="black" fillOpacity="1" filter="url(#filter-6)" href="#path-4"></use>
                        <use fill="url(#linearGradient-3)" fillRule="evenodd" href="#path-4"></use>
                      </g>
                      <g id="Combined-Shape-Copy-5" opacity="0.11453683" mask="url(#mask-5)">
                        <use fill="black" fillOpacity="1" filter="url(#filter-8)" href="#path-7"></use>
                        <use fill="#000000" fillRule="evenodd" href="#path-7"></use>
                      </g>
                      <g id="Group-6" mask="url(#mask-5)">
                        <g transform="translate(235.000000, 25.000000)">
                          <g id="noun_Cashback_2339770" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000, 13.000000)">
                            <path d="M23.2,0 L0.8,0 C0.32,0 0,0.32 0,0.8 L0,13.6 C0,14.08 0.32,14.4 0.8,14.4 L23.2,14.4 C23.68,14.4 24,14.08 24,13.6 L24,0.8 C24,0.32 23.68,0 23.2,0 Z M18.48,12.8 L5.52,12.8 C5.2,10.8 3.6,9.2 1.6,8.88 L1.6,5.52 C3.6,5.2 5.2,3.6 5.52,1.6 L18.48,1.6 C18.8,3.6 20.4,5.2 22.4,5.52 L22.4,8.88 C20.4,9.2 18.8,10.8 18.48,12.8 Z M22.4,3.92 C21.28,3.6 20.4,2.72 20.08,1.6 L22.4,1.6 L22.4,3.92 Z M3.92,1.6 C3.6,2.72 2.72,3.6 1.6,3.92 L1.6,1.6 L3.92,1.6 Z M1.6,10.48 C2.72,10.8 3.6,11.68 3.92,12.8 L1.6,12.8 L1.6,10.48 Z M20.08,12.8 C20.4,11.68 21.28,10.8 22.4,10.48 L22.4,12.8 L20.08,12.8 Z M16,8 C16,9.76 14.56,11.2 12.8,11.2 L9.6,11.2 C9.12,11.2 8.8,10.88 8.8,10.4 C8.8,9.92 9.12,9.6 9.6,9.6 L12.8,9.6 C13.68,9.6 14.4,8.88 14.4,8 C14.4,7.12 13.68,6.4 12.8,6.4 L11.52,6.4 L11.76,6.64 C12.08,6.96 12.08,7.44 11.76,7.76 C11.76,7.76 11.76,7.76 11.76,7.76 C11.44,8.08 10.96,8.08 10.64,7.76 C10.64,7.76 10.64,7.76 10.64,7.76 L9.04,6.16 C8.96,6.08 8.88,6 8.88,5.92 C8.8,5.76 8.8,5.52 8.88,5.28 C8.88,5.2 8.96,5.12 9.04,5.04 L10.64,3.44 C10.96,3.12 11.44,3.12 11.76,3.44 C12.08,3.76 12.08,4.24 11.76,4.56 L11.52,4.8 L12.8,4.8 C14.56,4.8 16,6.24 16,8 L16,8 Z" id="Shape" fill="#1FC200" fillRule="nonzero"></path>
                          </g>
                          <g id="Group-2-Copy-2" strokeWidth="1" fillRule="evenodd" transform="translate(37.000000, 19.000000)">
                            <g id="Group-2-Copy" transform="translate(0.000000, 4.000000)">
                              <rect id="Rectangle" x="0" y="0" width="12" height="12"></rect>
                              <path d="M8.04654346,11.3333333 L8.04654346,11.2509158 L4.67214237,7.32234432 C5.67145791,7.32234432 6.45174538,7.11630037 7.01300479,6.70421245 C7.5742642,6.29212454 7.89596167,5.67857143 7.97809719,4.86355311 L7.97809719,4.86355311 L9.01848049,4.86355311 L9.33333333,3.77838828 L7.95756331,3.77838828 C7.87999087,3.24267399 7.69518595,2.78937729 7.40314853,2.41849817 L7.40314853,2.41849817 L9.01848049,2.41849817 L9.33333333,1.33333333 L3.00205339,1.33333333 L2.68035592,2.41849817 L4.6036961,2.41849817 C5.72621492,2.45512821 6.39698836,2.90842491 6.61601643,3.77838828 L6.61601643,3.77838828 L2.98151951,3.77838828 L2.66666667,4.86355311 L6.62970568,4.86355311 C6.5338809,5.28479853 6.30800821,5.61790293 5.95208761,5.8628663 C5.59616701,6.10782967 5.13301392,6.23260073 4.56262834,6.23717949 L4.56262834,6.23717949 L2.85831622,6.23717949 L2.86516085,7.09569597 L6.49281314,11.3333333 L8.04654346,11.3333333 Z" id="₹" fill="#FFFFFF" fillRule="nonzero"></path>
                            </g>
                            <text id="200" fontFamily="Helvetica" fontSize="16" fontWeight="normal" fill="#FFFFFF">
                              <tspan x="14" y="15">{props.cashBack}</tspan>
                            </text>
                          </g>
                          <text id="Cashback" fontFamily="Helvetica" fontSize="12" fontWeight="normal">
                            <tspan x="40" y="11" fill="#FFFFFF">Cashback</tspan>
                          </text>
                        </g>
                      </g>
                      <g id="Group-6-Copy-2" mask="url(#mask-5)">
                        <g transform="translate(29.000000, 25.000000)">
                          <g id="noun_Price-Tag_3098112" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000, 10.000000)">
                            <g id="Group" fillRule="nonzero">
                              <path d="M19.6547374,8.24662131 L19.2838121,2.23750674 C19.2294978,1.35775862 18.5281267,0.656373471 17.6483786,0.602073241 L11.63925,0.231147862 C11.6032743,0.22891692 11.5673828,0.227822506 11.5315474,0.227822506 C11.0699393,0.227822506 10.6252806,0.410843793 10.2968132,0.739311149 L0.563080897,10.4730435 C-0.11891331,11.1550377 -0.11891331,12.2607562 0.563080897,12.9427504 L6.9431349,19.3228044 C7.284132,19.6638015 7.73106368,19.8342931 8.17798133,19.8342931 C8.62491301,19.8342931 9.07184471,19.6638015 9.41282777,19.3228044 L19.1465601,9.58907207 C19.500536,9.23509621 19.6855777,8.7462537 19.6547374,8.24662131 Z M18.7401783,9.18269014 L9.00644589,18.9164225 C8.78516189,19.1377065 8.49094437,19.2595804 8.17798133,19.2595804 C7.86503234,19.2595804 7.57081483,19.1377065 7.34951678,18.9164225 L0.969462782,12.5363685 C0.512639195,12.0795449 0.512639195,11.336249 0.969462782,10.8794254 L10.7031951,1.14569303 C10.921266,0.927622138 11.2232006,0.802535149 11.5315755,0.802535149 C11.5556248,0.802535149 11.5796881,0.803264759 11.6038496,0.804766092 L17.6129641,1.17569147 C18.2021148,1.21206 18.6738113,1.68375653 18.7101798,2.27290715 L19.0811192,8.28203577 C19.101801,8.61705561 18.9775278,8.94532653 18.7401783,9.18269014 Z" id="Shape" stroke="#FFB730" fill="#000000"></path>
                              <path d="M11.0349076,14 L11.0349076,13.9423077 L8.50410678,11.1923077 C9.25359343,11.1923077 9.83880903,11.0480769 10.2597536,10.7596154 C10.6806982,10.4711538 10.9219713,10.0416667 10.9835729,9.47115385 L10.9835729,9.47115385 L11.7638604,9.47115385 L12,8.71153846 L10.9681725,8.71153846 C10.9099932,8.33653846 10.7713895,8.01923077 10.5523614,7.75961538 L10.5523614,7.75961538 L11.7638604,7.75961538 L12,7 L7.25154004,7 L7.01026694,7.75961538 L8.45277207,7.75961538 C9.29466119,7.78525641 9.79774127,8.1025641 9.96201232,8.71153846 L9.96201232,8.71153846 L7.23613963,8.71153846 L7,9.47115385 L9.97227926,9.47115385 C9.90041068,9.76602564 9.73100616,9.99919872 9.46406571,10.1706731 C9.19712526,10.3421474 8.84976044,10.4294872 8.42197125,10.4326923 L8.42197125,10.4326923 L7.14373717,10.4326923 L7.14887064,11.0336538 L9.86960986,14 L11.0349076,14 Z" id="₹" fill="#FFB730"></path>
                              <path d="M14.4386321,3.43848604 C13.8537893,4.02349738 13.8537893,4.97550344 14.4386321,5.56051478 C14.7221482,5.84393645 15.0990792,6 15.5,6 C15.9009208,6 16.2778518,5.84393641 16.5613679,5.56051478 C17.1462107,4.97550344 17.1462107,4.02349738 16.5613679,3.43848604 C15.9757981,2.85347467 15.0234749,2.85420142 14.4386321,3.43848604 Z" id="Shape" stroke="#FFB730"></path>
                            </g>
                          </g>
                          <g id="Group-2-Copy-2" strokeWidth="1" fillRule="evenodd" transform="translate(36.000000, 17.000000)">
                            <g id="Group-2-Copy" transform="translate(0.000000, 4.000000)">
                              <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
                              <path d="M13.4109058,18.8888889 L13.4109058,18.7515263 L7.78690395,12.2039072 C9.45242984,12.2039072 10.752909,11.8605006 11.6883413,11.1736874 C12.6237737,10.4868742 13.1599361,9.46428571 13.2968287,8.10592186 L13.2968287,8.10592186 L15.0308008,8.10592186 L15.5555556,6.2973138 L13.2626055,6.2973138 C13.1333181,5.40445665 12.8253099,4.64896215 12.3385809,4.03083028 L12.3385809,4.03083028 L15.0308008,4.03083028 L15.5555556,2.22222222 L5.00342231,2.22222222 L4.46725987,4.03083028 L7.67282683,4.03083028 C9.54369154,4.09188034 10.6616473,4.84737485 11.026694,6.2973138 L11.026694,6.2973138 L4.96919918,6.2973138 L4.44444444,8.10592186 L11.0495095,8.10592186 C10.8898015,8.80799756 10.513347,9.36317155 9.92014602,9.77144383 C9.32694501,10.1797161 8.5550232,10.3876679 7.60438056,10.3952991 L7.60438056,10.3952991 L4.76386037,10.3952991 L4.77526808,11.82616 L10.8213552,18.8888889 L13.4109058,18.8888889 Z" id="₹" fill="#FFFFFF" fillRule="nonzero"></path>
                            </g>
                            <text id="{amount}" fontFamily="Helvetica" fontSize="22" fontWeight="normal" fill="#FFFFFF">
                              <tspan x="21" y="21">{props.amount ? props.amount : 0}</tspan>
                            </text>
                          </g>
                          <text id="Voucher-Amount" fontFamily="Helvetica" fontSize="12" fontWeight="normal">
                            <tspan x="39" y="11" fill="#FFFFFF">Voucher Amount</tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
);


export default VoucherIcon;
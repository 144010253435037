import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";

const AlertDismissible = (props) => {
  useEffect(() => {
    let timer1 = setTimeout(() => {
      props.toggleError(false);
    }, 5000);
    return () => clearTimeout(timer1);
  }, [props.show]);

  return (
    <div>
      {(props.show) &&
        <Alert
          variant={props.type === 'success' ? 'success' : 'danger'}
          onClose={() => props.toggleError(false)}
          dismissible
        >
          {props.errorMessage}
        </Alert>
      }
    </div>
  );
};
export default AlertDismissible;

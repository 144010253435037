import React from 'react'
import { Link } from 'react-router-dom'
import '../../assets/sass/admin/AdminSidebar.scss'

class AdminSidebar extends React.Component {
  state = {
    currentOption: null,

    items: [
      { name: 'Dashboard', nestedOpts: [], url: '/admin/dashboard' },
      // { name: "Settings", nestedOpts: [], url: "/admin/settings" },
      { name: 'Coupon Category', nestedOpts: [], url: '/admin/couponCategory' },
      { name: 'Vendor Brand Configurations', nestedOpts: [], url: '/admin/vendorBrandConfigurations' },
      { name: 'Shop Brands', nestedOpts: [], url: '/admin/brands' },
      { name: 'Redeem Brands', nestedOpts: [], url: '/admin/redeemBrand' },
      { name: 'Coupons', nestedOpts: [], url: '/admin/coupons' },
      { name: 'Upload Coupons', nestedOpts: [], url: '/admin/coupons/logs' },


      // {
      //   name: 'Brand',
      //   nestedOpts: [
      //     { name: 'Brand', url: '/admin/brands' },
      //     { name: 'Brand Coupon', url: '/admin/coupons' },
      //     { name: 'Upload Coupons', url: '/admin/coupons/logs' }

      //   ]
      // },
      // {
      //   name: 'Redeem Brand',
      //   nestedOpts: [
      //     { name: 'Redeem Brand', url: '/admin/redeemBrand' },
      //     { name: 'Redeem Brand Coupon', url: '/admin/redeemBrandCoupon' },
      //     { name: 'Upload Redeem Coupon', url: '/admin/redeemBrandCoupon/upload' }
      //   ]
      // },
      {
        name: 'Promo Code', nestedOpts: [
          { name: 'Promo Code', url: '/admin/promoCode' },
          { name: 'Upload Promo Code', url: '/admin/promoCode/logs' }
        ]
      },
      { name: 'User Listing', nestedOpts: [], url: '/admin/registeredUser' },
      { name: 'Partners', nestedOpts: [], url: '/admin/partners' },
      { name: 'Orders', nestedOpts: [], url: '/admin/orders' },
      // { name: 'UPI Orders', nestedOpts: [], url: '/admin/upiorders' },

      { name: 'Eazydiner Tracks', nestedOpts: [], url: '/admin/easyDine' }

    ]
  }

  onOptionClick = index => {
    const { currentOption } = this.state
    if (currentOption === index) {
      this.setState({ currentOption: null })
    } else {
      this.setState({ currentOption: index })
    }
  }

  render() {
    const { currentOption, items } = this.state

    return (
      <div className="sidebar">
        <div
          className="sidebar-menu"
          style={{ display: `${this.props.display}` }}
        >
          <ul>
            {items.map((item, i) => {
              if (item.nestedOpts.length) {
                return (
                  <li
                    className={currentOption === i ? 'bg-white' : null}
                    onClick={() => this.onOptionClick(i)}
                  >
                    {item.nestedOpts.length ? (
                      <span>
                        {item.name}
                        {!item.nestedOpts.length ? null : currentOption ===
                          i ? (
                          <i className="fas fa-caret-up"></i>
                        ) : (
                          <i className="fas fa-caret-down"></i>
                        )}
                      </span>
                    ) : (
                      <Link>
                        {item.name}
                        {!item.nestedOpts.length ? null : currentOption ===
                          i ? (
                          <i className="fas fa-caret-up"></i>
                        ) : (
                          <i className="fas fa-caret-down"></i>
                        )}
                      </Link>
                    )}

                    {currentOption === i ? (
                      <ul>
                        {items[currentOption].nestedOpts.map(opt => {
                          return <Link to={opt.url}>{opt.name}</Link>
                        })}
                      </ul>
                    ) : null}
                  </li>
                )
              } else {
                if (!item.isStatic) {
                  return (
                    <Link to={item.url} onClick={() => this.onOptionClick(i)}>
                      {item.name}
                    </Link>
                  )
                } else {
                  return (
                    <Link
                      style={{
                        background: '#fff',
                        color: '#333',
                        border: 'none'
                      }}
                      onClick={() => this.onOptionClick(i)}
                    >
                      {item.name}
                    </Link>
                  )
                }
              }
            })}
          </ul>
        </div>
        {this.props.showResponsiveSidebar ? (
          <div
            className="sidebar-menu res"
            style={{ display: `${this.props.display}` }}
          >
            <ul>
              {items.map((item, i) => {
                if (item.nestedOpts.length) {
                  return (
                    <li onClick={() => this.onOptionClick(i)}>
                      {item.nestedOpts.length ? (
                        <span>
                          {item.name}
                          {!item.nestedOpts.length ? null : currentOption ===
                            i ? (
                            <i className="fas fa-caret-up"></i>
                          ) : (
                            <i className="fas fa-caret-down"></i>
                          )}
                        </span>
                      ) : (
                        <Link>
                          {item.name}
                          {!item.nestedOpts.length ? null : currentOption ===
                            i ? (
                            <i className="fas fa-caret-up"></i>
                          ) : (
                            <i className="fas fa-caret-down"></i>
                          )}
                        </Link>
                      )}

                      {currentOption === i ? (
                        <ul>
                          {items[currentOption].nestedOpts.map(opt => {
                            return <Link to={opt.url}>{opt.name}</Link>
                          })}
                        </ul>
                      ) : null}
                    </li>
                  )
                } else {
                  if (!item.isStatic) {
                    return (
                      <Link to={item.url} onClick={() => this.onOptionClick(i)}>
                        {item.name}
                      </Link>
                    )
                  } else {
                    return (
                      <Link
                        style={{
                          background: '#fff',
                          color: '#333',
                          border: 'none'
                        }}
                        onClick={() => this.onOptionClick(i)}
                      >
                        {item.name}
                      </Link>
                    )
                  }
                }
              })}
            </ul>
          </div>
        ) : null}
      </div>
    )
  }
}

export default AdminSidebar

import React from "react";
import ReactDatePicker from "react-datepicker";
const SlabComponent = ({
    HideDelete,
    DeleteAction,

    VoucherNumber,
    PinNumber,
    Amount,
    DATE,

    ComponentIndex,
    ComponentId,

    AllSlabs,
    SetAllSlabs,
    ComponentDisabled
}) => {
    // console.log(AllSlabs)

    const HandleSlabValues = (data) => {
        const { name, value } = data.target;
        // console.log("Getting Name & Values", name, value)


        var NewSlabs = AllSlabs.map(slab => {
            if (slab._id === ComponentId) {
                return {
                    ...slab,
                    [name]: value,
                }
            } else {
                return slab;
            }
        })

        SetAllSlabs(NewSlabs)
    }


    return (
        <div >
            <hr />

            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label>GUID/SKU:</label>
                        <input className="form-control"
                            disabled={ComponentDisabled}
                            name="guid"
                            placeholder="GUID/SKU"
                            value={VoucherNumber}
                            onChange={(e) => HandleSlabValues(e)}
                            required
                        />
                    </div>
                </div>

                <div className="col-md-3" >
                    <div className="form-group">
                        <label>Amount:</label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="amount"
                            placeholder="Amount"
                            value={Amount}
                            onChange={(e) => HandleSlabValues(e)}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-3" >
                    <div className="form-group">
                        <label>Expiry Date:</label>
                        <ReactDatePicker
                            name="expiry_date"
                            className="form-control"
                            selected={DATE}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => HandleSlabValues({
                                target: {
                                    name: "expiry_date",
                                    value: e,
                                }
                            })}
                        />

                        {/* <input type="date" className="form-control"
                            // value={new Date()}
                            onChange={(e) => console.log(e)}
                        /> */}
                    </div>
                </div>
                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Pin:</label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="pin_number"
                            placeholder="Pin"
                            value={PinNumber}
                            onChange={(e) => HandleSlabValues(e)}
                        // required
                        />
                    </div>
                </div>
                {/* {
                    !HideDelete
                    && */}
                <div className="col-md-1" >
                    <div className="form-group">
                        <label>&nbsp;</label>
                        <button className="form-control bg-transparent"
                            disabled={HideDelete}
                            type="button"
                            style={{
                                border: "none",
                                boxShadow: "none",
                                opacity: HideDelete ? .5 : 1
                            }}
                            onClick={DeleteAction}
                        >
                            <i className="fas fa-trash-alt text-danger" />
                        </button>
                    </div>
                </div>
                {/* } */}
            </div>
        </div>
    );
}

export default SlabComponent;
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTelephoneInput from "react-telephone-input";
import { toast } from "react-toastify";
import { logout, post, get } from "../../../utills/API";
import DataTable from "react-data-table-component";
import { GenerateAcronyms } from "./GenerateAcronyms";


//New Bootstrap Table
import paginationFactory, {
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationProvider,
  } from "react-bootstrap-table2-paginator"

  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import { Row, Col, Form } from "reactstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// ------Zing area--------
import "./Partners.scss";


const AddPartners = (props) => {

    const [is_brand_enabled, set_is_brand_enabled] = useState([])
    const [cashBackDetails, setCashBackDetails] = useState([])
    const [partnerDetails, setPartnerDetails] = useState({
        company_name: "",
        contact_name: "",
        email: "",
        country_code: "",
        mobile: "", 
        float_balance: 0,
        is_active: 1,
        service_type: "API",
        cashfloat_description: "",
    })

    const [cashbackForPartner, setCashbackForPartner] = useState("")
    const [allBrands, setAllBrands] = useState([])
    const [filterText, setFilterText] = useState("")

    // New Data Tables Requirements of state
    const [brandAssignToPartnerList, setBrandAssignToPartnerList] = useState([])
    const [partnerId, setPartnerId] = useState(null)
    const [cashBack, setCashBack] = useState(null)
    const [loaderForAssignBrand, setLoaderForAssignBrand] = useState(false)
    const [get_Warning_id , setWarningId] = useState(null)
    const [is_brand_enable , setEnable] = useState(true)
    

    const [mobileError, setMobileError] = useState({});
    const [is_buttonDisabled, setIs_buttonDisabled] = useState(false)
    const [CompanyAcronyms, setCompanyAcronyms] = useState("")

    const FormResponsiveClasses = `
    col-sm-6 col-md-4 col-xs-12 p-1
    `
    const handleCancel = () => props.history.goBack();
    const handleInputChange = (telNumber, selectedCountry) => {
        let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
        let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
        // this.setState({ countryCode: `+${selectedCountry.dialCode}`, mobile });
        setPartnerDetails(e => ({
            ...e,
            country_code: `+${selectedCountry.dialCode}`,
            mobile
        }))
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "company_name") {
            var CompanyAcronyms = GenerateAcronyms(value)
            setCompanyAcronyms(CompanyAcronyms)
        }

        setPartnerDetails(e => ({
            ...e,
            [name]: name === "float_balance" ? parseInt(value) : value,
        }))
    }
    const handleMobileNumberValidation = async () => {
        if (partnerDetails.mobile !== "") {
            if (partnerDetails.mobile.length < 10) {
                return {
                    status: true,
                    message: "Mobile number should be 10 digits."
                };
            } else if (partnerDetails.mobile.length > 10){
                return {
                    status: true,
                    message: "Mobile number should be 10 digits."
                };
            } else {
                setIs_buttonDisabled(true);
                return {
                    status: false,
                    message: ""
                };
            }
        } else {
            return {
                status: true,
                message: "Please enter mobile number."
            };
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const isValidMobileNumber = await handleMobileNumberValidation();
            if (!isValidMobileNumber.status) {
                console.log(partnerDetails)
                const data = await post("partners/client", {
                    ...partnerDetails,
                    cash_float: partnerDetails["float_balance"],
                    cashfloat_description: partnerDetails["cashfloat_description"]
                });
                console.log(data);
                // if(data.status === 200){
                //     try{
                //         const data = await post("/partners/updateBrandPartner", {
                //             ...cashBackDetails
                //         })
                //     } catch (error) {
                //         console.error(error)
                //     }
                // }
                toast.success("Partner added successfully.")
                setIs_buttonDisabled(false)
                setPartnerDetails({
                    company_name: "",
                    contact_name: "",
                    email: "",
                    country_code: "",
                    mobile: "",
                    float_balance: 0,
                    is_active: 1,
                    service_type: "",
                    cashfloat_description: "",
                });
                props.history.goBack();
            } else {
                // console.error("Mobile number cannot be empty.");
                setMobileError(isValidMobileNumber);
            }
        } catch (error) {
            // console.error(error)
            setIs_buttonDisabled(false);
            toast.error("Failed to create parnter!");
        }
    }

    // const fetchAllBrands = async () => {
    //     try {
    //       const { data } = await get("brands");
    //       var brandsData = data.data.brands.map((data, i) => ({ ...data, sr_no: ++i }));
    //       console.log(brandsData)
    //     } catch (e) {
    //       console.log("error", e);
    //     }
    //     setAllBrands(brandsData)
    // }

    // useEffect(() => {
    //     fetchAllBrands()
    // },[])

    // console.log(allBrands)
    
    // const BrandData = !allBrands[0] ? [] : allBrands.map((brand, i) => ({...brand}))
    
    // const columns = [
    //     {
    //       text: "S.No.",
    //       sort: true,
         
    //       dataField: "sr_no",
    //     },
    //     {
    //       text: "Brand Name",
    //       sort: true,
         
    //       dataField: "name",
    //     },
    //     {
    //       text: "Enable/Disable Brand For Partner",
    //       dataField:"is_active",
    //     },
        
    //     {
    //        text:"Cash Back",
    //        dataField:"cash_back"
    //     },
        
    // ]

    // var BrandData =  !allBrands[0] ? [] : allBrands.map((brand, i)  => {
    //     return {
    //         sr_no: parseInt(++i),
    //         name: brand.name,

    //         is_active: (

    //             <div className="col-md-10" style={{marginLeft:'30%'}}>
    //                 <input
    //                     className="form-control"
    //                     style={{width:'20px', textAlign:'center', border:'none', boxShadow:'none'}}
    //                     type="checkbox"
    //                     id={`is_brans_active${brand.id}`}
    //                     // value={brand.is_active}
    //                     // checked={brand.is_active}
    //                     onClick={(e) => {
    //                         if (e.target.checked) {
    //                             warningForCashback(brand.id) 

    //                         } else {
    //                             setBrandAssignToPartnerList(
    //                                 brandAssignToPartnerList.filter(
    //                                     (brand_data) => brand_data.brand_id != brand.id
    //                                 )
    //                             )
    //                             setWarningId(null)
    //                         }
    //                     }}
    //                 />
    //             </div>
    //         ),

    //         cash_back:(

    //             <div className="col-md-8">
    //                 <input
    //                 disabled={get_Warning_id && get_Warning_id === brand.id ? false : true}
    //                 className="form-control"
    //                 type="text"
    //                 id={"example-number-input"}
    //                 placeholder="Enter Cashback"
    //                 defaultValue={0}
    //                 onBlur={(e) => {
    //                     console.log(e)
    //                     brandAssignToPartnerList.push(
    //                         {
    //                             brand_id: brand.id,
    //                             cash_back: parseFloat(cashBack),
    //                             cash_back_type: "percentage"
    //                         }
    //                     )
                            

    //                 }}
    //                 onChange={(e) => {
    //                     setCashBack(e.target.value)
    //                     console.log(e.target.value)
    //                 }}
    //                 />
    //             </div>
    //         ),

         
    //     }
    //   })

    // console.log("brandAssignToPartnerList", brandAssignToPartnerList)

    // const pageOptions = {
    //     sizePerPage: 10,
    //     totalSize: BrandData.length, // replace later with size(customers),
    //     custom: true,
    // }

    // const defaultSorted = [
    //     {
    //       dataField: "id",
    //       order: "asc",
    //     },
    // ]
    
    // const warningForCashback = (id) => {
    //     setWarningId(id)
    // }

    // const statusHandleChange = (e, rowObj) => {
    //     const { name, value } = e.target;
    //     let rowId = rowObj.id;
    //     var arrOfAllEnableObject = cashBackDetails.push({
    //         brand_id: rowId,
    //         cash_back_type: "percentage",
    //         cash_back: 8,
    //         partner_id: 13
    //     })
    // };

    // const onFilter = e => {
    //     setFilterText(e.target.value)
    // };

    // const onClear = () => {
    //     setFilterText("")
    // };

    // const filteredItems = BrandData.filter(item => {
    //         return item.name.toLowerCase().includes(filterText.toLowerCase())
    //     }
    // );

    return (
        <div className="container zing-container" >
            <form onSubmit={handleSubmit} >
                <div className="card zing-card" >
                    <div
                        className="card-header bg-dark text-light zing-card-header"
                    >
                        Add Partner
                    </div>
                    <div className="card-body" >
                        <div className="row" >
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Company Name:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="company_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["company_name"]}
                                    onChange={handleChange}
                                    required
                                />
                                {
                                    CompanyAcronyms
                                    &&
                                    <label className="zing-form-label">
                                        Company Acronyms:
                                        <span className="text-danger ml-2" >
                                            {CompanyAcronyms}
                                        </span>
                                    </label>
                                }
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Contact Name:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="contact_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["contact_name"]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter E-mail:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="email"
                                    type="email"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["email"]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Float Balance:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="float_balance"
                                    type="number"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["float_balance"]}
                                    onChange={handleChange}
                                    min="1"
                                    // max={10}
                                    required
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Add Description:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="cashfloat_description"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["cashfloat_description"]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <div class="form-group zing-form-control">
                                    <label >
                                        Mobile <span>*</span>
                                    </label>
                                    <PhoneInput
                                        disabled={is_buttonDisabled}
                                        onChange={handleInputChange}
                                        country={"in"}
                                        // flagsImagePath="/flags.723494a4.png"
                                        onFocus={() => setMobileError({})}
                                    />
                                    {
                                        mobileError.status && <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >*{mobileError.message}</span>
                                    }
                                </div>
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Service Type:
                                </label>
                                <select className="form-control"
                                    disabled={is_buttonDisabled}
                                    name="service_type"
                                    value={partnerDetails["service_type"]}
                                    onChange={(e) => handleChange(e)}
                                    required>
                                    <option value={""} >Select Type</option>
                                    <option value={"API"}>API</option>
                                    <option value={"B2B"} >B2B</option>
                                </select>
                                {/* <select
                                    disabled={is_buttonDisabled}
                                    name="service_type"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["service_type"]}
                                    onChange={handleChange}
                                >
                                    <option value={"API"}>API</option>
                                    <option value={"B2B"}>B2B</option>
                                </select> */}
                                {/* <input
                                name="service_type"
                                type="text"
                                className="form-control zing-form-control"
                                placeholder=""
                                value={partnerDetails["service_type"]}

                            /> */}
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Status:
                                </label>
                                <div className="onoffswitch">
                                    <input
                                        disabled={is_buttonDisabled}
                                        type="checkbox"
                                        name="isStatus"
                                        checked={partnerDetails["is_active"]}
                                        onClick={(e) => {
                                            setPartnerDetails(e => ({
                                                ...e,
                                                is_active: !partnerDetails["is_active"],
                                            }))
                                        }}
                                        className="onoffswitch-checkbox"
                                        id={`isStatus`}
                                    />
                                    <label className="onoffswitch-label" for="isStatus">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer" >
                        <div className="row" >
                            <div className="col-md-6" >

                            </div>
                            <div className="col-md-3 col-sm-6 p-1" >
                                <Link to="/admin/partners" className={`zing-btn btn-danger text-center ${is_buttonDisabled && "disabled"}`}>
                                    Cancel
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 p-1" >
                                <button
                                    type="submit"
                                    className="zing-btn btn-secondary"
                                    disabled={is_buttonDisabled}
                                // onClick={handleSubmit}
                                >
                                    {
                                        is_buttonDisabled ?
                                            <>
                                                {""}
                                                <Spinner animation="border" size="sm" />
                                            </>
                                            :
                                            "Add"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </form>
            {/* <div className="card zing-card">
                <div
                    className="card-header bg-dark text-light zing-card-header"
                >
                    All Brands
                </div>
                <div className="card-body">
                    <Form>
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={BrandData}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={BrandData}
                            // search
                            >
                            {toolkitProps => (
                                <React.Fragment>
                                <Row>
                                    <Col xl="12">
                                    <div className="table-responsive">
                                        <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        // rowEvents={rowEvents}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                            "table align-middle table-nowrap me-20"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        />
                                    </div>
                                    </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                        />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                        {...paginationProps}
                                        />
                                    </div>
                                    </Col>
                                </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </Form>
                </div>
            </div> */}
        </div>
    );
}

// const FilterComponent = props => {
//     return (
//       <div className="row filterComp">
//         <div className="filterComp-input">
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Filter By Name"
//             value={props.filterText}
//             onChange={props.onFilter}
//           />
//         </div>
  
//         <button onClick={props.onClear}>X</button>
//       </div>
//     );
// };

export default AddPartners;
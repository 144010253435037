import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav,Badge } from "react-bootstrap";
import { urlLinks } from "../../utills/Constants";

class VoucherCategory extends Component {
  render() {
    const { currCategoryId, easyDineVendor, selectedVoucherKey, isredeem } = this.props;

    return (
      <Nav variant="sidebar" activeKey={selectedVoucherKey} className="flex-column ">
        <Nav.Link as={Link} to={isredeem === "redeem" ? ('/voucher/redeem'): '/voucher'} eventKey="" onClick={() => this.props.setCurrCategoryId(null, '', '')} >All Categories</Nav.Link>
        {this.props.categories.map((category) => (
          <Nav.Link as={Link} to={isredeem === "redeem" ? ('/voucher/redeem/'+urlLinks[category.id]):('/voucher/'+urlLinks[category.id])} eventKey={category.name} onClick={() => this.props.setCurrCategoryId(category.id, category.name, urlLinks[category.id])}>
            {category.name}
          </Nav.Link>
        ))}
      </Nav>
    )
  }
}

export default VoucherCategory;

import React, { useEffect, useState } from "react";
import ReactTelephoneInput from "react-telephone-input";
import { GenerateAcronyms } from "./GenerateAcronyms";
import { logout, post, get, put } from "../../../utills/API";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import DataTable from "react-data-table-component";

// ------Zing area--------
import "./Partners.scss";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DECODE_KEY } from "../../../utills/Constants";
import { decrypt, encrypt } from "../../../utills/function";
import CopyToClipboard from "react-copy-to-clipboard";

//Bootstrap Table
import paginationFactory, {
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationProvider,
  } from "react-bootstrap-table2-paginator"

  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
  
    CardTitle,
    Table,
  } from "reactstrap"


const EditPartner = (props) => {

    const [brandAssignToPartnerList, setBrandAssignToPartnerList] = useState([])
    const [brandRemovedFromPartnerList, setBrandRemovedFromPartnerList] = useState([])
    const [isAssign, setIsAssign] = useState(false)
    const [partnerId, setPartnerId] = useState(null)
    const [cashBack, setCashBack] = useState(null)
    const [brandArr, set_brand_Arr] = useState([])
    const [is_brand_enable , setEnable] = useState(true)
    const [loaderForAssignBrand, setLoaderForAssignBrand] = useState(false)
    const [warningMessage ,setWarningMessage] = useState(false)
    const [paginationReset, setPaginationReset] = useState(false)
    const [partnerDetails, setPartnerDetails] = useState({
        company_name: "",
        contact_name: "",
        email: "",
        country_code: "",
        mobile: "",
        float_balance: 0,
        is_active: 1,
        service_type: ""
    })
    const [allBrands, setAllBrands] = useState([])
    const [filterText, setFilterText] = useState("")
    const [get_Warning_id , setWarningId] = useState(null)



    const [is_buttonDisabled, setIs_buttonDisabled] = useState(false)
    const [CompanyAcronyms, setCompanyAcronyms] = useState("")
    const [mobileError, setMobileError] = useState({});
    const [is_btnDisabled, setIs_btnDisabled] = useState(false)
    const [is_updated, setIs_updated] = useState(false)
    const [isCopied, setIsCopied] = useState({
        copy_id: false,
        copy_secret: false,
    })


    const FormResponsiveClasses = `
    col-sm-6 col-md-4 col-xs-12 p-1 mb-2
    `
    const FormResponsiveClasses_sm = `
    col-sm-6 col-md-2 col-xs-12 p-1
    `

    const CopyStyle = {
        cursor: "pointer",
        marginLeft: "6px",
        marginRight: "6px"

    }

    const handleCancel = () => props.history.goBack();
    const handleInputChange = (telNumber, selectedCountry) => {
        let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
        let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
        // this.setState({ countryCode: `+${selectedCountry.dialCode}`, mobile });
        setPartnerDetails(e => ({
            ...e,
            country_code: `+${selectedCountry.dialCode}`,
            mobile
        }))
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "company_name") {
            var CompanyAcronyms = GenerateAcronyms(value)
            setCompanyAcronyms(CompanyAcronyms)
        }
        setPartnerDetails(e => ({
            ...e,
            [name]: name === "float_balance" ? parseInt(value) : value,
        }))
    }

    const handleMobileNumberValidation = async () => {
        if (partnerDetails.mobile !== "") {
            if (partnerDetails.mobile.length < 10) {
                return {
                    status: true,
                    message: "Mobile number should be 10 digits."
                };
            }else if (partnerDetails.mobile.length > 10){   
                return {
                    status: true,
                    message: "Mobile number should be 10 digits."
                };
            } else {
                setIs_buttonDisabled(true);
                return {
                    status: false,
                    message: ""
                };
            }
        } else {
            return {
                status: true,
                message: "Please enter mobile number."
            };
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const isValidMobileNumber = await handleMobileNumberValidation();
            if (!isValidMobileNumber.status) {
                const data = await put(`partners/${props.match.params.partnerId}`, {
                    partner: {
                        ...partnerDetails,
                        status: partnerDetails["is_active"],
                        cash_float: partnerDetails["float_balance"],
                    }
                });
                // console.log(data);
                toast.success("Partner updated successfully.")
                setIs_buttonDisabled(false)
                props.history.goBack();
            } else {
                // console.error("Mobile number cannot be empty.");
                setMobileError(isValidMobileNumber);
            }
        } catch (error) {
            // console.error(error)
            setIs_buttonDisabled(false);
            toast.error("Failed to update parnter!");
        }
    }


    useEffect(() => {
        const fetchPartner = async () => {
            try {
                const data = await get(`partners/${props.match.params.partnerId}`);
                const p_details = data.data.data.partner;
                // console.log("p_details", p_details)

                setPartnerId(p_details["id"])
                setPartnerDetails({
                    ...p_details,
                    company_name: p_details["name"],
                    float_balance: p_details["cash_float"],
                    is_active: p_details["status"],
                })
                var CompanyAcronyms = GenerateAcronyms(p_details["name"])
                setCompanyAcronyms(CompanyAcronyms)

            } catch (error) {
                console.log(error);
            }
        }
        fetchPartner();
    }, [is_updated])

    const handleSecretGenerate = async () => {
        setIs_btnDisabled(true);
        try {
            var date = new Date();
            var dateStr =
                date.getFullYear() +
                ("00" + (date.getMonth() + 1)).slice(-2) +
                ("00" + date.getDate()).slice(-2) +
                ("00" + date.getHours()).slice(-2) +
                ("00" + date.getMinutes()).slice(-2) +
                ("00" + date.getSeconds()).slice(-2);

            var random_digits = parseInt(dateStr);

            const obj = decrypt(decrypt(partnerDetails["client_secret"]))

            const newSecrete = encrypt(encrypt({
                ...obj,
                random_digits
            }))

            const data = await put(`partners/${props.match.params.partnerId}`, {
                partner: {
                    ...partnerDetails,
                    status: partnerDetails["is_active"],
                    cash_float: partnerDetails["float_balance"],
                    client_secret: newSecrete,
                }
            });
            setIs_updated(e => !e);
            setIs_btnDisabled(false);
            toast.success("Secret generated successfully.")

        } catch (err) {
            console.log("error");
        }

    }

    const handleCopy = (data) => {

        setIsCopied(e => ({
            ...e,
            [data]: true,
        }))

        // var copyText = document.getElementById(data);
        // console.log(copyText);
        // copyText.select();
        // // copyText.setSelectionRange(0, 99999); // For mobile devices
        // window.navigator.clipboard.write(copyText.value);
        // //document.execCommand("copy");

    }

    // const addCashBack = (e) => {
    //     setCashBack(e.target.value)
    // }

    const fetchAllBrands = async () => {
        try {
          const { data } = await get(`partners/allBrands/${props.match.params.partnerId}`);
          var brandData = data.data.map((data, i) => ({ ...data, sr_no: parseInt(++i) }));
          brandData.forEach(obj => {
            obj.cash_back_type = "percentage";
            obj.partner_id = parseInt(props.match.params.partnerId+"")
        })
        } catch (e) {
          console.log("error", e);
        }
        setAllBrands(brandData)
    }

    useEffect(() => {
        fetchAllBrands()
    },[])

    useEffect(() => {
        if (allBrands[0]) {
            var activeBrands = allBrands.filter((brand) => brand.is_active == true)
            // console.log("activeBrands", activeBrands)
            setBrandAssignToPartnerList([...activeBrands])
        }
    },[allBrands])

    useEffect(() => {
        if (allBrands[0]) {
            var search_bar = document.querySelector("#search-bar-0")
            search_bar.setAttribute("autoComplete", "off")
            search_bar.addEventListener("focus", () => setPaginationReset(true))
        }
    },[allBrands])

    // console.log(brandAssignToPartnerList)
    const columns = [
        {
          text: "S.No.",
          sort: true,
         
          dataField: "sr_no",
        },
        {
          text: "Brand Name",
          sort: true,
         
          dataField: "name",
        },
        {
          text: "Enable/Disable Brand",
          dataField:"is_active",
        },
        
        {
           text:"Cash Back",
           dataField:"cash_back"
        },
        
    ]

    // console.log(allBrands)
    var BrandData =  !allBrands[0] ? [] : allBrands.map((brand, i)  => {
        return {
            sr_no: brand.sr_no,
            name: brand.name,

            is_active: (

                <div className="col-md-10" style={{paddingLeft:'30%'}} key={brand.brand_id}>
                    <input
                        className="form-control"
                        style={{width:'20px', textAlign:'center', border:'none', boxShadow:'none'}}
                        type="checkbox"
                        id={`is_brans_active${brand.brand_id}`}
                        value={brand.is_active}
                        checked={brand.is_active}
                        onClick={(e) => {
                            // console.log(e.target.checked)
                            setEnable(!brand.is_active)
                            allBrands.filter(object => {
                                if(object.brand_id === brand.brand_id){
                                    {
                                        object.is_active = !object.is_active
                                    }
                                }
                            })
                            // allBrands.forEach(obj => {
                            //     if (obj.brand_id === brand.brand_id) {
                            //         console.log(" This is object",obj.brand_id)
                            //         obj.is_active = !is_brand_enable
                            //     }
                            // })
                            if (e.target.checked) {
                                warningForCashback(brand.brand_id) 
                                // setCashBack(null)
                                var checked_ele_textbox = document.getElementById(`example-number-input${brand.brand_id}`)
                                checked_ele_textbox.disabled = false

                            } else {
                                allBrands.map(obj => {
                                    if (obj.brand_id === brand.brand_id) {
                                        obj.cash_back = parseFloat(cashBack)
                                    }
                                })
                                var brandArr = allBrands.filter((brand) => brand.is_active == true);
                                  
                                // console.log(brandArr)
                                setBrandAssignToPartnerList([...brandArr])
                                // setBrandAssignToPartnerList(
                                //     brandAssignToPartnerList.filter(
                                //         (brand_data) => brand_data.brand_id != brand.brand_id
                                //     )
                                // )
                                var checked_ele_textbox = document.getElementById(`example-number-input${brand.brand_id}`)
                                checked_ele_textbox.disabled = true
                                checked_ele_textbox.value = ''
                                setWarningId(null)
                            }
                        }}
                    />
                </div>
            ),

            cash_back:(

                <div className="col-md-8" key={brand.brand_id}>
                    <input
                    disabled={!brand.is_active}
                    className="form-control"
                    type="number"
                    min={0}
                    // onKeyPress={(e) => {
                    //     handleCash_Back(e)
                    // }}
                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    onInput={(e) => {
                        const re = /[^0-9.]/g
                        if (re.test(e.target.value)) {
                            return e.target.value = null;
                        } else {
                            setCashBack(e.target.value)
                        }
                    }}
                    // value={get_Warning_id === brand.brand_id ? cashBack : brand.cash_back}
                    id={`example-number-input${brand.brand_id}`}
                    placeholder="Enter Cashback"
                    defaultValue={brand.is_active === true ? brand.cash_back : ''}
                    onBlur={(e) => {
                        if ((cashBack !== null && cashBack !== undefined && cashBack !== "") && get_Warning_id === brand.brand_id) {
                            allBrands.map(obj => {
                                if (obj.brand_id === brand.brand_id) {
                                    obj.cash_back = parseFloat(cashBack)
                                    
                                }
                            })
                            var brandArr = allBrands.filter((brand) => brand.is_active == true);
                              
                            // console.log(brandArr)
                            setBrandAssignToPartnerList([...brandArr])
                            setCashBack(null)
                        } else if ((cashBack !== null && cashBack !== undefined && cashBack !== "") && brand.is_active === true) {
                            allBrands.map(obj => {
                                if (obj.brand_id === brand.brand_id) {
                                    obj.cash_back = parseFloat(cashBack)
                                    
                                }
                            })
                            var brandArr = allBrands.filter((brand) => brand.is_active == true);
                              
                            // console.log(brandArr)
                            setBrandAssignToPartnerList([...brandArr])
                            setCashBack(null)
                        }
                            

                    }}
                    onChange={(e) => {
                        // handleCash_Back(e)
                        // setCashBack(e.target.value)
                        if (e.target.value < 0) e.target.value = 0;
                        if (e.target.value > 100) e.target.value = 100;
                    }}
                    />
                </div>
            ),

         
        }
      })
      
    const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: (BrandData).length}
    ];

    const pageOptions = {
        onPageChange: () => setPaginationReset(false),
        totalSize: BrandData.length, // replace later with size(customers),
        custom: true,
        sizePerPageList
    }
    const defaultSorted = [
        {
          dataField: "id",
          order: "asc",
        },
      ]

    // Validation for Cash Back  
    // const handleCash_Back = (e) => {
    //     console.log("Val: ", e.target.value)
    //     const re = /[^0-9.]/g
    //     if (re.test(e.target.value)) {
    //         return e.preventDefault();
    //     } else {
    //         setCashBack(e.target.value)
    //     }
    // };  
    // console.log("cashBack: ", cashBack)
    const { SearchBar } = Search  
    // var brand = [];
    const warningForCashback = (id) => {
        setWarningId(id)
    }
   
    const assignBrandToPartner = async (e) => {
        setLoaderForAssignBrand(true)
        e.preventDefault();
        try {
            // console.log("data before: " ,brandAssignToPartnerList)
            if (brandAssignToPartnerList) {
                
                if (brandAssignToPartnerList[0]) {
                    const enabledBrandForPartner = brandAssignToPartnerList.map(({sr_no, is_active, name, ...rest}) => {
                        return rest;
                    })
                    setBrandAssignToPartnerList([...enabledBrandForPartner])
                }
                // console.log("data after: " ,brandAssignToPartnerList)
                const data = await post("partners/updateBrandPartner", {
                    data : [
                        ...brandAssignToPartnerList
                    ],
                    partner_id: partnerId
                })
                // console.log(data)
                if (data.status == 201){
                    setAllBrands([])
                    setLoaderForAssignBrand(false)
                    if (!brandAssignToPartnerList[0]) {
                        toast.success("Brands unassigned successfully..!")
                    } else {
                        toast.success("Brands assigned successfully..!")
                    }
                    fetchAllBrands()
                    setPaginationReset(true)
                    window.location.reload()
                    // setTimeout(toast.success("Brands assign successfully..!"), 4000)
                }
            }
        } catch (error) {
            console.log(error)
            toast.error("Failed to assign brands..!")
        }
    }

    return (
        <div className="container zing-container" >
            <form onSubmit={handleSubmit} >

                <div className="card zing-card" >
                    <div
                        className="card-header bg-dark text-light zing-card-header"
                    >
                        Edit Partner
                    </div>
                    <div className="card-body" >
                        <div className="row" >
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Company Name:
                                </label>
                                <input
                                    disabled={true}
                                    name="company_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["company_name"]}
                                    onChange={handleChange}
                                />
                                {
                                    CompanyAcronyms
                                    &&
                                    <label className="zing-form-label">
                                        Company Acronyms:
                                        <span className="text-danger ml-2" >
                                            {CompanyAcronyms}
                                        </span>
                                    </label>
                                }
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Contact Name:
                                </label>
                                <input
                                    disabled={is_buttonDisabled}
                                    name="contact_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["contact_name"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter E-mail:
                                </label>
                                <input
                                    disabled={true}
                                    name="email"
                                    type="email"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["email"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Enter Float Balance:
                                </label>
                                <input
                                    disabled={true}
                                    name="float_balance"
                                    type="number"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["float_balance"]}
                                    onChange={handleChange}
                                    min="1"
                                    // max={10}
                                    required
                                />
                            </div>

                            <div className={FormResponsiveClasses} >
                                <div class="form-group zing-form-control">
                                    <label>
                                        Mobile <span>*</span>
                                    </label>
                                    <PhoneInput
                                        disabled={is_buttonDisabled}
                                        onChange={handleInputChange}
                                        country={"in"}
                                        // flagsImagePath="/flags.723494a4.png"
                                        value={partnerDetails["country_code"] + partnerDetails["mobile"]}
                                        onFocus={() => setMobileError({})}
                                    />
                                    {
                                        mobileError.status && <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >*{mobileError.message}</span>
                                    }
                                </div>
                            </div>
                            <div className={FormResponsiveClasses} >
                                <label className="zing-form-label">
                                    Select Service Type:
                                </label>
                                <select className="form-control"
                                    disabled={is_buttonDisabled}
                                    name="service_type"
                                    value={partnerDetails["service_type"]}
                                    onChange={(e) => handleChange(e)}
                                    required>
                                    <option value={""} >Select Type</option>
                                    <option value={"API"}>API</option>
                                    <option value={"B2B"} >B2B</option>
                                </select>
                            </div>
                            <div className={`${FormResponsiveClasses_sm} mt-2`} >
                                <label className="zing-form-label pt-2">
                                    Status:
                                </label>
                                <div className="onoffswitch">
                                    <input
                                        disabled={is_buttonDisabled}
                                        type="checkbox"
                                        name="isStatus"
                                        checked={partnerDetails["is_active"]}
                                        onClick={(e) => {
                                            setPartnerDetails(e => ({
                                                ...e,
                                                is_active: !partnerDetails["is_active"],
                                            }))
                                        }}
                                        className="onoffswitch-checkbox"
                                        id={`isStatus`}
                                    />
                                    <label className="onoffswitch-label" for="isStatus">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>

                            <div className={`${FormResponsiveClasses} mt-2`} >
                                <label className="zing-form-label pt-2">
                                    Client Id:
                                    <CopyToClipboard
                                        text={partnerDetails["client_id"]}
                                        onCopy={() => handleCopy("copy_id")}
                                    >
                                        <i class="far fa-clone"
                                            style={CopyStyle}
                                        />
                                    </CopyToClipboard>
                                    {isCopied["copy_id"] && <span style={{ color: "green" }} >Copied!</span>}
                                </label>
                                <input
                                    id="copy_id"
                                    disabled={true}
                                    name="contact_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails["client_id"]}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className={`${FormResponsiveClasses} mt-2`} >
                                <label className="zing-form-label pt-2">
                                    Client Secret:
                                    <CopyToClipboard
                                        text={partnerDetails.id && decrypt(partnerDetails["client_secret"])}
                                        onCopy={() => handleCopy("copy_secret")}
                                    >
                                        <i class="far fa-clone"
                                            style={CopyStyle}
                                        />
                                    </CopyToClipboard>
                                    {isCopied["copy_secret"] && <span style={{ color: "green" }} >Copied!</span>}
                                </label>
                                <input
                                    id="copy_secret"
                                    disabled={true}
                                    name="contact_name"
                                    type="text"
                                    className="form-control zing-form-control"
                                    placeholder=""
                                    value={partnerDetails.id && decrypt(partnerDetails["client_secret"])}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className={`${FormResponsiveClasses_sm} mt-2`} >
                                <label className="zing-form-label pt-2">
                                    Generate new secret:
                                </label>
                                <div className="">
                                    <button

                                        type="button"
                                        className="zing-btn btn-danger"
                                        disabled={is_btnDisabled}
                                        onClick={handleSecretGenerate}
                                    >
                                        {
                                            is_btnDisabled ?
                                                <>
                                                    {""}
                                                    <Spinner animation="border" size="sm" />
                                                </>
                                                :
                                                "Generate"
                                        }
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card-footer" >
                        <div className="row" >
                            <div className="col-md-6" >

                            </div>
                            <div className="col-md-3 col-sm-6 p-1" >
                                <Link to="/admin/partners" className={`zing-btn btn-danger text-center ${is_buttonDisabled && "disabled"}`}>
                                    Cancel
                                </Link>
                            </div>
                            <div className="col-md-3 col-sm-6 p-1" >
                                <button
                                    type="submit"
                                    className="zing-btn btn-secondary"
                                    disabled={is_buttonDisabled}
                                // onClick={handleSubmit}
                                >
                                    {
                                        is_buttonDisabled ?
                                            <>
                                                {""}
                                                <Spinner animation="border" size="sm" />
                                            </>
                                            :
                                            "Update"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="card zing-card">
                <div
                    className="card-header bg-dark text-light zing-card-header"
                >
                    All Brands
                </div>
                <div className="card-body">
                
                            <Form>

                              <PaginationProvider
                                pagination={
                                    paginationReset ?
                                      paginationFactory({
                                        ...pageOptions,
                                        page: 1
                                      }
                                      ) :
                                    paginationFactory(pageOptions)
                                }
                                keyField="id"
                                columns={columns}
                                data={BrandData}
                              >
                                {({ paginationProps, paginationTableProps }) => (
                                  <ToolkitProvider
                                    keyField="id"
                                    columns={columns}
                                    data={BrandData}
                                    search
                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row>
                                            <Col md="9"></Col>
                                            <Col md="3">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                    <SearchBar
                                                        autoComplete="off"
                                                        style={{ width: "100%", float: "right" }}
                                                        {...toolkitProps.searchProps}
                                                    />
                                                    <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive">
                                              <BootstrapTable
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                // rowEvents={rowEvents}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                // selectRow={selectRow}
                                                classes={
                                                  "table align-middle table-nowrap me-20"
                                                }
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                          <Col className="inner-custom-pagination">
                                            <div className="d-inline">
                                              <SizePerPageDropdownStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                            <div className="float-right ms-auto">
                                              <PaginationListStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                                )}
                              </PaginationProvider>
                            </Form>
                </div>
                <div className="card-footer" >
                        <div className="row" >
                            <div className="col-md-9" >

                            </div>
                            <div className="col-md-3 col-sm-6 p-1" >
                                <button
                                    type="button"
                                    className="zing-btn btn-secondary"
                                    disabled={warningMessage ? true : false}
                                    onClick={assignBrandToPartner}
                                >
                                    {
                                        loaderForAssignBrand ?
                                            <>
                                                {""}
                                                <Spinner animation="border" size="sm" />
                                            </>
                                            :
                                            "Assign Brands"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

// const FilterComponent = props => {
//     return (
//       <div className="row filterComp">
//         <div className="filterComp-input">
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Filter By Name"
//             value={props.filterText}
//             onChange={props.onFilter}
//           />
//         </div>
  
//         <button onClick={props.onClear}>X</button>
//       </div>
//     );
// };

export default EditPartner;
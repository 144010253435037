import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "../../assets/sass/admin/Dashboard.scss";
import {
  required,
  checkPassword,
  validatePassword,
} from "../../utills/Validation";
import { get, post } from "../../utills/API";
import { toast } from "react-toastify";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChangePasswordPopup: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      userId: "",
      errors: {
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
      },
    };
  }

  validateAllInputs = () => {
    const errors = {
      oldPasswordError: null,
      newPasswordError: null,
      confirmPasswordError: null,
    };

    const { oldPassword, newPassword, confirmPassword } = this.state;
    errors.oldPasswordError = required(oldPassword);
    errors.newPasswordError = checkPassword(newPassword);
    errors.confirmPasswordError = validatePassword(
      newPassword,
      confirmPassword
    );
    this.setState({ errors });
  };

  validityCheck = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    return (
      oldPassword &&
      newPassword &&
      confirmPassword &&
      newPassword.match(/^[^-\s]{8,20}$/)
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    try {
      const { data } = await get("users/detail");
      var userId = data.data.user.id;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ userId: userId });
  }

  changePassword = async (e) => {
    e.preventDefault();
    this.validateAllInputs();
    if (this.validityCheck()) {
      const changePasswordData = {
        user: {
          old_password: this.state.oldPassword,
          password: this.state.newPassword,
        },
      };
      try {
        const { data } = await post(
          `users/${this.state.userId}/change_password`,
          changePasswordData
        );
        if (data.status === 200) {
          this.setState({ showChangePasswordPopup: false });
        } else {
          toast.error("Something Went Wrong!", { autoClose: 2000 });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      console.log("Enter valid Details");
    }
  };

  showChangePasswordPopup = () => {
    this.setState({ showChangePasswordPopup: true });
  };

  closeChangePasswordPopup = () => {
    this.setState({ showChangePasswordPopup: false });
  };

  render() {
    const { showChangePasswordPopup } = this.state;
    return (
      <>
        <div className="admin-dashboard">
          <h1 className="title">Dashboard</h1>
          <div className="container">
            <div
              className="item"
              onClick={() => this.props.history.push("/admin/registeredUser")}
            >
              <img
                src={require("../../assets/images/registered-user.png")}
                alt=""
              />
            </div>
            <div onClick={this.showChangePasswordPopup} className="item">
              <img src={require("../../assets/images/change-pwd.png")} alt="" />
            </div>
            <div className="item">
              <img
                src={require("../../assets/images/refund-status.png")}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={require("../../assets/images/payment-report.png")}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={require("../../assets/images/bank-detail.png")}
                alt=""
              />
            </div>
            {/* <div className="item">
              <img src={require("../../assets/images/agent-log.png")} />
            </div> */}
          </div>
        </div>
        <ChangePasswordPopup
          showChangePasswordPopup={showChangePasswordPopup}
          closeChangePasswordPopup={this.closeChangePasswordPopup}
          state={this.state}
          handleChange={this.handleChange}
          changePassword={this.changePassword}
        />
      </>
    );
  }
}

const ChangePasswordPopup = (props) => {
  const { oldPassword, newPassword, confirmPassword } = props.state;
  return (
    <Modal
      className="delete-coupon-modal"
      show={props.showChangePasswordPopup}
      onHide={props.closeChangePasswordPopup}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={props.changePassword}>
          <div className="field">
            <label>Old Password</label>
            <input
              type="password"
              name="oldPassword"
              value={oldPassword}
              placeholder="Enter old password"
              onChange={props.handleChange}
            />
          </div>
          <div className="field">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              placeholder="Enter new password"
              onChange={props.handleChange}
              value={newPassword}
            />
          </div>
          <div className="field">
            <label>Confirm New Password</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm new password"
              onChange={props.handleChange}
              value={confirmPassword}
            />
          </div>
          <button className="submit-pass" type="submit">
            Change Password
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Dashboard;

import React, { Component } from "react";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import cookie from "react-cookies";
import { logout, post } from "../../../utills/API";
import { ToastContainer, toast } from "react-toastify";
import Signup from './signupModal';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
        modalSignUp: true,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
        modalSignUp: true,
      });
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    // this.props.history.push("/login");
  };

  setModalSignUp = (opt) => {
    this.setState({
      modalSignUp: opt,
    });
  };


  render() {
    return (
      <>
       <ToastContainer position={toast.POSITION.TOP_CENTER} />
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <div className="main-box">
        <Signup
          show={this.state.modalSignUp}
          onHide={() => this.setModalSignUp(false)}
        />
          </div>

        <Footer />
      </>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import { get, put, logout } from "../../utills/API";
import cookie from "react-cookies";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import GoNav from "../Common/GoNav";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import ReactTelInput from "react-telephone-input";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import AlertDismissible from "../../components/Common/Alert";
import moment from "moment";
import Loader from "../Loader";
import { encrypt, decrypt } from "../../utills/function";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      isLoading: false,
      isProcess: false,
      showError: false,
      errorMessage: "",
      type: "",
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { data } = await get("users/detail");
      var userInfo = decrypt(data.data.user);
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ userInfo: userInfo, isLoading: false });
  }

  editProfile = async (e) => {
    this.setState({ isProcess: true });

    try {
      const { data } = await put(`users/${this.state.userInfo.id}/profile`, {
        user:  encrypt(this.state.userInfo),
      });
      if (data.status === 200) {
        // this.props.history.push("/profile");
        this.setState({
          showError: true,
          errorMessage: "Profile updated successfully",
          type: "success",
        });
      } else {
        this.setState({
          showError: true,
          errorMessage: "Something Went Wrong!",
          type: "",
        });
      }
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isProcess: false });
  };

  isCurrentUser = () => {
    let isLogin = !!cookie.load("accessToken");
    return isLogin;
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
    this.props.history.push("/");
  };
  
  handleInputChange = (telNumber, selectedCountry) => {
    let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
    let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
    console.log("mobilecNumber", mobilecNumber, mobile);
    this.setState({ userInfo: { ...this.state.userInfo, mobile: mobile } });
  };

  handleChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({
      userInfo: { ...this.state.userInfo, [fieldName]: fleldVal },
    });
  }
  handleDob = (date) => {
    this.setState({ userInfo: { ...this.state.userInfo, dob: date } });
  };
  toggleError = (value) => {
    this.setState({ showError: value });
  };
  render() {
    return (
      <>
      {this.state.isLoading ? <Loader /> : null}
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()}  isLoggedIn={this.state.isLoggedIn}/>
        <section className="page page-profile pt-4">
          <div className="container">
            <div className="flex-xl-nowrap row">
              <div className="col-auto d-none d-lg-flex flex-column">
                <GoNav />
              </div>
              <div className="col">
                <Card className="card-profile d-none d-md-flex d-lg-flex">
                  <Card.Body>
                    <h1 className="text-22 mb-5">My Profile</h1>
                    <AlertDismissible
                      toggleError={this.toggleError}
                      errorMessage={this.state.errorMessage}
                      show={this.state.showError}
                      type={this.state.type}
                    />
                    <Form className="mb-5">
                      <Row>
                        <Col>
                          <Form.Group controlId="fname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              placeholder="First name"
                              type="text"
                              defaultValue={this.state.userInfo.first_name}
                              name="first_name"
                              onChange={this.handleChange.bind(this)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="lname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              placeholder="Last name"
                              type="text"
                              defaultValue={this.state.userInfo.last_name}
                              onChange={this.handleChange.bind(this)}
                              name="last_name"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          placeholder="Enter email"
                          type="text"
                          disabled
                          defaultValue={this.state.userInfo.email}
                          onChange={this.handleChange.bind(this)}
                          name="email"
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group controlId="mobile">
                            <Form.Label>Mobile Number</Form.Label>
                            <ReactTelInput
                              defaultCountry="in"
                              flagsImagePath="/flags.723494a4.png"
                              onChange={this.handleInputChange}
                              name="first_name"
                              disabled
                              value={
                                this.state.userInfo.country_code +
                                this.state.userInfo.mobile
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="dob">
                            <Form.Label>Date of Birth</Form.Label>
                            <DatePicker
                              name="dob"
                              // selected={moment.utc(this.state.userInfo.dob).format()}
                              selected={
                                this.state.userInfo.dob
                                  ? moment(this.state.userInfo.dob).toDate()
                                  : null
                              }
                              onChange={this.handleDob}
                              className="form-control"
                              popperClassName="go-datepicker"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          placeholder="Address"
                          type="text"
                          defaultValue={this.state.userInfo.address}
                          onChange={this.handleChange.bind(this)}
                          name="address"
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                              type="text"
                              defaultValue={this.state.userInfo.city}
                              onChange={this.handleChange.bind(this)}
                              name="city"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              type="text"
                              defaultValue={this.state.userInfo.country}
                              onChange={this.handleChange.bind(this)}
                              name="country"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    <div className="button-group text-center">
                      <Button
                        variant="primary"
                        onClick={(e) => this.editProfile(e)}
                        disabled={this.state.isProcess}
                        className="mr-4"
                      >
                        {this.state.isProcess ? "Saving..." : "Update"}
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.props.history.push("/my-vouchers");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <div className="d-block d-sm-block d-md-none">
                  <h1 className="mb-4">My Profile</h1>
                  <AlertDismissible
                      toggleError={this.toggleError}
                      errorMessage={this.state.errorMessage}
                      show={this.state.showError}
                      type={this.state.type}
                    />
                  <Form className="mb-4">
                    <Form.Group controlId="fname">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        placeholder="First name"
                        type="text"
                        defaultValue={this.state.userInfo.first_name}
                        name="first_name"
                        onChange={this.handleChange.bind(this)}
                      />
                    </Form.Group>
                    <Form.Group controlId="lname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        placeholder="Last name"
                        type="text"
                        defaultValue={this.state.userInfo.last_name}
                        onChange={this.handleChange.bind(this)}
                        name="last_name"
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={this.state.userInfo.email}
                        onChange={this.handleChange.bind(this)}
                        name="email"
                        disabled
                        placeholder="Enter email"
                      />
                    </Form.Group>

                    <Form.Group controlId="mobile">
                      <Form.Label>Mobile Number</Form.Label>
                      <ReactTelInput
                        defaultCountry="in"
                        flagsImagePath="/flags.723494a4.png"
                        onChange={this.handleInputChange}
                        name="first_name"
                        disabled
                        value={
                          this.state.userInfo.country_code +
                          this.state.userInfo.mobile
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="dob">
                      <Form.Label>Date of Birth</Form.Label>
                      <DatePicker
                        name="dob"
                        // selected={moment.utc(this.state.userInfo.dob).format()}
                        selected={
                          this.state.userInfo.dob
                            ? moment(this.state.userInfo.dob).toDate()
                            : null
                        }
                        onChange={this.handleDob}
                        className="form-control"
                        popperClassName="go-datepicker"
                      />
                    </Form.Group>
                    <Form.Group controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        placeholder="Address"
                        type="text"
                        defaultValue={this.state.userInfo.address}
                        onChange={this.handleChange.bind(this)}
                        name="address"
                        style={{ resize: "none" }}
                      />
                    </Form.Group>
                    <Form.Group controlId="state">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={this.state.userInfo.city}
                        onChange={this.handleChange.bind(this)}
                        name="city"
                      />
                    </Form.Group>
                    <Form.Group controlId="country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={this.state.userInfo.country}
                        onChange={this.handleChange.bind(this)}
                        name="country"
                      />
                    </Form.Group>
                  </Form>
                  <div className="button-group text-center">
                    <Button
                      variant="primary"
                      onClick={(e) => this.editProfile(e)}
                      disabled={this.state.isProcess}
                      className="mb-4"
                      block
                    >
                      {this.state.isProcess ? "Saving..." : "Update"}
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => {
                        this.props.history.push("/my-vouchers");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default withRouter(Profile);

import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/Coupon/ShowCoupon.scss";
import { get, Delete } from "../../../utills/API";
import { Link } from "react-router-dom";
import DeleteCoupon from "../Coupon/DeleteCoupon";

class ShowUploadCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "S.No.",
          selector: "sr_no",
          sortable: true
        },
        {
          name: "Brand Name",
          selector: "brand_name",
          sortable: true
        },

        {
          name: "Voucher Number",
          selector: "guid",
          sortable: true,
          width: "140px"
        },
        {
          name: "Amount",
          selector: "amount",
          sortable: true
        },

        // {
        //   name: "View/Edit",
        //   button: true,
        //   cell: row => (
        //     <Link to={row.is_redeem ? `/admin/redeemBrandCoupon/${row.id}/edit` : `/admin/coupons/${row.id}/edit`}>
        //       <i className="fa fa-eye" aria-hidden="true"></i>
        //     </Link>
        //   )
        // },
        // {
        //   name: "Delete",
        //   button: true,
        //   cell: row => (
        //     <a onClick={() => this.showDeleteModal(row.id)}>
        //       <i class="far fa-trash-alt"></i>
        //     </a>
        //   )
        // }
      ],
      filterText: "",
      resetPaginationToggle: false,
      brandCoupons: [],
      showDeleteModal: false,
      brandCouponId: "",
      isLoad: false
    };
  }

  showDeleteModal = id => {
    this.setState({
      showDeleteModal: true,
      brandCouponId: id
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  deleteBrandCoupon = async Id => {
    try {
      const { data } = await Delete(`coupons/${Id}`);
      this.setState({ showDeleteModal: false, isLoad: !this.state.isLoad });
    } catch (e) {
      console.log("error", e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { batch_id } = this.props.match.params;

    if (prevState.isLoad !== this.state.isLoad) {
      try {
        const { data } = await get(`coupons/bulk/logs/${batch_id}`);
        var brandCoupons = data.data.coupons.map((data, i) => ({ ...data, sr_no: ++i }));

        // let updatedBrandCoupons = brandCoupons.map(async coupon => {
        //   let category_name = await this.getCategoryName(coupon.category_id);
        //   let brand_name = await this.getBrandName(coupon.brand_id);
        //   coupon.category_name = category_name;
        //   coupon.brand_name = brand_name;

        //   return coupon;
        // });

        // brandCoupons = await Promise.all(updatedBrandCoupons);
      } catch (e) {
        console.log("error", e);
      }

      this.setState({ brandCoupons });
    }
  }

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;
    return brandName;
  };

  // async componentDidUpdate(prevProps)

  async componentDidMount() {
    const { batch_id } = this.props.match.params;
    try {
      const { data } = await get(`coupons/bulk/logs/${batch_id}`);
      var brandCoupons = data.data.coupons.map((data, i) => ({ ...data, sr_no: ++i }));
      // var brand_name = await this.getBrandName()
      // console.log(brandCoupons)
    } catch (e) {
      console.log("error", e);
    }
    // let updatedBrandCoupons = brandCoupons.map(async coupon => {
    //   let category_name = await this.getCategoryName(coupon.category_id);
    //   let brand_name = await this.getBrandName(coupon.brand_id);
    //   coupon.category_name = category_name;
    //   coupon.brand_name = brand_name;

    //   return coupon;
    // });

    // brandCoupons = await Promise.all(updatedBrandCoupons);
    console.log(batch_id)
    this.setState({ brandCoupons: brandCoupons });
  }

  getCategoryName = async id => {
    const { data } = await get(`categories/${id}`);
    var categoryName = data.data.category.name;
    return categoryName;
  };

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;

    return brandName;
  };

  searchHeader = e => {
    console.log("event", e);
  };

  onFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const { brandCoupons, columns, filterText } = this.state;
    const filteredItems = brandCoupons.filter(
      item =>
        item.guid &&
        item.guid.includes(filterText)
    );
    return (
      <div className="span9 brand-coupon">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Show Brand Coupons</div>

              <div className="muted pull-right">
                <Link
                  className="btn btn-primary delete"
                  to={"/admin/coupons/logs"}
                >
                  Back To Logs
                </Link>
              </div>
            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <FilterComponent
                        onFilter={this.onFilter}
                        onClear={this.onClear}
                        filterText={filterText}
                      />
                    }
                    columns={columns}
                    pagination={true}
                    //persistTableHead
                    data={filteredItems}
                  />
                  <DeleteCoupon
                    state={this.state}
                    showDeleteModal={this.showDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteBrandCoupon={this.deleteBrandCoupon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Voucher Number"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

export default ShowUploadCoupons;

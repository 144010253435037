import React, { Component } from "react";
import '../../assets/sass/admin/RegisteredUser.scss'
import { get, put, download, allUserDownload } from "../../utills/API";
import { downloadXML } from "../../utills/function";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import moment from "moment";
import DownLoadButton from "../Common/DownloadButton";
import DownloadModal from "../Common/DownloadModal";
import { debounce } from '@material-ui/core';
import Loader from "../Loader";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

class RegisteredUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "User Id",
          selector: "id",
          sortable: true,
        },
        {
          name: "Email Id",
          selector: "email",
          sortable: true,
          cell: (row) => <span style={{ width: "750px" }}>{row.email}</span>,
        },
        {
          name: "Name",
          selector: "first_name",
          sortable: true,
          cell: (row) => (
            <span style={{ width: "100px" }}>
              {row.first_name} {row.last_name}
            </span>
          ),
        },
        {
          name: "Mobile No.",
          selector: "mobile",
          sortable: true,
          cell: (row) => (
            <span style={{ width: "150px" }}>
              {row.country_code} {row.mobile}
            </span>
          ),
        },
        {
          name: "Status",
          button: true,
          cell: (row) => {
            return (
              <div className="onoffswitch">
                <input
                  type="checkbox"
                  name="isStatus"
                  checked={row.status === "active" ? true : false}
                  onClick={(e) => this.statusHandleChange(e, row)}
                  className="onoffswitch-checkbox"
                  id={`statusUser-${row.id}`}
                />
                <label
                  className="onoffswitch-label"
                  for={`statusUser-${row.id}`}
                >
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            );
          },
        },
        {
          name: "User Edit",
          button: true,
          cell: (row) => (
            <Link to={`/admin/registeredUser/${row.id}/edit`}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          ),
        },
        {
          name: "Transaction History",
          button: true,
          cell: (row) => (
            <Link to={`/admin/transactionHistory/${row.id}`}>
              <i className="fa fa-history" aria-hidden="true"></i>
            </Link>
          ),
        },
        {
          name: "Created",
          selector: "createdAt",
          sortable: true,
          cell: (row) => (
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          ),
        },
      ],
      userData: [],
      filterText: "",
      isStatus: false,
      isUpdate: false,
      partners: [],
      downLoadModalShow: false,
      isProcess: false,
      selectedPartner: '',
      new_user_register_count: 0,
      offset: 1,
      pageSize:10,
      totalData:100,
      isLoading: false,
      downloadAllUsersReport: false,
    };
  }

  DEBOUNCE_TIME = 300;

  fetchUsers= async(pagIndex, currentRowsPerPage)=>{
    this.setState({ isLoading: true });
    try {
      const { data } = await get(`users?page=${pagIndex-1}&size=${currentRowsPerPage ? currentRowsPerPage :this.state.pageSize}&search_param=${this.state.filterText}`, {
        partner_id: this.state.selectedPartner,
      });
      var userData = data.data.users.rows ? data.data.users.rows : [];
      var totalData = data.data.users.count ? data.data.users.count : []
      
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ userData, offset: pagIndex, totalData, pageSize:currentRowsPerPage ? currentRowsPerPage:this.state.pageSize, isLoading: false});
  }

  getPaginatedData=async(pagIndex)=>{
    this.fetchUsers(pagIndex)
  }

  changeRowsPerPage=(currentRowsPerPage, currentPage)=>{
    this.fetchUsers(currentPage, currentRowsPerPage)
  }

  async componentDidMount() {
    // try {
    //   const { data } = await get("users", {
    //     partner_id: this.state.selectedPartner,
    //   });
    //   var userData = data.data.users;
    // } catch (e) {
    //   console.log("error", e);
    // }
    this.fetchUsers(1);
    try {
      const { data } = await get("users/new_register_users");
      var new_user_register_count = data.data.user_count;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("partners");
      var partners = data.data.partners;
    } catch (e) {
      console.log("error", e);
    }
    this.setState({partners, new_user_register_count });
  }

  toggleDownloadModal = (value) => {
    this.setState({ downLoadModalShow: value });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isUpdate !== this.state.isUpdate) {
      try {
        const { data } = await get("users");
        var userData = data.data.users;
      } catch (e) {
        console.log("error", e);
      }
      this.setState({ userData });
    }
  }

  statusHandleChange = async (e, rowObj) => {
    const { checked } = e.target;
    let rowId = rowObj.id;
    let status = checked ? "active" : "deactive";
    try {
      const { data } = await put(`users/${rowId}/status_update/${status}`);
      this.setState({ isUpdate: !this.state.isUpdate });
    } catch (e) {
      console.log("error", e);
    }
  };

  onGetUsersByPartner = async (e) => {
    e.persist();
    const selectedPartner = e.target.value || null;
    // try {
    //   const { data } = await get("users", {
    //     partner_id: e.target.value === "def" ? null : e.target.value,
    //   });
    //   var userData = data.data.users;
    // } catch (e) {
    //   console.log("error", e);
    // }

    this.setState({
      selectedPartner,
    },()=>{
      this.fetchUsers(1)
    });
  };

  filterDebounce = debounce((value) => {
    this.setState({ filterText: value },()=>{
      this.fetchUsers(1)
    });
  },this.DEBOUNCE_TIME)

  onFilter = (e) => {
    this.setState({ filterText: e.target.value });
    // console.log(typeof(e.target.value))
    // this.filterDebounce(e.target.value)
  };

  onUserDetailsSearch = async () => {
    try {
      if (this.state.filterText === '') {
        this.fetchUsers(1)
      } else {
        this.setState({ isLoading: true });
        const { data } = await get(`users?page=&size=${this.state.totalData}&search_param=${this.state.filterText}&partner_id=`);
        if (data.status === 200) {
          var userData = data.data.users.rows ? data.data.users.rows : [];
          this.setState({ userData, isLoading: false });
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  downloadReport = async (startDate, endDate) => {
    this.setState({
      isProcess: true,
    });
    try {
      const { data } = await download(
        `users/users_download/?startDate=${startDate}&endDate=${endDate}&search_param=${this.state.filterText}`, {
          partner_id: this.state.selectedPartner,
        }
      );
      const fileName = `users_${moment(startDate).format("DD-MM-YYYY")}_${moment(endDate).format("DD-MM-YYYY")}.xlsx`; 
      downloadXML(data, fileName)
    } catch (e) {
      console.log("error", e);
    }
    this.toggleDownloadModal(false)
    this.setState({
      isProcess: false,
    });
  };

  // To Download All User Report
  downloadAllUserReport = async () => {
    this.setState({
      downloadAllUsersReport: true,
    });
    try {
      const { data } = await download(
        `users/allusers_download`
      );
      const fileName = `All_Users_List.xlsx`; 
      downloadXML(data, fileName)
      console.log(data)
      // toast.success("Report downloaded successfully.!")
    } catch (e) {
      console.log("error", e);
      // toast.error("Failed to downloaded report.!")
    }
    this.setState({
      downloadAllUsersReport: false,
    });
  };

  render() {
    const {
      userData,
      columns,
      partners,
      selectedPartner,
      filterText,
      downLoadModalShow,
      isProcess,
      totalData,
      isLoading,
      downloadAllUsersReport
    } = this.state;

    // const filteredItems = userData?.filter((item) =>
    //   item.id.toString().includes(filterText.toLowerCase()) || 
    //   item.email.toLowerCase().includes(filterText.toLowerCase()) ||
    //   item.mobile.toLowerCase().includes(filterText.toLowerCase()) || 
    //   moment(item.createdAt).format("DD-MM-YYYY").includes(filterText.toString()) ||
    //   (item.first_name !== null && item.first_name.toLowerCase().includes(filterText.toLowerCase())) ||
    //   (item.last_name !== null && item.last_name.toLowerCase().includes(filterText.toLowerCase()))
    // );
    return (
      <div class="row-fluid admin-registered-user">
        <div id="content" class="span9">
          <div class="row-fluid">
            <div class="block" id="regUser">
              <div class="navbar navbar-inner block-header">
                <div class="muted pull-left">
                  User List  <b>(New User Register After 16th Dec : {this.state.new_user_register_count})</b>
                </div>
                <div className="muted pull-right">
                  <button 
                    className="download-button p-1 mx-2" 
                    block 
                    onClick={this.downloadAllUserReport}
                    style={{
                      width:'135px'
                    }}
                  >
                    {
                      downloadAllUsersReport ? (
                        <Spinner animation="border" size="sm" />
                    ) : 
                      "All Users Report"
                    }
                  </button>
                  <DownLoadButton click={this.toggleDownloadModal} />
                </div>
              </div>

              <div class="block-content  in">
                <div class="span12">
                {isLoading ? <div class="loader-center"><Loader /></div> : null}

                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <select
                          style={{ background: "#fff", padding: "5px 10px" }}
                          name="selectedPartner"
                          value={selectedPartner}
                          onChange={this.onGetUsersByPartner}
                        >
                          <option value="">Show By Partner Name</option>
                          {partners.map((p) => (
                            <option value={p.id}>{p.name}</option>
                          ))}
                        </select>
                        <FilterComponent
                          onFilter={this.onFilter}
                          onUserDetailsSearch={this.onUserDetailsSearch}
                          filterText={filterText}
                        />

                        <DownloadModal
                          show={downLoadModalShow}
                          onHide={() => this.toggleDownloadModal(false)}
                          headerName={"Users"}
                          downloadReport={this.downloadReport}
                          isProcess={isProcess}
                        />
                      </div>
                    }
                    columns={columns}
                    data={userData}
                    pagination={true}
                    defaultSortField
                    paginationServer={true}
                    highlightOnHover
                    // expandableRows
                    paginationTotalRows={totalData}
                    paginationPerPage={10}
                    onChangePage={this.getPaginatedData}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = (props) => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input" style={{width:'72%'}}>
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name/Email/Mobile"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onUserDetailsSearch} style={{width:'28%'}}>
        Search
      </button>
    </div>
  );
};

export default RegisteredUser;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../assets/scss/components/_rewards_card.scss";
import classNames from "classnames/bind";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Spinner } from "react-bootstrap";
import RewardTerms from "../RewardT&C";

const RewardCard = (props) => {
  const { color, reward, white, plain, className, isProcess } = props;

  const [iscopied, setIscopied] = useState(false);
  const [showRewardTerms, setshowRewardTerms] = useState(false);
  const classes = classNames(
    "reward-card",
    color == "blue" && !reward.color ? "blue-card" : "",
    color == "purple" && !reward.color ? "purple-card" : "",
    color == "red" && !reward.color ? "red-card" : "",
    color == "black" && !reward.color ? "black-card" : "",
    reward.color == "white" ? "white-card" : "",
    reward.color == "plain" ? "plain-card" : "",
    className || ""
  );

  let walletBalance = localStorage.getItem("walletBalance");
  let rewardBalance = localStorage.getItem("rewardBalance");

  const addClassHandler = (id) => {
    // var card = document.querySelector('.reward-card');
    // card.addEventListener('click', function () {
    //   card.classList.add('is-flipped');
    // });
    props.flipCard(id, "white");
  };

  const toggleRewardTC = (value) => {
    setshowRewardTerms(value ? value : !showRewardTerms);
  };

  useEffect(() => {
    if (iscopied) {
      let timer1 = setTimeout(() => {
        setIscopied(false);
      }, 3000);
      return () => clearTimeout(timer1);
    }
  }, [iscopied]);

  return (
    <>
      <RewardTerms
        closeReward={toggleRewardTC}
        show={showRewardTerms}
        reward={reward}
      />
      {color == "blue" && !reward.color ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content">
              <div className="d-flex justify-content-between align-items-center mb-lg-3 mb-0">
                <p className="rewards-heading font-weight-bold mb-0">
                  {reward.brandName}
                </p>
                {reward.brand_image && (
                  <div className="company-logo d-flex align-items-center justify-content-center">
                    <img src={reward.brand_image} alt="Company Logo" />
                  </div>
                )}
              </div>
              {/* <p className="upto-txt font-weight-bold mb-0">Upto</p> */}
              <div className="d-flex align-items-center mb-0 mb-lg-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                >
                  <g transform="translate(-1173 -24)">
                    <g transform="translate(1147 12)">
                      <g transform="translate(0 9)">
                        <g transform="translate(26 1)">
                          <g transform="translate(0 2)">
                            <rect width="14" height="14" fill="none" />
                            <path
                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {reward.amount && (
                  <span className="rupee-txt">{reward.amount}</span>
                )}
              </div>
              {/* <p className="upto-txt font-weight-smbold mb-0">
                Expires in 30 days
              </p> */}
            </div>
            <div
              className="btn-flip text-uppercase flip-class "
              onClick={() => addClassHandler(reward.brand_id)}
            >
              click here to flip
            </div>
          </div>
        </div>
      ) : null}
      {color == "purple" && !reward.color ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content">
              <div className="d-flex justify-content-between align-items-center mb-lg-3 mb-0">
                <p className="rewards-heading font-weight-bold mb-0">
                  {reward.brandName}
                </p>
                {reward.brand_image && (
                  <div className="company-logo d-flex align-items-center justify-content-center">
                    <img src={reward.brand_image} alt="Company Logo" />
                  </div>
                )}
              </div>
              {/* <p className="upto-txt font-weight-bold mb-0">Upto</p> */}
              <div className="d-flex align-items-center mb-0 mb-lg-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                >
                  <g transform="translate(-1173 -24)">
                    <g transform="translate(1147 12)">
                      <g transform="translate(0 9)">
                        <g transform="translate(26 1)">
                          <g transform="translate(0 2)">
                            <rect width="14" height="14" fill="none" />
                            <path
                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {reward.amount && (
                  <span className="rupee-txt">{reward.amount}</span>
                )}
              </div>
              {/* <p className="upto-txt font-weight-smbold mb-0">
                Expires in 30 days
              </p> */}
            </div>
            <div
              className="btn-flip text-uppercase flip-class "
              onClick={() => addClassHandler(reward.brand_id)}
            >
              click here to flip
            </div>
          </div>
        </div>
      ) : null}
      {color == "red" && !reward.color ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content">
              <div className="d-flex justify-content-between align-items-center mb-lg-3 mb-0">
                <p className="rewards-heading font-weight-bold mb-0">
                  {reward.brandName}
                </p>
                {reward.brand_image && (
                  <div className="company-logo d-flex align-items-center justify-content-center">
                    <img src={reward.brand_image} alt="Company Logo" />
                  </div>
                )}
              </div>
              {/* <p className="upto-txt font-weight-bold mb-0">Upto</p> */}
              <div className="d-flex align-items-center mb-0 mb-lg-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                >
                  <g transform="translate(-1173 -24)">
                    <g transform="translate(1147 12)">
                      <g transform="translate(0 9)">
                        <g transform="translate(26 1)">
                          <g transform="translate(0 2)">
                            <rect width="14" height="14" fill="none" />
                            <path
                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {reward.amount && (
                  <span className="rupee-txt">{reward.amount}</span>
                )}
              </div>
              {/* <p className="upto-txt font-weight-smbold mb-0">
                Expires in 30 days
              </p> */}
            </div>
            <div
              className="btn-flip text-uppercase flip-class "
              onClick={() => addClassHandler(reward.brand_id)}
            >
              click here to flip
            </div>
          </div>
        </div>
      ) : null}
      {color == "black" && !reward.color ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content">
              <div className="d-flex justify-content-between align-items-center mb-lg-3 mb-0">
                <p className="rewards-heading font-weight-bold mb-0">
                  {reward.brandName}
                </p>

                {reward.brand_image && (
                  <div className="company-logo d-flex align-items-center justify-content-center">
                    <img src={reward.brand_image} alt="Company Logo" />
                  </div>
                )}
              </div>
              {/* <p className="upto-txt font-weight-bold mb-0">Upto</p> */}
              <div className="d-flex align-items-center mb-0 mb-lg-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                >
                  <g transform="translate(-1173 -24)">
                    <g transform="translate(1147 12)">
                      <g transform="translate(0 9)">
                        <g transform="translate(26 1)">
                          <g transform="translate(0 2)">
                            <rect width="14" height="14" fill="none" />
                            <path
                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {reward.amount && (
                  <span className="rupee-txt">{reward.amount}</span>
                )}
              </div>
              {/* <p className="upto-txt font-weight-smbold mb-0">
                Expires in 30 days
              </p> */}
            </div>
            <div
              className="btn-flip text-uppercase flip-class "
              onClick={() => addClassHandler(reward.brand_id)}
            >
              click here to flip
            </div>
          </div>
        </div>
      ) : null}

      {reward.color == "white" ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content text-center d-flex justify-content-between flex-column">
              {/*{reward.brand_image && (*/}
              <div className="d-flex justify-content-between align-items-center">
                {reward.brand_image && (
                  <div className="company-logo d-inline-flex">
                    <img src={reward.brand_image} alt="Company Logo" />
                  </div>
                )}
                <p
                  className="mb-0 term-condition-txt"
                  onClick={() => toggleRewardTC(true)}
                >
                  T&Cs
                </p>
              </div>
              {/*)}*/}
              <p className="rewards-heading-burn mb-sm-1 mb-0">Burn</p>
              <p className="rewards-heading mb-sm-2 mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                >
                  <g transform="translate(-1173 -24)">
                    <g transform="translate(1147 12)">
                      <g transform="translate(0 9)">
                        <g transform="translate(26 1)">
                          <g transform="translate(0 2)">
                            <rect width="14" height="14" fill="none" />
                            <path
                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                              fill="#333"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                {reward.amount}
              </p>
              <Button
                variant="primary"
                className="font-weight-smbold mb-0 p-1 p-sm-2"
                onClick={() => props.redeemAmount(reward)}
                disabled={
                  isProcess ||
                  Number(walletBalance) + Number(rewardBalance) <
                    reward.amount
                }
              >
                {isProcess && <Spinner animation="border" size="sm" />}
                Get it
              </Button>
              {/*<p className="txt font-weight-smbold mb-0">
                The amount has been added to your Reward Discount!
      </p>*/}
            </div>
          </div>
        </div>
      ) : null}

      {reward.color == "plain" ? (
        <div className="rewards-box">
          <div className={classes}>
            <div className="content text-center d-flex flex-column justify-content-between align-items-center">
              <p className="txt font-weight-smbold mb-0">
               {!reward.pin ? ' Rejoice!' :'Rejoice! View your PIN by clicking on the Redeem details in Transaction History or in confirmation email/SMS'}
                
              </p>
              <div
                className={
                  iscopied
                    ? "box-copy-code highlight font-weight-smbold w-100"
                    : "box-copy-code font-weight-smbold w-100"
                }
              >
                {reward.guid ? reward.guid : ""}
                <CopyToClipboard
                  text={reward.guid ? reward.guid : ""}
                  onCopy={() => {
                    setIscopied(true);
                  }}
                >
                  <i className="icon-copy ml-2" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RewardCard;

RewardCard.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  blue: PropTypes.bool,
  purple: PropTypes.bool,
  red: PropTypes.bool,
  black: PropTypes.bool,
  white: PropTypes.bool,
  plain: PropTypes.bool,
};

RewardCard.defaultProps = {
  blue: false,
  purple: false,
  red: false,
  black: false,
  white: false,
  plain: false,
  className: "",
};

import React from "react";
import { Modal } from "react-bootstrap";
// import "../../../assets/sass/admin/Category/DeleteCategory.scss";

const DeleteCategory = props => {
  return (
    <Modal
      show={props.state.showDeleteModal}
      onHide={props.closeDeleteModal}
      className="delete-category-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Brand</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure, you want to delete brand?</Modal.Body>
      <Modal.Footer>
        <div className="btn-container">
          <button
            className="btn btn-primary delete"
            onClick={() => props.deleteCategory(props.state.redeemBrandId)}
          >
            Delete
          </button>
          <button
            className="btn btn-primary delete"
            onClick={props.closeDeleteModal}
          >
            Cancel
          </button>
        </div>{" "}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCategory;

import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  InputGroup,
  Form,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import renderHTML from "react-render-html";

import voucher from "../../assets/images/gainon/voucher-icon.svg";
import shop from "../../assets/images/gainon/shop-icon.svg";
import cash from "../../assets/images/gainon/cash-icon.svg";

function BrandStep(props) {
  const [key, setKey] = useState("home");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      variant="gotabs"
    >
      <Tab eventKey="home" title={ props.isRedeem ? 'How to Redeem' : 'How to Pay'}>
        {props.brandData &&
        ( props.brandData.step1Text ||
          props.brandData.step2Text ||
          props.brandData.step3Text ||
          props.brandData.step4Text) ? (
          <div>
            {(props.brandData.step1Image || props.brandData.step1Text) && (
              <Card className="card-redeem card-brand-type">
                <div className="row no-gutters">
                  <div className="col-4">
                    <img
                      className="redeem-icon-img"
                      src={
                        props.brandData.step1Image
                          ? props.brandData.step1Image
                          : ""
                      }
                      alt="Redeem step 1"
                    />
                  </div>
                  <div className="col-8">
                    <Card.Body className="d-flex align-items-start flex-column">
                      <h4>Step 1</h4>
                      <p className="font-weight-normal">
                        {props.brandData.step1Text
                          ? props.brandData.step1Text
                          : ""}
                      </p>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            )}
            {(props.brandData.step2Image || props.brandData.step2Text) && (
              <Card className="card-redeem card-brand-type">
                <div className="row no-gutters">
                  <div className="col-4">
                    <img
                      className="redeem-icon-img"
                      src={
                        props.brandData.step2Image
                          ? props.brandData.step2Image
                          : ""
                      }
                      alt="Redeem step 2"
                    />
                  </div>
                  <div className="col-8">
                    <Card.Body className="d-flex align-items-start flex-column">
                      <h4>Step 2</h4>
                      <p className="font-weight-normal">
                        {props.brandData.step2Text
                          ? props.brandData.step2Text
                          : ""}
                      </p>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            )}
            {(props.brandData.step3Image || props.brandData.step3Text) && (
              <Card className="card-redeem card-brand-type">
                <div className="row no-gutters">
                  <div className="col-4">
                    <img
                      className="redeem-icon-img"
                      src={
                        props.brandData.step3Image
                          ? props.brandData.step3Image
                          : ""
                      }
                      alt="Redeem step 3"
                    />
                  </div>
                  <div className="col-8">
                    <Card.Body className="d-flex align-items-start flex-column">
                      <h4>Step 3</h4>
                      <p className="font-weight-normal">
                        {props.brandData.step3Text
                          ? props.brandData.step3Text
                          : ""}
                      </p>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            )}
            {(props.brandData.step4Image || props.brandData.step4Text) && (
              <Card className="card-redeem card-brand-type">
                <div className="row no-gutters">
                  <div className="col-4">
                    <img
                      className="redeem-icon-img"
                      src={
                        props.brandData.step4Image
                          ? props.brandData.step4Image
                          : ""
                      }
                      alt="Redeem step 4"
                    />
                  </div>
                  <div className="col-8">
                    <Card.Body className="d-flex align-items-start flex-column">
                      <h4>Step 4</h4>
                      <p className="font-weight-normal">
                        {props.brandData.step4Text
                          ? props.brandData.step4Text
                          : ""}
                      </p>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            )}
          </div>
        ) : (
          <div>
            <Card className="card-shop card-brand-type">
              <Card.Body>
                <div className="media">
                  <img className="icon-img" src={voucher} alt="Voucher icon" />
                  <div className="media-body">
                    <h4>Step 1</h4>
                    <p>
                      Visit the outlet/store nearby you that accepts the
                      e-voucher.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-shop card-brand-type">
              <Card.Body>
                <div className="media">
                  <img className="icon-img" src={shop} alt="shop icon" />
                  <div className="media-body">
                    <h4>Step 2</h4>
                    <p>Shop as usual</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-shop card-brand-type">
              <Card.Body className="d-flex align-items-start">
                <div className="media">
                  <img className="icon-img" src={cash} alt="Cash icon" />
                  <div className="media-body">
                    <h4>Step 3</h4>
                    <p>
                      Share your e-voucher at the time of billing & if required,
                      pay any remaining amount by cash or card.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </Tab>
      <Tab eventKey="profile" title="Description">
        <Card className="card-brand-type mt-3">
          <Card.Body>
            <p className="font-weight-normal">
              {renderHTML(
                `${
                  props.brandData && props.brandData.about
                    ? props.brandData.about
                    : ""
                }`
              )}
            </p>
          </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="contact" title="Terms & Condition">
        <Card className="card-brand-type mt-3">
          <Card.Body>
            {renderHTML(
              `${
                props.brandData && props.brandData.instruction
                  ? props.brandData.instruction
                  : ""
              }`
            )}
          </Card.Body>
        </Card>
      </Tab>
    </Tabs>
  );
}

export default BrandStep;

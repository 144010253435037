import React, { Component } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
// import "../../assets/sass/redeemBrand.scss";
import { get, logout, post } from "../../utills/API";
// import renderHTML from "react-render-html";
import cookie from "react-cookies";
import { toast } from "react-toastify";
// import Loader from "../Loader";
// import "../../assets/sass/newDesign/brand.scss";
// import SearchBrand from "./Dashboard/SearchBrand";
import { Row, Col, Card, Button, Form, Alert, Spinner } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import voucherIcon from "../../assets/img/arrow-icon.png";
// import HowToRedeem from "./Dashboard/HowToRedeem";
import TabHowTo from "../User/TabHowTo";
import redeemicon from "../../assets/images/gainon/redeem-brand-icon.svg";
import compeletedicon from "../../assets/images/gainon/completed-icon.svg";
import BrandStep from "./BrandStep";
import brandalert from "../../assets/images/gainon/alert-icon.svg";
import AlertDismissible from "../Common/Alert";
import { encrypt, decrypt } from "../../utills/function";

class RedeemBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: {},
      brandData: {},
      coupons: [],
      checkoutData: {},
      selectCouponId: null,
      redeemBrandId: null,
      isDisable: false,
      isLoading: false,
      isLoader: false,
      brandName: "",
      totalAmount: 0,
      errorMessage: "",
      showError: false,
      onlyLogin: false,
      isLoggedIn: false,
      isProcess: false,
    };
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  userDetail = (detail) => {
    this.setState({ userDetail: detail });
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
  };

  async componentDidMount() {
    this.setState({ isLoader: true });
    const { redeemBrandId } = this.props.match.params;
    if (redeemBrandId) {
      try {
        const { data } = await get(`ngp/brands/${redeemBrandId}`);
        let decBrandsData = decrypt(data.data.brand)
        var brandData = decBrandsData;
        var brandName = brandData.name;
        var coupons = this.getUnqueCoupons(brandData.coupons);
        console.log("coupons", coupons);
      } catch (e) {
        console.log("error", e);
      }
    }

    this.setState({
      brandData,
      coupons: coupons ? coupons : [],
      redeemBrandId,
      brandName,
      isLoader: false,
      totalAmount:
        Number(localStorage.getItem("walletBalance"))
        //  + Number(localStorage.getItem("rewardBalance")),
    });
  }

  getUnqueCoupons = (reviewData) => {
    let newArray = [];
    let newObject = {};
    for (let i in reviewData) {
      let objTitle = reviewData[i]["amount"];
      newObject[objTitle] = reviewData[i];
    }
    for (let i in newObject) {
      newArray.push(newObject[i]);
    }
    return newArray;
  };

  checkout = async (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();

    const orders = {
      order: {
        coupons: [this.state.checkoutData],
        brand_id: Number(this.state.redeemBrandId),
        total_amount: this.state.checkoutData.amount,
      },
    };
    this.setState({ isProcess: true });
    console.log('this.state.checkoutData', this.state.checkoutData)

    if (Object.keys(this.state.checkoutData).length !== 0) {
      if (this.isCurrentUser()) {
        try {
          const { data } = await post("orders/redeem_wallet", {order: encrypt(orders.order)});
          console.log(orders.order)
          this.setState({ isDisable: true });
          if (data.status === 200) {
            let decRedeemOrder = decrypt(data.data)
            this.props.history.push(`/orders/${decRedeemOrder.order_id}`);
          } else if (data.status === 422) {
            this.setState({ isProcess: false });
            // if(Number(localStorage.getItem("walletBalance") || 0) < 250){
            //   this.setState({
            //     errorMessage:
            //       "A minimum wallet balance of Rs. 250 is required before you can redeem.",
            //     showError: true,
            //   });
            // }else{
              this.setState({
                errorMessage:
                  data.errors,
                showError: true,
              });
            // }
            this.setState({ isDisable: false });
          }
        } catch (e) {
          this.setState({ isProcess: false });
          console.log("error", e);
        }
      } else {
        localStorage.setItem("checkoutData", JSON.stringify(orders));
        localStorage.setItem("checkoutPath", this.props.match.url);
        // this.props.history.push("/login");
        
        this.changeOnlyLogin(true)
      }
    } else {
      this.setState({
        errorMessage: "Please Select Coupon",
        showError: true,
        isProcess: false
      });
    }
    this.setState({ isLoading: false });
  };

  changeOnlyLogin=(value)=>{
    this.setState({
      onlyLogin: value,
    });
  }

  handleRadio = (eachCoupoun) => {

    let checkoutData = {
      id: eachCoupoun.id,
      amount: eachCoupoun.amount,
      quantity: 1,
    };

    console.log('this.state.checkoutData', eachCoupoun)

    this.setState({ checkoutData, selectCouponId: eachCoupoun.id });
  };

  blur = () => {
    this.setState({ checkoutData: {} });
  };

  selectBrand = async (brandId, brandData, brandName, coupons) => {
    this.setState({
      redeemBrandId: brandId,
      brandData,
      brandName,
      coupons: coupons ? coupons : [],
    });
  };

  toggleError = () => {
    this.setState({
      errorMessage: "",
      showError: false,
    });
  };

  render() {
    const {
      brandData,
      coupons,
      selectCouponId,
      isLoader,
      brandName,
      totalAmount,
    } = this.state;
    return (
      <>
           <Header
          logout={this.logout}
          isCurrentUser={this.isCurrentUser()}
          onlyLogin={this.state.onlyLogin}
          isLoggedIn={this.state.isLoggedIn}
          changeOnlyLogin={this.changeOnlyLogin}
        />
        
        <section className="page page-redeem page-brand">
          <div className="container">
            <Row>
              <Col lg={5} md={12}>
                <Card className="card-brand">
                  <Card.Body>
                    <div className="redeem-content d-flex justify-content-between">
                      <div className="redeem-logo-wrap d-flex align-items-center justify-content-center">
                        <div className="redeem-image-box d-flex align-items-center justify-content-center">
                          <img
                            src={brandData.image}
                            alt="redeem logo"
                          />
                        </div>
                        <span>{brandName}</span>
                      </div>
                      <div className="balance-blue-box d-flex align-items-center">
                        <span className="balance-label text-nowrap">Available Balance :</span>
                        <div className="d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g transform="translate(-1173 -24)">
                              <g transform="translate(1147 12)">
                                <g transform="translate(0 9)">
                                  <g transform="translate(26 1)">
                                    <g transform="translate(0 2)">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="none"
                                      />
                                      <path
                                        d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                        fill="#333"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <span>{this.state.brandData.wallet_cashback == false ? Number(localStorage.getItem("walletBalance") || 0) : totalAmount}</span>
                        </div>
                      </div>
                    </div>
                   { this.state.showError && <Alert className="brand-alert">
                          <div className="media">
                            <img
                              src={brandalert}
                              className="mr-2"
                              alt="brand alert icon"
                            />
                            <div className="media-body">
                              <p>
                                {this.state.errorMessage}
                              </p>
                            </div>
                          </div>
                          </Alert>}
                    <p>Select amount to redeem</p>

                    <div className="redeem-coupons-box d-flex flex-wrap">
                      {coupons.map((eachCoupoun) => {
                        return (
                          <Form.Check className="redeem-balance-value mb-3">
                            <Form.Check.Input
                              type="radio"
                              name="exampleRadios"
                              id={`formHorizontalRadios${eachCoupoun.amount}`}
                              onClick={()=>this.handleRadio(eachCoupoun)}
                            />
                            <Form.Check.Label
                              className="form-check-label d-flex align-items-center justify-content-between"
                              for={`formHorizontalRadios${eachCoupoun.amount}`}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 14 14"
                                >
                                  <g transform="translate(-1173 -24)">
                                    <g transform="translate(1147 12)">
                                      <g transform="translate(0 9)">
                                        <g transform="translate(26 1)">
                                          <g transform="translate(0 2)">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="none"
                                            />
                                            <path
                                              d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                              fill="#333"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                <span>{eachCoupoun.amount}</span>
                              </div>
                              <img src={compeletedicon} alt="Completed icon" />
                            </Form.Check.Label>
                          </Form.Check>
                        );
                      })}
                    </div>
                    <div className="pay-wrap d-flex align-items-center flex-column">
                      
                      <Button variant="primary" onClick={this.checkout} disable={this.state.isProcess}>
                      {this.state.isProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : null}
                        Redeem
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={7} md={12} className="brand-gotabs">
                <BrandStep brandData={this.state.brandData} isRedeem={true}/>
              </Col>
            </Row>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default RedeemBrand;

import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { required } from "../utills/Validation";
import { withRouter } from "react-router-dom";
import AlertDismissible from "../components/Common/Alert";
import { EMAIL_REGEX, MAILCHIMP_ACTION_URL } from "../utills/Constants";
import "../assets/sass/newDesign/subscriber-modal.scss";
import { jsonpRequest } from '../utills/API';

class MSubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {
        emailError: null
      },
      isProcess: false,
      showError: false,
      errorMessage: "",
    };
  }

  validateAllInputs = () => {
    const errors = {
      emailError: null
    };
    const { email } = this.state;
    errors.emailError = required(email);
    this.setState({ errors });
  };

  toggleError = (value) => {
    this.setState({ showError: value });
  };

  redirectUrl = (url) => {
    this.props.history.push(url);
  };

  validityCheck = () => {
    const { email } = this.state;
    return (
      email &&
      email.match(EMAIL_REGEX)
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  subscribe = async (e) => {
    // debugger
    e.preventDefault();
    this.setState({ isProcess: true });
    this.validateAllInputs();
    if (this.validityCheck()) {
      const { email } = this.state;
      const params = {
         u: 'ec78f533681191e9559ef92c9',
         id: '4fa4d5e277',
         EMAIL: email
      };
      await jsonpRequest(params);
      this.props.onHide()
    } else {
      console.log("Enter valid Details");
    }
    this.setState({ isProcess: false });
  };

  render() {
    const { props } = this;
    const { email, errors } = this.state;
    return (
      <>
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="subscribe-modal"
        >
          <form onSubmit={this.subscribe}>
            <Modal.Header
              className="pb-0 border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="pt-0">
              <AlertDismissible
                toggleError={this.toggleError}
                errorMessage={this.state.errorMessage}
                show={this.state.showError}
              />
              <div className="subscribe-banner">
                <div className="subscribe-input-box">
                  <div class="form-group">
                    <input
                      placeholder="Email ID"
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.handleChange}
                      class="form-control"
                    />
                    {errors.emailError ? (
                      <div className="error-warning">{errors.emailError}</div>
                    ) : null}
                  </div>
                  <div className="button-box">
                    <Button
                      variant="primary"
                      block
                      type="submit"
                      disabled={this.state.isProcess}
                    >
                      {this.state.isProcess ? (
                        <Spinner animation="border" size="sm" />
                      ) : null}
                      Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </form>
        </Modal>
      </>
    );
  }
}

export default withRouter(MSubscribeForm);

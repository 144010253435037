import React from "react";
import { Modal } from "react-bootstrap";
import thankyouImg from "../assets/images/gainon/thankyou-img.svg";

const SuccessSignup = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="go-modal"
      >
        <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={thankyouImg} alt="Thankyou-img" />
            <h1 className="font-weight-bold text-22 mb-2 mt-3">
              Sign up completed!
            </h1>
            <p className="mb-5">You will be auto-directed to our shop page.</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessSignup;

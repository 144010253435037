export const excelData = [
    {
        "category_id": 11,
        "title": "test",
        "brand_id": 36,
        "pin_number": 123,
        "guid": 11111,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1660262400,
        "end_date": 1669852800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 36,
        "pin_number": 456,
        "guid": 22222,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1660348800,
        "end_date": 1669939200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 36,
        "pin_number": 678,
        "guid": 33333,
        "amount": 1500,
        "is_active": "TRUE",
        "start_date": 1660435200,
        "end_date": 1670025600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 36,
        "pin_number": 987,
        "guid": 55555,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1660521600,
        "end_date": 1670112000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 36,
        "pin_number": 654,
        "guid": 77777,
        "amount": 3000,
        "is_active": "TRUE",
        "start_date": 1660608000,
        "end_date": 1670198400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 136,
        "pin_number": 767676,
        "guid": 74748,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1660694400,
        "end_date": 1670284800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 136,
        "pin_number": 3645,
        "guid": 74646,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1660780800,
        "end_date": 1670371200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 136,
        "pin_number": 3738,
        "guid": 373883,
        "amount": 1500,
        "is_active": "TRUE",
        "start_date": 1660867200,
        "end_date": 1670457600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 136,
        "pin_number": 83483,
        "guid": 992393,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1660953600,
        "end_date": 1670544000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 54,
        "pin_number": 37884,
        "guid": 657478,
        "amount": 100,
        "is_active": "TRUE",
        "start_date": 1661126400,
        "end_date": 1670716800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 54,
        "guid": 7838939,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1661212800,
        "end_date": 1670803200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 54,
        "pin_number": 5567,
        "guid": 7678676,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1661299200,
        "end_date": 1670889600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 54,
        "pin_number": 82829,
        "guid": 767676,
        "amount": 1500,
        "is_active": "TRUE",
        "start_date": 1661385600,
        "end_date": 1670976000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 54,
        "pin_number": 664,
        "guid": 564566786,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1661472000,
        "end_date": 1671062400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 54,
        "pin_number": 63737,
        "guid": 7657657,
        "amount": 3000,
        "is_active": "TRUE",
        "start_date": 1661558400,
        "end_date": 1671148800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 138,
        "pin_number": 89737,
        "guid": 7844,
        "amount": 200,
        "is_active": "TRUE",
        "start_date": 1661644800,
        "end_date": 1671235200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 138,
        "pin_number": 767678,
        "guid": 4763473,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1661731200,
        "end_date": 1671321600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 138,
        "pin_number": 34344,
        "guid": 82392309,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1661817600,
        "end_date": 1671408000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 138,
        "pin_number": 3223,
        "guid": 836376,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1661904000,
        "end_date": 1671494400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 138,
        "pin_number": 23334,
        "guid": 763764,
        "amount": 3000,
        "is_active": "TRUE",
        "start_date": 1661990400,
        "end_date": 1671580800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 27,
        "pin_number": 8634586,
        "guid": 78678,
        "amount": 100,
        "is_active": "TRUE",
        "start_date": 1662076800,
        "end_date": 1671667200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 27,
        "guid": 7634788,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1662163200,
        "end_date": 1671753600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 27,
        "pin_number": 45556,
        "guid": 783487,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1662249600,
        "end_date": 1671840000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 27,
        "pin_number": 6543,
        "guid": 8677,
        "amount": 1500,
        "is_active": "TRUE",
        "start_date": 1662336000,
        "end_date": 1671926400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 27,
        "pin_number": 763476,
        "guid": 784576,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1662422400,
        "end_date": 1672012800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 27,
        "pin_number": 766476,
        "guid": 8923090,
        "amount": 3000,
        "is_active": "TRUE",
        "start_date": 1662508800,
        "end_date": 1672099200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 136,
        "pin_number": 5647634,
        "guid": 8787,
        "amount": 100,
        "is_active": "TRUE",
        "start_date": 1662595200,
        "end_date": 1672185600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 136,
        "pin_number": 7834893,
        "guid": 66556,
        "amount": 200,
        "is_active": "TRUE",
        "start_date": 1662681600,
        "end_date": 1672272000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 136,
        "pin_number": 56347678,
        "guid": 54554,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1662768000,
        "end_date": 1672358400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 136,
        "guid": 8778797,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1662854400,
        "end_date": 1672444800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 136,
        "pin_number": 33344,
        "guid": 78678687,
        "amount": 4000,
        "is_active": "TRUE",
        "start_date": 1662940800,
        "end_date": 1672531200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 54,
        "pin_number": 3456,
        "guid": 234987,
        "amount": 100,
        "is_active": "TRUE",
        "start_date": 1663027200,
        "end_date": 1672617600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 54,
        "pin_number": 87654,
        "guid": 567098,
        "amount": 200,
        "is_active": "TRUE",
        "start_date": 1663113600,
        "end_date": 1672704000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 54,
        "pin_number": 567876,
        "guid": 766778,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1663200000,
        "end_date": 1672790400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 54,
        "pin_number": 8679845,
        "guid": 7236623,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1663286400,
        "end_date": 1672876800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 54,
        "pin_number": 987634569876,
        "guid": 76237238,
        "amount": 4000,
        "is_active": "TRUE",
        "start_date": 1663372800,
        "end_date": 1672963200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 54,
        "pin_number": 34567890,
        "guid": 746847628,
        "amount": 5000,
        "is_active": "TRUE",
        "start_date": 1663459200,
        "end_date": 1673049600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 36,
        "pin_number": 9999,
        "guid": 8585647,
        "amount": 100,
        "is_active": "TRUE",
        "start_date": 1663545600,
        "end_date": 1673136000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 36,
        "pin_number": 4444,
        "guid": 8748393756,
        "amount": 200,
        "is_active": "TRUE",
        "start_date": 1663632000,
        "end_date": 1673222400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 36,
        "pin_number": 8888,
        "guid": 62381290,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1663718400,
        "end_date": 1673308800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 36,
        "pin_number": 6655,
        "guid": 5346373829,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1663804800,
        "end_date": 1673395200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 36,
        "pin_number": 44455,
        "guid": 38475656,
        "amount": 4000,
        "is_active": "TRUE",
        "start_date": 1663891200,
        "end_date": 1673481600,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 14,
        "brand_id": 138,
        "pin_number": 76464,
        "guid": 7676347634,
        "amount": 200,
        "is_active": "TRUE",
        "start_date": 1663977600,
        "end_date": 1673568000,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 15,
        "brand_id": 138,
        "pin_number": 782382389,
        "guid": 6666666,
        "amount": 500,
        "is_active": "TRUE",
        "start_date": 1664064000,
        "end_date": 1673654400,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 11,
        "brand_id": 138,
        "pin_number": 823892389,
        "guid": 56778544,
        "amount": 1000,
        "is_active": "TRUE",
        "start_date": 1664150400,
        "end_date": 1673740800,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 12,
        "brand_id": 138,
        "pin_number": 745747,
        "guid": 3333333,
        "amount": 2000,
        "is_active": "TRUE",
        "start_date": 1664236800,
        "end_date": 1673827200,
        "status": "Success",
        "remark": "Uploaded successfully"
    },
    {
        "category_id": 13,
        "brand_id": 138,
        "pin_number": 763476378,
        "guid": 3222221,
        "amount": 3000,
        "is_active": "TRUE",
        "start_date": 1664323200,
        "end_date": 1673913600,
        "status": "Success",
        "remark": "Uploaded successfully"
    }
]

export const UploadLogs_dummy = [{
    batch_id: "12231231211",
    name: "temp_gainon_doc.xlxs",
    uploaded_date: new Date(1661107594 * 1000),
    status: "Success",
},
{
    batch_id: "12233323111",
    name: "temp02_gainon_doc.xlxs",
    uploaded_date: new Date(1662107594 * 1000),
    status: "Rolled Back",
},
{
    batch_id: "12231221211",
    name: "temp03_gainon_doc.xlxs",
    uploaded_date: new Date(1663107594 * 1000),
    status: "Success",
}
]
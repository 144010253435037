import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/Coupon/ShowCoupon.scss";
import { get } from "../../../utills/API";
// import { Link } from "react-router-dom";
import moment from "moment";

class EasyDine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "Brand Name",
          selector: "brand_name",
          sortable: true,
        },
        {
          name: "Email",
          selector: "email",
          sortable: true,
        },
        {
          name: "Mobile",
          selector: "mobile",
          sortable: true,
        },
        {
          name: "Credit",
          selector: "credit",
          sortable: true,
        },
        {
          name: "Created",
          selector: "created_at",
          sortable: true,
          cell: (row) => (
            <span>{moment(row.created_at).format("DD-MM-YYYY h:mm a")}</span>
          ),
        },
      ],
      filterText: "",
      resetPaginationToggle: false,
      eazydinerTracks: [],
      showDeleteModal: false,
      brandCouponId: "",
      isLoad: false,
    };
  }

  // async componentDidUpdate(prevProps, prevState) {
  //   if (prevState.isLoad !== this.state.isLoad) {
  //     try {
  //       const { data } = await get("eazydiner_tracks");
  //       var eazydinerTracks = data.data.eazydiner_tracks;
  //     } catch (e) {
  //       console.log("error", e);
  //     }

  //     this.setState({ eazydinerTracks });
  //   }
  // }

  async componentDidMount() {
    try {
      const { data } = await get("eazydiner_tracks");
      var eazydinerTracks = data.data.eazydiner_tracks;
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ eazydinerTracks });
  }

  onFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const { eazydinerTracks, columns, filterText } = this.state;
    const filteredItems = eazydinerTracks.filter(
      (item) =>
        item.mobile && item.mobile.toString().includes(filterText.toLowerCase())
    );
    return (
      <div className="span9 brand-coupon">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div style={{ padding: "20px 20px" }} className="muted pull-left">
                Showing Eazydiner Tracks
              </div>
            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <FilterComponent
                        onFilter={this.onFilter}
                        onClear={this.onClear}
                        filterText={filterText}
                      />
                    }
                    columns={columns}
                    pagination={true}
                    //persistTableHead
                    data={filteredItems}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = (props) => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Mobile"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

export default EasyDine;

import { read, utils, writeFileXLSX } from "xlsx";
import CsvDownload from 'react-json-to-csv'
import React, { Component } from "react";
import { post, get } from "../../../utills/API";
import { toast } from "react-toastify";
import "../../../assets/sass/admin/Coupon/AddCoupon.scss";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { required } from "../../../utills/Validation";
import DataTable from "react-data-table-component";
import { excelData } from "../UploadCoupons/dummyData"
import moment from "moment";

class UploadPromoCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                // {
                //     name: 'Category',
                //     selector: row => row.category_id,
                // },
                // {
                //     name: 'Brand',
                //     selector: row => row.brand_id,
                // },
                {
                    name: 'Coupon Code',
                    selector: row => row.code,
                    center: true,
                    width: "140px",


                },
                {
                    name: 'Title',
                    selector: row => row.title,
                    center: true,

                },
                // {
                //     name: 'Start date',
                //     selector: row => (new Date(row.start_date * 1000).toDateString()),
                //     center: true,

                //     width: "140px",
                // },
                // {
                //     name: 'End date',
                //     selector: row => (new Date(row.end_date * 1000).toDateString()),
                //     center: true,

                //     width: "140px",
                // },
                {
                    name: 'Start date',
                    selector: row => moment(row.valid_from).format("DD-MM-YYYY"),
                    center: true,

                    width: "140px",
                },
                {
                    name: 'End date',
                    selector: row => moment(row.valid_to).format("DD-MM-YYYY"),
                    center: true,

                    width: "140px",
                },
                {
                    name: 'Coupon value',
                    selector: row => row.amount,
                    center: true,
                    width: "140px",

                },
                {
                    name: 'Status',
                    selector: row => <span style={{ color: row.status === "Success" ? "green" : "red" }} >{row.status || `Failed`}</span>,
                    center: true,

                },
                {
                    name: 'Remark',
                    selector: row => row.remark,
                    width: "160px",
                    wrap: true,
                    // headerStyle: (selector, id) => {
                    //     return { textOverflow: "clip" };
                    // },
                },
            ],

            tableData: [],
            vendor_id: "",
            category_id: "",
            brand_id: "",
            guid: "",
            pin: '',
            amount: null,
            // is_active: false,
            // categoryArr: [],
            // brandsArr: [],
            // redeemBrandsArr: [],
            // category_name: "",
            // brand_name: "",
            // redeem_brand_name: "",
            errors: {},
            // brands: [],
            // redeemBrands: [],
            expiry_date: new Date(),
            is_active: true,
            is_redeem: false,

            excelData: [],
            fileData: null,
            isExcelUploaded: false,
            coupons: [],
            availCoupons: [],
            duplicateRecords: 0,
            uploadableRecords: 0,
            uploadableRecordsData: [],

            // sources: [],
            // source_id: "",
            uploadSummary: [],
            duplicateRecordsData: [],

            coupon_type: "paid",
            is_upload: false,


        };
        this.readExcelFile = this.readExcelFile.bind(this)
    }




    validateAllInputs = () => {
        const errors = {
            categoryError: null,
            brandError: null,
            guidError: null,
            amountError: null,
        };
        const { category_id, brand_id, guid, pin, amount, expiry_date } = this.state;
        errors.categoryError = required(category_id);
        errors.brandError = required(brand_id);
        errors.guidError = required(guid);
        errors.amountError = required(amount);

        if (errors.amountError === null && isNaN(amount)) {
            errors.amountError = "amount should only be a Number";
        }

        this.setState({ errors });
    };

    validityCheck = () => {
        const { category_id, brand_id, guid, pin, amount, expiry_date } = this.state;
        let isValid =
            category_id === "" || brand_id === "" || guid === "" || amount === null;
        return !isValid;
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === "category_id") {
        //     if (!this.state.is_redeem) {
        //         let filterBrandArr = this.state.brands.filter(
        //             (brand) => brand.category_id === Number(value)
        //         );
        //         this.setState({
        //             category_id: value,
        //             brandsArr: value ? filterBrandArr : this.state.brands,
        //         });
        //     } else {
        //         let filterBrandArr = this.state.redeemBrands.filter(
        //             (brand) => brand.category_id === Number(value)
        //         );
        //         this.setState({
        //             category_id: value,
        //             redeemBrandsArr: value ? filterBrandArr : this.state.redeemBrands,
        //         });
        //     }
        // } else {
        //     this.setState({ [name]: value });
        // }

        this.setState({
            [name]: value
        })
    };

    handleExpiryDate = (date) => {
        this.setState({ expiry_date: date });
    };

    addBrandCoupon = async (e) => {
        e.preventDefault();

        this.validateAllInputs();
        if (this.validityCheck()) {
            const {
                category_id,
                brand_id,
                guid,
                pin,
                amount,
                expiry_date,
                is_active,
            } = this.state;

            e.preventDefault();
            const addBrandCouponData = {
                coupon: {
                    brand_id: Number(brand_id),
                    category_id: Number(category_id),
                    guid,
                    pin_number: pin,
                    amount,
                    expiry_date,
                    is_active,
                },
            };

            // try {
            //     const { data } = await post("coupons", addBrandCouponData);
            //     if (data.status === 200) {
            //         this.props.history.push("/admin/coupons/logs");
            //     } else {
            //         toast.error("Something Went Wrong!", { autoClose: 2000 });
            //     }
            // } catch (e) {
            //     console.log("error", e);
            // }
        } else {
            console.log("Enter valid Details");
        }
    };

    // New functions---------------18/08/2022.
    // Function to convert excel file to json.
    readExcelFile(e) {
        e.preventDefault();
        this.setState({
            fileData: e.target.files
        })
        // setFileData(e.target.files)
        // console.log()
        if (e.target.files.length > 0 && e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json(worksheet);
                console.log(json);
                // setExcelToJson(json)

                var collectStartDate = json.map(startDate => {
                    if (!isNaN(startDate.start_date)) {
                        return new Date((startDate.start_date - 25569) * 86400 * 1000)
                    } else {
                        var FormateMMDDYYY = `${startDate.start_date.split("/")[1]}/${startDate.start_date.split("/")[0]}/${startDate.start_date.split("/")[2]}`
                        return moment(FormateMMDDYYY).toDate()
                    }
                })

                var collectEndDate = json.map(endDate => {
                    if (!isNaN(endDate.end_date)) {
                        return new Date((endDate.end_date - 25569) * 86400 * 1000)
                    } else {
                        var FormateMMDDYYY = `${endDate.end_date.split("/")[1]}/${endDate.end_date.split("/")[0]}/${endDate.end_date.split("/")[2]}`
                        return moment(FormateMMDDYYY).toDate()
                    }
                })

                console.log(collectStartDate, collectEndDate)

                this.setState({
                    excelData: json.map((doc, index) => ({
                        ...doc,
                        // name: "",
                        // guid: doc.voucher_number,
                        // is_active: true,
                        // pin_number: doc.pin_number || null,
                        // pin: doc.pin_number || null,
                        // codeType: this.state.coupon_type,
                        value: doc.amount,
                        code: doc.coupon_code,
                        valid_from: collectStartDate[index],
                        valid_to: collectEndDate[index],
                    })),
                })
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        } else {
            this.setState({
                excelData: [],
                fileData: null,
            })
            // setExcelToJson([])
            // setFileData(null)
        }
    }

    // Validate guid duplication in db and within excel:
    validateRecordsDuplication = () => {
        console.log("Total records in excel: ", this.state.excelData.length)


        var temp = []
        var filtered_array = []
        var duplicate_records = []
        // var filtered_array_1 = []
        // var duplicate_records_1 = []
        // var data_exist = []
        console.log(temp)
        for (let i = 0; i < this.state.excelData.length; i++) {
            for (let j = 0; j < this.state.excelData.length; j++) {
                if (temp.indexOf(this.state.excelData[j].code.toString()) === -1) {
                    if (this.state.excelData[i].code.toString() === this.state.excelData[j].code.toString()) {
                        temp.push(this.state.excelData[j].code.toString())
                        filtered_array.push({
                            ...this.state.excelData[j],
                            status: "Success",
                            remark: "Uploaded successfully"
                        })
                    }
                }
            }
        }
        var test_1 = filtered_array.map((dta, i) => {
            var test = this.state.excelData.filter(data => data.code.toString() === temp[i]).slice(1)
                .map(stat => ({
                    ...stat,
                    status: "Failed",
                    remark: "Promocode duplicate in sheet"
                }))
            duplicate_records.push(...test)
            return test
        })

        // console.log(temp, filtered_array, duplicate_records)
        console.log([...duplicate_records, ...filtered_array])
        this.setState({
            tableData: [...duplicate_records, ...filtered_array],
            duplicateRecords: duplicate_records.length,
            uploadableRecords: filtered_array.length,
            uploadableRecordsData: filtered_array,
            duplicateRecordsData: duplicate_records,
        })
        // setExcelToJson(filtered_array)

        // console.log("Duplicate records in excel: ", this.state.excelData.length - filtered_array.length);

        // console.log("Records to upload: ", filtered_array.length)

        // for (let i = 0; i < filtered_array.length; i++) {
        //     // for (let j = 0; j < excelToJson.length; j++) {
        //     if (this.state.availCoupons.indexOf(filtered_array[i].guid.toString()) === -1) {

        //         // if (excelToJson[i].guid.toString() === excelToJson[j].guid.toString()) {
        //         // temp.push(excelToJson[j].guid.toString())
        //         filtered_array_1.push({
        //             ...filtered_array[i],
        //             status: "success",
        //         })
        //         // }

        //     } else {
        //         duplicate_records_1.push({
        //             ...filtered_array[i],
        //             status: "failed"
        //         })
        //     }
        //     // }
        // }

        // // setFinalRecords(filtered_array_1)
        // console.log("Total records in db: ", this.state.availCoupons)
        // console.log("Duplicate records: ", filtered_array.length - filtered_array_1.length);
        // console.log("Records to upload: ", filtered_array_1.length, filtered_array_1)
        // var numberOfDulicateRecords = (this.state.excelData.length - filtered_array.length) + (filtered_array.length - filtered_array_1.length)
        // if (numberOfDulicateRecords > 0) {
        //     toast.error("This file contains " + numberOfDulicateRecords + " duplicate records.")
        // }

        // toast.success("Safe records to upload: " + filtered_array_1.length + "/" + this.state.excelData.length)
        // console.log("Data already exist: ", data_exist.length)
    }

    // filter redeem brands:
    handleRedeemBrands = () => {
        this.setState({
            is_redeem: !this.state.is_redeem,
        })
    }




    async componentDidMount() {
        // this.setState({
        //     isExcelUploaded: true,
        // })
        try {
            // const { data } = await get("categories");
            // const brands = await get("brands");
            // const redeemBrands = await get("reedem_brands");
            // const Coupons = await get("coupons");
            // const Sources = await get("sources");
            // const Vendors = await get("vendors");

            // var categoryData = data.data.categories;
            // this.setState({
            //     categoryArr: categoryData,
            //     brandsArr: brands.data.data.brands,
            //     brands: brands.data.data.brands,
            //     coupons: Coupons.data.data.coupons,
            //     availCoupons: Coupons.data.data.coupons.map(cpn => cpn.guid),
            //     redeemBrandsArr: redeemBrands.data.data.reedem_brands,
            //     redeemBrands: redeemBrands.data.data.reedem_brands,
            //     sources: Sources.data.data.sources,
            //     vendor_id: Vendors.data.data.vendors.filter(vend => vend.name === "NGP")[0].id
            // });
        } catch (e) {
            console.log("error", e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.excelData !== this.state.excelData) {




            // console.log(this.state.isExcelUploaded, this.state.fileData)
            if (this.state.fileData) {
                if (this.state.excelData[0] && this.state.excelData[0].coupon_code) {
                    this.validateRecordsDuplication()
                    // findDuplicateRecords()
                }
                else {
                    this.setState({
                        excelData: [],
                        fileData: null,
                    })
                    toast.error("This file isn't safe to upload.");
                    console.log("This file isn't safe to upload.")
                }

                // setFileUpload(false)
                // checkExistDb()
            }
        }
    }

    statusHandleChange = () => {
        this.setState({
            is_active: !this.state.is_active,
        });
    };

    HandleUploadPromoCodes = async () => {
        this.setState({
            is_upload: true,
        })
        try {

            const {
                fileData,
                // brand_id,
                // vendor_id,
                // source_id,
                // category_id,
                uploadableRecordsData,
                // is_redeem,
                coupon_type,
            } = this.state;
            // console.log(fileData)
            var UploadData = {
                // "data": {
                name: fileData[0].name,
                upload_date: new Date(),
                promocodes: uploadableRecordsData.map(promo => ({ ...promo, code_type: coupon_type })),
                codeType: coupon_type,
                is_redeem: 2,
            }

            const { data } = await post("promo_codes/bulk", {
                data: UploadData,
            });

            console.log(data)

            this.setState({
                tableData: [...this.state.duplicateRecordsData, ...data.data.coupons],
                uploadSummary: [...data.data.coupons]
            })

            // this.setState({
            //     tableData: uploadableRecordsData.map(promo => ({ ...promo, codeType: coupon_type })),
            //     uploadSummary: uploadableRecordsData.map(promo => ({ ...promo, codeType: coupon_type })),

            // })
            console.log(UploadData)
        } catch (err) {
            console.log(err)
        }



        // }
        // console.log(data)
    }

    // Download excel
    downloadExcel = (data, fileName) => {
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        writeFileXLSX(workbook, fileName ? `${fileName}.xlsx` : `Template-promocodes-${new Date().getTime()}.xlsx`);
    };


    render() {
        const {
            coupon_type,
            is_upload,
        } = this.state;
        const { handleChange, statusHandleChange } = this;
        // console.log("brnads", this.state.brandsArr);
        return (
            <div id="content" className="span9 brand-coupon-add" style={{
                marginTop: "-20px",
                // margin: "0 auto",
                width: "800px"
            }} >
                <div className="row-fluid">
                    <div className="block">
                        <div className="navbar navbar-inner block-header">
                            <div className="muted pull-left">Upload Brand Coupons</div>
                            <div className="muted pull-right">
                                {/* <CsvDownload data={[{
                                    coupon_code: "00000000",
                                    title: "title",
                                    amount: "000",
                                    start_date: moment(new Date()).format("DD/MM/YYYY"),
                                    end_date: moment(new Date()).format("DD/MM/YYYY"),

                                }]}
                                    filename={`Template-promocode-${new Date().getTime()}.csv`}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                        backgroundColor: "#c82e29",

                                    }} >
                                    <i class="fa fa-download"></i> Download Excel Template
                                </CsvDownload> */}

                                <button
                                    onClick={() => this.downloadExcel(
                                        [{
                                            coupon_code: "00000000",
                                            title: "title",
                                            amount: "000",
                                            start_date: moment(new Date()).format("DD/MM/YYYY"),
                                            end_date: moment(new Date()).format("DD/MM/YYYY"),

                                        }]
                                    )}

                                    className="btn btn-primary btn-sm"
                                    style={{
                                        backgroundColor: "#c82e29",

                                    }}
                                >

                                    <i class="fa fa-download"></i> Download Excel Template

                                </button>

                                {/* <Link
                                    className="btn btn-primary delete"
                                    to={"/admin/coupons/logs/upload"}
                                >
                                    Download Excel Template
                                </Link> */}
                            </div>
                        </div>
                        {/* <CsvDownload data={this.state.excelData} style={{
                            backgroundColor: "red"
                        }} /> */}


                        <div className="cont">
                            <div className="row"
                                style={{
                                    padding: "12px",
                                    // style={{
                                    //     // padding: "12px",
                                    // border: "1px solid red"
                                    // }}
                                }}
                            >
                                {/* <div className="col-md-12"> */}

                                {/* <div className="col-md-12" ></div> */}
                                {/* </div> */}
                                <div className="col-md-4"
                                    style={{
                                        marginBottom: "12px"
                                    }}
                                >
                                    <div className="">Coupon Type</div>
                                    <div className="">
                                        <select
                                            disabled={is_upload}
                                            name="coupon_type"
                                            value={coupon_type}
                                            className="form-control"
                                            onChange={handleChange}
                                        >
                                            <option value={"paid"}>Paid</option>
                                            <option value={"free"}>Free</option>

                                            {/* {this.state.sources.map((src) => (
                                                <option value={src.id}>{src.name}</option>
                                            ))} */}
                                        </select>
                                        {/* {errors.categoryError ? (
                                            <p className="error-warning">{errors.categoryError}</p>
                                        ) : null} */}
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="">Category Name</div>
                                    <div className="">
                                        <select
                                            disabled={this.state.uploadSummary[0]}
                                            name="category_id"
                                            value={category_id}
                                            className="form-control"
                                            onChange={handleChange}
                                        >
                                            <option value={""}>Select Category</option>

                                            {this.state.categoryArr.map((catgory) => (
                                                <option value={catgory.id}>{catgory.name}</option>
                                            ))}
                                        </select>
                                        {errors.categoryError ? (
                                            <p className="error-warning">{errors.categoryError}</p>
                                        ) : null}
                                    </div>
                                </div> */}
                                {/* <div className="col-md-4">
                                    <div className="name">Brand Name</div>
                                    <div className="val">
                                        <select
                                            disabled={this.state.uploadSummary[0]}
                                            name="brand_id"
                                            value={brand_id}
                                            className="form-control"
                                            onChange={handleChange}
                                        >
                                            <option value={""}>Select Brands</option>
                                            {
                                                !this.state.is_redeem ?
                                                    this.state.brandsArr.map((brand) => (
                                                        <option value={brand.id}>{brand.name}</option>
                                                    ))
                                                    :
                                                    this.state.redeemBrandsArr.map((brand) => (
                                                        <option value={brand.id}>{brand.name}</option>
                                                    ))
                                            }
                                        </select>
                                        {errors.brandError ? (
                                            <p className="error-warning">{errors.brandError}</p>
                                        ) : null}
                                    </div>
                                </div> */}
                                <div className="col-md-8">
                                    <div className="name">Select Excel File</div>
                                    <div className="val">
                                        <input
                                            disabled={is_upload}
                                            className="form-control"
                                            type="file"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={this.readExcelFile}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-md-4" >
                                <div className="name">&nbsp;</div>

                                    <Link
                                        to="/admin/coupons/logs" className="btn btn-primary btn-sm"
                                        style={{
                                            // marginLeft: "8px",
                                            backgroundColor: "#c82e29",
                                            width: "100%",
                                        }}
                                    >
                                        Cancel
                                    </Link>
                                </div> */}

                                <div className="col-md-12"
                                    style={{
                                        margin: "12px 0px",
                                    }}
                                >
                                    {
                                        !this.state.uploadSummary[0] ?
                                            <div className="action-center"
                                                style={{
                                                    // border: "1px solid red",
                                                    textAlign: "right"
                                                }}
                                            >
                                                <button
                                                    disabled={
                                                        is_upload
                                                        ||
                                                        !this.state.excelData[0]
                                                        // ||
                                                        // [
                                                        //     this.state.source_id,
                                                        //     this.state.brand_id,
                                                        //     this.state.category_id
                                                        // ].includes("")
                                                    }
                                                    onClick={this.HandleUploadPromoCodes}
                                                    className="btn btn-primary btn-sm"
                                                    style={{
                                                        backgroundColor: "#c82e29",
                                                        width: "20%",

                                                    }}
                                                >
                                                    Upload
                                                </button>
                                                <Link
                                                    to="/admin/promoCode/logs"
                                                    className={`btn btn-primary btn-sm ${is_upload && "disabled"}`}
                                                    style={{
                                                        marginLeft: "8px",
                                                        backgroundColor: "#c82e29",
                                                        width: "20%",
                                                    }}
                                                >
                                                    Cancel
                                                </Link>
                                            </div>
                                            :
                                            <div className="action-center"
                                                style={{
                                                    // border: "1px solid red",
                                                    textAlign: "right"
                                                }}
                                            >

                                                <Link
                                                    to="/admin/promoCode/logs" className="btn btn-primary btn-sm"
                                                    style={{
                                                        marginLeft: "8px",
                                                        backgroundColor: "#c82e29",
                                                        width: "20%",
                                                    }}
                                                >
                                                    Back To Logs
                                                </Link>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        /* Upload summary */
                        this.state.uploadSummary[0] &&
                        <div className="block">
                            <div className="navbar navbar-inner block-header">
                                <div className="muted pull-left">Upload Summary</div>
                                {/* <CsvDownload data={this.state.tableData}
                                    filename={`coupon-summary-${new Date().getTime()}.csv`}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                        backgroundColor: "#c82e29",

                                    }} >
                                    <i class="fa fa-download"></i> Download .csv
                                </CsvDownload> */}
                                <button
                                    className="btn btn-primary btn-sm"
                                    style={{
                                        backgroundColor: "#c82e29",

                                    }}
                                    onClick={() => this.downloadExcel(
                                        this.state.tableData,
                                        `coupon-summary-${this.state.uploadSummary[0].batch_id}`
                                    )}
                                >
                                    <i class="fa fa-download"></i> Download .xlsx
                                </button>
                            </div>


                            <div className="cont"
                                style={{
                                    // padding: "12px",
                                    // border: "1px solid red",
                                    // overflow: "hidden",
                                }}
                            >
                                <div className="row"
                                    style={{
                                        padding: "12px",
                                        // border: "1px solid red"
                                    }}
                                >
                                    <div className="col-md-12"
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            padding: "12px 0px",
                                            borderBottom: "1px solid rgba(0, 0, 0, .3)"
                                        }}
                                    >
                                        {/* <div>
                                            <span>Total Records: {this.state.excelData.length} </span>
                                        </div>
                                        <div>
                                            <span style={{ color: "red" }} >Duplicate Records: {this.state.duplicateRecords}</span>
                                        </div>
                                        <div>
                                            <span style={{ color: "green" }} >Uploadable Records: {this.state.uploadableRecords}</span>
                                        </div> */}

                                    </div>
                                    <div className="col-md-12" >

                                        <DataTable
                                            columns={this.state.columns}
                                            pagination={true}
                                            //persistTableHead
                                            data={this.state.tableData}
                                        // data={excelData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div >
        );
    }
}

// const FilterTypes = props => {
//     return (
//         <div className="row filterComp">
//             {/* <div className="filterComp-input"> */}
//             <div className="">
//                 <select
//                     // disabled={this.state.uploadSummary[0]}
//                     name="type"
//                     value={props.filterText}
//                     className="form-control"
//                     onChange={props.onFilter}
//                 >
//                     {/* <option value={""}>All</option> */}
//                     <option value={"Paid"}>Paid</option>
//                     <option value={"Free"}>Free</option>

//                     {/* {this.state.sources.map((src) => ( */}
//                     {/* <option value={src.id}>{src.name}</option> */}
//                     {/* ))} */}
//                 </select>

//             </div>
//             {/* </div> */}
//         </div>
//     )
// }

export default UploadPromoCodes;

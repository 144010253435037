import React, { Component } from "react";
class Login extends Component {
  constructor(props) {
    super(props);
    this.props.history.push("/");
  }

  

  render() {
    return(<></>)
  }
}

export default Login;

import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { post, get, put } from "../../utills/API";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../assets/sass/admin/BrandCoupon.scss";
import "../../assets/sass/admin/Brand.scss";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
      country: "",
      wallet: "",
      wallet: "",
      ngp_gift_discount: null
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    const { userId } = this.props.match.params;

    try {
      const { data } = await get(`users/${userId}/detail`);
      var userData = data.data.user;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      firstName: userData.first_name,
      lastName: userData.last_name,
      email: userData.email,
      mobile: userData.mobile,
      address: userData.address,
      city: userData.city,
      state: userData.state,
      country: userData.country,
      wallet: userData.wallet,
      ngp_gift_discount: userData.ngp_gift_discount,
      userId
    });
  }

  editUser = async e => {
    e.preventDefault();

    const editUserData = {
      user: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobile,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        address: this.state.address,
        wallet: this.state.wallet,
        ngp_gift_discount: this.state.ngp_gift_discount
      }
    };

    try {
      const { data } = await put(`users/${this.state.userId}`, editUserData);
      if (data.status === 200) {
        this.props.history.push("/admin/registeredUser");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      mobile,
      address,
      city,
      state,
      country,
      wallet,
      ngp_gift_discount
    } = this.state;
    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Edit User</div>
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <form onSubmit={this.editUser}>
              <div className="row add-coupon-main">
                <div className="col-md-12 main-row">
                  <div className="col-md-2">First Name</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Last Name</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Email Id</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Id"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Mobile No.</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Mobile No"
                      name="mobile"
                      value={mobile}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Address</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Address"
                      name="address"
                      value={address}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">City</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      value={city}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">State</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter State"
                      name="state"
                      value={state}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Country</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Country"
                      name="country"
                      value={country}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Wallet</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Wallet"
                      name="wallet"
                      value={wallet}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Gift Discount</div>
                  <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Gift Discount"
                      name="ngp_gift_discount"
                      value={ngp_gift_discount}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Wallet Cashback</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isWalletCashback"
                        checked={isWalletCashback}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="cashback"
                      />
                      <label className="onoffswitch-label" for="cashback">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-8 offset-2 brands-btn">
                    <button className="btn btn-primary " type="submit">
                      Update
                    </button>
                    <Link
                      to={"/admin/registeredUser"}
                      className="btn btn-primary"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddCategory);

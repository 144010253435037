import React from "react";
import "../../assets/sass/admin/Settings.scss";

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="content" class="span9 settings-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">List Pages</div>
            </div>
            <div className="block-content in">
              <div className="span12">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>S. No</th>
                      <th>Control Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>User Hotel Previous Booking Count</td>
                      <td>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="crs_user"
                            className="onoffswitch-checkbox"
                            id="1"
                            checked
                          />
                          <label className="onoffswitch-label" for="1">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Click 2 call enable/disable</td>
                      <td>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="crs_user"
                            className="onoffswitch-checkbox"
                            id="2"
                            checked
                          />
                          <label className="onoffswitch-label" for="2">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Travel Insurance</td>
                      <td>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="crs_user"
                            className="onoffswitch-checkbox"
                            id="3"
                          />
                          <label className="onoffswitch-label" for="3">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import AlertDismissible from "./../components/Common/Alert";
import { post } from "../utills/API";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      msg: "",
      errorMessage: "",
      showError: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onContactFormSubmit = async (e) => {
    e.preventDefault();
    const { name, mobile, email, msg } = this.state;
    const data = { name, mobile, email, msg };
    if (!email && !mobile && !msg) {
      this.setState({
        errorMessage: "Please enter details.",
        showError: true,
      });

      return;
    }
    try {
      const response = await post("ngp/contact", data);
      if (response.status === 200) {
        // toast.success(
        //   "Thank you for your email, we will get back to you soon.",
        //   { autoClose: 2000 }
        // );
        this.setState({ name: "", mobile: "", email: "", msg: "" });
        this.props.onHide()
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  toggleError = () => {
    this.setState({
      errorMessage: "",
      showError: false,
    });
  };

  render() {
    const { name, mobile, email, msg } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <form>
            <Modal.Header
              className="pb-0 border-bottom-0"
              closeButton
            ></Modal.Header>
            <AlertDismissible
              toggleError={this.toggleError}
              errorMessage={this.state.errorMessage}
              show={this.state.showError}
            />
            <Modal.Body className="pt-0">
              <div className="pl-4 pr-4">
                <h1 className="font-weight-bolder text-24 mb-5">Contact us</h1>

                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    class="form-control"
                    name="name"
                    required
                    value={name}
                    onChange={this.handleChange}
                  />
                </div>
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    type="number"
                    placeholder="Enter your phone number"
                    class="form-control"
                    id="mobile"
                    name="mobile"
                    maxlength="10"
                    value={mobile}
                    onChange={this.handleChange}
                  />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <div class="form-group">
                  <label>Message</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Start typing"
                    required
                    name="msg"
                    value={msg}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top-0">
              <div className="d-flex flex-column w-100 pl-4 pr-4 pb-4">
                <Button variant="primary" onClick={this.onContactFormSubmit}>
                  Send Email
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
export default Contact;

import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Landing from "./containers/Landing";
import Login from "./containers/Login";
import SignUp from "./containers/Signup";
import cookie from "react-cookies";
import Donation from "./containers/Donation";
import FAQ from "./containers/FAQ";
import About from "./containers/About";
import Privacy from "./containers/Privacy";
import Tnc from "./containers/TnC";
import Contact from "./containers/Contact";
import ForgotPassword from "./containers/ForgotPassword";
import SuperAdminLayout from "./SuperAdminLayout";
import Voucher from "./containers/Voucher";
import Brand from "./containers/Brand";
import Profile from "./containers/User/Profile";
import EditProfile from "./containers/User/EditProfile";
import ChangePassword from "./containers/User/ChangePassword";
import StudentVerification from "./containers/User/StudentVerification";
import Orders from "./containers/User/Orders";
import MyVoucher from "./containers/User/MyVoucher";
import Wallet from "./containers/User/Wallet";
import RedeemBrand from "./containers/User/RedeemBrand";
import Reward from "./components/Reward";
import Temp from "./containers/Temp";
import Landing_temp from "./containers/Landing_temp"
import Swiggy from "./components/Swiggy_Integration";
import HDFC_Integration from "./components/HDFC_integration";
import IIML_integration from "./components/IIML_integration";
import Sumaria_integration from "./components/sumaria_integration";
import ERO_power_integration from "./components/ERO_power_integration";
import ManUtd_integration from "./components/ManUtd_integration";
import GainonGoPointPartner_Integration from "./components/GainonGoPointPartner";
class Routes extends Component {
  constructor(props) {
    super(props);
    this.routes = [
      {
        path: "/",
        exact: true,
        component: Landing,
        title: "landing",
      },
      {
        path: "/login",
        exact: true,
        component: Login,
        title: "login",
      },
      {
        path: "/signup",
        exact: true,
        component: SignUp,
        title: "signup",
      },
      {
        path: "/donation",
        exact: true,
        component: Donation,
        title: "donation",
      },
      {
        path: "/faq",
        exact: true,
        component: FAQ,
        title: "faq",
      },
      {
        path: "/about",
        exact: true,
        component: About,
        title: "about",
      },
      {
        path: "/privacy",
        exact: true,
        component: Privacy,
        title: "privacy",
      },
      {
        path: "/temp",
        exact: true,
        component: Temp,
        title: "temp",
      },
      {
        path: "/tnc",
        exact: true,
        component: Tnc,
        title: "tnc",
      },
      {
        path: "/contact",
        exact: true,
        component: Contact,
        title: "contact",
      },
      {
        path: "/forgotPassword",
        exact: true,
        component: ForgotPassword,
        title: "forgotPassword",
      },
      {
        path: "/admin",
        exact: false,
        component: SuperAdminLayout,
        title: "admin",
      },
      {
        path: "/voucher/:isredeem?/:pathParam2?",
        exact: false,
        component: Voucher,
        title: "voucher",
      },
      {
        path: "/brand/:brand_id?",
        exact: false,
        component: Brand,
        title: "brand",
      },
      {
        path: "/profile",
        exact: false,
        component: Profile,
        title: "profile",
      },
      {
        path: "/editProfile",
        exact: false,
        component: EditProfile,
        title: "editProfile",
      },
      {
        path: "/changePassword",
        exact: false,
        component: ChangePassword,
        title: "changePassword",
      },
      {
        path: "/studentVerification",
        exact: false,
        component: StudentVerification,
        title: "studentVerification",
      },

      {
        path: "/orders/:orderId",
        exact: true,
        component: Orders,
        title: "orders",
      },
      {
        path: "/orders/:orderId/details",
        exact: true,
        component: Orders,
        title: "orders",
      },
      {
        path: "/wallet",
        exact: true,
        component: Wallet,
        title: "wallet",
      },
      {
        path: "/gopoints/swiggy/:spc",
        exact: true,
        component: Swiggy,
        title: "swiggy",
      },
      {
        path: "/gopoints/HDFC/:spc",
        exact: true,
        component: HDFC_Integration,
        title: "HDFC",
      },
      {
        path: "/gopoints/IIML/:spc",
        exact: true,
        component: IIML_integration,
        title: "IIML",
      },
      {
        path: "/gopoints/eropower/:spc",
        exact: true,
        component: ERO_power_integration,
        title: "eropower",
      },
      {
        path: "/gopoints/sumaria/:spc",
        exact: true,
        component: Sumaria_integration,
        title: 'sumaria'
      },
      {
        path: "/gopoints/ManUtd/:spc",
        exact: true,
        component: ManUtd_integration,
        title: 'ManUtd'
      }, {
        path: "/gopoints/gainon/:spc",
        exact: true,
        component: GainonGoPointPartner_Integration,
        title: 'GainonGoPointPartner'
      },
      {
        path: "/my-vouchers",
        exact: true,
        component: MyVoucher,
        title: "myVoucher",
      },
      {
        path: "/redeemBrand/:redeemBrandId?",
        exact: true,
        component: RedeemBrand,
        title: "redeemBrand",
      },
      {
        path: "/rewards",
        exact: true,
        component: Reward,
        title: "reward",
      },
      {
        path: "/:company_name",
        exact: true,
        component: Landing_temp,
        title: "White Labeling",
      },
      {
        path: "/:company_name/voucher/:isredeem?/:pathParam2?",
        exact: true,
        component: Voucher,
        title: "White Labeling Voucher",
      },
      {
        path: "/:company_name/redeemBrand/:redeemBrandId?",
        exact: true,
        component: RedeemBrand,
        title: "White Labeling Redeem Brand",
      },
      {
        path: "/:company_name/rewards",
        exact: true,
        component: Reward,
        title: "White Labeling Reward",
      },
    ];
  }

  isAllowed = (props, RouteComponent, title) => {
    if (this.isCurrentUser()) {
      var role = cookie.load("roleId");
      if (role === "ngp_admin") {
        return <SuperAdminLayout {...props} />;
      } else if (role === "user") {
        console.log("title", title);
        if (title === "landing") {
          return <Landing {...props} />;
        } else if (title === "voucher") {
          return <Voucher {...props} />;
        } else if (title === "brand") {
          return <Brand {...props} />;
        } else if (title === "profile") {
          return <Profile {...props} />;
        } else if (title === "editProfile") {
          return <EditProfile {...props} />;
        } else if (title === "changePassword") {
          return <ChangePassword {...props} />;
        } else if (title === "studentVerification") {
          return <StudentVerification {...props} />;
        } else if (title === "faq") {
          return <FAQ {...props} />;
        } else if (title === "about") {
          return <About {...props} />;
        } else if (title === "privacy") {
          return <Privacy {...props} />;
        } else if (title === "temp") {
          return <Temp {...props} />;
        } else if (title === "tnc") {
          return <Tnc {...props} />;
        } else if (title === "contact") {
          return <Contact {...props} />;
        } else if (title === "orders") {
          return <Orders {...props} />;
        } else if (title === "myVoucher") {
          return <MyVoucher {...props} />;
        } else if (title === "wallet") {
          return <Wallet {...props} />;
        } else if (title === "redeemBrand") {
          return <RedeemBrand {...props} />;
        } else if (title === "reward") {
          return <Reward {...props} />;
        } else if (title === "swiggy") {
          return <Swiggy {...props} />;
        } else if (title === "HDFC") {
          return <HDFC_Integration {...props} />;
        } else if (title === "IIML") {
          return <IIML_integration {...props} />;
        } else if (title === "sumaria") {
          return <Sumaria_integration {...props} />;
        } else if (title === "eropower") {
          return <ERO_power_integration {...props} />
        } else if (title === "ManUtd") {
          return <ManUtd_integration {...props} />
        } else if (title === "GainonGoPointPartner") {
          return <GainonGoPointPartner_Integration {...props} />
        } else {
          return <Redirect to={"/"} />;
        }
      }
    } else {
      if (title === "signup") {
        return <SignUp {...props} />;
      } else if (title === "landing") {
        return <Landing {...props} />;
      } else if (title === "donation") {
        return <Donation {...props} />;
      } else if (title === "faq") {
        return <FAQ {...props} />;
      } else if (title === "about") {
        return <About {...props} />;
      } else if (title === "privacy") {
        return <Privacy {...props} />;
      } else if (title === "temp") {
        return <Temp {...props} />;
      } else if (title === "tnc") {
        return <Tnc {...props} />;
      } else if (title === "contact") {
        return <Contact {...props} />;
      } else if (title === "forgotPassword") {
        return <ForgotPassword {...props} />;
      } else if (title === "adminLogin") {
        return <Login {...props} />;
      } else if (title === "voucher") {
        return <Voucher {...props} />;
      } else if (title === "wallet") {
        return <Wallet {...props} />;
      } else if (title === "brand") {
        return <Brand {...props} />;
      } else if (title === "login") {
        return <Login {...props} />;
      } else if (title === "redeemBrand") {
        return <RedeemBrand {...props} />;
      } else if (title === "reward") {
        return <Reward {...props} />;
      } else if (title === "White Labeling") {
        return <Landing_temp {...props} />;
      } else if (title === "White Labeling Voucher") {
        return <Voucher {...props} />;
      } else if (title === "White Labeling Redeem Brand") {
        return <RedeemBrand {...props} />;
      } else if (title === "White Labeling Reward") {
        return <Reward {...props} />;
      } else if (title === "swiggy") {
        return <Swiggy {...props} />;
      } else if (title === "HDFC") {
        return <HDFC_Integration {...props} />;
      } else if (title === "IIML") {
        return <IIML_integration {...props} />;
      } else if (title === "sumaria") {
        return <Sumaria_integration {...props} />;
      } else if (title === "eropower") {
        return <ERO_power_integration {...props} />;
      } else if (title === "ManUtd") {
        return <ManUtd_integration {...props} />
      } else if (title === "GainonGoPointPartner") {
        return <GainonGoPointPartner_Integration {...props} />
      } else {
        return <Redirect to={"/login"} />;
      }
    }
  };

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  render() {
    return (
      <>
        <Switch>
          {this.routes.map((route, i) => (
            <Route
              key={i}
              exact={route.exact}
              path={route.path}
              render={(props) =>
                this.isAllowed(props, route.component, route.title)
              }
            />
          ))}
        </Switch>
      </>
    );
  }
}

export default Routes;

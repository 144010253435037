import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import cookie from "react-cookies";
import { logout, post } from "../utills/API";
import { ToastContainer, toast } from "react-toastify";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      msg: ""
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    // this.props.history.push("/login");
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onContactFormSubmit = async (e) => {
    e.preventDefault()
    const {name, mobile, email, msg} = this.state;
    const data = {name, mobile, email, msg}
    if(!email && !mobile && !msg){
      toast.error("Please enter details.", { autoClose: 2000 });
      return false
    }
    try {
      const response = await post("ngp/contact" , data );
      if( response.status === 200 ) { 
        toast.success("Thank you for your email, we will get back to you soon.", { autoClose: 2000 });
        this.setState({name: "", mobile: "",  email: "", msg: ""})
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  render() {
    const {name, mobile, email, msg} = this.state
    return (
      <>
       <ToastContainer position={toast.POSITION.TOP_CENTER} />
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <div className="main-box">
          <div className="container">
            <div className="main-box-con">
              <h3>Contact</h3>

              <form action="#" method="post" id="contact" onSubmit={this.onContactFormSubmit}>
                <div
                  className="tab-content clearfix"
                  style={{ display: "block" }}
                >
                  <div className="col-md-4">
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control logpadding margtop10"
                      name="name"
                      required
                      value={name}
                      onChange={this.handleChange}
                    />
                    <input
                      type="text"
                      placeholder="Phone"
                      className="form-control logpadding margtop20"
                      id="mobile"
                      name="mobile"
                      maxlength="10"
                      value={mobile}
                      onChange={this.handleChange}
                    />
                    <span id="errmsg"></span>
                    <input
                      type="text"
                      placeholder="E-mail"
                      className="form-control logpadding margtop20"
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <textarea
                      rows="7"
                      className="form-control margtop10"
                      required
                      name="msg"
                      value={msg}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  <div className="col-md-4 opensans grey">
                    Address
                    <br />
                    <span className="dark">
                      B wing, 5th floor, Ackruti Trade Centre MIDC, <br />
                      Andheri East,
                      <br />
                      Mumbai,
                      <br />
                      Maharashtra 400093
                    </span>
                    <br />
                    <br />
                    Email
                    <br />
                    <a href="mailto:support@gainon.in" className="green2">
                      <span>support@gainon.in</span>
                    </a>
                  </div>
                </div>

                <div
                  className="searchbg3 clearfix"
                  style={{ position: "relative", top: "inherit" }}
                >
                  <input
                    type="submit"
                    className="fli-button mr20"
                    value="Send Email"
                    name="submit"
                    onClick={this.onContactFormSubmit}
                    style={{ marginTop: "10px", float: "right" }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { post, get } from "../../../utills/API";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../../assets/sass/admin/Category/AddCategory.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionEditorState: EditorState.createEmpty(),
      aboutEditorState: EditorState.createEmpty(),
      howToRedeemEditorState: EditorState.createEmpty(),
      brandName: "",
      selectCategory: "",
      img: "",
      convenienceFee: "",
      categoryArr: [],
      vendorsArr: [],
      rewardUsers:[],
      vendorId: null,
      rewardUserId:null,
      isPublishBrand: true,
      is_reward_brand:false,
      website: null,
      priority: 999,
      isTopBrand: false,
      videoLink: "",
      step1: '',
      step2: '',
      step3: '',
      step4: '',
      step1Image: '',
      step2Image: '',
      step3Image: '',
      step4Image: ''
    };
  }

  async componentDidMount() {
    try {
      const { data } = await get("categories");
      var categoryArr = data.data.categories;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("vendors");
      var vendorsArr = data.data.vendors;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("rewardUserType");
      var rewardUsers = data.data.redward_users ? data.data.redward_users: [];
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ categoryArr: categoryArr, vendorsArr: vendorsArr, rewardUsers: rewardUsers, });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "img") {
      this.setState({ img: e.target.files[0] });
    } else {
      this.setState({ [name]: value });
    }
  };

  cashbackWalletChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  handleImageChange = (e) =>{
    const { name, value } = e.target;
      this.setState({ [name]: e.target.files[0] });
  }

  topBrandChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  onEditorStateChange = (value) => {
    this.setState({ instructionEditorState: value });
  };

  onAboutEditorStateChange = (value) => {
    this.setState({ aboutEditorState: value });
  };

  onHowToRedeemEditorState = (value) => {
    this.setState({ howToRedeemEditorState: value });
  };

  addRedeemBrand = async (e) => {
    e.preventDefault();

    // const addBrandData = {
    //   brand: {
    //     name: this.state.brandName,
    //     category_id: Number(this.state.selectCategory),
    //     // image: this.state.img,
    //     cash_back: Number(this.state.cashback),
    //     cash_back_type: this.state.cashbackType,
    //     instruction: draftToHtml(
    //       convertToRaw(this.state.instructionEditorState.getCurrentContent())
    //     ),
    //     about: draftToHtml(
    //       convertToRaw(this.state.aboutEditorState.getCurrentContent())
    //     )
    //   },
    //   image: this.state.img
    // };

    let formData = new FormData();

    formData.append("image", this.state.img);
    formData.append("step1Image", this.state.step1Image);
    formData.append("step2Image", this.state.step2Image);
    formData.append("step3Image", this.state.step3Image);
    formData.append("step4Image", this.state.step4Image);
    formData.set("reedem_brand[step1Text]", this.state.step1);
    formData.set("reedem_brand[step2Text]", this.state.step2);
    formData.set("reedem_brand[step3Text]", this.state.step3);
    formData.set("reedem_brand[step4Text]", this.state.step4);
    formData.set("reedem_brand[name]", this.state.brandName);
    formData.set(
      "reedem_brand[category_id]",
      Number(this.state.selectCategory)
    );
    formData.set(
      "reedem_brand[convenience_fee]",
      Number(this.state.convenienceFee)
    );
    formData.set("reedem_brand[vendor_id]", this.state.vendorId);
    this.state.rewardUserId && formData.set("reedem_brand[reward_usertype_id]", this.state.rewardUserId)
    formData.set("reedem_brand[is_publish]", this.state.isPublishBrand);
    formData.set("reedem_brand[is_reward_brand]", this.state.is_reward_brand);
    formData.set("reedem_brand[home_page_show]", this.state.isTopBrand);
    formData.set("reedem_brand[website]", this.state.website);
    formData.set("reedem_brand[priority]", this.state.priority);
    formData.set("reedem_brand[video_link]", this.state.videoLink);
    formData.set(
      "reedem_brand[instruction]",
      draftToHtml(
        convertToRaw(this.state.instructionEditorState.getCurrentContent())
      )
    );
    formData.set(
      "reedem_brand[about]",
      draftToHtml(convertToRaw(this.state.aboutEditorState.getCurrentContent()))
    );

    formData.set(
      "reedem_brand[how_to_redeem]",
      draftToHtml(
        convertToRaw(this.state.howToRedeemEditorState.getCurrentContent())
      )
    );

    try {
      const { data } = await post("reedem_brands", formData);
      if (data.status === 200) {
        this.props.history.push("/admin/redeemBrand");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Something Went Wrong!", { autoClose: 2000 });
    }
  };

  render() {
    const {
      instructionEditorState,
      aboutEditorState,
      howToRedeemEditorState,
      brandName,
      selectCategory,
      convenienceFee,
      categoryArr,
      vendorId,
      rewardUserId,
      vendorsArr,
      rewardUsers,
      isPublishBrand,
      is_reward_brand,
      website,
      isTopBrand,
      priority,
      videoLink,
      step1,
      step2,
      step3,
      step4,
      step1Image,
      step2Image,
      step3Image,
      step4Image
    } = this.state;

    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Add New Redeem Brand</div>
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <form onSubmit={this.addRedeemBrand}>
              <div className="row add-coupon-main">
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Brand Name</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Brand Name"
                      name="brandName"
                      value={brandName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Category</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="selectCategory"
                      value={selectCategory}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Category</option>
                      {categoryArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Vendors</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="vendorId"
                      value={vendorId}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Vendors</option>
                      {vendorsArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Reward User Type</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="rewardUserId"
                      value={rewardUserId}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Reward Users</option>
                      {rewardUsers.length>0 && rewardUsers.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Publish Brand</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isPublishBrand"
                        checked={isPublishBrand}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="publishbrand"
                      />
                      <label className="onoffswitch-label" for="publishbrand">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Is Available For Reward</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="is_reward_brand"
                        checked={is_reward_brand}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="reward"
                      />
                      <label className="onoffswitch-label" for="reward">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Top Brand</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isTopBrand"
                        checked={isTopBrand}
                        onClick={this.topBrandChange}
                        className="onoffswitch-checkbox"
                        id="topbrand"
                      />
                      <label className="onoffswitch-label" for="topbrand">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Image Upload</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="img"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Convenience Fee</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      name="convenienceFee"
                      value={convenienceFee}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Website</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="url"
                      placeholder="Enter Website Url"
                      name="website"
                      value={website}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Priority of display</div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Priority of display"
                      name="priority"
                      value={priority}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 1</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 1"
                      name="step1"
                      value={step1}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 1 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step1Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 2</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 2"
                      name="step2"
                      value={step2}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 2 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step2Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 3</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 3"
                      name="step3"
                      value={step3}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 3 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step3Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 4</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 4"
                      name="step4"
                      value={step4}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 4 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step4Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Instructions</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={instructionEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">About</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={aboutEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onAboutEditorStateChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">How To Redeem</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={howToRedeemEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onHowToRedeemEditorState}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">How To Redeem Video Link</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter How To Redeem Video Link"
                      name="videoLink"
                      value={videoLink}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-8 offset-2 brands-btn">
                    <button className="btn btn-primary " type="submit">
                      Add Redeem Brand
                    </button>
                    <Link to={"/admin/redeemBrand"} className="btn btn-primary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddCategory);

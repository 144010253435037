import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import cookie from "react-cookies";
import { logout } from "../utills/API";

class TnC extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    this.props.history.push("/login");
  };

  render() {
    return (
      <>
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <div className="main-box">
          <div className="container">
            <div className="main-box-con" style={{paddingTop:"50px"}}>
              <h3> Terms of Use </h3>
              <div style={{marginLeft:"20px"}}>
                <p>This &#39;User Agreement&#39;, which is intended to be a legally binding contract between
                you and us, contains the following:</p>

                <p>(i) Terms of Sale : this governs your purchase and use of the E-Voucher;</p>

                <p>(ii) Website Terms of Use : this governs the access and use of the
                Website; and</p>

                <p>(iii) Privacy Policy : this governs the use of your personal information we
                collect.</p>

                <p>You agree that you are of legal age to enter into binding contracts, have read,
                understood and are bound by the User Agreement. If you do not want to be bound
                by this User Agreement, you should not use this Website or conduct any
                sale/purchase transaction.</p>

                <p>References to &quot;you&quot;, &quot;User&quot; shall mean the end user accessing the Website, its
                contents and using the Functionalities offered through the Website, &quot;Service
                Providers&quot; mean independent third party service providers or merchants, and &quot;we&quot;,
                &quot;us&quot;, &quot;Gainon&quot; and &quot;our&quot; shall mean Swipenow Solutions Private Limited.</p>

                <p>We as a merchant shall be under no liability whatsoever in respect of any loss or
                damage arising directly or indirectly out of the decline of authorization for any
                Transaction, on Account of the Cardholder having exceeded the preset limit mutually
                agreed by us with our acquiring bank from time to time</p>

                <h5>I. TERMS OF SALE</h5>
                <div style={{marginLeft:"20px"}}>
                  <p>(i) The E-Voucher you purchase is redeemable for goods or services by
                  the seller of such goods and services, or the Service Provider. The Service Provider,
                  not Gainon, is the seller of the goods and services and is solely responsible for
                  redeeming any E-Voucher you purchase.</p>

                  <p>(ii) The E-Voucher shall have the necessary redemption instructions on it.
                  The E-Voucher may have an offer period, or expiry date, mentioned on it. You shall
                  use it accordingly.</p>

                  <p>(iii) Unless otherwise stated, you will not be entitled to receive credit or
                  cash-back for whatever value of the E-Voucher you don&#39;t use.</p>

                  <p>(iv) Unless otherwise stated, you cannot combine the E-Voucher offer with
                  other special offers or promotions that may be on.</p>

                  <p>(v) Unless there is something wrong with the purchase or an error
                  attributable to us, we do not offer refunds. E-Voucher once released are non-
                  refundable, whether you use it or not.</p>

                  <p>(vi) Neither Gainon nor the Service Provider shall have any liability in the
                  event you lose your E-Voucher at the Service Provider venue.</p>

                  <p>(vii) Gainon is merely the aggregator of deals and distributor of usable E-
                  Voucher. The E-Voucher and the deals are the property of the respective third-party
                  Service Provider.</p>

                  <p>(viii) As such, Service Provider shall be fully responsible for any and all
                  injuries, illnesses, damages, claims, liabilities and costs suffered by or in respect of a
                  customer, caused in whole or in part by the Service Provider.</p>

                  <p>(ix) Notwithstanding anything to contrary, Gainon&#39;s entire liability to you shall
                  be the refund of the money charged from you for any specific E-Voucher, under
                  which the unlikely liability arises. Under no circumstance shall Gainon be liable for
                  any consequential, indirect or remote loss that you or your friends and family may
                  suffer.</p>
                </div>

                <h5>II. WEBSITE TERMS OF USE</h5>
                <div style={{marginLeft:"20px"}}>
                  <p>A. General</p>

                  <p>www.gainon.in (&quot;Website&quot;) is an Internet based portal owned and operated by
                  Swipenow Solutions Private Limited, a company incorporated under the laws of
                  India, having its registered office at Pune, India. Use of the Website is offered to you
                  conditioned on acceptance of all the terms, conditions and notices contained in these
                  Terms, along with any amendments made by Gainon at its sole discretion and
                  posted on the Website.</p>

                  <p>B. User Account, Password, and Security</p>

                  <p>The Website requires you to register as a User by creating an Account in order to
                  purchase E-Voucher(s) from the Website. You will receive a password and account
                  designation upon completing the Website&#39;s registration process. You will be
                  responsible for maintaining the confidentiality of the password and the account, and
                  are fully responsible for all activities that occur under your password or account. You
                  agree to (a) immediately notify Gainon of any unauthorized use of your password or
                  account or any other breach of security, and (b) ensure that you exit from your
                  account at the end of each session. Gainon cannot and will not be liable for any loss
                  or damage arising from your failure to comply with this Section B.</p>

                  <p>Communication</p>

                  <p>Gainon may use the information submitted by you to send you e-mails and SMS for
                  administrative and marketing purposes, through its servers and systems; or through
                  third party e-mail Or SMS service providers.</p>

                  <p>User can unsubscribe/opt-out from our communications sent on SMS and Email
                  anytime:</p>

                  <p>1. Please email us at support@gainon.in to unsubscribe from SMS or E-mail;</p>
                  <p>2. User can also un-subscribe from emails, by clicking the &quot;Unsubscribe URL&quot;
                  mentioned in the footer of the emails.</p>

                  <p>C. Functionalities Offered</p>

                  <p>This Website is an information gateway to the offers that Gainon extends to the
                  Users. Apart from providing information and a searchable database, the Website
                  also hosts the platform for the online sale/purchase of Gainon E-Vouchers.</p>

                  <p>D. Privacy Policy</p>

                  <p>The User hereby consents, expresses and agrees that he has read and fully
                  understands the Privacy Policy of Gainon in respect of the Website. You further
                  consent that the terms and contents of such Privacy Policy are acceptable to you.</p>

                  <p>E. User Obligations</p>

                  <p>You agree and undertake to use the Website only to post and upload material that is
                  proper. By way of example, and not as a limitation, you agree and undertake that
                  when using the Website, you will not:</p>

                  <p>(i) Defame, abuse, harass, stalk, threaten or otherwise violate the legal
                  rights of others;</p>

                  <p>(ii) Publish, post, upload, distribute or disseminate any inappropriate,
                  profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material
                  or information through any bookmark, tag or keyword;</p>

                  <p>(iii) Upload files that contain software or other material protected by
                  intellectual property laws unless you own or control the rights thereto or have
                  received all necessary consents;</p>

                  <p>(iv) Upload or distribute files that contain viruses, corrupted files, or any
                  other similar software or programs that may damage the operation of the Website or
                  another&#39;s computer;</p>

                  <p>(v) Conduct or forward surveys, contests, pyramid schemes or chain
                  letters;</p>

                  <p>(vi) Download any file posted by another user of a Service that you know, or
                  reasonably should know, cannot be legally distributed in such manner;</p>

                  <p>(vii) Falsify or delete any author attributions, legal or other proper notices or
                  proprietary designations or labels of the origin or source of software or other material
                  contained in a file that is uploaded;</p>

                  <p>(viii) Violate any code of conduct or other guidelines, which may be applicable
                  for or to any particular Service;</p>

                  <p>(ix) Violate any applicable laws or regulations for the time being in force in or
                  outside India;</p>

                  <p>(x) Violate any of the terms and conditions of this Agreement or any other
                  terms and conditions for the use of the Website contained elsewhere herein;</p>

                  <p>(xi) Reverse engineer, modify, copy, distribute, transmit, display, perform,
                  reproduce, publish, license, create derivative works from, transfer, or sell any
                  information or software obtained from the Website; and</p>

                  <p>(xii) Notwithstanding anything to contrary, Gainon&#39;s entire liability to you shall
                  be the refund of the money charged from you for any specific E-Voucher, under
                  which the unlikely liability arises. Under no circumstance shall Gainon be liable for
                  any consequential, indirect or remote loss that you or your friends and family may
                  suffer.</p>

                  <p>F. Usage Conduct</p>

                  <p>(i) You shall solely be responsible for maintaining the necessary computer
                  equipments and Internet connections that may be required to access, use and
                  transact on the Website;</p>

                  <p>(ii) You are also under an obligation to use this Website for reasonable and
                  lawful purposes only, and shall not indulge in any activity that is not envisaged
                  through the Website; and</p>

                  <p>(iii) You shall use this Website, and any E-Voucher purchased through it, for
                  personal, non-commercial use only and shall not re-sell the same to any other
                  person.</p>

                  <p> G. Intellectual Property Rights</p>

                  <p>(i) Unless otherwise indicated or anything contained to the contrary or any
                  proprietary material owned by a third party and so expressly mentioned, Gainon
                  owns all Intellectual Property Rights to and into the trademarks &quot;[•]&quot;, and the
                  Website, including, without limitation, any and all rights, title and interest in and to
                  copyright, related rights, patents, utility models, designs, know-how, trade secrets
                  and inventions (patent pending), goodwill, source code, meta tags, databases, text,
                  content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall
                  not use, reproduce or distribute any content from the Website belonging to Gainon
                  without obtaining authorization from Swipenow Solutions Private Limited.</p>

                  <p>(ii) Notwithstanding the foregoing, it is expressly clarified that you will solely
                  be responsible for any content that you provide or upload when using any Service,
                  including any text, data, information, images, photographs, music, sound, video or
                  any other material which may be accessible through your post, or any other content
                  that you upload, transmit or store when using the Website.</p>

                  <p>H. Links To Third Party Sites</p>

                  <p>Gainon will contain links to other websites (&quot; Linked Sites&quot;). The Linked Sites are not
                  under the control of Gainon, and Gainon is not responsible for the contents of any
                  Linked Site, including without limitation any link contained in a Linked Site, or any
                  changes or updates to a Linked Site. Gainon is not responsible for any form of
                  transmission, whatsoever, received by you from any Linked Site. Gainon provides
                  links of online advertisements to you only as a convenience and the inclusion of any
                  link does not imply endorsement by or affiliation with Gainon of the Linked Sites nor
                  does it represent the advice, views, opinions or beliefs of Gainon. The users are
                  requested to verify the accuracy of all information on their own before undertaking
                  any reliance on such information. In the event that by accessing the Website or
                  following links to third-party websites you are exposed to content that you consider
                  offensive or inappropriate, your only recourse will be to stop using the Website.</p>

                  <p>I. Disclaimer of Warranties &amp; Liability</p>

                  <p>Gainon has endeavored to ensure that all the information on the Website is correct,
                  but Gainon neither warrants nor makes any representations regarding the quality,
                  accuracy or completeness of any data, information, product or Service. In no event</p>

                  <p>shall Gainon be liable for any direct, indirect, punitive, incidental, special,
                  consequential damages or any other damages. Neither shall Gainon be responsible
                  for the delay or inability to use the Website or related Functionalities, the provision of
                  or failure to provide Functionalities, or for any information, software, products,
                  Functionalities and related graphics obtained through the Website, or otherwise
                  arising out of the use of the website, whether based on contract, tort, negligence,
                  strict liability or otherwise. Further, Gainon shall not be held responsible for non-
                  availability of the Website during periodic maintenance operations or any unplanned
                  suspension of access to the website that may occur due to technical reasons or for
                  any reason beyond Gainon&#39;s control. The user understands and agrees that any
                  material and/or data downloaded or otherwise obtained through the Website is done
                  entirely at their own discretion and risk and they will be solely responsible for any
                  damage to their computer systems or loss of data that results from the download of
                  such material and/or data.</p>

                  <p>J. Indemnification</p>

                  <p>You agree to indemnify, defend and hold harmless Gainon from and against any and
                  all losses, liabilities, claims, damages, costs and expenses (including legal fees and
                  disbursements in connection therewith and interest chargeable thereon) asserted
                  against or incurred by Gainon that arise out of, result from, or may be payable by
                  virtue of, any breach or non-performance of any representation, warranty, covenant
                  or agreement made or obligation to be performed by you pursuant to these Terms.</p>

                  <p>K. Termination</p>

                  <p>Gainon may suspend or terminate your use of the Website or any Service at its sole
                  and absolute discretion.</p>

                  <p>(i) Notwithstanding the foregoing sub-section above, these Terms will
                  survive indefinitely unless and until Gainon chooses to terminate them; and</p>

                  <p>(ii) If you or Gainon terminates your use of the Website, Gainon may delete
                  any content or other materials relating to your use of the Website and Gainon will
                  have no liability to you or any third party for doing so. Offers of discounts and
                  freebies cannot be clubbed with any other promotional offers</p>

                  <p>L. Governing Law</p>

                  <p>These terms shall be governed by and constructed in accordance with the laws of
                  India without reference to conflict of laws principles, and disputes arising in relation
                  hereto shall be subject to the exclusive jurisdiction of the courts at Mumbai, India.</p>

                  <p>M. Report Abuse</p>

                  <p>If you come across any abuse or violation of these Terms, please report to
                  support@gainon.in</p>
                </div>
                <h5>III. PRIVACY POLICY</h5>
                <div style={{marginLeft:"20px"}}>
                  <p>A. General Usage</p>

                  <p>This Privacy Policy explains how Gainon collects, uses, discloses and protects your
                  Personal Information, obtained explicitly through www.gainon.in website (&quot; Site&quot;), as
                  well as other information collected automatically without direct submission by you.
                  &#39;Personal Information&#39; is information that enables anyone to contact you and your
                  credit-card details. Your full name, address, IP address, e-mail address are other
                  examples of Personal Information.</p>

                  <p>You may need to provide Gainon your Personal Information during the course of
                  using some services of the Gainon; for instance, while registering on the website and
                  while making an online purchase.</p>

                  <p>Gainon may use the information submitted by you to send you e-mails for
                  administrative and marketing purposes. Gainon does not make these e-mail
                  addresses available to any third party. Gainon DOES NOT trade or sell your
                  Personal Information in any manner, except as is provided under Section III (B)
                  below, or if express consent is sought from you.</p>

                  <p>When you use the Site, Gainon&#39;s servers (which may be hosted by a third party
                  service provider) may collect information indirectly and automatically about your
                  activities on the Site; for instance by way of cookies. A cookie is a small amount of
                  data that is sent to a user&#39;s browser from a web server and is eventually stored on a
                  user&#39;s computer hard drive. This anonymous information is maintained distinctly and
                  is not linked to the Personal Information you submit to Gainon. The anonymous</p>

                  <p>information will be used for Gainon&#39;s understanding of how the Site is used and for
                  other statistical purposes, for system administration and to personalize your
                  experience. Gainon may also collect information related to access times, browser
                  type, and your IP address for tracking usage patterns and, if necessary, deny service
                  in accordance with our Terms.</p>

                  <p>Gainon may disclose aggregated information about the use of the Site, but it will
                  never contain Personal Information. Gainon does not disclose Personal Information
                  to third parties in the normal course of operations. However, in situations when
                  Gainon is legally obligated to disclose information to the government or other third
                  parties, Gainon will do so.</p>

                  <p>Gainon implements standard measures to protect against unauthorized access to
                  and unlawful interception of Personal Information. However, no internet site can fully
                  eliminate security risks.</p>

                  <p>B. Shared Personal Information</p>

                  <p>You are required to submit your personal information at the time of making an online
                  purchase of Gainon E-Vouchers. Gainon uses an online payment gateway that is
                  operated by a third party and the personal information that you share with Gainon is
                  transferred and shared with such third party payment gateway operator. The said
                  operator may also have access to your online purchase history/details that you make
                  from www.gainon.in.</p>

                  <p>Extremely sensitive information like you credit-card details are transacted upon
                  secure sites digitally under encryption, thereby providing the highest possible degree
                  of care are available as per current technology. You are advised, however, that
                  Internet technology is not 100% safe and you should exercise discretion on using the
                  same.</p>

                  <p>Gainon also uses third party e-mail and SMS service providers to send transaction
                  and marketing e-mails and messages to users transacting or using the gainon.in
                  website.</p>

                  <p>Gainon at its discretion may also use external vendors and providers, to ship out the
                  orders for E-Vouchers and gift vouchers received on gainon.in. Gainon may share
                  your personal information like Name, Address, Phone number etc to these service
                  providers solely for the purpose of shipping out the user order.</p>

                  <p>C. General</p>

                  <p>Gainon will use information only in accordance with the Privacy Policy under which
                  the information was collected unless we have received explicit authorization</p>

                  <p>"We as a merchant shall be under no liability whatsoever in respect of any loss or
                  damage arising directly or indirectly out of the decline of authorization for any
                  Transaction"</p>  
                </div>  
              </div>   
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default TnC;

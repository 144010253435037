import React, { Component } from "react";
import { get, post, logout } from "../../utills/API";
import {
  checkPassword,
  validatePassword,
  required,
} from "../../utills/Validation";
import { decrypt } from "../../utills/function";

import cookie from "react-cookies";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import GoNav from "../Common/GoNav";
import { Card, Form, Button } from "react-bootstrap";
import AlertDismissible from "../../components/Common/Alert";
import { withRouter } from "react-router-dom";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      userId: "",
      isProcess: false,
      errors: {
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
      },
      showError: false,
      errorMessage: "",
      type: "",
    };
  }

  isCurrentUser = () => {
    let isLogin = !!cookie.load("accessToken");
    return isLogin;
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
    this.props.history.push("/");
  };

  validateAllInputs = () => {
    const errors = {
      oldPasswordError: null,
      newPasswordError: null,
      confirmPasswordError: null,
    };
    const { oldPassword, newPassword, confirmPassword } = this.state;
    errors.oldPasswordError = required(oldPassword);
    errors.newPasswordError = checkPassword(newPassword);
    errors.confirmPasswordError = validatePassword(
      newPassword,
      confirmPassword
    );
    this.setState({ errors });
  };

  validityCheck = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    return (
      oldPassword &&
      newPassword &&
      confirmPassword &&
      newPassword.match(/^[^-\s]{8,20}$/)
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    try {
      const { data } = await get("users/detail");
      let decData = decrypt(data.data.user);
      var userId = decData.id;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ userId: userId });
  }

  changePassword = async (e) => {
    e.preventDefault();
    this.setState({ isProcess: true });
    this.validateAllInputs();
    if (this.validityCheck()) {
      const changePasswordData = {
        user: {
          old_password: this.state.oldPassword,
          password: this.state.newPassword,
        },
      };
      try {
        const { data } = await post(
          `users/${this.state.userId}/change_password`,
          changePasswordData
        );
        if (data.status === 200) {
          this.setState({
            showError: true,
            errorMessage: "Password change successfully.",
            type: "success",
          });
        } else if (data.status === 422) {
          this.setState({
            showError: true,
            errorMessage: data.message,
            type: ''
          });
        }
        {
          this.setState({
            showError: true,
            errorMessage: data.message,
            type: ''
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      console.log("Enter valid Details");
    }
    this.setState({ isProcess: false });
  };

  toggleError = (value) => {
    this.setState({ showError: value });
  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      errors,
      isProcess,
    } = this.state;

    return (
      <>
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} isLoggedIn={this.state.isLoggedIn} />
        <section className="page page-profile pt-4">
          <div className="container">
            <div className="flex-xl-nowrap row">
              <div className="col-auto d-none d-lg-flex flex-column">
                <GoNav />
              </div>
              <div className="col">
                <Card className="card-profile d-none d-md-flex d-lg-flex">
                  <Card.Body>
                    <h1 className="text-22 mb-5">Change Password</h1>
                    <AlertDismissible
                      toggleError={this.toggleError}
                      errorMessage={this.state.errorMessage}
                      show={this.state.showError}
                      type={this.state.type}
                    />
                    <Form className="mb-5">
                      <Form.Group controlId="oldPassword">
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="oldPassword"
                          onChange={this.handleChange}
                          value={oldPassword}
                          placeholder="Enter Old Password"
                        />
                        {errors.oldPasswordError ? (
                          <p className="error-warning">
                            {errors.oldPasswordError}
                          </p>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          onChange={this.handleChange}
                          value={newPassword}
                          placeholder="Enter New Password"
                        />
                        {errors.newPasswordError ? (
                          <p className="error-warning">
                            {errors.newPasswordError}
                          </p>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="confirmPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          onChange={this.handleChange}
                          value={confirmPassword}
                          placeholder="Confirm Password"
                        />
                        {errors.confirmPasswordError ? (
                          <p className="error-warning">
                            {errors.confirmPasswordError}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Form>
                    <div className="button-group text-center">
                      <Button
                        variant="primary"
                        onClick={this.changePassword}
                        className="mr-4"
                      >
                        {isProcess ? "Updating" : "Update"}
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.props.history.push("/my-vouchers");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <div className="d-block d-sm-block d-md-none">
                  <h1 className="font-weight-bold mb-4">Change Password</h1>
                  <AlertDismissible
                    toggleError={this.toggleError}
                    errorMessage={this.state.errorMessage}
                    show={this.state.showError}
                    type={this.state.type}
                  />
                  <Form className="mb-5">
                    <Form.Group controlId="oldPassword">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="oldPassword"
                        onChange={this.handleChange}
                        value={oldPassword}
                        placeholder="Enter Old Password" />
                      {errors.oldPasswordError ? (
                        <p className="error-warning">
                          {errors.oldPasswordError}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password"
                        name="newPassword"
                        onChange={this.handleChange}
                        value={newPassword}
                        placeholder="Enter New Password" />
                      {errors.newPasswordError ? (
                        <p className="error-warning">
                          {errors.newPasswordError}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control type="password"
                        name="confirmPassword"
                        onChange={this.handleChange}
                        value={confirmPassword}
                        placeholder="Enter Confirm Password" />
                      {errors.confirmPasswordError ? (
                        <p className="error-warning">
                          {errors.confirmPasswordError}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Form>
                  <div className="button-group text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="mb-4"
                      onClick={this.changePassword}
                      block
                    >
                      {isProcess ? "Updating" : "Update"}
                    </Button>
                    <Button variant="link"
                      onClick={() => {
                        this.props.history.push("/my-vouchers");
                      }}>Cancel</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default withRouter(ChangePassword);

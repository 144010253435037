import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "../../assets/sass/Voucher.scss";
import logo from "./../../assets/images/logoManagement/LOGOMM.png"
import eazyDinerLogo from "./../../assets/images/eazydiner.svg"

class TakeMeModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;
    const { brand } = this.props.brand;
    return (
      <>
      <Modal
        className="tech-me-modal"
        show={props.showTakeMe}
        onHide={props.close}
      >
        {/* <Modal.Header closeButton style={{ fontWeight: "600"}}>Promo Codes</Modal.Header> */}
        <button onClick={props.close} type="button" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          <div className="tech-me">
            <div className="techme-left">
              <h2>How IT WORKS</h2>
              <ul>
                <li>
                  <div className="number"><div>1</div></div>
                  <div className="text">Make sure you are logged in</div>
                </li>
                <li>
                  <div className="number"><div>2</div></div>
                  <div className="text">You will be re-directed to the partner website</div>
                </li>
                <li>
                  <div className="number"><div>3</div></div>
                  <div className="text">Complete your purchase on our partner website</div>
                </li>
                <li>
                  <div className="number"><div>4</div></div>
                  <div className="text">You will automatically be credited with a one time Rs. 10 cashback</div>
                </li>
              </ul>
            </div> 
            <div className="techme-right">
              <div className="logos">
                {/* <img alt="gainon-logo" src={logo} className="perk-logo" /> */}
                <img alt="brand-logo" src={eazyDinerLogo} className="perk-logo" />
              </div>
              <div className="techme">
                  <button class="techme-btn" onClick={() => props.goToPartnerWebsite()} style={{fontWeight:"700"}}>Take Me There</button>
                  {/* <a class="techme-btn" href={this.props.brand ? this.props.brand.website : ""} target="_blank">Tech Me</a> */}
              </div>
            </div> 
          </div>
      </Modal>
      </>
    );
  }
}

export default TakeMeModal;

import React from "react";
import '../../assets/sass/admin/Download.scss'

const DownLoadButton = (props) => {
  return (
    <>
      <button className="download-button p-1" onClick={()=>props.click(true)}>
        <i class="fa fa-download"></i> Download
      </button>
    </>
  );
};

export default DownLoadButton;

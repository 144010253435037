import React, { Component } from "react";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import bgmask from "../../assets/images/gainon/home-mask.png";
import bgmasksm from "../../assets/images/gainon/home-mask-sm.png";

import selectBrand from "../../assets/images/gainon/favourite-brand.png";
import redeemcash from "../../assets/images/gainon/redeemcash.png";
import dailycash from "../../assets/images/gainon/daily-cash@2x.png";
class HowItWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      howStep: 1,
      howImg: selectBrand,
    };
  }
  async componentDidMount() {
    const interval = setInterval(() => {
      switch (this.state.howStep){
        case 1: 
        return this.setHow(2)
        case 2: 
        return this.setHow(3)
        case 3: 
        return this.setHow(1)
      }
      
    }, 3000);
    return () => clearInterval(interval);
  }
  setHow = (no) => {
    this.setState({ howStep: no });
    if (no === 1) {
      this.setState({ howImg: selectBrand });
    }
    if (no === 2) {
      this.setState({ howImg: redeemcash });
    }
    if (no === 3) {
      this.setState({ howImg: dailycash });
    }
  };
  render() {
    const { howStep, howImg } = this.state;
    return (
      <>
        <section className="section-gainon section-how-it-works">
          <img
            src={bgmask}
            className="modal-blur bgmask d-none d-xl-block d-lg-block"
            alt="bg"
          />
          <img
            src={bgmasksm}
            className="modal-blur bgmask d-block d-lg-none"
            alt="bg"
          />

          <Container>
            <div className="box-works">
              <h2 className="title">How it works</h2>
              <Row className="d-none d-lg-flex">
                <Col>
                  <Card
                    className={
                      "card-how-it-works p-4 " +
                      (howStep === 1 ? "active " : null)
                    }
                    onClick={() => this.setHow(1)}
                  >
                    <ul>
                      <li>
                        Pay at top brands using e-vouchers from gainon.
                      </li>
                    </ul>
                    <p>
                      Select a brand then enter the amount you wish to pay and proceed to payment.
                    </p>
                  </Card>
                  <Card
                    className={
                      "card-how-it-works p-4 " +
                      (howStep === 2 ? "active " : null)
                    }
                    onClick={() => this.setHow(2)}
                  >
                    <ul>
                      <li>Earn Daily Cash on your purchases.</li>
                    </ul>
                    <p>
                      Complete the payment securely through Razorpay
                      and Instant Loyalty Cashback will be added to your
                      account.
                    </p>
                  </Card>
                  <Card
                    className={
                      "card-how-it-works p-4 " +
                      (howStep === 3 ? "active " : null)
                    }
                    onClick={() => this.setHow(3)}
                  >
                    <ul>
                      <li>Redeem your Cash</li>
                    </ul>
                    <p>
                      Redeem your Loyalty Cashback as a discount on your next payment on gainon - it's that simple.
                    </p>
                  </Card>
                </Col>
                <Col>
                  <img
                    src={howImg}
                    alt="Select Brand"
                    className="select-brand-img"
                  />
                </Col>
              </Row>
              <Carousel
                touch
                className="carousel-how-it-works d-block d-sm-block d-lg-none"
              >
                <Carousel.Item className="text-center">
                  <img
                    src={selectBrand}
                    alt="Select Brand"
                    className="d-block w-100"
                  />
                  <h6>Pay at top brands using e-vouchers from gainon.</h6>
                  <p>
                    Select a brand then enter the amount you wish to pay and proceed to payment.
                  </p>
                </Carousel.Item>
                <Carousel.Item className="text-center">
                  <img
                    src={redeemcash}
                    alt="Select Brand"
                    className="d-block w-100"
                  />
                  <h6>Earn Daily Cash on your purchases.</h6>
                  <p>
                    Complete the payment securely through Razorpay and
                    Instant Loyalty Cashback will be added to your account.
                  </p>
                </Carousel.Item>
                <Carousel.Item className="text-center">
                  <img
                    src={dailycash}
                    alt="Select Brand"
                    className="d-block w-100"
                  />
                  <h6>Redeem your Cash</h6>
                  <p>
                    Redeem your Loyalty Cash back at any of the brands or as
                    Paytm cash – it's that simple. Minimum wallet balance of Rs. 250 is required for redeems.
                  </p>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default HowItWork;

import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
class GoNav extends Component {
  render() {
    return (
      <>
        <Nav variant="sidebar" className="flex-column">
          <Nav.Link as={NavLink} to="/my-vouchers" exact>
            Transaction History
          </Nav.Link>
          <Nav.Link as={NavLink} to="/profile">
            My Profile
          </Nav.Link>
          <Nav.Link as={NavLink} to="/changePassword">
            Change Password
          </Nav.Link>

          <Nav.Link
            as={NavLink}
            to="/studentVerification"
          >
            Student Verification
          </Nav.Link>
        </Nav>
      </>
    );
  }
}
export default GoNav;

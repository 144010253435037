import React, { useState } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import "../../assets/sass/admin/Download.scss";
import DatePicker from "react-datepicker";

const DownloadModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const today = new Date();

  const changeDate = (type, value) => {
    switch (type) {
      case "start":
        setStartDate(value);
        return
      case "end":
        setEndDate(value);
        return
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          className="pb-0 border-bottom-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <h3 className="font-weight-bolder text-24 mb-5 header-center">
            Download {props.headerName}
          </h3>
          <Row className="date-picker-center ">
            <Col>
              <label>Start Date</label>
              <DatePicker
                name="startdate"
                selected={startDate}
                onChange={(e)=>changeDate('start',e)}
                className="form-control"
                popperClassName="go-datepicker"
                maxDate={today}
              />
            </Col>
            <Col>
              <label>End Date</label>
              <DatePicker
                name="enddate"
                selected={endDate}
                onChange={(e)=>changeDate('end',e)}
                className="form-control"
                popperClassName="go-datepicker"
                maxDate={today}
                minDate={startDate}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          <div className="d-flex flex-column w-100 pl-4 pr-4 pb-4">
            <Button
              variant="danger"
              block
                disabled={props.isProcess}
              onClick={()=>props.downloadReport(startDate, endDate)}
            >
              {props.isProcess ? (
                <Spinner animation="border" size="sm" />
              ) : "Download"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DownloadModal;

import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/Coupon/ShowCoupon.scss";
import { get, Delete } from "../../../utills/API";
import { Link } from "react-router-dom";
// import DeleteCoupon from "./DeleteCoupon";
import DeleteCoupon from "../Coupon/DeleteCoupon";
import { UploadLogs_dummy } from "./dummyData";
import moment from "moment";

class ShowUploadLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    name: "Batch Id",
                    selector: "batch_id",
                    sortable: true,
                    width: "160px",

                },
                {
                    name: "Sheet Name",
                    selector: "name",
                    sortable: true,
                    wrap: true,
                    width: "160px",
                    // center: true,

                },

                {
                    name: "Uploaded Date",
                    selector: row => moment(row.uploadedAt).format("DD-MM-YYYY"),
                    sortable: true,
                    width: "140px",

                    // center: true,
                },
                {
                    name: "Status",
                    selector: row => <span style={{ color: row.status === "Uploaded" ? "green" : "red" }} >{row.status || `Failed`}</span>,
                    sortable: true,
                    width: "100px",
                    center: true,

                },

                {
                    name: "View",
                    button: true,
                    cell: row => (
                        <Link to={`/admin/coupons/logs/coupons/${row.batch_id}`}>
                            {
                                !["Rolled Back", "Rollback", "Rollbacked", "RollBack", "Duplicate", "Duplicated"].includes(row.status) &&
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            }
                        </Link>
                    ),
                    width: "80px",
                },
                {
                    name: "Rollback",
                    button: true,
                    cell: row => (
                        <a>
                            {/* <i class="far fa-trash-alt"></i> */}
                            <button
                                style={{
                                    backgroundColor: "#c82e29",
                                    width: "80px",
                                    height: "30px",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    border: "none",
                                    opacity: `${["Rolled Back", "Rollback", "Rollbacked", "RollBack", "Duplicate", "Duplicated"].includes(row.status) && ".5"}`,



                                }}
                                disabled={["Rolled Back", "Rollback", "Rollbacked", "RollBack", "Duplicate", "Duplicated"].includes(row.status)}
                                onClick={async () => {
                                    const { data } = await Delete(`coupons/bulk/rollback/${row.batch_id}`)
                                    this.setState({
                                        isLoad: !this.state.isLoad,
                                    })
                                    console.log(data)
                                }}
                            >Rollback</button>
                        </a>
                    )
                }
            ],


            filterText: "",
            filterType: "",
            resetPaginationToggle: false,
            brandCoupons: [],
            showDeleteModal: false,
            brandCouponId: "",
            isLoad: false,

            uploadedLogs: [],
        };
    }

    // showDeleteModal = id => {
    //   this.setState({
    //     showDeleteModal: true,
    //     brandCouponId: id
    //   });
    // };

    // closeDeleteModal = () => {
    //   this.setState({
    //     showDeleteModal: false
    //   });
    // };

    // deleteBrandCoupon = async Id => {
    //   try {
    //     const { data } = await Delete(`coupons/${Id}`);
    //     this.setState({ showDeleteModal: false, isLoad: !this.state.isLoad });
    //   } catch (e) {
    //     console.log("error", e);
    //   }
    // };

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoad !== this.state.isLoad) {
            try {
                // const { data } = await get("coupons");
                // var brandCoupons = data.data.coupons;
                const { data } = await get("coupons/bulk/logs");
                var Records = data.data.records
                // let updatedBrandCoupons = brandCoupons.map(async coupon => {
                //   let category_name = await this.getCategoryName(coupon.category_id);
                //   let brand_name = await this.getBrandName(coupon.brand_id);
                //   coupon.category_name = category_name;
                //   coupon.brand_name = brand_name;

                //   return coupon;
                // });

                // brandCoupons = await Promise.all(updatedBrandCoupons);
            } catch (e) {
                console.log("error", e);
            }

            // this.setState({ brandCoupons });
            this.setState({
                uploadedLogs: Records,
            })
        }
    }

    getBrandName = async id => {
        const { data } = await get(`brands/${id}`);
        var brandName = data.data.brand.name;
        return brandName;
    };

    // async componentDidUpdate(prevProps)

    async componentDidMount() {
        try {
            const { data } = await get("coupons/bulk/logs");
            // const coupons_with_status = await get("coupons/all/with_status");
            // var brandCoupons = data.data.coupons;
            // console.log(coupons_with_status)
            this.setState({
                uploadedLogs: data.data.records,
            })
        } catch (e) {
            // console.log("error", e);
        }

    }


    searchHeader = e => {
        console.log("event", e);
    };

    onFilter = e => {
        this.setState({ filterText: e.target.value });
    };

    onClear = () => {
        this.setState({ filterText: "" });
    };

    render() {
        const { columns, filterText, uploadedLogs, filterType } = this.state;
        const filteredItems = uploadedLogs
            .sort((a, b) => (b.batch_id > a.batch_id) ? 1 : -1)
            .filter(
                item =>
                    item.batch_id &&
                    item.batch_id.toLowerCase().includes(filterText.toLowerCase())
            ).filter(
                item =>
                    item.type.toString() &&
                    item.type.toString().includes(filterType)
            );
        // const filterTypes = uploadedLogs.filter(
        //     item =>
        //         item.type.toString() &&
        //         item.type.toString() === filterType
        // );
        return (
            <div className="span9 brand-coupon">
                <div className="row-fluid">
                    <div className="block">
                        <div className="navbar navbar-inner block-header">
                            <div className="muted pull-left">Show Upload Logs</div>

                            <div className="muted pull-right">
                                <Link
                                    className="btn btn-primary delete"
                                    to={"/admin/coupons/logs/upload"}
                                >
                                    Upload Excel
                                </Link>
                            </div>
                        </div>

                        <div className="block-content  in">
                            <div className="span12" style={{ marginLeft: "0" }}>
                                <div className="">
                                    <DataTable
                                        subHeader
                                        subHeaderComponent={
                                            <>
                                                <FilterTypes
                                                    onFilter={(e) => this.setState({ filterType: e.target.value })}
                                                    onClear={this.onClear}
                                                    filterText={filterType}
                                                />
                                                <FilterComponent
                                                    onFilter={this.onFilter}
                                                    onClear={this.onClear}
                                                    filterText={filterText}
                                                />
                                            </>
                                        }
                                        columns={columns}
                                        pagination={true}
                                        //persistTableHead
                                        data={filteredItems}
                                    />
                                    {/* <DeleteCoupon
                    state={this.state}
                    showDeleteModal={this.showDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteBrandCoupon={this.deleteBrandCoupon}
                  /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const FilterComponent = props => {
    return (
        <div className="row filterComp">
            <div className="filterComp-input">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Filter By Batch Id"
                    value={props.filterText}
                    onChange={props.onFilter}
                />
            </div>

            <button onClick={props.onClear}>X</button>
        </div>
    );
};

const FilterTypes = props => {
    return (
        <div className="row filterComp">
            {/* <div className="filterComp-input"> */}
            <div className="">
                <select
                    // disabled={this.state.uploadSummary[0]}
                    name="type"
                    value={props.filterText}
                    className="form-control"
                    onChange={props.onFilter}
                >
                    <option value={""}>All</option>
                    <option value={"0"}>Shop Brands</option>
                    <option value={"1"}>Redeem Brands</option>

                    {/* {this.state.sources.map((src) => ( */}
                    {/* <option value={src.id}>{src.name}</option> */}
                    {/* ))} */}
                </select>

            </div>
            {/* </div> */}
        </div>
    )
}

export default ShowUploadLogs;

import React, { Component } from "react";
import { get, put } from "../../../utills/API";
import "../../../assets/sass/admin/Category/EditCategory.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: "",
      categoryId: "",
    };
  }

  handleCatogeryChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  editCategory = async (e) => {
    e.preventDefault();

    const editCategoryData = { category: { name: this.state.categoryName } };

    try {
      const { data } = await put(
        `categories/${this.state.categoryId}`,
        editCategoryData
      );

      if (data.status === 200) {
        this.props.history.push("/admin/couponCategory");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      toast.error("Something Went Wrong!", { autoClose: 2000 });
    }
  };

  async componentDidMount() {
    const { categoryId } = this.props.match.params;

    try {
      const { data } = await get(`categories/${categoryId}`);
      var categoryName = data.data.category.name;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ categoryName: categoryName, categoryId: categoryId });
  }

  render() {
    const { categoryName } = this.state;
    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Edit Coupon Category</div>
              {/* <Link to="/admin/couponCategory">Back</Link> */}
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <div className="block-content  in">
              <div className="span12">
                <div className="span3"></div>
                <div className="span6">
                  <form
                    className="form-horizontal"
                    onSubmit={this.editCategory}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="control-group"
                    >
                      <label for="focusedInput" className="control-label">
                        Coupon Category
                      </label>
                      <div className="controls">
                        <input
                          type="text"
                          name="categoryName"
                          className="input-xlarge focused cat-input"
                          style={{ padding: "5px" }}
                          value={categoryName}
                          onChange={this.handleCatogeryChange}
                        />
                      </div>
                    </div>

                    <div className="control-group btn-section">
                      <div
                        style={{ margin: "0 0 20px 0" }}
                        className="controls"
                      >
                        <input
                          className="btn btn-submit"
                          type="submit"
                          value="Update"
                        />
                        <Link
                          to="/admin/couponCategory"
                          className="btn"
                          type="reset"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="span3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditCategory);

import React, { Component } from "react";
import "../../../assets/sass/admin/RegisteredUser.scss";
import { get, download, post } from "../../../utills/API";
import { httpResponses } from "../../../utills/Constants";
import { downloadXML } from "../../../utills/function";
import DownLoadButton from "../../Common/DownloadButton";
import DownloadModal from "../../Common/DownloadModal";
import DataTable from "react-data-table-component";
// import { Link } from "react-router-dom";
import VoucherModal from "../AllUsers/VoucherModal";
import { toast } from "react-toastify";
import moment from "moment";
import { debounce } from "@material-ui/core";
import Loader from "../../Loader";
import { encrypt, decrypt, getUrlParams } from "../../../utills/function";
import MessageModal from "./MessageModal";

class AllOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "Order Id",
          sortable: true,
          selector: "uuid",
          width: "180px",
          cell: (row) => {
            if (row.order.is_voix_user === true) {
              return (
                <span>
                  <span>{row.order.uuid}</span>&nbsp;{" "}
                  <span style={{ fontWeight: "bold", color: "blue" }}>
                    VOIX
                  </span>
                </span>
              );
            } else {
              return (
                <span>
                  <span>{row.order.uuid}</span>&nbsp;
                </span>
              );
            }
          },
        },
        {
          name: "Brand Name",
          selector: "brand_name",
          sortable: true,
          width:'150px',
          cell: (row) => (
            <span>
              {row.order.brand_name}
            </span>
          ),
        },
        {
          name: "User Name",
          selector: "first_name",
          sortable: true,
          width: '150px',
          cell: (row) => {
            let name;
            if(row.order.first_name == row.order.last_name){
              name = row.order.first_name
              return (
                <span>
                  {name}
                </span>
              )
            } else {
              if(row.order.last_name == null){
                name = row.order.first_name
              } else {
                name = `${row.order.first_name} ${row.order.last_name}`
              }
              return (
                <span>
                  {name}
                </span>
              )
            }
          }  
        },
        {
          name: "Total Amount",
          selector: "total_amount",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.order.total_amount}
            </span>
          ),
        },
        {
          name: "Pay Amount",
          selector: "pay_amount",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.order.pay_amount}
            </span>
          ),
        },
        {
          name: "Gift Discount",
          selector: "ngp_discount",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.order.ngp_discount}
            </span>
          ),
        },
        {
          name: "Gopoints Amount",
          selector: "go_point_discount",
          sortable: true,
          cell: (row) => (
            <span>
              {console.log(row)}
              <i className="fa fa-inr"></i>&nbsp;{row.order.go_point_discount ? row.order.go_point_discount : 0}
            </span>
          ),
        },
        {
          name: "Wallet Use",
          selector: "pay_by_wallet_amount",
          sortable: true,
          cell: (row) => (
            <span>
              {/* <i class="fa fa-inr"></i>&nbsp; */}
              {row.order.pay_by_wallet_amount ? "Yes" : "No"}
            </span>
          ),
        },
        {
          name: "Order Status",
          selector: "status",
          sortable: true,
          width: "180px",
          cell: (row) => {
            var status;
            var color;
            if (row.order.order_status === "order_completed") {
              color = "green";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  ORDER COMPLETED
                </span>
              );
            } else if (row.order.order_status === "payment_failure") {
              color = "red";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  PAYMENT FAILURE
                </span>
              );
            } else if (row.order.order_status === "payment_completed") {
              color = "green";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  PAYMENT COMPLETED
                </span>
              );
            } else if (row.order.order_status === "voucher_fail") {
              status = "VOUCHAR FAIL";
              color = "red";
              return (
                <div>
                  <span style={{ fontWeight: "bold", color }}>VOUCHAR FAIL</span>
                  <a
                    style={{ marginLeft: "10px", cursor: "pointer",color:color }}
                    onClick={() => 
                      this.setState({
                        restoreVoucher: {
                          order_id : row.order.uuid,
                          brand_id: row.order.brand_id
                        },
                        isShowMessage:true
                      })
                    }
                    id="restoreFailedVouchers"
                  >
                    <i className="fas fa-repeat"></i>
                  </a>
                </div>
              );
             } else if (row.order.order_status === "created") {
              status = "PAYMENT COMPLETED";
              color = "blue";
              return (
                <div>
                  <span style={{ fontWeight: "bold", color }}>CREATED</span>
                  <a
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => this.sendOrderPendingMail(row.order.order_id)}
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                  <a
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => 
                      {this.setState({
                        restoreVoucher: {
                          order_id : row.order.uuid,
                          brand_id: row.order.brand_id
                        },
                        isShowMessage:true
                      })
                      console.log("UUID: ", row.order.uuid)
                      console.log("Brand Id: ", row.order)
                    }}
                    id="restoreFailedVouchers"
                  >
                    <i className="fa fa-exclamation-triangle"></i>
                  </a>
                </div>
              );
            }
          },
        },
        // {
        //   name: "Order Status",
        //   selector: "payment_status",
        //   sortable: true,
        //   width: "180px",
        //   cell: (row) => {
        //     var status;
        //     var color;
        //     if (row.order.payment_status !== null && row.order.payment_status.toLowerCase() === "success") {
        //       color = "green";
        //       return (
        //         <span style={{ fontWeight: "bold", color }}>
        //           ORDER COMPLETED
        //         </span>
        //       );
        //     } else if (row.order.payment_status !== null && row.order.payment_status.toLowerCase() === "fail") {
        //       color = "red";
        //       return (
        //         <span style={{ fontWeight: "bold", color }}>
        //           PAYMENT FAILURE
        //         </span>
        //       );
        //     } else if (row.order.payment_status !== null && row.order.payment_status.toLowerCase() === "created") {
        //       status = "PAYMENT COMPLETED";
        //       color = "blue";
        //       return (
        //         <div>
        //           <span style={{ fontWeight: "bold", color }}>CREATED</span>
        //           <a
        //             style={{ marginLeft: "10px", cursor: "pointer" }}
        //             onClick={() => this.sendOrderPendingMail(row.order.id)}
        //           >
        //             <i class="fa fa-envelope"></i>
        //           </a>
        //           <a
        //             style={{ marginLeft: "10px", cursor: "pointer" }}
        //             onClick={() => 
        //               this.setState({
        //                 restoreVoucher: {
        //                   order_id : row.order.uuid,
        //                   brand_id: row.order.brand_id
        //                 },
        //                 isShowMessage:true
        //               })
        //             }
        //             id="restoreFailedVouchers"
        //           >
        //             <i class="fa fa-exclamation-triangle"></i>
        //           </a>
        //         </div>
        //       );
        //     }
        //   },
        // },

        // {
        //   name: "Vouchers",
        //   button: true,
        //   cell: (row) => (
        //     <a onClick={() => this.showVoucherComponent(row.id, true)}>
        //       <i class="fa fa-gift"></i>
        //     </a>
        //   ),
        // },
        {
          name: "Last Wallet Amount",
          selector: "last_wallet_balance",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.order.last_wallet_balance}
            </span>
          ),  
        },
        {
          name: "Created Date",
          selector: "createdAt",
          sortable: true,
          cell: (row) => (
            <span>{moment(row.order.createdAt).format("DD-MM-YYYY")}</span>
          ),
        },
        {
          name: "Transaction Id",
          selector: "txn_id",
          sortable: true,
          width:'180px',
          cell: (row) => (
            <span>
              {row.order.txn_id}
            </span>
          ),
        },
      ],
      // columnVoucher: [
      //   {
      //     name: "Order Id",
      //     sortable: true,
      //     selector: "external_order_id",
      //     width: "200px",
      //   },
      //   {
      //     name: "Quantity",
      //     sortable: true,
      //     selector: "quantity",
      //     width: "100px",
      //   },
      //   {
      //     name: "Status",
      //     sortable: true,
      //     selector: "status",
      //     cell: (row) => {
      //       var status;
      //       var color;
      //       if (row.status === "Success") {
      //         status = "SUCCESS";
      //         color = "green";
      //       } else if (row.status === "fail") {
      //         status = "FAILURE";
      //         color = "red";
      //       } else {
      //         status = row.status.toUpperCase();
      //         color = "blue";
      //       }

      //       return <span style={{ fontWeight: "bold", color }}>{status}</span>;
      //     },
      //     width: "100px",
      //   },
      //   {
      //     name: "Amount",
      //     sortable: true,
      //     selector: "amount",
      //     cell: (row) => (
      //       <span>
      //         <i class="fa fa-inr"></i>&nbsp;{row.amount}
      //       </span>
      //     ),
      //     width: "100px",
      //   },
      //   {
      //     name: "GUID",
      //     sortable: true,
      //     selector: "guid",
      //     width: "240px",
      //   },
      //   {
      //     name: "Gift Vouchers",
      //     button: true,
      //     cell: (row) => (
      //       <a onClick={() => this.showVoucherModal(row.id)}>
      //         <i class="fa fa-gift"></i>
      //       </a>
      //     ),
      //   },
      // ],
      orderData: [],
      filterText: "",
      isShowVoucher: false,
      voucherData: [],
      isTransactionTableShow: true,
      userVoucherId: null,
      userVoucherData: [],
      partners: [],
      selectedPartner: "",
      showBackBtn: false,
      orderCount: 0,
      offset: 1,
      pageSize: 10,
      totalData: 100,
      isLoading: false,
      isShowMessage:false,
      restoreVoucher:null
    };
  }

  DEBOUNCE_TIME = 300;

  toggleDownloadModal = (value) => {
    this.setState({ downLoadModalShow: value });
  };

  showVoucherComponent = async (id) => {
    try {
      const { data } = await get(`orders/${id}/user_vouchers`);
      var userVoucherData = data.data.user_vouchers;
      console.log("userVoucherData", userVoucherData)
    } catch (e) {
      console.log("error", e);
    }
    this.setState({
      isTransactionTableShow: false,
      userVoucherId: id,
      userVoucherData,
      showBackBtn: true,
    });
  };

  sendOrderPendingMail = async (id) => {
    try {
      const { data } = await get(`orders/${id}/order_pending_email`);
      if (data.status == httpResponses.HTTP_SUCCESS) {
        toast.success("Mail sent", {
          autoClose: 4000,
        });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to send mail.!", {
        autoClose: 4000,
      });
    }
  };

  restoreFailedVouchers= async () => {
    this.setState({
      isProcess: true,
    });
    console.log(this.state?.restoreVoucher)
    if(this.state?.restoreVoucher!==null){
      try {
        let orderData = encrypt({
          order_id: this.state?.restoreVoucher?.order_id,
          brand_id: this.state?.restoreVoucher?.brand_id
        });

        console.log("order data : " , decrypt(orderData))
          const { data } = await post("razorpay/restore_failed_vouchers",{data:orderData});
          if (data.status == httpResponses.HTTP_SUCCESS) {
            this.getPaginatedData(this.state.offset);
            this.setState({
              isProcess: false,
              isShowMessage:false,
              restoreVoucher:null
            });
            toast.success(data?.message, {
              autoClose: 2000,
            });
          }else{
            this.setState({
              isProcess: false,
              isShowMessage:false,
              restoreVoucher:null
            });
            toast.error(data?.message, {
              autoClose: 2000,
            });
          }
      } catch (e) {
        console.log("error", e);
        this.setState({
          isProcess: false,
          isShowMessage:false,
          restoreVoucher:null
        });
      }
    }else{
      this.setState({
        isProcess: true,
        isShowMessage:false,
      });
    }
  };

  closeMessageModal = ()=>{
    this.setState({isShowMessage:false, restoreVoucher:null })
  }

  showVoucherModal = async (id) => {
    try {
      const { data } = await get(`vouchers/orders/user_vouchers/${id}`);
      var voucherData = data.data.vouchers;
      console.log("voucherData", voucherData)
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isShowVoucher: true, voucherData });
  };

  hideVoucherModal = () => {
    this.setState({ isShowVoucher: false });
  };
  hideVoucherComponent = () => {
    this.setState({ isTransactionTableShow: true, showBackBtn: false });
  };

  fetchOrders = async (pagIndex, currentRowsPerPage) => {
    // console.log("pagIndex: ", pagIndex, " currentRowsPerPage: ", currentRowsPerPage)
    this.setState({ isLoading: true });
    try {
      const { data } = await post(`orders/report/?startDate=Sun%20Jan%2001%202023%2017:19:17%20GMT+0530%20(India%20Standard%20Time)&endDate=${new Date()}`);
      var orderData = data.data ? data.data.map((item) => item) : [];
      var totalData = data.data.length ? data.data.length : [];
    } catch (e) {
      console.log("error", e); 
    }
    this.setState({
      orderData,
      offset: 1,
      totalData,
      pageSize: this.state.pageSize,
      isLoading: false,
    });
  };

  getPaginatedData = async (pagIndex) => {
    this.fetchOrders(pagIndex);
  };

  changeRowsPerPage = (currentRowsPerPage, currentPage) => {
    this.fetchOrders(currentPage, currentRowsPerPage);
  };

  async componentDidMount() {
    // try {
    //   const { data } = await get(`orders`, {
    //     partner_id: this.state.selectedPartner,
    //   });
    //   var orderData = data.data.orders;
    // } catch (e) {
    //   console.log("error", e);
    // }
    this.fetchOrders(1);
    // try {
    //   const { data } = await get(`orders/new_order_counts`);
    //   var orderCount = data.data.order_count;
    // } catch (e) {
    //   console.log("error", e);
    // }
    try {
      const { data } = await get("partners");
      var partners = data.data.partners;
    } catch (e) {
      console.log("error", e);
    }
    // this.setState({ orderData, partners, orderCount });
    this.setState({ partners });
  }

  onGetOrdersByPartner = async (e) => {
    // e.persist();
    this.setState({ isLoading: true });
    const selectedPartner = e.target.value || null;
    // try {
    //   const { data } = await get("orders", {
    //     partner_id: e.target.value === "" ? null : e.target.value,
    //   });
    //   var orderData = data.data.orders;
    // } catch (e) {
    //   console.log("error", e);
    // }
    try {
      const { data } = await post(`orders/report/?startDate=Sun%20Jan%2001%202023%2017:19:17%20GMT+0530%20(India%20Standard%20Time)&endDate=${new Date()}&search_param=${selectedPartner}`);
      var orderData = data.data ? data.data.map((item) => item) : [];
      var totalData = data.data.length ? data.data.length : [];
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      orderData,
      offset: 1,
      totalData,
      pageSize: this.state.pageSize,
      isLoading: false,
    });

    // this.setState(
    //   {
    //     selectedPartner,
    //   },
    //   () => {
    //     this.fetchOrders(1);
    //   }
    // );
  };

  onFilter = (e) => {
    this.setState({ filterText: e.target.value })
    // console.log('e--->',e)
    // this.setState({ filterText: e.target.value },()=>{
    //   this.fetchOrders(1)
    // });
    // this.filterDebounce(e.target.value);
  };

  filterDebounce = debounce((value) => {
    this.setState({ filterText: value }, () => {
      this.fetchOrders(1);
    });
  }, this.DEBOUNCE_TIME);

  onClear = () => {
    this.setState({ filterText: "" })
    // this.setState({ filterText: "" },()=>{
    //   this.fetchOrders(1)
    // });
    // this.filterDebounce("");
  };

  downloadReport = async (startDate, endDate) => {
    this.setState({
      isProcess: true,
    });
    try {
      const { data } = await download(
        `orders/orders_download/?startDate=${startDate}&endDate=${endDate}&search_param=${this.state.filterText}`,
        {
          partner_id: this.state.selectedPartner,
        }
      );
      const fileName = `orders_${moment(startDate).format("DD-MM-YYYY")}_${moment(endDate).format("DD-MM-YYYY")}.xlsx`; 
      downloadXML(data, fileName)
      
    } catch (e) {
      console.log("error", e);
    }
    this.toggleDownloadModal(false);
    this.setState({
      isProcess: false,
    });
  };

  render() {
    const {
      orderData,
      columns,
      filterText,
      // voucherData,
      userVoucherData,
      // columnVoucher,
      selectedPartner,
      partners,
      downLoadModalShow,
      isProcess,
      totalData,
      isLoading,
    } = this.state;

    const filteredItems = orderData && orderData.filter((item) =>
      item.order.uuid.toLowerCase().includes(filterText.toLowerCase())
    );
    // console.log("filteredItems: ", filteredItems)
    const customStyles = {
      headCells: {
          style: {
            fontWeight: 'bold',
          },
      },
  }
    return (
      <>
        <div className="row-fluid admin-registered-user">
          {isLoading ? (
            <div className="loader-center">
              <Loader />
            </div>
          ) : null}
          <div id="content" className="span9">
            <div className="row-fluid">
              <div className="block">
                <div className="navbar navbar-inner block-header">
                  <div className="muted pull-left">
                    {/* Transaction History <b>(New Orders After 16th Dec :{" "} {this.state.orderCount})</b> */}
                    Transaction History

                  </div>
                  {this.state.showBackBtn ? (
                    <div className="muted pull-right">
                      <a
                        className="btn btn-primary delete"
                        onClick={this.hideVoucherComponent}
                      >
                        Back
                      </a>
                    </div>
                  ) : <DownLoadButton click={this.toggleDownloadModal} />}
                </div>

                <div className="block-content  in">
                  <div className="span12">
                    {/* {this.state.isTransactionTableShow ? ( */}
                      <DataTable
                        subHeader
                        subHeaderComponent={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <select
                              style={{
                                background: "#fff",
                                padding: "5px 10px",
                              }}
                              name="selectedPartner"
                              value={selectedPartner}
                              onChange={this.onGetOrdersByPartner}
                            >
                              <option value="" selected disabled>Show By Partner Name</option>
                              <option value="all">Show All</option>
                              {partners.map((p) => (
                                <option value={p.id} key={p.id}>{p.name}</option>
                              ))}
                            </select>
                            
                            <FilterComponent
                              onFilter={this.onFilter}
                              onClear={this.onClear}
                              filterText={filterText}
                            />
                            
                            <DownloadModal
                              show={downLoadModalShow}
                              onHide={() => this.toggleDownloadModal(false)}
                              headerName={"Orders"}
                              downloadReport={this.downloadReport}
                              isProcess={isProcess}
                            />
                          </div>
                        }
                        columns={columns}
                        data={filteredItems}
                        pagination={true}
                        defaultSortField
                        // paginationServer={true}
                        customStyles={customStyles}
                        // highlightOnHover
                        expandableRows
                        // paginationTotalRows={totalData}
                        paginationPerPage={10}
                        expandableRowsComponent={<SampleExpandedComponent />}
                        // onChangePage={this.getPaginatedData}
                        // onChangeRowsPerPage={this.changeRowsPerPage}
                      />

                    {/* ) : (
                      <DataTable
                        columns={columnVoucher}
                        data={userVoucherData}
                        pagination={true}
                        defaultSortField
                        highlightOnHover
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VoucherModal
            hideVoucher={this.hideVoucherModal}
            show={this.state.isShowVoucher}
            voucherData={this.state.voucherData}
          />
        </div>
        <MessageModal
          isShowMessage={this.state?.isShowMessage}
          restoreFailedVouchers={this.restoreFailedVouchers}
          closeMessageModal={this.closeMessageModal}
          isProcess={this.state?.isProcess}
        />
      </>
    );
  }
}

const FilterComponent = (props) => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Order Id"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

// const sampleExpandedComponent = (data) => {
//   console.log("data: ", data)
//   return (
//     <table className="table">
//           <tr>
//             <th scope="row">Transaction Id</th><td>{data.txn_id}</td>
//             <th scope="row">Voucher Value</th><td><i class="fa fa-inr mx-1"></i>{data.value}</td>
//             <th scope="row">Bank Transaction Id</th><td>{data.bank_txn_id}</td>
//           </tr>
//           <tr>
//             <th scope="row">Quantity</th><td>{data.quantity}</td>
//             <th scope="row">GUID</th><td>{data.guid}</td>
//             <th scope="row">Amount</th><td><i class="fa fa-inr mx-1"></i>{data.amount}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Brand Name</th><td>{data.brand_name}</td>
//             <th scope="row">Status</th>
//             {
//                 data.status === "success" ?
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'green', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//                 :
//                 data.status === "fail" ?
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'red', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//                 :
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'blue', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//             }
//             <th scope="row">Voucher No.</th><td>{data.number}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Pin</th><td>{data.pin}</td>
//             <th scope="row">Voucher Code</th><td>{data.code}</td>
//             <th scope="row">Earning</th><td><i class="fa fa-inr mx-1"></i>{data.earn}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Bank Name</th><td>{data.bank_name}</td>
//             {/* <th scope="row">Payment Status</th><td>{data.payment_status}</td> */}
//             <th scope="row">End Date</th><td>{data.end_date}</td>
//           </tr>
//     </table>
//   );
// };

const SampleExpandedComponent = (data) => {
  console.log("data :", data.data.vouchers)
 
  const columnVoucher = [
    {
      name: "Voucher No.",
      sortable: true,
      selector: "number",
      cell: (row) => (
        <span>
          {row.number}
        </span>
      ), 
      width: "220px",
    },
    {
      name: "Voucher Code",
      sortable: true,
      selector: "code", 
      cell: (row) => (
        <span>
          {row.code}
        </span>
      ),
      width: "180px",
    },
    {
      name: "Pin",
      sortable: true,
      selector: "pin", 
      cell: (row) => (
        <span>
          {row.pin}
        </span>
      ),
      width: "100px",
    },
    {
      name: "End Date",
      sortable: true,
      selector: "end_date", 
      cell: (row) => (
        <span>
          {row.end_date}
        </span>
      ),
      width: "150px",
    },
    {
      name: "Status",
      sortable: true,
      selector: "status", 
      cell: (row) => {
        var status;
        var color;
        if (row.status.toLowerCase() === "success") {
          status = "SUCCESS";
          color = "green";
        } else if (row.status.toLowerCase() === "fail") {
          status = "FAILURE";
          color = "red";
        } else {
          status = row.status.toUpperCase();
          color = "blue";
        }

        return <span 
          style={{ 
            fontWeight: "bold", 
            color, 
          }}
        >
          {status}
        </span>;
      },
      width: "150px",
    },
    {
      name: "Voucher Value",
      sortable: true,
      selector: "value", 
      cell: (row) => (
        row.value &&
        <span>
          <i className="fa fa-inr"></i>&nbsp;{row.value}
        </span>
      ),
      width: "130px",
    },
    {
      name: "Earnings",
      sortable: true,
      selector: "earn", 
      cell: (row) => (
        row.earn !== null && 
        <span>
          <i className="fa fa-inr"></i>&nbsp;{row.earn.toString()}
        </span>
      ),
      width: "130px",
    },
    {
      name: "Quantity",
      sortable: true,
      selector: "quantity", 
      cell: (row) => (
        <span>
          {row.quantity}
        </span>
      ),
      width: "120px",
    },
    
    {
      name: "GUID",
      sortable: true,
      selector: "guid", 
      cell: (row) => (
        <span>
          {row.guid}
        </span>
      ),
      width: "350px",
    },
  ]

  const customStyles = {
    rows: {
        style: {
          backgroundColor: "#f0ecec",
          fontWeight: 'bold',
        },
    },
    headCells: {
        style: {
          backgroundColor: "#f0ecec",
          fontWeight: 'bold',
        },
    },
}

    return (
        <>
          <DataTable
            columns={columnVoucher}
            data={data.data.vouchers}
            pagination={false}
            defaultSortField
            customStyles={customStyles}
          />
        </>
    )
}

export default AllOrders;

import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/Coupon/ShowCoupon.scss";
import { get, Delete } from "../../../utills/API";
import { Link } from "react-router-dom";
import DeleteCoupon from "./DeleteCoupon";

class ShowCoupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "S.No.",
          selector: "sr_no",
          sortable: true
        },
        {
          name: "Brand Name",
          selector: "name",
          sortable: true
        },

        {
          name: "Product Guid",
          selector: "guid",
          sortable: true
        },
        {
          name: "Amount",
          selector: "amount",
          sortable: true
        },
        {
          name: "Status",
          selector: "status",
          sortable: true
        },
        {
          name: "View/Edit",
          button: true,
          cell: row => (
            <Link to={row.is_redeem ? `/admin/redeemBrandCoupon/${row.id}/edit` : `/admin/coupons/${row.id}/edit`}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Link>
          )
        },
        {
          name: "Delete",
          button: true,
          cell: row => (
            <a onClick={() => this.showDeleteModal(row.id)}>
              <i class="far fa-trash-alt"></i>
            </a>
          )
        }
      ],
      filterText: "",
      filterCoupons: "",
      filterStatus: "",
      resetPaginationToggle: false,
      allCoupons: [],
      brandCoupons: [],
      redeemBrandCoupons: [],
      showDeleteModal: false,
      brandCouponId: "",
      isLoad: false
    };
  }

  showDeleteModal = id => {
    this.setState({
      showDeleteModal: true,
      brandCouponId: id
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  deleteBrandCoupon = async Id => {
    try {
      const { data } = await Delete(`coupons/${Id}`);
      this.setState({ showDeleteModal: false, isLoad: !this.state.isLoad });
    } catch (e) {
      console.log("error", e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoad !== this.state.isLoad) {
      try {
        // const { data } = await get("coupons");
        const { data } = await get("coupons/all/coupon_status");

        var brandCoupons = data.data.shopCoupons;
        var redeemBrandCoupons = data.data.redeemCoupons;
        var allCoupons = [...data.data.shopCoupons, ...data.data.redeemCoupons]
          .map((cpn, i) => {
            if (cpn.is_active) {
              return {
                ...cpn,
                status: "Active",
                sr_no: ++i,
              }
            } else {
              return {
                ...cpn,
                sr_no: ++i,
                status: "Issued",
              }
            }
          })
        // let updatedBrandCoupons = brandCoupons.map(async coupon => {
        //   let category_name = await this.getCategoryName(coupon.category_id);
        //   let brand_name = await this.getBrandName(coupon.brand_id);
        //   coupon.category_name = category_name;
        //   coupon.brand_name = brand_name;

        //   return coupon;
        // });

        // brandCoupons = await Promise.all(updatedBrandCoupons);
      } catch (e) {
        console.log("error", e);
      }

      // this.setState({ brandCoupons });
      this.setState({ brandCoupons, redeemBrandCoupons, allCoupons });

    }
  }

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;
    return brandName;
  };

  // async componentDidUpdate(prevProps)

  async componentDidMount() {
    try {
      // const { data } = await get("coupons");

      const { data } = await get("coupons/all/coupon_status");


      var brandCoupons = data.data.shopCoupons;
      var redeemBrandCoupons = data.data.redeemCoupons;
      var allCoupons = [...data.data.shopCoupons, ...data.data.redeemCoupons]
        .map((cpn, i) => {
          if (cpn.is_active) {
            return {
              ...cpn,
              status: "Active",
              sr_no: ++i,
            }
          } else {
            return {
              ...cpn,
              sr_no: ++i,
              status: "Issued",
            }
          }
        })
      // var brandCoupons = BrandCouponWithStatus;
    } catch (e) {
      console.log("error", e);
    }
    // let updatedBrandCoupons = brandCoupons.map(async coupon => {
    //   let category_name = await this.getCategoryName(coupon.category_id);
    //   let brand_name = await this.getBrandName(coupon.brand_id);
    //   coupon.category_name = category_name;
    //   coupon.brand_name = brand_name;

    //   return coupon;
    // });

    // brandCoupons = await Promise.all(updatedBrandCoupons);

    this.setState({ brandCoupons, redeemBrandCoupons, allCoupons });
  }

  getCategoryName = async id => {
    const { data } = await get(`categories/${id}`);
    var categoryName = data.data.category.name;
    return categoryName;
  };

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;

    return brandName;
  };

  searchHeader = e => {
    console.log("event", e);
  };

  onFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const { brandCoupons, columns, filterText, allCoupons, filterCoupons, filterStatus } = this.state;
    const filteredItems = allCoupons.filter(
      item =>
        item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())
    ).filter(item =>
      item.name && item.is_redeem.toString().includes(filterCoupons.toLowerCase())
    ).filter(item =>
      item.name && item.status.toLowerCase().includes(filterStatus.toLowerCase())
    )

    return (
      <div className="span9 brand-coupon">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Show Brand Coupons</div>

              <div className="muted pull-right">
                <Link
                  className="btn btn-primary delete"
                  to={"/admin/coupons/new"}
                  style={{
                    marginRight: "8px",
                  }}
                >
                  Add Coupons
                </Link>
                <Link
                  className="btn btn-primary delete"
                  to={"/admin/redeemBrandCoupon/new"}
                >
                  Add Redeem Coupons
                </Link>
              </div>

            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <>
                        <FilterTypes
                          onFilter={(e) => this.setState({ filterCoupons: e.target.value })}
                          filterText={filterCoupons}
                        />
                        <FilterStatus
                          onFilter={(e) => this.setState({ filterStatus: e.target.value })}
                          filterText={filterStatus}
                        />
                        <FilterComponent
                          onFilter={this.onFilter}
                          onClear={this.onClear}
                          filterText={filterText}
                        />
                      </>
                    }
                    columns={columns}
                    pagination={true}
                    //persistTableHead
                    data={filteredItems}
                  />
                  <DeleteCoupon
                    state={this.state}
                    showDeleteModal={this.showDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteBrandCoupon={this.deleteBrandCoupon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};
const FilterTypes = props => {
  return (
    <div className="row filterComp">
      {/* <div className="filterComp-input"> */}
      <div className="">
        <select
          // disabled={this.state.uploadSummary[0]}
          name="type"
          value={props.filterText}
          className="form-control"
          onChange={props.onFilter}
        >
          <option value={""}>All</option>
          <option value={"0"}>Shop Brands</option>
          <option value={"1"}>Redeem Brands</option>

          {/* {this.state.sources.map((src) => ( */}
          {/* <option value={src.id}>{src.name}</option> */}
          {/* ))} */}
        </select>

      </div>
      {/* </div> */}
    </div>
  )
}
const FilterStatus = props => {
  return (
    <div className="row filterComp">
      {/* <div className="filterComp-input"> */}
      <div className="">
        <select
          // disabled={this.state.uploadSummary[0]}
          name="type"
          value={props.filterText}
          className="form-control"
          onChange={props.onFilter}
        >
          <option value={""}>All</option>
          <option value={"Active"}>Active</option>
          <option value={"Issued"}>Issued</option>

          {/* {this.state.sources.map((src) => ( */}
          {/* <option value={src.id}>{src.name}</option> */}
          {/* ))} */}
        </select>

      </div>
      {/* </div> */}
    </div>
  )
}

export default ShowCoupon;

import React, { Component } from "react";
import ParallaxBg from "./Landing/ParallaxBg";
import { logout, get } from "../utills/API";
import cookie from "react-cookies";
import logo from "../assets/images/logoManagement/GainOnBlack.svg";
import puma from "../assets/img/puma.png";
import bigbazar from "../assets/img/bigbazar.png";
import myntra from "../assets/img/myntra.png";
import bigbasket from "../assets/img/bigbasket.png";
import { Link } from "react-router-dom";
import { Carousel, Container } from "react-bootstrap";
import HowItWork from "./Landing/HowItWork";
import Header from "./Common/Header";
import search from "../assets/images/gainon/search.svg";
import rightArrow from "../assets/images/gainon/right-arrow.svg";
import FooterMain from "./Common/FooterMain";
import popularicon from "../assets/images/gainon/myntra-icon.svg";
import favbgmask from "../assets/images/gainon/favourite-brand-bg.png";
import favbgmasksm from "../assets/images/gainon/favourite-brand-bg-sm.png";

class Landing_temp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topBrands: [],
      topRedeemBrands: [],
      isLoggedIn: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    try {
      const { data } = await get("ngp/top_brands");
      var topBrands = data ? data.data.brands : [];
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("ngp/top_redeem_brands");
      var topRedeemBrands = data ? data.data.brands : [];
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ topBrands, topRedeemBrands });
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
    // this.props.history.push("/login");
  };

  popularBrand = async (id) => {
    this.props.history.push(`/brand/${id}`);
  };

  favRedeem = async (id) => {
    this.props.history.push(`/redeemBrand/${id}`);
  };

  render() {
    const { topBrands, topRedeemBrands } = this.state;
    return (
      <>
        <Header
          logout={this.logout}
          isCurrentUser={this.isCurrentUser()}
          isLoggedIn={this.state.isLoggedIn}
        />
        <ParallaxBg search={search} rightarrow={rightArrow} />
        {/* {!this.isCurrentUser() ? ( */}
        <HowItWork
          logo={logo}
          puma={puma}
          bigbazar={bigbazar}
          myntra={myntra}
          bigbasket={bigbasket}
          search={search}
          rightarrow={rightArrow}
        />
        {/* ) : null} */}

        {(
          <>
            <section className="section-gainon section-most-popular">
              <Container>
                <div className="d-flex justify-content-between mb-4">
                  <h2 className="title">Most Popular</h2>
                  <Link
                    to={"/voucher"}
                    className="d-none d-md-block d-lg-block"
                  >
                    View all
                  </Link>
                </div>
                <div className="brand-home-wrap">
                  {topBrands &&
                    topBrands.length > 0 &&
                    topBrands.map((brand, i) => (
                      <div className="brand-box-home d-none d-xl-block d-lg-block" key={i}>
                        <div className="box-content">
                          <div className="w-100 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                            <div className="brand-logo">
                              {brand.image && (
                                <img
                                  src={brand.image}
                                  alt="Popular brand logo"
                                />
                              )}
                            </div>
                          </div>
                          <strong>
                            <span>Earn upto {brand.cash_back}%</span>
                          </strong>
                          <p>{brand.name}</p>
                          <div className="button-wrap">
                            <a
                              href={`/brand/${brand.id}`}
                              className="btn btn-primary btn-block"
                            >
                              Pay Here
                            </a>
                          </div>
                        </div>
                      </div>

                    ))}

                  {/* // For mobile devices */}
                  {topBrands &&
                    topBrands.length > 0 &&
                    topBrands.map((brand, i) => (
                      <div className="brand-box-home-sm d-block d-lg-none" key={i} onClick={() => this.popularBrand(brand.id)}>
                        <div className="box-content">
                          <div className="w-100 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                            <div className="brand-logo">
                              {brand.image && (
                                <img
                                  src={brand.image}
                                  alt="Popular brand logo"
                                />
                              )}
                            </div>
                          </div>
                          <strong>
                            <span>Earn upto {brand.cash_back}%</span>
                          </strong>
                          <p>{brand.name}</p>
                        </div>
                      </div>

                    ))}
                </div>
                <Link to={"/voucher"} className="d-block d-sm-block d-md-none">
                  View all
                </Link>
              </Container>
            </section>

            <section className="section-gainon section-favourite-brand">
              <img
                src={favbgmask}
                className="modal-blur bgmask d-none d-xl-block d-lg-block"
                alt="bg"
              />
              <img
                src={favbgmasksm}
                className="modal-blur bgmask d-block d-lg-none"
                alt="bg"
              />
              <Container>
                <div className="d-flex justify-content-between mb-4">
                  <h2 className="title">Redeem With Your Favourite Brand</h2>
                  <Link
                    to={"/voucher/redeem"}
                    className="d-none d-md-block d-lg-block"
                  >
                    View all
                  </Link>
                </div>
                <div className="brand-box-wrap">
                  {topRedeemBrands &&
                    topRedeemBrands.length > 0 &&
                    topRedeemBrands.map((brand, i) => (
                      <div className="voucher-box-home d-none d-xl-block d-lg-block" key={i} >
                        <div className="box-content">
                          <div className="w-100 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                            <div className="brand-logo">
                              {brand.image && (
                                <img
                                  src={brand.image}
                                  alt="Favourite brand logo"
                                />
                              )}
                            </div>
                          </div>
                          <strong>
                            <span>{brand.name}</span>
                          </strong>
                          <p>
                            Minimum redeemable <br />
                            amount : <strong>₹ 250</strong>
                          </p>
                          <div className="button-wrap">
                            <a
                              href={`/redeemBrand/${brand.id}`}
                              className="btn btn-primary btn-block"
                            >
                              Redeem
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* // For mobile devices */}
                  {topRedeemBrands &&
                    topRedeemBrands.length > 0 &&
                    topRedeemBrands.map((brand, i) => (
                      <div className="voucher-box-content" key={i}>
                        <div className="voucher-box-home-sm d-block d-lg-none" onClick={() => this.favRedeem(brand.id)}>
                          <div className="box-content">
                            <div className="w-100 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                              <div className="brand-logo">
                                {brand.image && (
                                  <img
                                    src={brand.image}
                                    alt="Favourite brand logo"
                                  />
                                )}
                              </div>
                            </div>
                            <strong>
                              <span>{brand.name}</span>
                            </strong>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <Link to={"/voucher/redeem"} className="d-block d-sm-block d-md-none">
                  View all
                </Link>
              </Container>
            </section >
          </>
        )
        }
        
        <FooterMain />
      </>
    );
  }
}

export default Landing_temp;

import React, { Component } from "react";
import { post, get } from "../../../utills/API";
import { ToastContainer, toast } from "react-toastify";
import "../../../assets/sass/admin/Coupon/AddCoupon.scss";
import { Link } from "react-router-dom";
import { required } from "../../../utills/Validation";
import DatePicker from "react-datepicker";

class AddCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_id: "",
      brand_id: "",
      guid: "",
      pin: '',
      amount: null,
      categoryArr: [],
      brandsArr: [],
      category_name: "",
      brand_name: "",
      errors: {},
      brands: [],
      couponName: "",
      expiry_date: new Date(),
      is_active: false,
      vendor_id: null,
    };
  }

  validateAllInputs = () => {
    const errors = {
      categoryError: null,
      brandError: null,
      guidError: null,
      amountError: null,
      couponNameError: null
    };
    const { category_id, brand_id, guid, amount, couponName, expiry_date } = this.state;
    errors.categoryError = required(category_id);
    errors.brandError = required(brand_id);
    errors.guidError = required(guid);
    errors.amountError = required(amount);
    errors.couponNameError = required(couponName);

    if (errors.amountError === null && isNaN(amount)) {
      errors.amountError = "amount should only be a Number";
    }

    this.setState({ errors });
  };

  validityCheck = () => {
    const { category_id, brand_id, guid, amount, couponName, expiry_date } = this.state;
    let isValid =
      category_id === "" ||
      brand_id === "" ||
      guid === "" ||
      couponName === "" ||
      amount === null;
    return !isValid;
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === "category_id") {
      let filterBrandArr = this.state.brands.filter(
        brand => brand.category_id === Number(value)
      );
      this.setState({
        category_id: value,
        brandsArr: value ? filterBrandArr : this.state.brands
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  addBrandCoupon = async e => {
    e.preventDefault();

    this.validateAllInputs();
    if (this.validityCheck()) {
      const { category_id, brand_id, guid, pin, amount, couponName, expiry_date, is_active } = this.state;
      e.preventDefault();
      const addBrandCouponData = {
        reedem_brand_coupon: {
          brand_id: Number(brand_id),
          category_id: Number(category_id),
          guid,
          pin_number: pin,
          amount,
          name: couponName,
          expiry_date,
          is_active,
          vendor_id: this.state.vendor_id
        }
      };

      try {
        const { data } = await post("reedem_brand_coupons", addBrandCouponData);
        if (data.status === 200) {
          this.props.history.push("/admin/coupons");
        } else {
          toast.error("Something Went Wrong!", { autoClose: 2000 });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      console.log("Enter valid Details");
    }
  };

  async componentDidMount() {
    try {
      const { data } = await get("categories");
      const brands = await get("reedem_brands");
      const Vendors = await get("vendors");

      var categoryData = data.data.categories;
      this.setState({
        categoryArr: categoryData,
        brandsArr: brands.data.data.reedem_brands,
        brands: brands.data.data.reedem_brands,
        vendor_id: Vendors.data.data.vendors.filter(vend => vend.name.toLowerCase() === "ngp")[0].id

      });
    } catch (e) {
      console.log("error", e);
    }
  }

  handleExpiryDate = date => {
    this.setState({ expiry_date: date });
  };

  componentDidUpdate() { }

  statusHandleChange = () => {
    this.setState({
      is_active: !this.state.is_active
    })
  }

  render() {
    const {
      category_id,
      brand_id,
      guid,
      pin,
      amount,
      errors,
      couponName,
      expiry_date,
      is_active
    } = this.state;
    const { handleChange, statusHandleChange } = this;
    return (
      <div id="content" className="span9 brand-coupon-add">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Add New Redeem Brand Coupon</div>
            </div>
            <div className="cont">
              <div className="row add-coupon-main">
                <div className="col-md-12 main-row">
                  <div className="name">Coupon Name</div>
                  <div className="val">
                    <input
                      className="form-control"
                      type="text"
                      name="couponName"
                      placeholder="Enter Coupon Name"
                      value={couponName}
                      onChange={handleChange}
                    />
                    {errors.couponNameError ? (
                      <p className="error-warning">{errors.couponNameError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">Category Name</div>
                  <div className="val">
                    <select
                      name="category_id"
                      value={category_id}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value={""}>Select Category</option>

                      {this.state.categoryArr.map(catgory => (
                        <option value={catgory.id}>{catgory.name}</option>
                      ))}
                    </select>
                    {errors.categoryError ? (
                      <p className="error-warning">{errors.categoryError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">Redeem Brand Name</div>
                  <div className="val">
                    <select
                      name="brand_id"
                      value={brand_id}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value={""}>Select Redeem Brand</option>
                      {this.state.brandsArr.map(brand => (
                        <option value={brand.id}>{brand.name}</option>
                      ))}
                    </select>
                    {errors.brandError ? (
                      <p className="error-warning">{errors.brandError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">Guid</div>
                  <div className="val">
                    <input
                      className="form-control"
                      type="text"
                      name="guid"
                      placeholder="Enter Guid"
                      value={guid}
                      onChange={handleChange}
                    />
                    {errors.guidError ? (
                      <p className="error-warning">{errors.guidError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">PIN</div>
                  <div className="val">
                    <input
                      className="form-control"
                      type="text"
                      name="pin"
                      placeholder="Enter Pin number"
                      value={pin}
                      onChange={handleChange}
                    />
                    {errors.pinError ? (
                      <p className="error-warning">{errors.pinError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">Amount</div>
                  <div className="val">
                    <input
                      className="form-control"
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={handleChange}
                    />
                    {errors.amountError ? (
                      <p className="error-warning">{errors.amountError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="name">Expiry Date</div>
                  <div className="val">
                    <DatePicker
                      className="form-control"
                      selected={expiry_date}
                      onChange={this.handleExpiryDate}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-3">Active</div>
                  <div className="onoffswitch">
                    <input
                      type="checkbox"
                      name="isStatus"
                      checked={is_active}
                      onClick={e => statusHandleChange()}
                      className="onoffswitch-checkbox"
                      id={`isStatus`}
                    />
                    <label
                      className="onoffswitch-label"
                      for="isStatus"
                    >
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="action-center">
                    <button
                      onClick={this.addBrandCoupon}
                      className="btn btn-primary"
                    >
                      Add Coupon
                    </button>
                    {/* <Link
                      to="/admin/redeemBrandCoupon"
                      className="btn btn-primary"
                    >
                      Cancel
                    </Link> */}
                    <Link to="/admin/coupons" className="btn btn-primary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCoupon;

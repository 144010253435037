import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { post } from "../utills/API";
import { ToastContainer, toast } from "react-toastify";
import AlertDismissible from "./../components/Common/Alert";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      showError: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  forgotPassword = async (e) => {
    e.preventDefault();

    const forgotPasswordData = {
      user: {
        email: this.state.email,
      },
    };
    try {
      const { data } = await post("forgot-password", forgotPasswordData);
      if (data.status === 200) {
        this.props.setModalSuccessPassword(true, this.state.email);
      } else if (data.status === 400) {
        // toast.error(data.message);
        this.setState({
          errorMessage: data.message,
          showError: true
        })
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  toggleError = () => {
    this.setState({
      errorMessage: "",
      showError: false
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { props } = this;

    return (
      <>
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            className="pb-0 border-bottom-0"
            closeButton
          ></Modal.Header>
          <Modal.Body>
            {/* <ToastContainer position={toast.POSITION.TOP_CENTER} /> */}
            <AlertDismissible
              toggleError={this.toggleError}
              errorMessage={this.state.errorMessage}
              show={this.state.showError}
            />
            <div className="pl-4 pr-4">
              <h1 className="font-weight-bolder text-24 mb-5">
                Forgot Password
              </h1>
              <form onSubmit={this.forgotPassword}>
                <div class="form-group">
                  <label>
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Enter Email ID"
                    class="form-control"
                    required
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0">
            <div className="d-flex flex-column w-100 pl-4 pr-4 pb-5">
              <Button
                variant="primary"
                block
                onClick={this.forgotPassword}
                disabled={this.state.isProcess}
              >
                {this.state.isProcess ? (
                  <Spinner animation="border" size="sm" />
                ) : null}
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* <ToastContainer position={toast.POSITION.TOP_CENTER} />

        <div
          className="container cou-wids min-heigs"
          style={{ marginTop: "80px", minHeight: "500px" }}
        >
          <div className="main-register fl-wrap">
            <div id="tab-2">
              <h3>
                <span>
                  <strong>Forgot Password</strong>
                </span>
              </h3>
              <div className="custom-form">
                
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default ForgotPassword;

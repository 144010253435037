import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/CouponCategory.scss";
import { get, Delete } from "../../../utills/API";
import { Link } from "react-router-dom";
import DeletePromoCode from "./DeletePromoCode";
import "../../../assets/sass/admin/Category/ShowCategory.scss";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";


class ShowCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "S.No.",
          sortable: true,
          selector: "sr_no"
        },
        {
          name: "Coupon Title",
          selector: "title",
          sortable: true,
          width: "150px",

        },
        {
          name: "Coupon Code",
          selector: "code",
          width: "150px",
          sortable: true
        },
        {
          name: "Amount",
          selector: "value",
          width: "150px",

          sortable: true
        },
        {
          name: "Expiry Date",
          selector: (row) => (

            row.isExpired ==  "Expired"?
              <span style={{ color: "red" }} >
                Expired
              </span>
              :
              <span style={{ color: "green" }} >
                {row.isExpired}
              </span>

          ),
          sortable: true,
          width: "150px",
        },
        {
          name: "Type",
          selector: (row) => row.code_type.toUpperCase(),
          sortable: true
        },
        {
          name: "Partner Name",
          selector: (row) => row.gopoint_partner_name && row.code_type == "gopoint" ? row.gopoint_partner_name.toUpperCase() : "-",
          sortable: true
        },
        {
          name: "Used By",
          button: true,
          cell: row => (
            <Link to={`/admin/promoCode/${row.id}/users`}>
              <i className="fa fa-users" aria-hidden="true"></i>
            </Link>
          )
        },
        {
          name: "Edit",
          button: true,
          cell: row => (
            <Link to={`/admin/promoCode/${row.id}/edit`}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>
          )
        },
        {
          name: "Delete",
          button: true,
          cell: row => (
            <a onClick={() => this.showDeleteModal(row.id)}>
              <i class="far fa-trash-alt"></i>
            </a>
          )
        }, {
          name: "Copy URL",
          button: true,
          cell: (row) => (
            <>
              {
                row.code_type == "gopoint" &&
                (
                  <CopyToClipboard
                    text={row.URL}
                    onCopy={(e) => this.handleCopy("copy_id", e)}
                  >
                    <i class="far fa-clone" style={this.CopyStyle} />
                  </CopyToClipboard>
                )
              }
            </>
          ),
        }
      ],
      filterText: "",
      resetPaginationToggle: false,
      promoCodeData: [],
      showDeleteModal: false,
      redeemBrandId: "",
      isLoad: false,
      isCopied: {
        copy_id: false,
      },
    };
  }

  handleCopy = (data, e) => {
    this.setState({
      isCopied: {
        ...e,
        [data]:true
      }
    })
    // navigator.clipboard.writeText(data)
    toast.success("Copied successfully")
  }
   
  CopyStyle = {
    cursor: "pointer",
    marginLeft: "6px",
    marginRight: "6px"

}


  showDeleteModal = id => {
    this.setState({
      showDeleteModal: true,
      redeemBrandId: id
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoad !== this.state.isLoad) {
      try {
        const { data } = await get("promo_codes");
        var GetAllPromoCodeData = data.data.promo_codes;

        var GetExpiredPromoCodes = GetAllPromoCodeData
          .filter(promo => moment(promo.valid_to).isBefore(new Date()))
        var GetActivePromoCodes = GetAllPromoCodeData
          .filter(promo => moment(promo.valid_to).isSameOrAfter(new Date()))
        // var promoCodeData = data.data.promo_codes;
        var promoCodeData = [...GetActivePromoCodes, ...GetExpiredPromoCodes]
          .map((data, i) => ({ ...data, sr_no: ++i }))
      } catch (e) {
        console.log("error", e);
      }

      this.setState({ promoCodeData: promoCodeData });
    }
  }

  deleteCategory = async Id => {
    try {
      const { data } = await Delete(`promo_codes/${Id}`);
      this.setState({ showDeleteModal: false, isLoad: !this.state.isLoad });
    } catch (e) {
      console.log("error", e);
    }
  };

  async componentDidMount() {
    try {
      const { data } = await get("promo_codes");
      var GetAllPromoCodeData = data.data.promo_codes;
      console.log(GetAllPromoCodeData)

      var GetExpiredPromoCodes = GetAllPromoCodeData
        .filter(promo => moment(promo.valid_to).isBefore(new Date()))
      var GetActivePromoCodes = GetAllPromoCodeData
        .filter(promo => moment(promo.valid_to).isSameOrAfter(new Date()))
      // var promoCodeData = data.data.promo_codes;
      var promoCodeData = [...GetActivePromoCodes, ...GetExpiredPromoCodes]
        .map((data, i) => ({ ...data, sr_no: ++i }))




    } catch (e) {
      console.log("error", e);
    }

    this.setState({ promoCodeData: promoCodeData });
  }

  searchHeader = e => {
    console.log("event", e);
  };

  onFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {
    const { promoCodeData, columns, filterText } = this.state;
    const filteredItems = promoCodeData.filter(item =>
      item.title.toLowerCase().includes(filterText.toLowerCase()) || 
      item.code.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <div className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block" style={{ width: "900px", marginLeft: "-20px" }}>
            <div
              className="navbar navbar-inner block-header"
              style={{ padding: "0px 15px" }}
            >
              <div className="muted pull-left">Show Promo Codes</div>
              <div className="muted pull-right">
                <Link className="btn btn-primary delete" to={"promoCode/new"}>
                  Add Promo Code
                </Link>
              </div>
            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <FilterComponent
                        onFilter={this.onFilter}
                        onClear={this.onClear}
                        filterText={filterText}
                      />
                    }
                    columns={columns}
                    data={filteredItems}
                    pagination={true}
                    // persistTableHead
                    defaultSortField
                  />
                  <DeletePromoCode
                    state={this.state}
                    showDeleteModal={this.showDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteCategory={this.deleteCategory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

export default ShowCategory;

import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { isCurrentUser } from "../../utills/function";
import { Button } from "react-bootstrap";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";


class BrandVoucher extends Component {
  redirect = (id) => {
    let url;
    if (isCurrentUser()) {
      url = `/${this.props.redirectUrl}/${id}`;
    } else {
      url = `/login`;
    }
    return url;
  };

  render() {
    const {
      id,
      name,
      image,
      cash_back,
      cash_back_type,
      vendor_id,
    } = this.props.brand;
    return (
      <>
        {this.props.redeemTab === true ? (
          <div className="voucher-box">
            <div class="content">
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="brand-logo">
                  {image && <img alt={name} src={image} />}
                </div>
              </div>
              <strong>{name}</strong>
              <div class="button-wrap">
                <Button
                  variant="primary"
                  block
                  href={`/${this.props.redirectUrl}/${id}`}
                  >
                  Redeem
                </Button>
              </div>

            </div>
          </div>
        ) : vendor_id !== 3 ? (
          <div className="brand-box">
            <div className="w-100 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
              <div className="brand-logo">
                {image && <img alt={name} src={image} />}
              </div>
            </div>
            <p>{name}</p>
            {this.props.redeemTab === true ? null : (
              <>
                <strong>
                  Earn {cash_back_type !== "percentage" ? "Flat" : "Upto"}{" "}
                  {cash_back}
                  {cash_back_type === "percentage" ? "%" : null}
                </strong>
                <div className="button-wrap">
                  <Button
                    variant="primary"
                    block
                    href={`/${this.props.redirectUrl}/${id}`}
                  >
                    Pay Here
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

// export default withRouter(BrandVoucher);
export default BrandVoucher;

import React, { Component } from 'react';
import { Navbar, Nav, Dropdown, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Signup from '../MSignup';
import Login from '../MLogin';
import ForgotPassword from '../MForgotPassword';
import SuccessForgotPassword from '../MSucessfullPassword';
import SuccessSignup from '../MsuccessfullSignupModal';
import { get } from '../../utills/API';
import { loggedUser, isCurrentUser, isSubscribeModal, subscribeForm } from '../../utills/function';
// import MyAccountDropdown from "../User/MyAccountDropdown";
// import FlashModal from "../../components/User/FlashModal";
// import Search from "../Landing/Search";
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/gainon/logo-header.png';
import Contact from '../MContact';
import { encrypt, decrypt } from '../../utills/function';
import MSubscribeForm from '../MSubscribeForm';
import { LogoList } from './WhiteLabelLogoList';

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdown: false,
      userData: {},
      first: false,
      showFlashModal: false,
      isSearch: false,
      modalSignUp: false,
      modalLogin: false,
      modalPassword: false,
      modalSubscribe: false,
      modalSuccessPassword: false,
      changePasswordemail: '',
      showMenu: false,
      loggedInUserName: '',
      loggedInUserAmount: 0,
      navExtendedLink: '',
      isCompanyAvail: false,
    };
    this.showMenu = this.showMenu.bind(this);
  }
  showMenu(event) {
    event.preventDefault();

    this.setState({
      showMenu: !this.state.showMenu,
    });
  }
  setModal = (modal, opt) => {
    // set all false
    this.setState({
      modalSignUp: false,
      modalLogin: false,
      modalPassword: false,
    });
    // set only true
  };

  setModalSignUp = (opt) => {
    this.setState({
      modalLogin: false,
      modalPassword: false,
      modalSignUp: opt,
      modalSubscribe: false
    });
    if (this.props.onlyLogin) {
      this.props.changeOnlyLogin(false);
    }
  };

  setModalSubscribe = (opt) => {
    this.setState({
      modalLogin: false,
      modalPassword: false,
      modalSignUp: false,
      modalSubscribe: opt
    });
    if (this.props.onlyLogin) {
      this.props.changeOnlyLogin(false);
    }
  };

  setModalLogin = (opt) => {
    this.setState({
      modalSignUp: false,
      modalPassword: false,
      modalLogin: opt,
      modalSubscribe: false
    });
    if (this.props.onlyLogin) {
      this.props.changeOnlyLogin(false);
    }
  };

  setModalPassword = (opt) => {
    this.setState({
      modalSignUp: false,
      modalLogin: false,
      modalPassword: opt,
      modalSubscribe: false
    });
    if (this.props.onlyLogin) {
      this.props.changeOnlyLogin(false);
    }
  };

  setModalSuccessPassword = (opt, email) => {
    this.setState(
      {
        modalPassword: false,
        modalSuccessPassword: opt,
        changePasswordemail: email,
      },
      () => {
        if (!this.state.modalLogin) setTimeout(this.openLoginModal, 2000);
      }
    );
  };

  openLoginModal = () => {
    this.setState({
      modalLogin: true,
      modalSuccessPassword: false,
    });
  };

  setModalSuccessSignup = (opt) => {
    this.setState(
      {
        modalSuccessSignup: opt,
      },
      () => {
        setTimeout(this.handleClose, 2000);
      }
    );
    if (this.props.onlyLogin) {
      this.props.changeOnlyLogin(false);
    }
  };

  handleClose = () => {
    this.setState({
      modalSuccessSignup: false,
    });
    this.props.history.push('/voucher');
  };

  showDropdown = () => {
    this.setState({ isDropdown: !this.state.isDropdown });
  };

  hideDropDown = () => {
    this.setState({ isDropdown: false });
  };

  handleClickOut = () => {
    this.setState({ isDropdown: false });
  };

  getUserDetails = async () => {
    try {
      const { data } = await get('users/detail');
      let decData = decrypt(data.data.user);
      if (this.props.getUserId) {
        this.props.getUserId(decData.id);
      }
      var userData = decData;
      var firstname = '';
      var lastname = '';
      var wallet = 0;
      loggedUser(userData);
      console.log(userData)
      localStorage.setItem('walletBalance', userData.wallet);
      localStorage.setItem('rewardBalance', userData.ngp_gift_discount);
      localStorage.setItem('goPointsBalance', userData.go_points)

      if (userData.first_name) {
        firstname = userData.first_name.charAt(0);
      }
      if (userData.last_name) {
        lastname = userData.last_name.charAt(0);
      }
      if (this.props.userDetail) {
        this.props.userDetail(userData);
      }
      wallet =
        // userData.wallet || userData.ngp_gift_discount
        //   ? Number(userData.wallet) + Number(userData.ngp_gift_discount)
        //   : 0;
        userData.wallet
          ? Number(userData.wallet)
          : 0;
    } catch (e) {
      console.log('error', e);
    }
    this.setState({
      userData,
      loggedInUserName: firstname.toUpperCase() + lastname.toUpperCase(),
      loggedInUserAmount: Math.floor(Number(wallet)) + Math.floor(Number(userData.go_points)),
    });
    if (this.props.setTotalAmount)
      this.props.setTotalAmount(
        Number(userData.wallet) >= 0
          ? wallet
          : Number(userData.ngp_gift_discount)
      );
    this.setModalLogin(false);
  };

  async componentDidMount() {
    const GetLogo = LogoList
      .filter(compny => compny.company_name.toLowerCase() === this.props.match.params.company_name && this.props.match.params.company_name.toLowerCase());

    if (GetLogo[0]) {
      this.setState({
        isCompanyAvail: GetLogo[0]
      })
    }
    const SUB_LOAD_TIME = 30000;
    if (this.props.isCurrentUser) {
      this.getUserDetails();
    } else {
      if (!isSubscribeModal()) {
        setTimeout(async () => {
          await this.setModalSubscribe(true)
          await subscribeForm(true)
        }, SUB_LOAD_TIME)
      }
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.onlyLogin !== this.props.onlyLogin) {
      if (this.props.onlyLogin) {
        this.setState({
          modalLogin: !this.state.modalLogin,
        });
      }
    }
    if (
      prevProps.changedAmount !== this.props.changedAmount &&
      this.props.isCurrentUser
    ) {
      this.getUserDetails();
    }
    if (prevProps.navExtendedLink !== this.props.navExtendedLink) {
      this.setState({
        navExtendedLink: this.props.navExtendedLink,
      });
    }
  }

  togleFlashModal = () => {
    this.setState({
      showFlashModal: !this.state.showFlashModal,
    });
  };

  logout = () => {
    if (this.props.setTotalAmount) this.props.setTotalAmount(0);
    this.props.logout();
  };

  searchHandle = () => {
    this.setState({ isSearch: true });
  };

  searchClose = () => {
    this.setState({ isSearch: false });
  };

  goToWallet = () => {
    //   <Link to={"/wallet"}>
    // </Link>
    this.props.history.push('/wallet');
  };

  setmodalContact = (opt) => {
    this.setState({
      modalContact: opt,
    });
  };

  render() {
    // const { isSearch } = this.state;
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href="/"
        ref={ref}
        style={{ display: 'flex' }}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <i className="icon-angle-down ml-2" style={{ fontSize: '10px' }} />
      </a>
    ));

    return (
      <>
        {!this.props.onlyLogin && (
          <div style={{ height: '53px' }}>
            <Navbar fixed="top" expand="lg" className="go-header">
              <Container>
                {
                  this.state.isCompanyAvail ?
                    <Navbar.Brand href={`/${this.state.isCompanyAvail.company_name}`} className="p-0">
                      <img src={this.state.isCompanyAvail.logo} alt="Company Logo" />
                    </Navbar.Brand>
                    :
                    <Navbar.Brand href="/" className="p-0">
                      <img src={logo} alt="Company Logo" />
                    </Navbar.Brand>
                }

                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                {/* <Navbar.Collapse className="go-menu" id="basic-navbar-nav"> */}
                {/* <Nav className="m-auto d-none d-lg-flex">
          <Nav.Link href="#home">Shop</Nav.Link>
          <Nav.Link href="#link">Redeem</Nav.Link>
        </Nav> */}
                {
                  this.state.isCompanyAvail ?

                    <Nav className={`${this.state.isCompanyAvail.company_name}-nav m-auto d-none d-lg-flex`}>
                      <Nav.Link to={"/" + this.state.isCompanyAvail.company_name + '/voucher' + '/' + this.state.navExtendedLink} exact as={NavLink} className="mr-3">
                        <i className="icon-shop mr-2"></i>
                        Shop
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to={"/" + this.state.isCompanyAvail.company_name + "/voucher/redeem" + '/' + this.state.navExtendedLink}
                        className="mr-3"
                      >
                        <i className="icon-redeem mr-2"></i>
                        Redeem
                      </Nav.Link>
                      <Nav.Link as={NavLink} exact to={"/" + this.state.isCompanyAvail.company_name + "/rewards"} className="mr-3">
                        <i className="icon-gift mr-2"></i>
                        Rewards
                      </Nav.Link>
                    </Nav>
                    :

                    <Nav className="go-nav m-auto d-none d-lg-flex">
                      <Nav.Link to={'/voucher' + '/' + this.state.navExtendedLink} exact as={NavLink} className="mr-3">
                        <i className="icon-shop mr-2"></i>
                        Shop
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to={"/voucher/redeem" + '/' + this.state.navExtendedLink}
                        className="mr-3"
                      >
                        <i className="icon-redeem mr-2"></i>
                        Redeem
                      </Nav.Link>
                      <Nav.Link as={NavLink} exact to="/rewards" className="mr-3">
                        <i className="icon-gift mr-2"></i>
                        Rewards
                      </Nav.Link>
                    </Nav>
                }
                <Nav className="justify-content-end flex-grow-0 flex-row">
                  {isCurrentUser() && (
                    <div
                      onClick={() => this.goToWallet()}
                      className="box-money d-flex align-items-center"
                    >
                      <div className="box-rupee align-items-center d-lg-flex d-none">
                        <i className="icon-wallet mr-2" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g transform="translate(-1173 -24)">
                            <g transform="translate(1147 12)">
                              <g transform="translate(0 9)">
                                <g transform="translate(26 1)">
                                  <g transform="translate(0 2)">
                                    <rect width="14" height="14" fill="none" />
                                    <path
                                      d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                      fill="#333"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        {Math.floor(this.state.loggedInUserAmount)}
                      </div>
                    </div>
                  )}
                  {isCurrentUser() ? (
                    <Dropdown alignRight className="d-lg-block d-none">
                      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                        <div className="box-avtar">
                          {this.state.loggedInUserName}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-0">
                        <Dropdown.Item href="/my-vouchers">
                          <i className="icon-voucher mr-3" />
                          Transaction History
                        </Dropdown.Item>
                        <Dropdown.Item href="/profile">
                          <i className="icon-profile mr-3" />
                          My Profile
                        </Dropdown.Item>
                        <Dropdown.Item href="/faq">
                          <i className="icon-faq mr-3" />
                          FAQs
                        </Dropdown.Item>
                        <Dropdown.Item href="/about">
                          <i className="icon-faq mr-3" />
                          About Us
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            this.logout();
                          }}
                        >
                          <i className="icon-logout mr-3" />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <>
                      <Nav.Item
                        as="li"
                        className="d-lg-block d-md-block d-none"
                      >
                        <Nav.Link
                          onClick={() => this.setModalSignUp(true)}
                          className="p-2"
                        >
                          Signup
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item
                        as="li"
                        className="d-lg-block d-md-block d-none"
                      >
                        <Nav.Link
                          onClick={() => this.setModalLogin(true)}
                          className="p-2"
                        >
                          Login
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                </Nav>
                {/* </Navbar.Collapse> */}
                <button
                  type="button"
                  className="btn btn-menu d-lg-none"
                  onClick={this.showMenu}
                >
                  <i
                    className={this.state.showMenu ? 'icon-close' : 'icon-bars'}
                  />
                </button>
              </Container>
            </Navbar>

            <div
              className={
                'box-sidebar d-lg-none pt-0 ' +
                (this.state.showMenu ? 'open' : '')
              }
            >
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link
                  to={'/voucher' + '/' + this.state.navExtendedLink}
                  exact
                  as={NavLink}
                  className="text-14 font-weight-bold px-0 py-3"
                >
                  <div
                    onClick={(e) => {
                      this.showMenu(e);
                      this.props.history.push('/voucher');
                    }}
                  >
                    <i className="icon-shop mr-2"></i>
                    Shop
                  </div>
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  exact
                  to={"/voucher/redeem" + '/' + this.state.navExtendedLink}
                  className="text-14 font-weight-bold px-0  py-3"
                >
                  <div
                    onClick={(e) => {
                      this.showMenu(e);
                      this.props.history.push('/voucher/redeem');
                    }}
                  >
                    <i className="icon-redeem mr-2"></i>
                    Redeem
                  </div>
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  exact
                  to="/rewards"
                  className="text-14 font-weight-bold px-0  py-3"
                >
                  <div
                    onClick={(e) => {
                      this.showMenu(e);
                      this.props.history.push('/rewards');
                    }}
                  >
                    <i className="icon-gift mr-2"></i>
                    Rewards
                  </div>
                </Nav.Link>
                {isCurrentUser() && (
                  <Nav.Link
                    as={NavLink}
                    exact
                    to="/wallet"
                    className="d-flex justify-content-between text-14 font-weight-bold px-0  py-3"
                  >
                    <div>
                      <i className="icon-wallet mr-2" /> My Wallet
                    </div>
                    <div style={{ color: '#DD3A3A' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <g transform="translate(-1173 -24)">
                          <g transform="translate(1147 12)">
                            <g transform="translate(0 9)">
                              <g transform="translate(26 1)">
                                <g transform="translate(0 2)">
                                  <rect width="14" height="14" fill="none" />
                                  <path
                                    d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                    fill="#DD3A3A"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      {this.state.loggedInUserAmount}
                    </div>
                  </Nav.Link>
                )}

                <div style={{ borderTop: '1px solid #979797' }}>
                  {isCurrentUser() && (
                    <div>
                      <Nav.Link
                        as={NavLink}
                        exact
                        to="/my-vouchers"
                        className="text-14 px-0  pt-3 pb-2"
                      >
                        Transaction History
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        exact
                        to="/profile"
                        className="text-14 px-0 pt-3 pb-2"
                      >
                        My Profile
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        exact
                        to="/changePassword"
                        className="text-14 px-0 pt-3 pb-2"
                      >
                        Change Password
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        exact
                        to="/studentVerification"
                        className="text-14 px-0 pt-3 pb-2"
                      >
                        Student Verification
                      </Nav.Link>
                    </div>
                  )}
                  <>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to="/faq"
                      className="text-14 px-0 pt-3 pb-2"
                    >
                      FAQs
                    </Nav.Link>
                    <Nav.Link
                      onClick={(e) => {
                        this.showMenu(e);
                        this.setmodalContact(true);
                      }}
                      className="text-14 px-0 pt-3 pb-2"
                    >
                      Contact us
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to="/about"
                      className="text-14 px-0 pt-3 pb-2"
                    >
                      About Us
                    </Nav.Link>
                  </>
                  {isCurrentUser() && (
                    <Nav.Link
                      className="text-14 px-0 pt-3 pb-2"
                      onClick={this.props.logout}
                    >
                      Logout
                    </Nav.Link>
                  )}
                  {!isCurrentUser() && (
                    <div>
                      <Nav.Link
                        className="text-14 px-0 pt-3 pb-2"
                        onClick={(e) => {
                          this.showMenu(e);
                          this.setModalLogin(true);
                        }}
                      >
                        Login
                      </Nav.Link>
                      <Nav.Link
                        className="text-14 px-0 pt-3 pb-2"
                        onClick={(e) => {
                          this.showMenu(e);
                          this.setModalSignUp(true);
                        }}
                      >
                        Signup
                      </Nav.Link>
                    </div>
                  )}
                </div>
              </Nav>
            </div>
          </div>
        )}

        <Signup
          show={this.state.modalSignUp}
          onHide={() => this.setModalSignUp(false)}
          setModalSuccessSignup={this.setModalSuccessSignup}
          getUserDetails={this.getUserDetails}
        />
        <Login
          show={this.state.modalLogin}
          onHide={() => this.setModalLogin(false)}
          openloginCloseSignup={this.setModalSignUp}
          setModalPassword={this.setModalPassword}
          getUserDetails={this.getUserDetails}
        />
        <MSubscribeForm
          show={this.state.modalSubscribe}
          onHide={() => this.setModalSubscribe(false)}
        />
        <ForgotPassword
          show={this.state.modalPassword}
          onHide={() => this.setModalPassword(false)}
          setModalSuccessPassword={this.setModalSuccessPassword}
        />
        <SuccessForgotPassword
          show={this.state.modalSuccessPassword}
          onHide={() => this.setModalSuccessPassword(false, '')}
          changePasswordemail={this.state.changePasswordemail}
        />
        <SuccessSignup
          show={this.state.modalSuccessSignup}
          onHide={() => this.setModalSuccessSignup(false)}
        />
        <Contact
          show={this.state.modalContact}
          onHide={() => this.setmodalContact(false, '')}
        />
      </>
    );
  }
}

export default withRouter(UserHeader);
// export default UserHeader;

import React from "react";
import { Modal } from "react-bootstrap";
// import "../../../assets/sass/admin/Coupon/DeleteCoupon.scss";

const DeleteCoupon = props => {
  return (
    <Modal
      className="delete-coupon-modal"
      show={props.state.showDeleteModal}
      onHide={props.closeDeleteModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure, you want to delete this Brand Coupon?
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-container">
          <button
            className="btn btn-primary delete"
            onClick={() => props.deleteBrandCoupon(props.state.brandCouponId)}
          >
            Delete Brand Coupon
          </button>
          <button
            className="btn btn-primary delete"
            onClick={props.closeDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCoupon;

import React, { Component } from 'react'
import '../../../assets/sass/admin/RegisteredUser.scss'
import { get, put } from '../../../utills/API'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import moment from 'moment'

class PromoCodeUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          name: 'Email Id',
          selector: 'email',
          sortable: true
        },
        {
          name: 'Name',
          selector: 'first_name',
          sortable: true,
          cell: row => (
            <span>
              {row.first_name} {row.last_name}
            </span>
          )
        },
        {
          name: 'Mobile No.',
          selector: 'mobile',
          sortable: true,
          cell: row => (
            <span>
              {row.country_code} {row.mobile}
            </span>
          )
        },
        {
          name: 'Used On',
          selector: 'updatedAt',
          sortable: true,
          cell: row => <span>{moment(row.updatedAt).format('DD-MM-YYYY')}</span>
        },
      ],
      userData: [],
      filterText: ''
    }
  }

  async componentDidMount() {
    const { promoCodeId } = this.props.match.params;

    try {
      const { data } = await get(`promo_codes/${promoCodeId}/users`);
      var userData = data.data.users;
      this.setState({ userData })
    } catch (e) {
      console.log("error", e);
    }
  }

  onFilter = e => {
    this.setState({ filterText: e.target.value })
  }

  onClear = () => {
    this.setState({ filterText: '' })
  }

  render() {
    const {
      columns,
      userData,
      filterText
    } = this.state
    const filteredItems = userData.filter(item =>
      item.email.toLowerCase().includes(filterText.toLowerCase()) || item.mobile.toLowerCase().includes(filterText.toLowerCase()) || item.first_name.toLowerCase().includes(filterText.toLowerCase()) || item.last_name.toLowerCase().includes(filterText.toLowerCase())
    )
    return (
      <div class="row-fluid admin-registered-user">
        <div id="content" class="span9">
          <div class="row-fluid">
            <div class="block" id="regUser">
              <div class="navbar navbar-inner block-header">
                <div class="muted pull-left">User List For Promo Code</div>
                <div className="muted pull-right">
                  <Link to={`/admin/promoCode`} className="btn btn-primary delete">
                    Back
                  </Link>
                </div>
              </div>

              <div class="block-content  in">
                <div class="span12">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                      >
                        <FilterComponent
                          onFilter={this.onFilter}
                          onClear={this.onClear}
                          filterText={filterText}
                        />
                      </div>
                    }
                    columns={columns}
                    data={filteredItems}
                    pagination={true}
                    // persistTableHead
                    defaultSortField
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name/Email/Mobile"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  )
}

export default PromoCodeUser

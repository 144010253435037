import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { post } from "../../../utills/API";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../../assets/sass/admin/Category/AddCategory.scss";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: ""
    };
  }

  handleCatogeryChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  addCategory = async e => {
    e.preventDefault();

    const addCategoryData = { category: { name: this.state.category } };

    try {
      const { data } = await post("categories", addCategoryData);
      if (data.status === 200) {
        this.props.history.push("/admin/couponCategory");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Something Went Wrong!", { autoClose: 2000 });
    }
  };

  render() {
    const { category } = this.state;
    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Add New Coupon Category</div>
              {/* <Link to="/admin/couponCategory">Back</Link> */}
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <div className="block-content  in">
              <div className="span12">
                <div className="span3"></div>
                <div className="span6">
                  <form className="form-horizontal" onSubmit={this.addCategory}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      className="control-group"
                    >
                      <label for="focusedInput" className="control-label">
                        Coupon Category
                      </label>
                      <div className="controls">
                        <input
                          type="text"
                          name="category"
                          className="input-xlarge focused cat-input"
                          style={{ padding: "5px" }}
                          value={category}
                          onChange={this.handleCatogeryChange}
                          placeholder="enter new coupon category name here..."
                        />
                      </div>
                    </div>

                    <div className="control-group btn-section">
                      <div
                        style={{ margin: "0 0 20px 0" }}
                        className="controls"
                      >
                        <input
                          className="btn btn-submit"
                          type="submit"
                          value="Submit"
                        />
                        <Link
                          to="/admin/couponCategory"
                          className="btn"
                          type="reset"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="span3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddCategory);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../assets/scss/components/_svg-voucher.scss';
import classNames from 'classnames/bind';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SvgVoucher = (props) => {
  const { blue, purple, className } = props;
  const [copiedValue, setCopiedValue] = useState('');
  const [iscopied, setIscopied] = useState(false);
  const [isPincopied, setPinIscopied] = useState(false);

  const classes = classNames(
    'box-vouchcer',
    blue ? 'blue-wrapper' : '',
    purple ? 'purple-wrapper' : '',
    className || ''
  );

  useEffect(() => {
    if (iscopied || isPincopied) {
      let timer1 = setTimeout(() => {
        setIscopied(false);
        setPinIscopied(false);
      }, 3000);
      return () => clearTimeout(timer1);
    }
  }, [iscopied, isPincopied]);

  return (
    <>
      <div className={classes}>
        <div className="content">
          <div className="brand-wrap d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="company-logo d-flex align-items-center overflow-hidden mr-2 mr-lg-3">
                <img src={props.imagePath} alt="Brand Logo" />
              </div>
              <p className="brand-text mb-0">{props.voucher.brand_name}</p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                {props.voucher.value <= 1000 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 14 14"
                  >
                    <g transform="translate(-1173 -24)">
                      <g transform="translate(1147 12)">
                        <g transform="translate(0 9)">
                          <g transform="translate(26 1)">
                            <g transform="translate(0 2)">
                              <rect width="14" height="14" fill="none" />
                              <path
                                d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                fill="#FFFFFF"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 14 14"
                  >
                    <g transform="translate(-1173 -24)">
                      <g transform="translate(1147 12)">
                        <g transform="translate(0 9)">
                          <g transform="translate(26 1)">
                            <g transform="translate(0 2)">
                              <rect width="14" height="14" fill="none" />
                              <path
                                d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                fill="#FFFFFF"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
              </div>

              <p className="rupee-text mb-0">{parseInt(props.voucher.value)}</p>
            </div>
          </div>
          <div className="voucher-code-wrap d-lg-flex d-md-flex d-sm-block justify-content-between">
            <span className="title mb-1 mb-lg-0 text-uppercase">
              Voucher Code
            </span>
            <span className="voucher-id">{props.voucher.code || '-'}</span>
          </div>
          <div className="date-wrap d-lg-flex d-md-flex d-sm-block justify-content-between">
            <span className="title mb-1 mb-lg-0 text-uppercase">
              Valid Till
            </span>
            <span className="voucher-date">{props.voucher.end_date || '-'}</span>
          </div>
          <div
            className={'copy-code-wrap ' + (!props.voucher.pin ? 'space' : ' ')}
          >
            <div className="voucher-number-wrap text-center">
              <span className="title text-uppercase">Voucher Number</span>
              <div
                className={
                  iscopied
                    ? 'box-copy-code highlight mb-lg-3 mt-lg-3 mt-1 mb-2'
                    : 'box-copy-code mb-lg-3 mt-lg-3 mt-1 mb-2'
                }
              >
                {props.voucher.number || '-'}

                <CopyToClipboard
                  text={props.voucher.number}
                  onCopy={() => {
                    setIscopied(true);
                  }}
                >
                  {/* <a
              alt="copy voucher code"
              className={`voucher-number-${props.voucher.id}`}
            > */}
                  <i className="icon-copy ml-2" />
                  {/* </a>  */}
                </CopyToClipboard>
              </div>
            </div>
            {props.voucher.pin && (
              <div className="voucher-number-wrap text-center">
                <span className="title text-uppercase">Pin</span>
                <div
                  className={
                    isPincopied
                      ? 'box-copy-code highlight mb-lg-3 mt-lg-3 mt-1 mb-2'
                      : 'box-copy-code mb-lg-3 mt-lg-3 mt-1 mb-2'
                  }
                >
                  {props.voucher.pin}
                  <CopyToClipboard
                    text={props.voucher.pin}
                    onCopy={() => {
                      setPinIscopied(true);
                    }}
                  >
                    {/* <a
              alt="copy voucher code"
              className={`voucher-number-${props.voucher.id}`}
            > */}
                    <i className="icon-copy ml-2" />
                    {/* </a>  */}
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
          <p className="title-bottom mb-0">
            Please enter these details in the applicable section on brand
            website/app or show it to a member of staff to encash.
          </p>
        </div>
      </div>
    </>
  );
};

export default SvgVoucher;

SvgVoucher.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  blue: PropTypes.bool,
  purple: PropTypes.bool,
};

SvgVoucher.defaultProps = {
  blue: false,
  purple: false,
  className: '',
};

import React, { Component } from "react";
import { Container, InputGroup, FormControl } from "react-bootstrap";
import brandLogo from "../../assets/images/gainon/brand.svg";
import { post } from "../../utills/API";
import onClickOutside from "react-onclickoutside";

class OutsideSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchDropdown: false,
      search: "",
      searchDataArr: [],
    };
  }

  handleClickOutside = () => {
    console.log("onClickOutside() method called");
    this.setState({ isSearchDropdown: false });
  };

  handleSearchOnChange = async (e) => {
    const { value } = e.target;
    const searchData = {
      q: value,
    };
    let searchedBrand = [];
    try {
      var { data } = await post("ngp/pay_brand_search", searchData);
      searchedBrand = data.data.brands;
    } catch (e) {
      console.log("error", e);
    }    
    this.setState({ searchDataArr: searchedBrand, isSearchDropdown: true });
  };
  render() {
    const { search, searchDataArr, isSearchDropdown } = this.state;

    return (
      <>
        <div disableOnClickOutside={true} className="input-box-field">
          <FormControl
            placeholder="Search for your favourite brands"
            aria-label="Amount (to the nearest dollar)"
            onChange={(e) => this.handleSearchOnChange(e)}
          />
          {isSearchDropdown ? (
            <div className="dropdown" disableOnClickOutside={true}>
              {searchDataArr.length > 0 && (
                <>
                  {searchDataArr.map((data, index) => (
                    <div className="dropdown-item" key={index}>
                      <a href={`/brand/${data.id}`}>
                        {data.image && <img src={data.image} alt="" />}
                        <span className="ml-1">{data.name}</span>
                      </a>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default onClickOutside(OutsideSearch);

import React, { Component } from "react";
import { get, post, logout } from "../../utills/API";

import cookie from "react-cookies";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import GoNav from "../Common/GoNav";
import { Card, Form, Button } from "react-bootstrap";
import { STUDENT_CLIENT_ID, SERVICE_URL } from "../../utills/Constants";
import { withRouter } from "react-router-dom";
import { decrypt } from "../../utills/function";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcess: false,
      showError: false,
      errorMessage: "",
      type: "",
      userId: "",
      student_identity_status: "dfa",
    };
  }

  isCurrentUser = () => {
    let isLogin = !!cookie.load("accessToken");
    return isLogin;
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
    this.props.history.push("/");
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    try {
      const { data } = await get("users/detail");
      let decyptdata = decrypt(data.data.user);
      var userId = decyptdata.id;
      var student_identity_status = decyptdata.student_identity_status;
    } catch (e) {
      console.log("error", e);
    }
    console.log("decyptdata", student_identity_status);
    this.setState({ userId: userId, student_identity_status });
  }

  verifyStudent = async (e) => {
    e.preventDefault();
    let url = `https://app.studentidentify.com?oauth=true&clientId=${STUDENT_CLIENT_ID}&response_type=code&scope=status,basic_info,mobile&state=${this.state.userId}&redirect_uri=${SERVICE_URL}/studentidentify/callback`;
    console.log("url is url", url);
    window.location.href = url;
  };

  render() {
    const { isProcess, student_identity_status } = this.state;

    return (
      <>
        <Header
          logout={this.logout}
          isCurrentUser={this.isCurrentUser()}
          isLoggedIn={this.state.isLoggedIn}
        />
        <section className="page page-profile pt-4">
          <div className="container">
            <div className="flex-xl-nowrap row">
              <div className="col-auto d-none d-lg-flex flex-column">
                <GoNav />
              </div>
              <div className="col">
                <Card className="card-profile d-none d-md-flex d-lg-flex">
                  <Card.Body>
                    <h1 className="text-22 mb-5">Student Verification</h1>
                    <div className="button-group text-center">
                      <Button
                        variant="primary"
                        onClick={this.verifyStudent}
                        disabled={
                          student_identity_status == "verified" ? true : false
                        }
                        className="mr-4"
                      >
                        {student_identity_status == "verified"
                          ? "Verified"
                          : student_identity_status == "pending"
                          ? "Pending"
                          : isProcess
                          ? "Verifing"
                          : "Verify"}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <div className="d-block d-sm-block d-md-none">
                  <h1 className="font-weight-bold mb-4">
                    Student Verification
                  </h1>
                  <div className="button-group text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="mb-4"
                      disabled={
                        student_identity_status == "verified" ? true : false
                      }
                      onClick={this.verifyStudent}
                      block
                    >
                      {student_identity_status == "verified"
                        ? "Verified"
                        : student_identity_status == "pending"
                        ? "Pending"
                        : isProcess
                        ? "Verifing"
                        : "Verify"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default withRouter(ChangePassword);

import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import RewardCard from "./Common/RewardsCard";
import { post, get, logout } from "../utills/API";
import { useHistory } from "react-router";
import { encrypt, decrypt } from "../utills/function";
import "../assets/scss/components/_rewards_card.scss";
import Loader from "./Loader";

const Reward = (props) => {
  const [rewards, setRewards] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [changedAmount, setchangedAmount] = useState(0);
  const [onlyLogin, setOnlyLogin] = useState(false);
  const [isFirstStep, setisFirstStep] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const history = useHistory();

  let walletBalance = localStorage.getItem("walletBalance");
  let rewardBalance = localStorage.getItem("rewardBalance");

  const isCurrentUser = () => {
    let isLogin = !!cookie.load("accessToken");
    return isLogin;
  };

  const logoutUser = async () => {
    await logout();
    let isLogin = isCurrentUser();
    setisLoggedIn(isLogin);
    history.push({
      pathname: "/",
    });
  };

  const flipCard = (id, type, guid, pin) => {
    if (!isCurrentUser()) {
      changeOnlyLogin(true);
      return;
    }
    setisFirstStep(false);
    let changedReward = rewards.map((reward) => {
      if (reward.brand_id == id) {
        reward["color"] = type;
        if (guid) reward["guid"] = guid;
        if (pin) reward["pin"] = pin;
      }
      return reward;
    });

    setRewards([...changedReward]);
  };

  const changeOnlyLogin = (value) => {
    setOnlyLogin(value);
  };

  const redeemAmount = async (reward) => {
    setIsProcess(true);
    try {
      const { data } = await post("orders/redeem_reward", {
        selected_reward: encrypt(reward.brand_id),
      });

      if (data.status === 200) {
        setIsProcess(false);
        let decRedeemOrder = decrypt(data.data);
        console.log("decRedeemOrder", decRedeemOrder);
        flipCard(
          reward.brand_id,
          "plain",
          decRedeemOrder.guid ? decRedeemOrder.guid : "",
          decRedeemOrder.pin ? decRedeemOrder.pin : ""
        );
        setchangedAmount(
          Number(walletBalance) + Number(rewardBalance) - reward.amount
        );
      } else {
        setIsProcess(false);
      }
    } catch (e) {
      setIsProcess(false);
    }
  };

  const fetchRewards = async (id) => {
    try {
      const { data } = await get(id ? `ngp/rewards/${id}` : "ngp/rewards");
      const decBrand = decrypt(data.data.rewards);
      const uniquieCoupouns = getUnqueCoupons(decBrand);
      setisLoading(false);
      setRewards(uniquieCoupouns);
    } catch (e) {
      setisLoading(false);
    }
  };

  const getUnqueCoupons = (reviewData) => {
    let newArray = [];
    let newObject = {};
    for (let i in reviewData) {
      let objTitle = reviewData[i]["amount"] + reviewData[i]["brand_id"];
      newObject[objTitle] = reviewData[i];
    }
    for (let i in newObject) {
      newArray.push(newObject[i]);
    }
    return newArray;
  };

  const getUserId = (id) => {
    if (isFirstStep) fetchRewards(id);
  };

  useEffect(() => {
    setisLoading(true);
    if (!isCurrentUser()) {
      fetchRewards();
      setchangedAmount(Number(walletBalance) + Number(rewardBalance));
    }
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Header
        logout={logoutUser}
        isCurrentUser={isCurrentUser()}
        isLoggedIn={isLoggedIn}
        changedAmount={changedAmount}
        changeOnlyLogin={changeOnlyLogin}
        onlyLogin={onlyLogin}
        getUserId={getUserId}
      />
      <section className="page page-wallet pt-4 pt-lg-5">
        <Container>
          {!isLoading && (
            <div className="rewards-wrap">
              <p className="text-18 font-weight-bold">Rewards</p>

              <div className="rewards-card-wrap">
                {rewards &&
                  rewards.length > 0 &&
                  rewards.map((reward) => {
                    return (
                      <RewardCard
                        color={
                          reward.amount <= 100
                            ? "blue"
                            : reward.amount <= 200
                            ? "purple"
                            : reward.amount <= 300
                            ? "red"
                            : "black"
                        }
                        reward={reward}
                        flipCard={flipCard}
                        redeemAmount={redeemAmount}
                        isProcess={isProcess}
                      />
                    );
                  })}
              </div>
              {!rewards ||
                (rewards.length <= 0 && (
                  <p className="text-center py-5">
                    You currently do not have any Rewards available. Check back
                    soon!
                  </p>
                ))}
            </div>
          )}
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default withRouter(Reward);

export const GenerateAcronyms = (str1) => {
    if (str1.includes(" ")) {
        var split_names = str1.trim().split(" ");
        if (split_names.length > 1) {
            let clientstr = split_names.slice(0, 3).map((client) => client[0]).join('')
            return (`${clientstr.toUpperCase()}`);
        }
        return split_names[0].toUpperCase();
    } else {
        let result = str1.slice(0, 3);
        let code = `${result}`
        return code.toUpperCase()
    }
};
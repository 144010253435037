import axios from "axios";
import cookie from "react-cookies";
import { SERVICE_URL } from "./Constants";
import jsonp from "jsonp"

const headerConfig = {
  "Content-Type": "application/json",
};

// const URL = SERVICE_URL;

export const signup = async (signupData) => {
  return await axios.post('/ngp/sign-up', signupData, headerConfig);
};

export const login = async (loginData) => {
  return await axios.post(`/ngp/login`, loginData);
};

export const logout = async () => {
  await cookie.remove("accessToken", { path: "/" });
  await cookie.remove("roleId", { path: "/" });
  await cookie.remove("userId", { path: "/" });
  await localStorage.removeItem("checkoutPath");
  await localStorage.removeItem("checkoutData");
};

export const otpVerify = async (otpData) => {
  return await axios.post(`/ngp/verify-otp-web`, otpData, headerConfig);
};

export const otpResend = async (otpData) => {
  return await axios.post(`/resend-otp`, otpData, headerConfig);
};

export const post = async (basePath, data) => {
  return await axios.post(`/${basePath}`, data, headerConfig);
};

export const put = async (basePath, data) => {
  return await axios.put(`/${basePath}`, data, headerConfig);
};

export const get = async (basePath, data) => {
  return await axios.get(`/${basePath}`, { params: data ? data : {} });
};

export const Delete = async (basePath) => {
  return await axios.delete(`/${basePath}`, headerConfig);
};

export const download = async (basePath, data) => {
  return await axios.get(`/${basePath}`, { params: data ? data : {},  responseType: 'blob' });
};

export const jsonpRequest = async (params) => {
  const url= `https://gainon.us17.list-manage.com/subscribe/post-json?u=${params.u}&id=${params.id}&EMAIL=${params.EMAIL}`;
  return await jsonp(url, { param: "c" });
};

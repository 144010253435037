import React, { Component } from "react";
import BrandVoucher from "./BrandVoucher";
import { get, post } from "../../utills/API";
import cookie from "react-cookies";
import TakeMeModal from "./TakeMeModal";
import shopbanner from "../../assets/images/gainon/shop-brand-banner.jpeg";

class BrandVoucherContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryBrands: [],
      isProcess: false,
      showTakeMe: false,
      easyDineBrand: null,
    };
  }

  clickOnEasyDineBrand = async (brand) => {
    console.log('clickOnEasyDineBrand')
    this.setState({ isProcess: true });
    if (this.isCurrentUser()) {
      this.setState({
        showTakeMe: true,
        easyDineBrand: brand,
      });
    } else {
      //   // localStorage.setItem("checkoutData", JSON.stringify(this.state.coupons));
      //   // localStorage.setItem("checkoutPath", this.props.match.url);
      this.props.props.history.push("/login");
    }
    this.setState({ isProcess: false });
  };

  goToPartnerWebsite = async () => {
    const { easyDineBrand } = this.state;
    let userId = cookie.load("userId");
    window.open(easyDineBrand.website, "_blank");
    try {
      const formData = {
        user_id: userId ? Number(userId) : null,
        brand_id: easyDineBrand.id,
      };
      const { data } = await post("eazydiner_tracks", formData);
      this.setState({
        easyDineBrand: null,
        showTakeMe: false,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  techMeClose = () => {
    this.setState({
      showTakeMe: false,
      easyDineBrand: null,
    });
  };

  // async componentWillReceiveProps(nextProps) {
  //   const { currCategoryId, easyDineVendor } = nextProps;
  //   try {
  //     const { data } = await get("ngp/brands");
  //     var categoryBrands = data.data.brands;
  //   } catch (e) {
  //     console.log("error", e);
  //   }

  //   if (currCategoryId && !easyDineVendor) {
  //     categoryBrands = categoryBrands.filter(
  //       (brand) => brand.category_id === currCategoryId
  //     );
  //   } else if (!currCategoryId && easyDineVendor) {
  //     categoryBrands = categoryBrands.filter(
  //       (brand) => brand.vendor_id === easyDineVendor
  //     );
  //   }

  //   this.setState({ categoryBrands });
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currCategoryId !== this.props.currCategoryId ||
      prevProps.brands !== this.props.brands ||
      prevProps.easyDineVendor !== this.props.easyDineVendor
    ) {
      const { currCategoryId, easyDineVendor } = this.props;
      var categoryBrands = this.props.brands;
      if (currCategoryId && !easyDineVendor) {
        categoryBrands = categoryBrands.filter(
          (brand) => brand.category_id === currCategoryId
        );
      }
      // } else if (!currCategoryId && easyDineVendor) {
      //   categoryBrands = categoryBrands.filter(
      //     (brand) => brand.vendor_id === easyDineVendor
      //   );
      // }
      this.setState({ categoryBrands });
    }
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  render() {
    const { categoryBrands, showTakeMe, easyDineBrand } = this.state;
    return (
      <>
        {categoryBrands.length ? (
          <div className="">
            {/* <img src={shopbanner} alt="Shop Banner Img" className="shop-banner-img mb-4" /> */}
          <div className="brand-box-wrap">
          { this.state.categoryBrands.map((brand) => (
            
            <BrandVoucher
              clickOnEasyDineBrand={this.clickOnEasyDineBrand}
              brand={brand}
              redirectUrl={this.props.redirectUrl}
              redeemTab={this.props.redeemTab}
              props={this.props}
            />
           
          ))} </div>
          </div>
        ) : null}
        {showTakeMe ? (
          <TakeMeModal
            goToPartnerWebsite={this.goToPartnerWebsite}
            brand={easyDineBrand}
            showTakeMe={showTakeMe}
            close={this.techMeClose}
          />
        ) : null}
      </>
    )
  }
}

export default BrandVoucherContainer;

import React from "react";
import ReactDOM from "react-dom";
import axiosInitializer from "./axios_initializer";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { toast } from "react-toastify";
import * as serviceWorker from './serviceWorker';

const currpath = window.location.pathname;
console.log(currpath.startsWith('/admin', 0));
if (currpath.startsWith('/admin', 0) !== true) {
  // New UI scss
  require("./index.scss");
}
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "./assets/assets/css/custom.min.css";
// import "./assets/assets/css/jquery-ui.min.css";
// import "./assets/css/bootstrap-select.min.css";
// import "./assets/css/color.min.css";
// import "./assets/css/font-awesome.min.css";
// import "./assets/css/index.min.css";
// import "./assets/css/plugins.css";
// import "./assets/css/travelite-style.min.css";
// import "./assets/css/megamenu/responsive.css";
// import "./assets/css/megamenu/so_megamenu.css";
// import "./assets/css/megamenu/theme-red.css";
// import "./assets/sass/main.scss";
// import "react-toastify/dist/ReactToastify.css";


axiosInitializer.config();
toast.configure();

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import React, { Component } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import AdminHeader from "./components/SuperAdmin/Header";
import AdminSidebar from "./components/SuperAdmin/AdminSidebar";
import Dashboard from "./containers/SuperAdmin/Dashboard";
import Settings from "./components/SuperAdmin/Settings";
import RegisteredUser from "./components/SuperAdmin/RegisteredUser";
import ShowCategory from "./containers/SuperAdmin/Category/ShowCategory";
import AddCategory from "./containers/SuperAdmin/Category/AddCategory";
import EditCategory from "./containers/SuperAdmin/Category/EditCategory";
import ShowCoupon from "./containers/SuperAdmin/Coupon/ShowCoupon";
import AddCoupon from "./containers/SuperAdmin/Coupon/AddCoupon";
import EditCoupon from "./containers/SuperAdmin/Coupon/EditCoupon";
import ShowBrand from "./containers/SuperAdmin/Brand/ShowBrand";
import AddBrand from "./components/SuperAdmin/Brand/AddBrand";
import EditBrand from "./containers/SuperAdmin/Brand/EditBrand";
import ShowRedeemBrand from "./containers/SuperAdmin/RedeemBrand/ShowRedeemBrand";
import AddRedeemBrand from "./containers/SuperAdmin/RedeemBrand/AddRedeemBrand";
import EditRedeemBrand from "./containers/SuperAdmin/RedeemBrand/EditRedeemBrand";
import AddRedeemBrandCoupon from "./containers/SuperAdmin/RedeemBrandCoupon/AddRedeemBrandCoupon";
import EditRedeemBrandCoupon from "./containers/SuperAdmin/RedeemBrandCoupon/EditRedeemBrandCoupon";
import ShowRedeemBrandCoupon from "./containers/SuperAdmin/RedeemBrandCoupon/ShowRedeemBrandCoupon";
import ShowPromoCode from "./containers/SuperAdmin/PromoCode/ShowPromoCode";
import AddPromoCode from "./containers/SuperAdmin/PromoCode/AddPromoCode";
import EditPromoCode from "./containers/SuperAdmin/PromoCode/EditPromoCode";
import PromoCodeUser from "./containers/SuperAdmin/PromoCode/PromoCodeUser";
import UserTransactionHistory from "./containers/SuperAdmin/UserTransactionHistory";
import EditUser from "./components/SuperAdmin/EditUser";
import Partners from './components/SuperAdmin/Partners/ShowPartners';
import EasyDine from './components/SuperAdmin/EasyDine/ShowEasyDine';
import EditPartner from './components/SuperAdmin/Partners/EditPartner';
import AllOrders from './components/SuperAdmin/AllOrders/AllOrders';
import AllUpiOrders from "./components/SuperAdmin/AllUpiOrders/AllUpiOrders";
import ShowUploadCoupons from "./components/SuperAdmin/UploadCoupons/ShowUploadCoupons";
import UploadCoupons from "./components/SuperAdmin/UploadCoupons/UploadCoupons";
import ShowUploadLogs from "./components/SuperAdmin/UploadCoupons/ShowUploadLogs";
import ShowUploadedPromoLogs from "./components/SuperAdmin/UploadPromoCodes/ShowUploadedPromoLogs";
import UploadPromoCodes from "./components/SuperAdmin/UploadPromoCodes/UploadPromoCodes";
import ShowUploadePromo from "./components/SuperAdmin/UploadPromoCodes/ShowUploadedPromo";
import VendorBrandConfigurations from "./components/SuperAdmin/VendorBrandConfigurations/VendorBrandConfigurations";
import ShowVendorBrandConfigurations from "./components/SuperAdmin/VendorBrandConfigurations/ShowVendorBrandConfigurations";
import EditVendorBrandConfigurations from "./components/SuperAdmin/VendorBrandConfigurations/EditVendorBrandConfigurations";
import AddPartners from "./components/SuperAdmin/Partners/AddPartners";
import ShowBalanceLogs from "./components/SuperAdmin/Partners/ShowBalanceLogs";


const currpath = window.location.pathname;
console.log(currpath.startsWith('/admin', 0));
if (currpath.startsWith('/admin', 0)) {
  // index css start
  require("../node_modules/bootstrap/dist/css/bootstrap.min.css");
  //require( "./assets/assets/css/custom.min.css");
  require("./assets/assets/css/jquery-ui.min.css");
  require("./assets/css/bootstrap-select.min.css");
  require("./assets/css/color.min.css");
  require("./assets/css/font-awesome.min.css");
  //require( "./assets/css/index.min.css");
  //require( "./assets/css/plugins.css");
  require("./assets/css/travelite-style.min.css");
  require("./assets/css/megamenu/responsive.css");
  require("./assets/css/megamenu/so_megamenu.css");
  require("./assets/css/megamenu/theme-red.css");
  require("./assets/sass/main.scss");
  require("react-toastify/dist/ReactToastify.css");
  // index css end
  require("./assets/sass/admin/SuperAdminLayout.scss");
  require("./assets/css/admin/dashboard.css");
  require("./assets/css/admin/booking.css");
  require("./assets/css/admin/common.css");
  require("./assets/css/admin/styles.css");
  require("./assets/css/admin/metisMenu.min.css");
  require("./assets/css/admin/datepicker.css");

}
class SuperAdminLayout extends Component {
  constructor(props) {
    super(props);

    console.log("SuperAdminLayout", props.match)

    this.routes = [
      {
        path: "/dashboard",
        exact: true,
        component: Dashboard,
        title: "dashboard"
      },
      {
        path: "/settings",
        exact: true,
        component: Settings,
        title: "settings"
      },
      {
        path: "/couponCategory",
        exact: true,
        component: ShowCategory,
        title: "ShowCategory"
      },
      {
        path: "/couponCategory/new",
        exact: true,
        component: AddCategory,
        title: "AddCategory"
      },
      {
        path: "/couponCategory/:categoryId/edit",
        exact: true,
        component: EditCategory,
        title: "EditCategory"
      },
      // Show vendor brand configurations route.
      {
        path: "/vendorBrandConfigurations",
        exact: true,
        component: ShowVendorBrandConfigurations,
        title: "Vendor Brand Configurations"
      },
      // Create vendor brand configurations.
      {
        path: "/vendorBrandConfigurations/create",
        exact: true,
        component: VendorBrandConfigurations,
        title: "Create Configurations"
      },
      // Edit vendor brand configurations.
      {
        path: "/vendorBrandConfigurations/:config_id",
        exact: true,
        component: EditVendorBrandConfigurations,
        title: "Edit Configurations"
      },

      {
        path: "/coupons",
        exact: true,
        component: ShowCoupon,
        title: "Show Coupon"
      },
      {
        path: "/coupons/new",
        exact: true,
        component: AddCoupon,
        title: "Add Coupon"
      },
      {
        path: "/coupons/logs",
        exact: true,
        component: ShowUploadLogs,
        title: "Show Upload Logs"
      },
      {
        path: "/coupons/logs/coupons/:batch_id",
        exact: true,
        component: ShowUploadCoupons,
        title: "Show Upload Coupons"
      },
      {
        path: "/coupons/logs/upload",
        exact: true,
        component: UploadCoupons,
        title: "Upload Coupons"
      },
      {
        path: "/coupons/:couponId/edit",
        exact: true,
        component: EditCoupon,
        title: "Edit Coupon"
      },

      {
        path: "/brands",
        exact: true,
        component: ShowBrand,
        title: "ShowBrand"
      },
      {
        path: "/brands/new",
        exact: true,
        component: AddBrand,
        title: "AddBrand"
      },
      {
        path: "/brands/:brandId/edit",
        exact: true,
        component: EditBrand,
        title: "EditBrand"
      },
      {
        path: "/redeemBrand",
        exact: true,
        component: ShowRedeemBrand,
        title: "ShowRedeemBrand"
      },
      {
        path: "/redeemBrand/new",
        exact: true,
        component: AddRedeemBrand,
        title: "AddRedeemBrand"
      },
      {
        path: "/redeemBrand/:redeemBrandId/edit",
        exact: true,
        component: EditRedeemBrand,
        title: "EditRedeemBrand"
      },
      {
        path: "/redeemBrandCoupon",
        exact: true,
        component: ShowRedeemBrandCoupon,
        title: "ShowRedeemBrandCoupon"
      },
      {
        path: "/redeemBrandCoupon/new",
        exact: true,
        component: AddRedeemBrandCoupon,
        title: "AddRedeemBrandCoupon"
      },
      {
        path: "/redeemBrandCoupon/:redeemBrandCouponId/edit",
        exact: true,
        component: EditRedeemBrandCoupon,
        title: "EditRedeemBrandCoupon"
      },
      {
        path: "/promoCode",
        exact: true,
        component: ShowPromoCode,
        title: "ShowPromoCode"
      },
      {
        path: "/promoCode/logs",
        exact: true,
        component: ShowUploadedPromoLogs,
        title: "showUploadedPromoLogs"
      },
      {
        path: "/promoCode/logs/upload",
        exact: true,
        component: UploadPromoCodes,
        title: "uploadPromoCodes"
      },
      {
        path: "/promoCode/logs/coupons/:batch_id",
        exact: true,
        component: ShowUploadePromo,
        title: "Show Upload Coupons"
      },
      {
        path: "/promoCode/new",
        exact: true,
        component: AddPromoCode,
        title: "AddPromoCode"
      },
      {
        path: "/promoCode/:promoCodeId/edit",
        exact: true,
        component: EditPromoCode,
        title: "EditPromoCode"
      },
      {
        path: "/promoCode/:promoCodeId/users",
        exact: true,
        component: PromoCodeUser,
        title: "PromoCodeUser"
      },
      {
        path: "/registeredUser",
        exact: true,
        component: RegisteredUser,
        title: "Registered User"
      },
      {
        path: "/transactionHistory/:Id",
        exact: true,
        component: UserTransactionHistory,
        title: "transaction history"
      },
      {
        path: "/registeredUser/:userId/edit",
        exact: true,
        component: EditUser,
        title: "edit user"
      },
      {
        path: "/partners",
        exact: true,
        component: Partners,
        title: "partners"
      },
      {
        path: "/partners/:partnerId/edit",
        exact: true,
        component: EditPartner,
        title: "edit Partner"
      },

      {
        path: "/partners/add",
        exact: true,
        component: AddPartners,
        title: "Add partners"
      },
      {
        path: "/partners/balancelogs/:partnerId",
        exact: true,
        component: ShowBalanceLogs,
        title: "Show Balance Logs"
      },
      {
        path: "/orders",
        exact: true,
        component: AllOrders,
        title: "all orders"
      },
      {
        path: '/upiorders',
        exact: true,
        component: AllUpiOrders,
        title: "All UPI orders",
      },
      {
        path: "/easyDine",
        exact: true,
        component: EasyDine,
        title: "easy dine"
      },
    ];
    this.state = {
      showResponsiveSidebar: false
    };
  }

  responsiveSidebarDisplay = () => {
    this.setState({ showResponsiveSidebar: !this.state.showResponsiveSidebar });
  };

  isAllowed = (props, RouteComponent, title, parentPath) => {
    var props1 = {};
    var newProps = { ...props, ...props1 };
    if (parentPath === "/admin") {
      return <RouteComponent {...newProps} />;
    } else if (parentPath === "/") {
      return <Redirect to={"/admin/dashboard"} />;
    } else {
      return <Redirect to={"/admin/dashboard"} />;
    }
  };

  render() {
    return (
      <div className="super-admin-layout">
        <AdminHeader
          showResponsiveSidebar={this.state.showResponsiveSidebar}
          responsiveSidebarDisplay={this.responsiveSidebarDisplay}
        />
        <AdminSidebar
          showResponsiveSidebar={this.state.showResponsiveSidebar}
        />
        <div className="main-admin-content">
          <Switch>
            {this.routes.map((route, i) => (
              <Route
                key={i}
                exact={route.exact}
                path={this.props.match.path + route.path}
                render={props =>
                  this.isAllowed(
                    props,
                    route.component,
                    route.title,
                    this.props.match.path
                  )
                }
              />
            ))}
            <Redirect to={"/admin/dashboard"} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(SuperAdminLayout);

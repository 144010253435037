import React, { Component, useState } from "react";
import Slider from "react-slick";
import moment from "moment";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import cookie from "react-cookies";
import { Modal } from "react-bootstrap";
import { logout, get } from "../../utills/API";
import {
  Button,
  Form,
  Tabs,
  Tab,
  Accordion,
  Card,
  Container,
  ListGroup,
} from "react-bootstrap";
import completeImg from "../../assets/images/gainon/thankyou-img.svg";
import voucherFail from "../../assets/images/gainon/voucher_fail.gif";
import "slick-carousel/slick/slick.css";
import SvgVoucher from "../../components/Common/SvgVoucher";
// import "../../assets/sass/orders.scss";
import Loader from "../Loader";
import { decrypt } from "../../utills/function";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: "",
      orderId: null,
      orderData: {},
      voucherData: [],
      partialOrder: false,
      voucherId: null,
      isLoading: false,
      totalEarn: 0,
      isDetailPath: false,
      Activation_Failed_Message: "",
    };
  }

  handleClose = () => {
    this.setState({ partialOrder: false });
  };

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    this.props.history.push("/login");
  };

  copyToClipboard = (e, id) => {
    var text = document.querySelector(`.voucher-number-${id}`);
    text.select();
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccess: "Copied!", voucherId: id });
  };

  copyToClipboardPin = (e, id) => {
    var text = document.querySelector(`.pin-${id}`);
    text.select();
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccess: "Copied!", voucherId: id });
  };

  // ShowActivationFailed = (message) => {
  //   this.setState({Activation_Failed_Message:message})

  // }

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ isLoading: true });
    const { orderId } = this.props.match.params;
    const isDetailPath =
      this.props.match.path == "/orders/:orderId/details" ? true : false;

    // ReactGA.initialize('AW-458145258');
    try {
      const { data } = await get(`orders/${orderId}`);
      let decData = decrypt(data.data.order);
      if (data.status == 200) {
        var orderData = decData[0];
      } else {
        this.props.history.push("/");
      }
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get(`vouchers/orders/${orderData.id}`);
      
      var voucherData = data.data.vouchers;
      var Activation_Failed_Message =  data.data.QC_message && data.data.QC_message.QcrsponseVoucherErrormsg;
    
      if(Activation_Failed_Message){
        this.setState({Activation_Failed_Message:Activation_Failed_Message})
       
      }
     

      // console.log(
      //   "Voucher Data...here",
      //   Activation_Failed_Message
      // );
      let totalEarn = 0;
      if (voucherData.length > 0) {
        voucherData.map((voucher, index) => {
          totalEarn += voucher.earn;
        });
        this.setState({ totalEarn: totalEarn, isDetailPath });
      }
      if (
        voucherData.length >= 1 &&
        voucherData.length != Number(data.data.total_vouchers)
      ) {
        this.setState({ partialOrder: true });
      }
    } catch (e) {
      console.log("error", e);
    }

    localStorage.removeItem("checkoutPath");
    localStorage.removeItem("checkoutData");

    this.setState({ orderId, orderData, voucherData, isLoading: false });
    window.scrollTo(0, 0);
  }

  statusShow = (status, orderData) => {
    let statusText;
    let extraMessage;
    let color;
    let errorIcon;
    if (status === "order_completed") {
      var settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        initialSlide: 0,
        variableWidth: true,
        centerPadding: 0,
        appendDots: (dots) => (
          <div
            style={{
              padding: "28px 10px 10px",
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: (i) => (
          <div
            style={{
              width: "26px",
              color: "#333333",
              borderRadius: "2px",
              fontWeight: "600",
            }}
            className="abc"
          >
            {i + 1}
          </div>
        ),
      };
      statusText = (
        <>
          <section className="page page-order-completed">
            <Container>
              {this.state.isDetailPath ? (
                <div className="transcation-wrap">
                  <div className="d-flex align-items-center mb-4">
                    <div className="back-wrap">
                      <a href="/my-vouchers">
                        <i className="icon-arrow-right d-inline-block font-weight-bold text-24" />
                      </a>
                    </div>
                    <h2 className="mb-0">
                      Transaction ID - {orderData.uuid ? orderData.uuid : ""}
                    </h2>
                  </div>
                  <Card className="card-transaction">
                    <Card.Body className="px-3 px-lg-5">
                      <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex justify-content-between align-items-center py-3 px-0">
                          <p>Transaction Type</p>
                          <Button
                            variant="success"
                            className="py-1 px-2 d-lg-block d-none"
                          >
                            Purchase
                          </Button>

                          <div className="d-block d-lg-none">
                            {orderData.is_redeem === 0 ? (
                              <div>
                                <i className="icon-arrow-right shop-arrow mr-2" />
                                <span>Shop</span>
                              </div>
                            ) : (
                              <div className="d-block d-lg-none">
                                <i className="icon-arrow-right redeem-arrow mr-2" />
                                <span>Redeem</span>
                              </div>
                            )}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center py-3 px-0">
                          <p>Brand</p>
                          <p style={{ color: "#333333" }}>
                            <img
                              height="20px"
                              src={
                                orderData.brand_image
                                  ? orderData.brand_image
                                  : ""
                              }
                              alt=""
                            ></img>
                            {orderData.brand_name ? orderData.brand_name : ""}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center py-3 px-0">
                          <p>Date</p>
                          <div>
                            {orderData.txn_date && orderData.txn_date != ""
                              ? moment(orderData.txn_date).format("D MMM YYYY")
                              : "-"}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center py-3 px-0">
                          <p>Value</p>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 14 14"
                            >
                              <g transform="translate(-1173 -24)">
                                <g transform="translate(1147 12)">
                                  <g transform="translate(0 9)">
                                    <g transform="translate(26 1)">
                                      <g transform="translate(0 2)">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="none"
                                        />
                                        <path
                                          d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                          fill="#333"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            {orderData.total_amount
                              ? orderData.total_amount
                              : ""}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center py-3 px-0">
                          <p>Earning</p>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 14 14"
                            >
                              <g transform="translate(-1173 -24)">
                                <g transform="translate(1147 12)">
                                  <g transform="translate(0 9)">
                                    <g transform="translate(26 1)">
                                      <g transform="translate(0 2)">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="none"
                                        />
                                        <path
                                          d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                          fill="#333"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            {this.state.totalEarn}
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      {/* <Button variant="link" className="mt-3 ml-lg-0 ml-5"><i className="icon-download mr-3 text-16 font-weight-bold" style={{ verticalAlign: "top" }} />Download Invoice</Button> */}
                    </Card.Body>
                  </Card>
                </div>
              ) : (
                <div className="order-complete-wrap text-center mb-3">
                  <img src={completeImg} alt="Complete Logo" />
                  <h1 className="text-22 font-weight-bold mb-2">
                    Order completed!
                  </h1>
                  {orderData &&
                  orderData.pay_by_wallet_amount == 0 &&
                  orderData.is_redeem != 1 && this.state.totalEarn > 0 ? (
                    <>
                      <p className="text-16">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g transform="translate(-1173 -24)">
                            <g transform="translate(1147 12)">
                              <g transform="translate(0 9)">
                                <g transform="translate(26 1)">
                                  <g transform="translate(0 2)">
                                    <rect width="14" height="14" fill="none" />
                                    <path
                                      d="M9.215,12.833v-.1L5.264,8.154A4.565,4.565,0,0,0,8,7.433a2.872,2.872,0,0,0,1.13-2.147h1.218l.369-1.266H9.111a3.262,3.262,0,0,0-.649-1.587h1.891l.369-1.266H3.309L2.933,2.433H5.184Q7.155,2.5,7.54,4.019H3.285L2.917,5.285H7.556a1.873,1.873,0,0,1-.793,1.166,2.862,2.862,0,0,1-1.627.437h-2l.008,1L7.4,12.833Z"
                                      fill="#29C009"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <span style={{ color: "#29C009" }}>
                          {this.state.totalEarn}
                        </span>{" "}
                        
                        has been added to your wallet.
                      </p>,
                      {console.log(orderData)}  
                    </>
                  ) : null}
                </div>
              )}
              <Slider
                {...settings}
                className={
                  this.state.voucherData.length < 2 ? "slick-no-slide" : null
                }
              >
                {this.state.voucherData &&
                  this.state.voucherData.length > 0 &&
                  this.state.voucherData.map((voucher, index) => {
                    return (
                      <div key={voucher.id}>
                        <SvgVoucher
                          blue
                          voucher={voucher}
                          imagePath={this.state.orderData.brand_image}
                          copyToClipboard={this.copyToClipboard}
                        />
                      </div>
                    );
                  })}
              </Slider>
              {this.state.orderData.brand_website && (
                <div className="text-center my-5">
                  <Button variant="primary">
                    <a
                      href={this.state.orderData.brand_website}
                      target="_blank"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Take me to {this.state.orderData.brand_name}
                      <i className="icon-external-link-alt font-weight-bold ml-3" />
                    </a>
                  </Button>{" "}
                </div>
              )}
              {/* <div className="d-flex align-items-center justify-content-center">
            <Button variant="link" className="mr-4">
              <i className="icon-share font-weight-bold mr-2" /> Share
            </Button>
            <Button variant="link">
              <i className="icon-download font-weight-bold mr-2" />
              Download
            </Button>
          </div> */}
            </Container>
          </section>
        </>
      );
      color = "#80d612";
    } else if (status === "payment_failure") {
      statusText = (
        <div className="container d-flex justify-content-center">
          <div
            className="vou-suc"
            style={{
              height: "auto",
              fontSize: "26px",
              textAlign: "center",
              padding: "30px",
              fontWeight: "bold",
              backgroundColor: "white",
              width: "450px",
              marginTop: "16px",
            }}
          >
            <div style={{ color: "red" }}>
              <span style={{ color: "red", fontSize: "40px" }}>
                <i class="fas fa-times-circle"></i>
              </span>
              <br />
              <br />
              Payment Failed
              <br />
              <br />
              <div
                style={{ color: "black", fontSize: "14px", textAlign: "left" }}
              >
                <div style={{ textAlign: "center" }}>
                  Oh no something didn’t quite go right, please verify the
                  details and try again.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (status === "voucher_fail") {
      statusText = (
        <div className="container d-flex justify-content-center">
          <div
            className="vou-suc"
            style={{
              height: "auto",
              // fontSize: "26px",
              textAlign: "center",
              padding: "30px",
              fontWeight: "bold",
              backgroundColor: "white",
              width: "450px",
              marginTop: "16px",
            }}
          >
            <div style={{ color: "black" }}>
              <span>
                <img src={voucherFail} width="45%"></img>
              </span>
              <br />
              <br />
              <span style={{ lineHeight: "30px", fontSize:"26px", }}>
                Oh no, something went wrong
              </span>
              <br />
              <span style = {{color:"#17a2b8" , fontSize:"16px", textAlign:'center', marginTop:"15px"}}>{this.state.Activation_Failed_Message}</span>
              <br />
              <div
                style={{ color: "black", fontSize: "14px", textAlign: "left" }}
              >
                <>
                  <div style={{ textAlign: "center", marginTop:"5px" }}>
                    Don't worry, a refund has automatically been initiated for
                    the failed payment voucher(s) and will reflect in your
                    account within 5-7 days.
                  </div>
                  <br />
                  <span
                    style={{
                      color: "grey",
                      fontWeight: "normal",
                      fontSize: "12px",
                      textAlign:'center'
                    }}
                  >
                    <br />
                    We apologise for the inconvenience caused. In case you
                    require further assistance you can reach us here:{" "}
                    <a href="mailto:support@gainon.in">support@gainon.in</a>
                  </span>{" "}
                </>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{statusText}</>;
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <Loader /> : null}
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        {this.state.partialOrder && (
          <Modal show={this.state.partialOrder} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Order Partially Completed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Sorry, the brand didn't provide all payment vouchers in time. Any
              failed amount has automatically been refunded, kindly allow 5-7
              days for it to reflect back in your account. You may create a new
              payment to procure the vouchers.
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-primary btn-block"
                onClick={this.handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <div
          className="vou-bkp cf min-heigs orders-box"
          style={{ minHeight: "80vh" }}
        >
          {this.statusShow(this.state.orderData.status, this.state.orderData)}
        </div>
        <Footer />
      </>
    );
  }
}

export default Contact;

import React, { useEffect, useState } from "react";
// ------------External-libraries-----------------
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { get, Delete } from "../../../utills/API";
import DeleteConfiguration from "./DeleteConfiguration";
import { toast } from "react-toastify";

// -----------------------------------------------

// -----------Styling----------
import "./VendorBrandConfigurations.scss";
// ----------------------------

// Main component.
const ShowVendorBrandConfigurations = () => {
    const [searchText, setSearchText] = useState("");
    const [allConfigurations, setAllConfigurations] = useState([])

    // States for delete model:
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [deleteConfigById, setDeleteConfigById] = useState("");
    const [disabledButton, setDisabledButton] = useState(false)
    const [fetchConfig, setFetchConfig] = useState(false)
    // fetching all configurations:

    useEffect(() => {
        const GetConfigs = async () => {
            try {
                const { data } = await get("coupons/config/fetch");
                setAllConfigurations(data.data.records.map((xData, index) => xData.is_slab ? ({ ...xData, is_slab: "Slab", id: ++index }) : ({ ...xData, is_slab: "Range", id: ++index })));

            } catch (error) {
                console.log(error);
            }
        }
        GetConfigs();
    }, [fetchConfig])

    // Basic Examples:
    const columns = [
        {
            name: 'Sr. No.',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Vendor',
            selector: row => row.vendor_name,
            sortable: true,
        },
        {
            name: 'Brand',
            selector: row => row.brand_name,
            wrap: true,
            // minWidth: "200px",
        },
        {
            name: 'Configuration Type',
            selector: row => row.is_slab,
        },
        {
            name: 'Edit/View',
            // selector: row => row.is_slab,
            button: true,
            cell: row => (
                <Link to={`/admin/vendorBrandConfigurations/${row.config_id}`}>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                </Link>
            )
        },
        {
            name: 'Delete',
            // selector: row => row.is_slab,
            button: true,
            cell: row => (
                <a onClick={
                    () => {
                        setShowDeleteModel(true)
                        setDeleteConfigById(row.config_id)
                    }
                }>
                    <i class="far fa-trash-alt"></i>
                </a>
            )
        },
    ];

    // const data = [
    //     {
    //         id: 1,
    //         vendor_name: "Flipkart",
    //         brand_name: "Bata",
    //         is_slab: 0,

    //     },
    //     {
    //         id: 2,
    //         vendor_name: "Voucha Gram",
    //         brand_name: "bata",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 3,
    //         vendor_name: "Flipkart",
    //         brand_name: "Nike",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 4,
    //         vendor_name: "Flipkart",
    //         brand_name: "Peter England",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 5,
    //         vendor_name: "Flipkart",
    //         brand_name: "Oppo",
    //         is_slab: 0,

    //     },
    //     {
    //         id: 6,
    //         vendor_name: "Flipkart",
    //         brand_name: "Bata",
    //         is_slab: 0,

    //     },
    //     {
    //         id: 7,
    //         vendor_name: "Voucha Gram",
    //         brand_name: "bata",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 8,
    //         vendor_name: "Flipkart",
    //         brand_name: "Nike",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 9,
    //         vendor_name: "Flipkart",
    //         brand_name: "Peter England",
    //         is_slab: 1,

    //     },
    //     {
    //         id: 10,
    //         vendor_name: "Flipkart",
    //         brand_name: "Oppo",
    //         is_slab: 0,

    //     },


    // ].map(xData => xData.is_slab ? ({ ...xData, is_slab: "Slab" }) : ({ ...xData, is_slab: "Range" }))

    const FilterData = allConfigurations
        .filter(fData =>
            fData.id.toString().toLowerCase().includes(searchText.toLowerCase())
            ||
            fData.vendor_name.toLowerCase().includes(searchText.toLowerCase())
            ||
            fData.brand_name.toLowerCase().includes(searchText.toLowerCase())
            ||
            fData.is_slab.toLowerCase().includes(searchText.toLowerCase())

        )

    // Delete config:
    const handleDeleteConfig = async () => {
        setDisabledButton(true)
        try {
            const data = await Delete(`coupons/config/delete/${deleteConfigById}`);
            setDisabledButton(false)
            setShowDeleteModel(false)
            setDeleteConfigById("")
            setFetchConfig(e => !e)
            toast.success("Configuration deleted successfully.")
        } catch (error) {
            console.log(error);
            setDisabledButton(false)
            setShowDeleteModel(false)
            setDeleteConfigById("")
            setFetchConfig(e => !e)
            toast.error("Failed to delete configuration!")
        }
    }

    return (
        <div className="container vendor-brand-configurations">
            <div className="card">
                <div className="card-header bg-dark" >
                    <div className="row">
                        <div
                            className="col col-sm-8 text-light pt-1"
                            style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                            }}
                        >

                            Vendor Brand Configurations
                        </div>
                        <div className="col col-sm-4" >
                            <Link
                                to={"/admin/vendorBrandConfigurations/create"}
                                className="btn btn-primary btn-sm btn-block"
                            >
                                Add Configuration
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body" >
                    <div className="app-datatable">
                        <DataTable
                            pagination
                            columns={columns}
                            data={FilterData}

                            subHeader={true}
                            subHeaderComponent={<SearchBar
                                SearchText={searchText}
                                SetSearchText={(data) => setSearchText(data)}
                            />}
                        />
                    </div>
                    <div>
                        <DeleteConfiguration
                            ShowDeleteModel={showDeleteModel}
                            CloseDeleteModel={() => setShowDeleteModel(false)}
                            HandleDeleteConfig={handleDeleteConfig}
                            DisabledButton={disabledButton}

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const SearchBar = ({ SearchText, SetSearchText }) => {

    return (
        <div className="form-group pr-1 d-flex" >
            <input className="form-control"
                placeholder="Search here..."
                style={{
                    zIndex: 1,
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                }}
                value={SearchText}
                onChange={(e) => SetSearchText(e.target.value)}
            />
            <i
                className="btn btn-danger fa fa-times btn-vbc"
                style={{
                    // display: "block",
                    // fontSize: "20px",
                    marginLeft: "-4px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",

                }}
                onClick={() => SetSearchText("")}
            ></i>
        </div>
    )
}

export default ShowVendorBrandConfigurations;
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { Link } from "react-router-dom";
import { post, get } from "../../../utills/API";

class SearchBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchDropdown: false,
      brandName: this.props.brandName,
      searchDataArr: [],
    };
  }

  handleClickOutside = (e) => {
    this.setState({ isSearchDropdown: false });
  };

  handleSearchClick = () => {
    this.setState({ isSearchDropdown: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brandName !== this.props.brandName) {
      this.setState({ brandName: this.props.brandName });
    }
  }

  handleSearchOnChange = async (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });

    const searchData = {
      q: value,
      is_redeem: this.props.isRedeem,
    };

    try {
      const { data } = await post("ngp/pay_brand_search", searchData);
      var searchDataArr = data.data.brands;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({ searchDataArr, isSearchDropdown: true });
  };

  selectBrand = async (brandId) => {
    try {
      const { data } = await get(`ngp/brands/${brandId}`);
      var brandData = data.data.brand;
      var brandName = data.data.brand.name;
      var coupons = brandData ? brandData.coupons : [];
      var minAmount;
      var maxAmount;
      var coupons = brandData.coupons;
      if (coupons.length > 0 && coupons.length >= 1) {
        minAmount = coupons[0].amount;
        maxAmount = coupons[coupons.length - 1].amount * 5;
      }
    } catch (e) {
      console.log("error", e);
    }
    if (this.props.isRedeem) {
      this.props.selectBrand(brandId, brandData, brandName, coupons);
    } else {
      this.props.selectBrand(
        brandId,
        brandData,
        brandName,
        minAmount,
        maxAmount
      );
    }

    this.setState({ isSearchDropdown: false });
  };

  render() {
    const { brandName, searchDataArr } = this.state;
    return (
      <div className="brand-select">
        <input
          type="text"
          placeholder="Select Brand Name"
          name="brandName"
          value={brandName}
          onChange={this.handleSearchOnChange}
          disabled={this.props.params ? true : false}
          autoComplete="off"
        />
        <div className="down-key-box">
          <i class="fas fa-chevron-down"></i>
        </div>
        {this.state.isSearchDropdown ? (
          <div className="dropdown">
            {searchDataArr.length > 0 ? (
              <>
                {searchDataArr.map((data, index) => (
                  <div className="dropdown-item" key={index}>
                    <Link onClick={() => this.selectBrand(data.id)}>
                      <span>{data.name}</span>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
                <div className="dropdown-item">Enter the brand name</div>
              )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(SearchBrand);

import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { post } from "../utills/API";
import { ToastContainer, toast } from "react-toastify";
import AlertDismissible  from "./../components/Common/Alert";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      showError: false
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  forgotPassword = async e => {
    e.preventDefault();
    const forgotPasswordData = {
      user: {
        email: this.state.email
      }
    };

    try {
      const { data } = await post("forgot-password", forgotPasswordData);
      if (data.status === 200) {
        toast.success("A temporary password has been sent on your email.");
      } else if (data.status === 400) {
        // toast.error(data.message);
        this.setState({
          errorMessage: data.message,
          showError: true
        })
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  toggleError = () => {
    this.setState({
      errorMessage: "",
      showError: false
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* <ToastContainer position={toast.POSITION.TOP_CENTER} /> */}
        <AlertDismissible
          toggleError={this.toggleError}
          errorMessage={this.state.errorMessage}
          show={this.state.showError}
        />
        <Header />

        <div
          className="container cou-wids min-heigs"
          style={{ marginTop: "80px", minHeight: "500px" }}
        >
          <div className="main-register fl-wrap">
            <div id="tab-2">
              <h3>
                <span>
                  <strong>Forgot Password</strong>
                </span>
              </h3>
              <div className="custom-form">
                <form onSubmit={this.forgotPassword}>
                  <label>
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Enter Email ID"
                  />
                  <input
                    className="log-submit-btn"
                    value="Submit"
                    type="submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default ForgotPassword;

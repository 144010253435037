import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import RangeComponent from "./RangeComponent";
import SlabComponent from "./SlabComponent";
import { v4 as uuid } from 'uuid';
import { post, get, put } from "../../../utills/API";
import "./VendorBrandConfigurations.scss"
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// const config_data = {
//     data: [
//         {
//             id: 1,
//             vendor_id: 8,
//             brand_id: 3,
//             category_id: 1,
//             vendor_name: "Flipkart",
//             brand_name: "Bata",
//             is_slab: 0,
//             range: {
//                 min_amount: 100,
//                 max_amount: 10000,
//                 multiple_of: 10,
//                 expiry_date: new Date(),
//                 pin_number: "",
//                 guid: "btA200Uoneei"
//             },

//         },
//         {
//             id: 2,
//             vendor_id: 1,
//             brand_id: 3,
//             category_id: 1,
//             vendor_name: "Voucha Gram",
//             brand_name: "Bata",
//             is_slab: 1,

//             slab: [
//                 {
//                     amount: 100,
//                     guid: "btA200Uon77eei",
//                     pin: "",
//                     expiry_date: new Date(),
//                 },
//                 {
//                     amount: 50,
//                     guid: "ptujei899",
//                     pin: "",
//                     expiry_date: new Date(),
//                 },
//             ],


//         },
//     ]
// }



const EditVendorBrandConfigurations = (props) => {
    const [configType, setConfigType] = useState("");
    const [slabs, setSlabs] = useState([{
        _id: uuid(),
        guid: "",
        pin_number: "",
        amount: "",
        expiry_date: new Date(),
    }])
    const [range, setRange] = useState({
        _id: uuid(),
        guid: "",
        pin_number: "",
        min_amount: "",
        max_amount: "",
        multiple_of: "",
        expiry_date: new Date(),
    })

    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState("");

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [brands, setBrands] = useState([]);
    const [filteredBrands, setFilteredBrands] = useState([])
    const [selectedBrand, setSelectedBrand] = useState("");

    const [is_buttonDisabled, setIs_buttonDisabled] = useState(false)
    const [readOnly, setReadOnly] = useState(true);

    // Life cycles:
    useEffect(() => {

        const fetchConfigurations = async () => {
            try {
                const ConfigData = await get(`coupons/config/fetch/${props.match.params.config_id}`)
                const data = ConfigData.data.records
                // console.log(data)
                // .filter(getConfig => parseInt(getConfig.id) === parseInt(props.match.params.config_id));

                const {
                    vendor_id,
                    brand_id,
                    category_id,
                    is_slab,
                    range,
                    slab
                } = data;

                setSelectedVendor(vendor_id);
                setSelectedCategory(category_id);
                setSelectedBrand(brand_id);
                setConfigType(is_slab ? "slab" : "range")

                is_slab ?
                    setSlabs(slab.map(slb => ({
                        id: slb.id,
                        _id: slb.id,
                        amount: slb.amount,
                        pin_number: slb.pin_number,
                        guid: slb.guid,
                        expiry_date: slb.expiry_date ? new Date(slb.expiry_date) : null,
                    })))
                    :
                    setRange({
                        ...range,
                        expiry_date: range.expiry_date ? new Date(range.expiry_date) : null
                    });

            } catch (error) {
                console.log(error)
            }
        }
        fetchConfigurations();

    }, [])










    // Add denominations:
    const AddDenominations = () => {
        setSlabs([...slabs, {
            _id: uuid(),
            guid: "",
            pin_number: "",
            amount: "",
            expiry_date: new Date(),
        }])
    }
    // Delete denominations:
    const DeleteDenominations = (Id) => {
        setSlabs(slabs.filter(slab => slab.id !== Id))
    }


    const HandleSubmit = async (e) => {
        e.preventDefault();
        setIs_buttonDisabled(true)
        try {

            var configCoupons;

            if (configType === "slab")
                configCoupons = { slab: slabs };
            else
                configCoupons = { range: range };


            var data = {
                vendor_id: selectedVendor,
                category_id: selectedCategory,
                brand_id: selectedBrand,
                config_type: configType,
                is_slab: configType === "slab" ? 1 : 0,
                config_id: props.match.params.config_id,
                ...configCoupons,

            }
            // console.log(data)

            const response_config = await put("coupons/config/update", data)
            console.log(response_config)
            toast.success("Brand configuration updated successfully!")
            setIs_buttonDisabled(false)
            props.history.push("/admin/vendorBrandConfigurations");
            // setSelectedVendor("");
            // setSelectedCategory("");
            // setSelectedBrand("");
            // setRange({
            //     id: uuid(),
            //     guid: "",
            //     pin_number: "",
            //     min_amount: "",
            //     max_amount: "",
            //     multiple_of: "",
            //     expiry_date: new Date(),
            // });
            // setSlabs([{
            //     id: uuid(),
            //     guid: "",
            //     pin_number: "",
            //     amount: "",
            //     expiry_date: new Date(),
            // }])

        } catch (error) {
            console.log(error)
            toast.error("Opps! Something went wrong!")

        }


    }

    const HandleFetch = async () => {
        try {
            const vendors = await get("vendors")
            setVendors(vendors.data.data.vendors.filter(vendor => vendor.name !== "NGP"))
            const categories = await get("categories")
            setCategories(categories.data.data.categories)
            const brands = await get("brands")
            const redeemBrands = await get("reedem_brands");

            setBrands([...brands.data.data.brands, ...redeemBrands.data.data.reedem_brands])
            setFilteredBrands([...brands.data.data.brands, ...redeemBrands.data.data.reedem_brands])

        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        HandleFetch()
    }, [])


    const HandleChange = (data) => {
        const { name, value } = data.target;

        if (name === "select_category") {
            setSelectedCategory(value)
            let filterBrandArr = brands.filter(
                (brand) => brand.category_id === Number(value)
            );

            setFilteredBrands(value ? filterBrandArr : brands)
        }

        if (name === "select_vendor")
            setSelectedVendor(value)

        if (name === "select_brand")
            setSelectedBrand(value)
    }

    return (
        <div className="" >
            <div
                className="card vendor-brand-configurations"
            // style={{
            //     width: "900px",
            //     marginLeft: "-40px"

            // }}
            >

                <form onSubmit={(e) => HandleSubmit(e)}>
                    <div
                        className="card-header bg-dark text-light"
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                        }}
                    >
                        Vendor Brand Configurations
                    </div>
                    <div
                        className="card-body"
                    >
                        <div className="row">
                            <div className="col-md-3" >
                                <div className="form-group">
                                    <select className="form-control"
                                        disabled={is_buttonDisabled || readOnly}
                                        name="select_vendor"
                                        value={selectedVendor}
                                        onChange={(e) => HandleChange(e)}
                                        required>
                                        <option value={""} >Select Vendor</option>
                                        {
                                            vendors.map((vendor, index) => (
                                                <option key={index} value={vendor.id} >{vendor.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3" >
                                <div className="form-group">
                                    <select className="form-control"
                                        disabled={is_buttonDisabled || readOnly}

                                        name="select_category"

                                        value={selectedCategory}
                                        onChange={(e) => HandleChange(e)}

                                        required>
                                        <option value={""} >Select Category</option>
                                        {
                                            categories.map((category, index) => (
                                                <option key={index} value={category.id} >{category.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3" >
                                <div className="form-group">
                                    <select className="form-control"
                                        disabled={is_buttonDisabled || readOnly}

                                        name="select_brand"
                                        value={selectedBrand}
                                        onChange={(e) => HandleChange(e)}
                                        required>
                                        <option value={""} >Select Brand</option>
                                        {
                                            filteredBrands.map((brand, index) => (
                                                <option key={index} value={brand.id} >{brand.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3" >
                                <div className="form-group">
                                    <select className="form-control"
                                        disabled={is_buttonDisabled || readOnly}

                                        value={configType}
                                        onChange={(e) => setConfigType(e.target.value)}
                                        required>
                                        <option value={""} >Select Type</option>
                                        <option value={"range"}>Range</option>
                                        <option value={"slab"} >Slab/Denominations</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        {
                            configType === "slab" ?
                                (
                                    slabs[0] && slabs.map((slab, key_index) => (

                                        <SlabComponent
                                            key={key_index}
                                            ComponentIndex={key_index}

                                            ComponentId={slab._id}
                                            AllSlabs={slabs}

                                            SetAllSlabs={(data) => setSlabs(data)}

                                            VoucherNumber={slab.guid}
                                            PinNumber={slab.pin_number}
                                            Amount={slab.amount}
                                            DATE={slab.expiry_date}



                                            DeleteAction={() => DeleteDenominations(slab.id)}
                                            HideDelete={key_index ? false : true}
                                            ComponentDisabled={is_buttonDisabled}

                                        />
                                    ))
                                )
                                :
                                // <></>
                                configType && <RangeComponent
                                    RangeObject={range}
                                    SetRangeObject={(data) => setRange(data)}
                                    ComponentDisabled={is_buttonDisabled}
                                />
                        }
                        {
                            configType === "slab"
                            &&
                            <>
                                <hr />
                                <div className="row" >
                                    {/* <div className="col-md-8" ></div> */}
                                    <div className="col-md-4 col-lg-3" >
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block btn-sm text-uppercase"
                                            onClick={AddDenominations}
                                        >
                                            {/* <i class="fa fa-plus"></i> */}
                                            Add Denomination
                                        </button>
                                    </div>
                                    {/* <div className="col-md-8" ></div> */}
                                </div>
                            </>
                        }


                    </div >
                    <div className="card-footer p-2" >
                        <div className="row" >
                            {/* {
                            configType === "slab"
                                ?
                                <div className="col-md-3" >
                                    <button
                                        type="button"
                                        className="form-control bg-primary text-light text-uppercase font-weight-bold"
                                        onClick={AddDenominations}
                                    >
                                        Add Denominations
                                    </button>
                                </div>
                                :
                                <div className="col-md-3" >

                                </div>
                        } */}
                            <div className="col-md-6" >

                            </div>
                            <div className="col-md-3" >
                                <Link to="/admin/vendorBrandConfigurations" className={`zing-btn btn-danger text-center ${is_buttonDisabled && "disabled"}`}>
                                    Cancel
                                </Link>
                            </div>
                            <div className="col-md-3" >
                                <button
                                    disabled={is_buttonDisabled}
                                    className="zing-btn btn-secondary"

                                >
                                    {
                                        is_buttonDisabled ?
                                            <>
                                                {""}
                                                <Spinner animation="border" size="sm" />
                                            </>
                                            :
                                            "Update"

                                    }

                                </button>
                                {/* <button
                                disabled={is_buttonDisabled}
                                // onClick={this.HandleUploadPromoCodes}
                                className="btn btn-primary btn-sm"
                                style={{
                                    backgroundColor: "#c82e29",
                                    // width: "20%",

                                }}
                            >
                                Upload
                            </button> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div>
    );
}

export default EditVendorBrandConfigurations;
import React from "react";
// import "../assets/sass/loader.scss";
import Spinner from 'react-bootstrap/Spinner'

const Loader = () => {
  return (
    <>
    <div className="fade modal-backdrop show"></div>
    <div className="bg-loader d-flex  align-items-center justify-content-center">
      <Spinner animation="border" role="status" variant="danger" >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
    </>
  );
};

export default Loader;

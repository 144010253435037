import React, { Component } from "react";
import { toast } from "react-toastify";
import { get, put, logout } from "../../utills/API";
// import { Link } from "react-router-dom";
import cookie from "react-cookies";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Loader from "../Loader";
import { encrypt, decrypt } from "../../utills/function";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      fname: "",
      lname: "",
      username: "",
      email: "",
      mobile: "",
      dob: "",
      address: "",
      city: "",
      state: "",
      country: "",
      id: "",
      isLoading: false,
      isProcess: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    this.props.history.push("/login");
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { data } = await get("users/detail");
      var userInfo = data.data.user;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      userInfo: userInfo,
      fname: userInfo.first_name,
      lname: userInfo.last_name,
      email: userInfo.email,
      mobile: userInfo.mobile,
      id: userInfo.id,
      city: userInfo.city,
      state: userInfo.state,
      country: userInfo.country,
      address: userInfo.address,
      dob: userInfo.dob,
      isLoading: false,
    });
  }

  editProfile = async (e) => {
    e.preventDefault();
    this.setState({ isProcess: true });
    const editProfileData = {
      user: {
        first_name: this.state.fname,
        last_name: this.state.lname,
        email: this.state.email,
        mobile: this.state.mobile,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        address: this.state.address,
        username: this.state.username,
        dob: this.state.dob,
      },
    };
    try {
      const { data } = await put(
        `users/${this.state.id}/profile`,
        {user: encrypt(editProfileData.user)}
      );
      if (data.status === 200) {
        this.props.history.push("/profile");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isProcess: false });
  };

  render() {
    const {
      userInfo,
      fname,
      lname,
      mobile,
      city,
      country,
      state,
      address,
      // username,
      dob,
      email,
      isLoading,
    } = this.state;
    return (
      <>
        {isLoading ? <Loader /> : null}
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <div className="container mt25 ngp-editProfile">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xs-offset-0 col-sm-offset-0 col-md-offset-3 col-lg-offset-3 toppad">
              <div className="panel panel-info">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    Welcome {userInfo !== null ? userInfo.first_name : null}
                  </h3>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <form onSubmit={this.editProfile}>
                      <div className=" col-md-12 col-lg-12 ">
                        <table className="table table-user-information">
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>
                                <label style={{ marginRight: "18px" }}>
                                  <input
                                    type="radio"
                                    name="optionsRadios"
                                    value="Male"
                                  />
                                  Male
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="optionsRadios"
                                    value="Female"
                                  />
                                  Female
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>First Name*:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control edit-control"
                                  name="fname"
                                  onChange={this.handleChange}
                                  value={fname}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Last Name*:</td>
                              <td>
                                <input
                                  type="text"
                                  name="lname"
                                  className="form-control edit-control"
                                  onChange={this.handleChange}
                                  value={lname}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>E-mail*:</td>
                              <td>
                                <input
                                  type="text"
                                  name="email"
                                  className="form-control edit-control"
                                  onChange={this.handleChange}
                                  value={email}
                                  readOnly
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Phone number*:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control edit-control"
                                  name="mobile"
                                  onChange={this.handleChange}
                                  value={mobile}
                                  readOnly
                                />
                                <span id="errmsg"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Birth:</td>
                              <td>
                                <input
                                  type="date"
                                  name="dob"
                                  className="form-control edit-control dob_dobpicker"
                                  onChange={this.handleChange}
                                  value={dob}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Address:</td>
                              <td>
                                <textarea
                                  type="text"
                                  className="form-control edit-control"
                                  name="address"
                                  onChange={this.handleChange}
                                  value={address}
                                >
                                  manapparai
                                </textarea>
                              </td>
                            </tr>
                            <tr>
                              <td> City:</td>
                              <td>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control edit-control"
                                  onChange={this.handleChange}
                                  value={city}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>State:</td>
                              <td>
                                <input
                                  type="text"
                                  name="state"
                                  className="form-control edit-control"
                                  onChange={this.handleChange}
                                  value={state}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Country:</td>
                              <td>
                                <input
                                  type="text"
                                  name="country"
                                  className="form-control edit-control"
                                  onChange={this.handleChange}
                                  value={country}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="edit-profile-action">
                          <button
                            className="bluebtn"
                            value={`${this.state.isProcess} ? "Saving..." : "Save"`}
                            type="submit"
                          >
                            {this.state.isProcess ? "Saving..." : "Save"}
                          </button>
                          <button className="bluebtn">
                            <a href="/profile">Cancel</a>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </>
    );
  }
}

export default EditProfile;

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import Timer from "react-compound-timer";
import { otpVerify, otpResend } from "../../../utills/API";
import { loginCookie } from "../../../utills/function";

class OtpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      status: "",
      errorMessage: null,
      isResendOtp: false,
      time: "",
      successMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ otp: "" });

    setTimeout(() => {
      this.setState({ isResendOtp: true });
    }, 120000);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.otp !== this.state.otp) {
      if (this.state.otp.length === 4) {
        const otpVerifyData = {
          user: { email: this.props.state.email },
          otp: this.state.otp,
        };
        try {
          const { data } = await otpVerify(otpVerifyData);
          let status;
          if (data.status === 200) {
            status = "Success";
            loginCookie(data.data.token, data.data.user.ngp_name);
            this.props.handleClose();
          } else if (data.status === 400) {
            status = "Failure";
          }

          this.setState({ status: status });
        } catch (e) {
          console.log("error", e);
        }
      }
    }
  }

  resendOtp = async () => {
    const otpData = { user: { email: this.props.state.email } };
    try {
      const { data } = await otpResend(otpData);
    } catch (e) {
      console.log("error", e);
    }
  };

  checkStatus = () => {
    if (this.state.status === "Failure") {
      return true;
    }
    return false;
  };

  incorrectMessage = () => {
    if (this.state.status === "Failure") {
      return "Incorrect OTP";
    }
    return null;
  };

  dontHide=()=>{

  }

  render() {
    return (
      <>
        
        <Modal
          show={this.props.show}
          onHide={this.dontHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="go-modal"
        >
          {/* <Modal.Header
            className="pb-0 border-bottom-0"
            closeButton
            onClick={(e)=>{this.props.handleClose()}}
          ></Modal.Header> */}
          <Modal.Body>
            <div className="pb-5 text-center">
              <h1 className="font-weight-bold text-24 mb-4">Verify OTP</h1>
              <p className="text-14 mb-3" style={{ color: "#333333" }}>
                A 4 digit OTP has been sent to {this.props.state.countryCode}-{this.props.state.mobile}
              </p>
              <OtpInput
                onChange={(otp) => this.setState({ otp })}
                numInputs={4}
                inputStyle="otp-input"
                value={this.state.otp}
                isInputNum={false}
                hasErrored={this.checkStatus}
                shouldAutoFocus={true}
                containerStyle="box-otp"
              />
              <div className="otp-incorrect" style={{ marginBottom: "8px" }}>
                {this.incorrectMessage()}
              </div>
              {this.state.isResendOtp ? (
                <button className="btn btn-link" onClick={this.resendOtp}>
                  Resend OTP
                </button>
              ) : (
                <Timer
                  formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                  initialTime={120000}
                  direction="backward"
                >
                  {() => (
                    <>
                      <div style={{ color: "#939393", fontSize: "14px" }}>
                        <Timer.Minutes />:
                        <Timer.Seconds />
                      </div>
                    </>
                  )}
                </Timer>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default OtpModal;
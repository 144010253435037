import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import {
  required,
  validateMobile,
  validateEmail,
  checkPassword,
} from "../../../utills/Validation";
import { signup, post } from "../../../utills/API";
import { ToastContainer, toast } from "react-toastify";
import OtpModal from "./otpModal";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import MobileModal from "./Signup/MobileModal";
// import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../utills/Constants";
import { loginCookie } from "../../../utills/function";
import ReactTelInput from "react-telephone-input";
import { withRouter } from "react-router-dom";
import AlertDismissible from "../../../components/Common/Alert";
import { getUrlParams  } from "../../../utills/function";

import "react-telephone-input/lib/withStyles";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      password: "",
      countryCode: "+91",
      mobile: "",
      inviteCode: "",
      errors: {
        fnameError: null,
        lnameError: null,
        emailError: null,
        passwordError: null,
        mobileError: null,
        countryCodeError: null,
        inviteCodeError: null,
      },
      showOtpModal: false,
      setShowOtpModal: false,
      showMobileModal: false,
      setShowMobileModal: false,
      authUser: {
        email: null,
        provider: null,
        token: null,
        provider_id: null,
        provider_img: null,
      },
      isProcess: false,
      showError: false,
      errorMessage: "",
    };
  }

  validateAllInputs = () => {
    const errors = {
      fnameError: null,
      lnameError: null,
      emailError: null,
      passwordError: null,
      mobileError: null,
      countryCodeError: null,
    };
    const { fname, lname, email, password, countryCode, mobile } = this.state;
    errors.fnameError = required(fname);
    errors.lnameError = required(lname);
    errors.emailError = validateEmail(email);
    errors.passwordError = checkPassword(password);
    errors.mobileError = validateMobile(mobile);
    errors.countryCodeError = required(countryCode);
    this.setState({ errors });
  };

  handleClose = () => {
    this.setState({
      showOtpModal: false,
    });
    this.props.history.push("/");
  };

  handleShow = () => {
    this.setState({
      showOtpModal: true,
      setShowOtpModal: true,
    });
  };

  validityCheck = () => {
    const { fname, lname, email, password, countryCode, mobile } = this.state;
    return (
      fname &&
      lname &&
      email &&
      password &&
      countryCode &&
      mobile &&
      password.match(/^[^-\s]{8,20}$/) &&
      email.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      ) &&
      mobile.length === 10
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  adapSignupCode = (txnId, utmTrack) => {
    (new Image).src="https://trk.superadappt.com/pixel?adid=6005644e6eada532337285de&sub1=" + txnId + "&sub2=" + utmTrack + "&sub3=&sub4=";
  }

  signUp = async (e) => {
    this.setState({ isProcess: true });
    e.preventDefault();
    this.validateAllInputs();
    if (this.validityCheck()) {
      const {
        fname,
        lname,
        email,
        password,
        countryCode,
        mobile,
        inviteCode,
      } = this.state;
      const signupData = {
        user: {
          first_name: fname,
          last_name: lname,
          email: email,
          password: password,
          country_code: countryCode,
          mobile: mobile,
          invite_code: inviteCode,
        },
      };
      try {
        const { data } = await signup(signupData);
        if (data.status === 200) {
          this.setState({
            showOtpModal: true,
            setShowOtpModal: true,
          });
          const ReactPixel = require('react-facebook-pixel');
          ReactPixel.default.init('1430251647147026');
          ReactPixel.default.init('1326840351009817');
          ReactPixel.default.trackCustom("Signup");
          const utm_term = getUrlParams('utm_term', window.location.search)
          this.adapSignupCode('', utm_term);
          this.props.onHide();
        } else if (data.status === 400) {
          this.setState({
            showError: true,
            errorMessage: data.message,
          });
        } else if (data.status === 422) {
          this.setState({
            showError: true,
            errorMessage: data.message,
          });
        }
      } catch (e) {
        console.log("error", e);
        this.setState({
          showError: true,
          errorMessage: "Email Already Exist",
        });

      }
    } else {
      console.log("Enter valid Details");
    }
    this.setState({ isProcess: false });
  };

  responseGoogle = (response) => {
    this.signupOAuth(response, "google");
  };

  responseFacebook = (response) => {
    this.signupOAuth(response, "facebook");
  };

  errorGoogle = (error) => {
    console.log(error);
  };

  signupOAuth = async (resp, type) => {
    let signupOAuthData;
    let res = resp ? resp : null;
    if (type === "google" && res.w3.U3) {
      signupOAuthData = {
        user: {
          email: res.w3.U3,
          provider: type,
          token: res.accessToken,
          provider_id: res.googleId,
          provider_img: res.profileObj.imageUrl,
        },
      };
    } else if (type === "facebook" && res.email) {
      signupOAuthData = {
        user: {
          email: res.email,
          provider: type,
          token: res.accessToken,
          provider_id: res.id,
          provider_img: res.picture.data.url,
        },
      };
    }

    try {
      const { data } = await post("outh-signin", signupOAuthData);
      if (data.status === 200 && data.data.user.mobile === null) {
        this.setState({
          showMobileModal: true,
          setShowMobileModal: true,
          authUser: signupOAuthData.user,
          email: signupOAuthData.user.email,
        });
      } else if (
        data.status === 200 &&
        data.message === "Mobile number not register"
      ) {
        this.setState({
          showMobileModal: true,
          setShowMobileModal: true,
          authUser: signupOAuthData.user,
          email: signupOAuthData.user.email,
        });
      } else if (data.status === 200 && data.data.token) {
        loginCookie(data.data.token, data.data.user.ngp_name);
        if (localStorage.getItem("checkoutData")) {
          let redirectPath = localStorage.getItem("checkoutPath");
          this.props.history.push(redirectPath);
        } else {
          this.props.history.push("/voucher");
        }
      } else if (data.status === 422) {

        this.setState({
          showError: true,
          errorMessage: "User already registered with this email",
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  validateMobileModal = () => {
    const errors = {
      mobileError: null,
      countryCodeError: null,
    };
    const { countryCode, mobile } = this.state;
    errors.mobileError = validateMobile(mobile);
    errors.countryCodeError = required(countryCode);
    this.setState({ errors });
  };

  registerMobile = async () => {
    this.validateMobileModal();
    if (
      this.state.mobile &&
      this.state.mobile.length === 10 &&
      this.state.countryCode
    ) {
      const { authUser, mobile, countryCode, inviteCode } = this.state;
      const registerMobileData = {
        user: {
          email: authUser.email,
          provider_id: authUser.provider_id,
          provider: authUser.provider,
        },
        mobile: mobile,
        country_code: countryCode,
        invite_code: inviteCode,
      };

      try {
        const { data } = await post("register-mobile", registerMobileData);
        if (data.status === 200) {
          this.setState({ showOtpModal: true, showMobileModal: false });
        } else if (data.status === 422) {
          this.setState({
            showError: true,
            errorMessage: "Invalid Invite Code",
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    } else {
      console.log("Enter valid Details");
    }
  };

  redirectUrl = (url) => {
    // this.props.history.push(url);
    this.props.setModalSuccessSignup(true);
  };

  handleInputChange = (telNumber, selectedCountry) => {
    let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
    let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
    this.setState({ countryCode: `+${selectedCountry.dialCode}`, mobile });
  };

  handleInputBlur = (telNumber, selectedCountry) => {
    console.log(
      "Focus off the ReactTelephoneInput component. Tel number entered is: ",
      telNumber,
      " selected country is: ",
      selectedCountry
    );
  };

  dontHide=()=>{

  }

  toggleError = (value) => {
    this.setState({ showError: value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { props } = this;
    const {
      fname,
      lname,
      email,
      password,
      // countryCode,
      // mobile,
      errors,
      inviteCode,
    } = this.state;
    // return (
    //   <>
    //     <ToastContainer position={toast.POSITION.TOP_CENTER} />
    //     <Header />

    //     <Footer />
    //   </>
    // );

    return (
      <>
        <Modal
          show={props.show}
          onHide={this.dontHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <form onSubmit={this.signUp}>
            {/* <Modal.Header
              className="pb-0 border-bottom-0"
              closeButton
            ></Modal.Header> */}
            <Modal.Body>
              <AlertDismissible
                toggleError={this.toggleError}
                errorMessage={this.state.errorMessage}
                show={this.state.showError}
              />
              <div className="pl-4 pr-4">
                <h1 className="font-weight-bolder text-22 mb-4">Sign Up</h1>
                <ToastContainer position={toast.POSITION.TOP_CENTER} />
                <div class="form-group">
                  <label>
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="fname"
                    placeholder="Enter First Name"
                    value={fname}
                    onChange={this.handleChange}
                    class="form-control"
                  />
                  {errors.fnameError ? (
                    <div className="error-warning">{errors.fnameError}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    placeholder="Enter Last Name"
                    value={lname}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  {errors.lnameError ? (
                    <div className="error-warning">{errors.lnameError}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Email ID"
                    value={email}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  {errors.emailError ? (
                    <div className="error-warning">{errors.emailError}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  {errors.passwordError ? (
                    <div className="error-warning">{errors.passwordError}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    Mobile <span>*</span>
                  </label>

                  <ReactTelInput
                    defaultCountry="in"
                    flagsImagePath="/flags.723494a4.png"
                    onChange={this.handleInputChange}
                  />
                  {errors.mobileError ? (
                    <div className="error-warning">{errors.mobileError}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>Invite Code</label>
                  <input
                    type="text"
                    name="inviteCode"
                    value={inviteCode}
                    onChange={this.handleChange}
                    placeholder="Invite Code (optional)"
                    className="form-control"
                  />
                </div>

                
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top-0">
              <div className="d-flex flex-column w-100 pl-4 pr-4 pb-4">
                <Button
                  variant="primary"
                  block
                  disabled={this.state.isProcess}
                  type="submit"
                >
                  {this.state.isProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : null}
                  sign up
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
        <OtpModal
          state={this.state}
          show={this.state.showOtpModal}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
        />
      </>
    );
  }
}

export default withRouter(Signup);

import React, { Component } from "react";
import logo from "../../assets/images/gainon/footer-logo.png";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Footer from "./Footer";
import Contact from "../MContact";

class FooterMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalContact: false,
    };
  }
  setmodalContact = (opt) => {
    this.setState({
      modalContact: opt,
    });
  };

  render() {
    return (
      <>
        <footer className="go-main-footer">
          <Container>
            <Row>
              <Col sm={5}>
                <img src={logo} alt="Logo" style={{ width: "149px" }} />
                <p className="details">
                  Gainon is a members-only rewards program that offers instant
                  loyalty cashback on every payment - earn and redeem across
                  100+ leading brands you love.
                </p>
                <p className="details font-weight-bold my-0 mb-sm-0 mb-5">
                  Spend smart, Earn more
                </p>
              </Col>
              <Col sm={2}>
                <h6 className="link-txt">Important links</h6>
                <Nav
                  defaultActiveKey="/about"
                  className="flex-column nav-importanat-link mb-sm-0 mb-5"
                >
                  <Nav.Link href="/about">About Us</Nav.Link>
                  <Nav.Link href="" onClick={() => this.setmodalContact(true)}>
                    Contact us
                  </Nav.Link>
                  <Nav.Link href="/voucher" eventKey="/voucher">
                    Shop
                  </Nav.Link>
                  <Nav.Link href="/voucher/redeem" eventKey="/voucher/redeem">
                    Redeem
                  </Nav.Link>
                  <Nav.Link href="/faq" eventKey="/">
                    FAQ
                  </Nav.Link>
                </Nav>
              </Col>
              <Col sm={5}>
                <h6 className="link-txt">Contact info</h6>
                <dl className="row contact-list">
                  <dt className="col-sm-2 col-md-2 col-3">Address:</dt>
                  <dd className="col-sm-10 col-md-10 col-9">
                    B wing, 5th floor, Ackruti Trade Centre MIDC, Andheri East,
                    Mumbai, Maharashtra 400093
                  </dd>

                  <dt className="col-sm-2 col-md-2 col-3">Support:</dt>
                  <dd className="col-sm-10 col-md-10 col-9">
                    <a href="mailto:support@gainon.com">support@gainon.in</a>
                  </dd>
                </dl>
              </Col>
            </Row>
          </Container>
          <Footer />
          <Contact
            show={this.state.modalContact}
            onHide={() => this.setmodalContact(false, "")}
          />
        </footer>
      </>
    );
  }
}
export default FooterMain;
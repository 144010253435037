import React from "react";
import { Modal } from "react-bootstrap";

const MessageModal = ({restoreFailedVouchers,isShowMessage,closeMessageModal, isProcess})=> {
  return (
    <Modal
      className="modal"
      show={isShowMessage}
      onHide={closeMessageModal}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{color:'#a0221e'}}>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to create the voucher without payment?
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-container">
          <button
            className="btn btn-danger delete"
            onClick={restoreFailedVouchers}
            disabled={isProcess}
          >
            Create Vouchers
          </button>
          <button
            style={{marginLeft:"10px"}}
            className="btn btn-secondary"
            onClick={closeMessageModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
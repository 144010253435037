import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { put, get } from "../../../utills/API";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../../assets/sass/admin/Category/AddCategory.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class EditPromoCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "paid",
      goPointPartnerID: null,
      categoryArr: [],
      brandId: null,
      brandsArr: [],
      title: "",
      subIntro: "",
      poi: "",
      couponCode: "",
      validFrom: new Date(),
      validTo: new Date(),
      minAmount: "",
      maxAmount: "",
      desc: "",
      couponMode: "percentage",
      couponValue: "",
      img: "",
      disclaimer: "",
      howToRedeem: "",
      tnc: "",
      promoCodeId: "",
      is_publish: "",
    };
  }

  async componentDidMount() {
    const { promoCodeId } = this.props.match.params;

    try {
      const { data } = await get(`promo_codes/${promoCodeId}`);
      var promoCodeData = data.data.promo_code;
      console.log(promoCodeData);
    } catch (e) {
      console.log("error", e);
    }
    try {
      const { data } = await get("promo_codes/all/goPointpartner");
      var categoryArr = data.data.goPointPartner;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("brands");
      var brandsArr = data.data.brands;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      categoryArr: categoryArr,
      brandsArr: brandsArr,
      type: promoCodeData.code_type,
      goPointPartnerID: promoCodeData.gopoint_partner_id,
      brandId: promoCodeData.brand_id,
      title: promoCodeData.title,
      subIntro: promoCodeData.sub_intro,
      poi: promoCodeData.price_of_incentive,
      couponCode: promoCodeData.code,
      validFrom: new Date(promoCodeData.valid_from),
      validTo: new Date(promoCodeData.valid_to),
      minAmount: promoCodeData.min_amount,
      maxAmount: promoCodeData.max_amount,
      desc: promoCodeData.description,
      // couponMode: promoCodeData.mode,
      couponValue: promoCodeData.value,
      disclaimer: promoCodeData.disclaimer,
      howToRedeem: promoCodeData.reedem_instruction,
      tnc: promoCodeData.term_and_condition,
      promoCodeId: promoCodeId,
      is_publish: promoCodeData.is_publish,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "img") {
      this.setState({ img: e.target.files[0] });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleValidFrom = (date) => {
    this.setState({ validFrom: date });
  };

  handleValidTo = (date) => {
    this.setState({ validTo: date });
  };

  isPublishChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  editPromoCode = async (e) => {
    e.preventDefault();
    const {
      type,
      goPointPartnerID,
      // categoryArr,
      brandId,
      // brandsArr,
      title,
      subIntro,
      poi,
      couponCode,
      validFrom,
      validTo,
      minAmount,
      maxAmount,
      desc,
      couponMode,
      couponValue,
      img,
      disclaimer,
      howToRedeem,
      tnc,
      promoCodeId,
      is_publish,
    } = this.state;
    let formData = new FormData();

    formData.append("image", img);
    formData.set("promo_code[code_type]", type);
    formData.set("promo_code[gopoint_partner_id]", Number(goPointPartnerID))
    formData.set("promo_code[title]", title);
    formData.set("promo_code[sub_intro]", subIntro);
    formData.set("promo_code[price_of_incentive]", poi);
    formData.set("promo_code[code]", couponCode);
    formData.set("promo_code[valid_from]", validFrom);
    formData.set("promo_code[valid_to]", validTo);
    formData.set("promo_code[min_amount]", minAmount);
    formData.set("promo_code[max_amount]", maxAmount);
    formData.set("promo_code[description]", desc);
    formData.set("promo_code[mode]", couponMode);
    formData.set("promo_code[value]", couponValue);
    formData.set("promo_code[disclaimer]", disclaimer);
    formData.set("promo_code[reedem_instruction]", howToRedeem);
    formData.set("promo_code[term_and_condition]", tnc);
    formData.set("promo_code[brand_id]", brandId);
    formData.set("promo_code[category_id]", goPointPartnerID);
    formData.set("promo_code[is_publish]", is_publish);

    try {
      const { data } = await put(`promo_codes/${promoCodeId}`, formData);
      if (data.status === 200) {
        this.props.history.push("/admin/promoCode");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Something Went Wrong!", { autoClose: 2000 });
    }
  };

  render() {
    const {
      type,
      goPointPartnerID,
      categoryArr,
      brandId,
      brandsArr,
      title,
      // subIntro,
      // poi,
      couponCode,
      validFrom,
      validTo,
      minAmount,
      maxAmount,
      desc,
      couponMode,
      couponValue,
      // img,
      disclaimer,
      howToRedeem,
      tnc,
      is_publish,
    } = this.state;

    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Add Promo Code</div>
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <form onSubmit={this.editPromoCode}>
              <div className="row add-coupon-main">
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Coupon Type</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      onChange={this.handleChange}
                      value={type}
                      name="type"
                    >
                      <option value="paid">Paid</option>
                      <option value="free">Free</option>
                      <option value="gopoint">Gopoint</option>
                    </select>
                  </div>
                </div>

                {/* Select gopoint partner */}


                {this.state.type === "gopoint" ? (<div className="col-md-12 main-row">
                  <div className="col-md-2">Gopoint Partner</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="goPointPartnerID"
                      value={goPointPartnerID}
                      onChange={this.handleChange}
                    >

                      {console.log(goPointPartnerID)}
                      <option value="">Select Partner</option>
                      {categoryArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>) : ''}
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Category</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="goPointPartnerID"
                      value={goPointPartnerID}
                      onChange={this.handleChange}
                    >

                      {console.log(goPointPartnerID)}
                      <option value="">Select Category</option>
                      {categoryArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Brand</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="brandId"
                      value={brandId}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Brand</option>
                      {brandsArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-2"> Title</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Title"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2"> Sub Introduction</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sub Introduction"
                      name="subIntro"
                      value={subIntro}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Price of Incentive</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Price of Incentive"
                      name="poi"
                      value={poi}
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Coupon Code</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Coupon Code"
                      name="couponCode"
                      value={couponCode}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Valid From</div>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control"
                      selected={validFrom}
                      onChange={this.handleValidFrom}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Valid To</div>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control"
                      selected={validTo}
                      onChange={this.handleValidTo}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Minimum Amount</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Min Amount"
                      name="minAmount"
                      value={minAmount}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Maximum Amount</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Max Amount"
                      name="maxAmount"
                      value={maxAmount}
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Coupon Mode</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      onChange={this.handleChange}
                      value={couponMode}
                      name="couponMode"
                    >
                      <option value="percentage">Percentage (%)</option>
                      <option value="flat">Flat</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Coupon Value</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Value"
                      name="couponValue"
                      value={couponValue}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Image Upload</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="img"
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Publish</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="is_publish"
                        checked={is_publish}
                        onClick={this.isPublishChange}
                        className="onoffswitch-checkbox"
                        id="is_publish"
                      />
                      <label className="onoffswitch-label" for="is_publish">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 main-row">
                  <div className="col-md-2">Description</div>
                  <div className="col-md-8">
                    <textarea
                      className="form-control"
                      name="desc"
                      value={desc}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Disclaimer</div>
                  <div className="col-md-8">
                    <textarea
                      className="form-control"
                      onChange={this.handleChange}
                      value={disclaimer}
                      name="disclaimer"
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">How To Redeem</div>
                  <div className="col-md-8">
                    <textarea
                      className="form-control"
                      onChange={this.handleChange}
                      value={howToRedeem}
                      name="howToRedeem"
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Terms & Conditions</div>
                  <div className="col-md-8">
                    <textarea
                      className="form-control"
                      onChange={this.handleChange}
                      value={tnc}
                      name="tnc"
                    />
                  </div>
                </div> */}
                <div className="col-md-12 main-row">
                  <div className="col-md-8 offset-2 brands-btn">
                    <button className="btn btn-primary" type="submit">
                      Update Promo Code
                    </button>
                    <Link to={"/admin/promoCode"} className="btn btn-primary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditPromoCode);

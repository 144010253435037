import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import cookie from "react-cookies";
import { logout } from "../utills/API";
import renderHTML from "react-render-html";
import { Accordion, Card } from "react-bootstrap";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      rowId: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    this.props.history.push("/");
  };

  handleFaq = (id) => {
    console.log("id", typeof id);

    this.setState({ activeId: id });
  };

  render() {
    const { activeId } = this.state;
    return (
      <>
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <section className="page page-faq">
          <div className="container">
            <h1 className="mb-0" style={{ padding: "30px 0" }}>
              FAQs
            </h1>
            <Accordion bsPrefix="gofaq" defaultActiveKey="0">
              {faq.map((data, i) => {
                return (
                  <>
                    <Card key={i}>
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey={i}
                        onClick={() => this.handleFaq(i)}
                      >
                        {data.ques}
                        <i
                          className={
                            i === activeId
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          }
                        ></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>{renderHTML(`${data.ans}`)}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </>
                );
              })}
            </Accordion>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default FAQ;

const faq = [
  {
    id: 1,
    ques: "How do I make a payment?",
    ans: `<p>Select any brand, enter the amount, and complete the payment using Razorpay gateway. You will then receive e-voucher(s) with loaded cash that can be used at the selected brand.</p>
      <p>E.g. If you get an Rs.500/- e-voucher, the brand will deduct that from your final payable amount. Any remaining amount should be paid directly to the brand using cash or card.</p>
      <p>All e-voucher(s) have a validity of 6-12 months. It is not necessary to pay the entire bill amount using Gainon. Some e-voucher(s) can be re-used if any balance remains after initial use. All terms and conditions are provided on the brand page.</p>`,
  },
  {
    id: 2,
    ques: "How do I redeem the unique codes I receive?",
    ans: `<p>E-voucher details will need to be entered in the ‘Promocode’ section, 'Gift Card' section or otherwise be shown to a member of staff at the billing counter</p>
    <p>Always check the important instructions section on every brand. This will include key information such as how to redeem, terms and conditions, restrictions and any other details for consideration. We encourage everyone to read all the information as e-vouchers are strictly non-refundable.</p>`,
  },
  {
    id: 3,
    ques: "How do I earn Loyalty Cashback?",
    ans: `<p>Every time a payment is completed on the Gainon website/ web app you earn Instant Loyalty Cashback (the Cashback is added in your Gainon wallet).
      See all the brands <a href="/voucher">here</a>.</p>`,
  },
  {
    id: 4,
    ques: "What can I do with my Wallet Cash?",
    ans: `<p>You can use your wallet cash as a discount when paying through gainon at any of the 150+ brands. And don't worry, there is no minimum wallet balance restriction for redeems!</p>`,
  },
  {
    id: 5,
    ques: "Do I earn Cashback every time?",
    ans: `<p>Yes. The more you pay with Gainon, the more you will benefit!</p>`,
  },
  {
    id: 6,
    ques: "Do I need to create an account to use Gainon?",
    ans: `<p>Yes, to guarantee the best experience we require an account to be created.</p>`,
  },
  {
    id: 7,
    ques: "Still feel you have an issue?",
    ans: `<p>Contact our team here – <a href="mailto:support@gainon.in">support@gainon.in</a></p>
    <p>We hope to serve you in the very best way possible, please do bear with us whilst we solve your query.</p>`,
  },
];

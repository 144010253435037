import React from "react";
import "../../assets/sass/admin/Header.scss";
import { logout } from "../../utills/API";
import { withRouter } from "react-router-dom";

class AdminHeader extends React.Component {
  state = {
    adminDropDown: false,
  };

  onAdminClick = () => {
    this.setState({ adminDropDown: !this.state.adminDropDown });
  };

  onLogout = () => {
    logout();
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="admin-header-container">
        <div className="admin-header">
          <div className="navbar navbar-light">
            <i
              onClick={this.props.responsiveSidebarDisplay}
              className={`fal ${
                this.props.showResponsiveSidebar ? "fa-times" : "fa-bars"
              } `}
            ></i>
            <img
              src={require("../../assets/images/logo.png")}
              className="brand-logo navbar-brand"
              alt=""
            />
            <div onClick={this.onAdminClick} className="admin-menu">
              <i className="fa fa-user"></i>
              <span>Admin</span>
            </div>
          </div>
          {this.state.adminDropDown ? (
            <div className="admin-dropdown">
              <ul>
                <li onClick={this.onLogout}>Logout</li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(AdminHeader);

import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Nav, Badge } from "react-bootstrap";
import { urlLinks } from "../utills/Constants";

const AllCategories = 
(props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="modal-all-categories"
      >
        <Modal.Header className="border-bottom-0" closeButton>
          <p className="mb-0">Select category</p>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <Nav
            variant="category"
            activeKey={props.selectedVoucherKey}
            className="flex-column "
          >
            <Nav.Link className="px-0 py-3 text-14" as={Link} to={props.isredeem === "redeem" ? ('/voucher/redeem'): '/voucher'} eventKey="" onClick={() => props.setCurrCategoryId(null,'','')} >
              All Categories
              {props.currCategoryId == null  ? <i className="icon-check" /> : null}
            </Nav.Link>
            {/* {props.setEasyDine ? (
              <Nav.Link className="px-0 py-3 text-14" onClick={() => props.setEasyDine(3)} eventKey="eazydiner">Eazydiner{' '}
                {props.currCategoryId == "3"  ? <i className="icon-check" /> : null}
                <Badge pill="true" variant="secondary">New</Badge>
              </Nav.Link>
            ) : null} */}
            {
              props.categories.map((category) => (
                <Nav.Link as={Link} to={props.isredeem === "redeem" ? ('/voucher/redeem/'+urlLinks[category.id]):('/voucher/'+urlLinks[category.id])}  eventKey={category.name} className="px-0 py-3 text-14" onClick={() => props.setCurrCategoryId(category.id, category.name, urlLinks[category.id])}>
                  {category.name}
                  {props.currCategoryId == category.id  ? <i className="icon-check" /> : null}
                </Nav.Link>
              ))
            }
          </Nav>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AllCategories;

import React from "react";
import ReactDatePicker from "react-datepicker";
import "./VendorBrandConfigurations.scss"
const RangeComponent = ({ RangeObject, SetRangeObject, ComponentDisabled }) => {

    const {
        guid,
        pin_number,
        min_amount,
        max_amount,
        multiple_of,
        expiry_date
    } = RangeObject;


    // Handle range data:
    const HandleRangeData = (data) => {
        const { name, value } = data.target;

        var NewRangeObject = {
            ...RangeObject,
            [name]: value,
        };

        SetRangeObject(NewRangeObject);
    }

    return (
        <div>
            <hr />
            <div className="row">


                <div className="col-md-2" >
                    <div className="form-group">
                        <label>GUID/SKU:</label>
                        <input className="form-control"
                            disabled={ComponentDisabled}
                            name="guid"
                            placeholder="GUID/SKU"
                            value={guid}
                            onChange={(e) => HandleRangeData(e)}
                            required
                        />
                    </div>
                </div>

                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Min. Amount:</label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="min_amount"
                            placeholder="Min. Amount"
                            value={min_amount}
                            onChange={(e) => HandleRangeData(e)}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Max. Amount:</label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="max_amount"
                            placeholder="Max. Amount"
                            value={max_amount}
                            onChange={(e) => HandleRangeData(e)}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Multiple Of: </label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="multiple_of"
                            placeholder="Multiple of"
                            value={multiple_of}
                            onChange={(e) => HandleRangeData(e)}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Expiry Date:</label>
                        <ReactDatePicker
                            className="form-control"
                            selected={expiry_date}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => HandleRangeData({
                                target: {
                                    name: "expiry_date",
                                    value: e
                                }
                            })}
                        />
                    </div>
                </div>
                <div className="col-md-2" >
                    <div className="form-group">
                        <label>Enter Pin:</label>

                        <input className="form-control"
                            disabled={ComponentDisabled}

                            name="pin_number"
                            placeholder="Pin"
                            value={pin_number}
                            onChange={(e) => HandleRangeData(e)}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default RangeComponent;
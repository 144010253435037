import React, { useState } from "react";
import { Tabs, Tab, Card } from "react-bootstrap";
import voucher from "../../assets/images/gainon/voucher-icon.svg";
import shop from "../../assets/images/gainon/shop-icon.svg";
import redeem1 from "../../assets/images/gainon/redeem-step-1.svg";
import redeem2 from "../../assets/images/gainon/redeem-step-2.svg";
import redeem3 from "../../assets/images/gainon/redeem-step-3.svg";
import redeem4 from "../../assets/images/gainon/redeem-step-4.svg";
import cash from "../../assets/images/gainon/cash-icon.svg";

function TabHowTo(props) {


  const [key, setKey] = useState("home");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      variant="gotabs"
    >
      <Tab eventKey="home" title="How to Redeem">
        <Card className="card-shop card-brand-type">
          <Card.Body>
            <div className="media">
              <img className="icon-img" src={voucher} alt="Voucher icon" />
              <div className="media-body">
                <h4>Stepsssss 1</h4>
                <p>
                  Visit the outlet/store nearby you that accepts the e-voucher.
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="card-shop card-brand-type">
          <Card.Body>
            <div className="media">
              <img className="icon-img" src={shop} alt="shop icon" />
              <div className="media-body">
                <h4>Step 2</h4>
                <p>Shop as usual</p>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="card-shop card-brand-type">
          <Card.Body className="d-flex align-items-start">
            <div className="media">
              <img className="icon-img" src={cash} alt="Cash icon" />
              <div className="media-body">
                <h4>Step 3</h4>
                <p>
                  Share your e-voucher at the time of billing & if required, pay
                  any remaining amount by cash or card.
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className="card-redeem card-brand-type">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                className="redeem-icon-img"
                src={redeem1}
                alt="Redeem step 1"
              />
            </div>
            <div className="col-md-8">
              <Card.Body className="d-flex align-items-start flex-column">
                <h4>Step 1</h4>
                <p className="font-weight-normal">
                  Visit : https://www.flipkart.com/ or Flipkart app and on
                  payment page select Add Gift Card. Enter the E-Voucher code &
                  PIN.
                </p>
              </Card.Body>
            </div>
          </div>
        </Card>
        <Card className="card-redeem card-brand-type">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                className="redeem-icon-img"
                src={redeem2}
                alt="Redeem step 2"
              />
            </div>
            <div className="col-md-8">
              <Card.Body className="d-flex align-items-start flex-column">
                <h4>Step 2</h4>
                <p className="font-weight-normal">
                  Select Gift Cards from the Navigation on the top right of the
                  page.
                </p>
              </Card.Body>
            </div>
          </div>
        </Card>
        <Card className="card-redeem card-brand-type">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                className="redeem-icon-img"
                src={redeem3}
                alt="Redeem step 3"
              />
            </div>
            <div className="col-md-8">
              <Card.Body className="d-flex align-items-start flex-column">
                <h4>Step 3</h4>
                <p className="font-weight-normal">
                  Select Add Gift Card Enter E-Voucher Code & PIN.
                </p>
              </Card.Body>
            </div>
          </div>
        </Card>
        <Card className="card-redeem card-brand-type">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img
                className="redeem-icon-img"
                src={redeem4}
                alt="Redeem step 4"
              />
            </div>
            <div className="col-md-8">
              <Card.Body className="d-flex align-items-start flex-column">
                <h4>Step 4</h4>
                <p className="font-weight-normal">Press Add Gift Card.</p>
              </Card.Body>
            </div>
          </div>
        </Card>
      </Tab>
      <Tab eventKey="profile" title="Description">
        <Card className="card-brand-type mt-3">
          <Card.Body>
            <p className="font-weight-normal">
              Shop at Big Bazaar for the latest household items, groceries,
              apparel and accessories, electronics, home and furniture, footwear
              , cosmetics, sportswear, and more under one roof. This convenience
              store gives you millions of products across various segments to
              choose from. Instead of going to multiple stores to have your
              needs met, shop from Big Bazaar to get everything at one place.
              You name it Big Bazaar has it covered. This hypermarket chain
              started with an aim to provide all the everyday essentials at one
              space. Big Bazaar is making departmental store shopping easier for
              you.
            </p>
          </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="contact" title="Terms & Condition">
        <Card className="card-brand-type mt-3">
          <Card.Body>
            <ul className="terms-tab">
              <li>Payment to be made using E-Vouchers.</li>
              <li>
                E-Vouchers are ACCEPTED at all Big Bazaar/ Food Bazaar & FBB
                Stores across India.
              </li>
              <li>E-Vouchers CANNOT be used Online.</li>
              <li>E-Vouchers CAN be used to buy most of the products.</li>
              <li>Multiple E-Vouchers CAN be used in one bill.</li>
              <li>E-Vouchers to be applied as an e-gift voucher.</li>
            </ul>
          </Card.Body>
        </Card>
      </Tab>
    </Tabs>
  );
}

export default TabHowTo;
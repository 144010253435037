import React from "react";
import moment from "moment";

const VoucherCoupon = (props) => (
  <svg
    viewBox="0 0 408 164"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      {Number(props.eachVoucher.value) <= 1000 ? (
        <linearGradient
          x1="20.7592046%"
          y1="45.8668467%"
          x2="79.7038133%"
          y2="55.4854674%"
          id="linearGradient-1"
        >
          <stop stopColor="#3C5FF4" offset="0%"></stop>
          <stop stopColor="#3C98F4" offset="100%"></stop>
        </linearGradient>
      ) : Number(props.eachVoucher.value) <= 5000 ? (
        <linearGradient
          x1="20.7592046%"
          y1="45.8668467%"
          x2="79.7038133%"
          y2="55.4854674%"
          id="linearGradient-1"
        >
          <stop stopColor="#3C5FF4" offset="0%"></stop>
          <stop stopColor="#3C98F4" offset="100%"></stop>
        </linearGradient>
      ) : (
            <linearGradient
              x1="20.7592046%"
              y1="45.8668467%"
              x2="79.7038133%"
              y2="55.4854674%"
              id="linearGradient-1"
            >
              <stop stopColor="#3C5FF4" offset="0%"></stop>
              <stop stopColor="#3C98F4" offset="100%"></stop>
            </linearGradient>
          )}
      <path
        d="M380,0 C386.627417,-1.21743675e-15 392,5.372583 392,12 L392,136 C392,142.627417 386.627417,148 380,148 L124,148 L124,148 C124,141.372583 118.627417,136 112,136 C105.372583,136 100,141.372583 100,148 L12,148 C5.372583,148 8.11624501e-16,142.627417 0,136 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 L100,0 L100,0 C100,6.627417 105.372583,12 112,12 C118.627417,12 124,6.627417 124,0 L380,0 Z"
        id="path-2"
      ></path>
      <filter
        x="-3.6%"
        y="-6.8%"
        width="107.1%"
        height="118.9%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feOffset
          dx="0"
          dy="4"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0431326486 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Final-Screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="4.2" transform="translate(-350.000000, -151.000000)">
        <g id="Group-42-Copy-3" transform="translate(358.000000, 155.000000)">
          <g id="Combined-Shape-Copy-3">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-3)"
              href="#path-2"
            ></use>
            <use
              fill="url(#linearGradient-1)"
              fillRule="evenodd"
              href="#path-2"
            ></use>
          </g>
          <g id="Group-8" transform="translate(8, 25)">
            <g id="Group-19-Copy-4">
              <image
                id="Bitmap"
                x="0.497175141"
                y="0.497175141"
                width="100"
                height="100"
                href={props.eachVoucher.image}
              ></image>
            </g>
          </g>
          <g id="Group-43" transform="translate(152.000000, 24.000000)">
            <text
              id="Flipkart---5-voucher"
              fontFamily="Helvetica"
              fontSize="14"
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x="6" y="14">
                {props.eachVoucher.brand_name}
              </tspan>
            </text>
            <g id="Group-3" transform="translate(0.000000, 26.000000)">
              <text
                id="12,400"
                fontFamily="Helvetica"
                fontSize="32"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="28" y="31">
                  {parseInt(props.eachVoucher.value)}
                </tspan>
              </text>
              <g id="Group-2-Copy" transform="translate(0.000000, 7.000000)">
                <rect id="Rectangle" x="0" y="0" width="26" height="26"></rect>
                <path
                  d="M18.1964286,24.2202381 L18.1964286,24.0416667 L10.860119,15.5297619 C13.0327381,15.5297619 14.7291667,15.0833333 15.9494048,14.1904762 C17.1696429,13.297619 17.8690476,11.968254 18.047619,10.202381 L18.047619,10.202381 L20.3095238,10.202381 L20.9940476,7.85119048 L18.0029762,7.85119048 C17.8343254,6.69047619 17.4325397,5.70833333 16.797619,4.9047619 L16.797619,4.9047619 L20.3095238,4.9047619 L20.9940476,2.55357143 L7.22916667,2.55357143 L6.5297619,4.9047619 L10.7113095,4.9047619 C13.1517857,4.98412698 14.610119,5.96626984 15.0863095,7.85119048 L15.0863095,7.85119048 L7.18452381,7.85119048 L6.5,10.202381 L15.1160714,10.202381 C14.9077381,11.1150794 14.4166667,11.8368056 13.6428571,12.3675595 C12.8690476,12.8983135 11.8621032,13.1686508 10.6220238,13.1785714 L10.6220238,13.1785714 L6.91666667,13.1785714 L6.93154762,15.0386905 L14.8184524,24.2202381 L18.1964286,24.2202381 Z"
                  id="₹"
                  stroke="#FFFFFE"
                  strokeWidth="0.5"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
            <g
              id="Group-5"
              transform="translate(6.000000, 82.000000)"
              fill="#FFFFFF"
              fontFamily="Helvetica"
              fontWeight="normal"
            >
              <g id="Group-33-Copy-4">
                <g id="Group-32">
                  <g
                    id="Group-34"
                    transform="translate(89.000000, 0.000000)"
                    fontSize="14"
                  >
                    <text
                      id={moment(props.eachVoucher.end_date).format(
                        "D MMM YYYY"
                      )}
                    >
                      <tspan x="4.828125" y="14">
                        {props.eachVoucher.end_date == '' ? '-' : moment(props.eachVoucher.end_date).format(
                          "D MMM YYYY"
                        )}
                      </tspan>
                    </text>
                  </g>
                  <text id="Valid-Till-:" fontSize="12" letterSpacing="1">
                    <tspan x="0" y="12">
                      VALID TILL :
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
          <line
            x1="112"
            y1="24.2830688"
            x2="112"
            y2="130.716931"
            id="Line-3"
            stroke="#FFFFFF"
            strokeLinecap="square"
            strokeDasharray="9"
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export default VoucherCoupon;

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
// import "../../assets/sass/admin/Brand.scss";

class Promocode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCondition: false,
      promocodeId: "",
    };
  }

  toggleCondition = (id) => {
    this.setState({
      showCondition:
        this.state.promocodeId === id ? !this.state.showCondition : true,
      promocodeId: id,
    });
  };

  copyCode = (promocode) => {
    var el = document.querySelector(`.promocode-${promocode.id}`);
    el.select();
    document.execCommand("copy");
    this.setState({ promocodeId: promocode.id });
    this.props.setPromocode(promocode);
  };

  render() {
    const { props } = this;
    return (
      <>
        <Modal
          className="voucher-modal-box promocode-modal"
          show={props.state.showPromocode}
          onHide={props.close}
        >
          <Modal.Header closeButton style={{ fontWeight: "600" }}>
            Promo Codes
          </Modal.Header>
          <div
            className="vou-suc"
            style={{
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <div className="row">
              <div className="col-md-12 col-xs-12 padding-rm">
                {props.promocodes.map((promocode) => {
                  return (
                    <div className="row bors-bot promocode-box">
                      <div className="col-md-12 col-xs-12">
                        <input
                          value={promocode.code}
                          className={`promocode-${promocode.id}`}
                          readOnly
                        />
                        {this.props.state.promocode === promocode.code &&
                        promocode.id === this.state.promocodeId ? (
                          <button
                            className="copy-btn"
                            type="button"
                            value={promocode.code}
                            onClick={() => this.copyCode(promocode)}
                          >
                            Copied
                          </button>
                        ) : (
                          <button
                            className="copy-btn"
                            type="button"
                            value={promocode.code}
                            onClick={() => this.copyCode(promocode)}
                          >
                            Copy
                          </button>
                        )}
                      </div>
                      <div className="col-md-12 col-xs-12 description">
                        {promocode.description ? (
                          <p>{promocode.description}</p>
                        ) : null}
                        <span
                          className="view-detail"
                          onClick={() => this.toggleCondition(promocode.id)}
                        >
                          view details
                        </span>
                        {this.state.showCondition &&
                        this.state.promocodeId === promocode.id ? (
                          <>
                            <div style={{ marginTop: "10px" }}>
                              Terms & Conditions
                            </div>
                            <p className="term-condition clearfix">
                              {promocode.term_and_condition}
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Promocode;

import React from "react";
import { Modal } from "react-bootstrap";
// import "../../assets/sass/mobileModal.scss";
import ReactTelInput from "react-telephone-input";
// import "react-telephone-input/lib/withStyles";

const MobileModal = props => {
  return (
    <>
      <Modal className="mobile-modalBox" show={props.state.showMobileModal}>
        <div className="mobile-box">
          <div className="mobile-heading">Enter Your Mobile Number</div>
          <div className="row no-margin">
            {/* <div> */}{" "}
            <ReactTelInput
              defaultCountry="in"
              flagsImagePath="/flags.723494a4.png"
              onChange={props.handleInputChange}
            />
            {/* </div> */}
          </div>
          {props.state.errors.mobileError ? (
            <div className="error-warning">
              {props.state.errors.mobileError}
            </div>
          ) : null}
          <div className="mobile-heading" style={{ paddingTop: "15px" }}>
            Invite Code
          </div>
          <div className="row no-margin">
            <div className="col-md-12" style={{ paddingRight: "0px" }}>
              <input
                className="form-control"
                type="text"
                name="inviteCode"
                placeholder="Enter Invite Code"
                value={props.state.inviteCode}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button className="btn-send-otp" onClick={props.registerMobile}>
              Send OTP
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MobileModal;

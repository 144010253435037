import React, { Component } from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import cookie from "react-cookies";
import { logout } from "../utills/API";

class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    // this.props.history.push("/login");
  };

  render() {
    return (
      <>
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />

        <div className="main-box">
          <div className="container">
            <div className="main-box-con">
              <h3>Privacy Policy</h3>
              <p>
              Swipenow Solutions Private Limited (“the Company / We / Us / Gainon”) is committed to operating this Website/App with the
highest ethical standards and appropriate internal controls. Your privacy is important to us and maintaining your trust is paramount.
This Privacy Policy explains how we collect, use, and disclose information about you. By using our Website/App and affiliated
services, you consent to the terms of our privacy policy (“Privacy Policy”) in addition to our Terms and Conditions. We encourage
you to read this Privacy Policy regarding the collection, use, and disclosure of your information. If you are not comfortable with any
of the terms or policies described in this Privacy Policy, you may choose to discontinue usage of our Website/App. This Privacy
Policy shall be subject to our Terms and Conditions available at https://gainon.in/tnc
              </p>
              <p>
              This Privacy Policy applies to the Website/App that link to this Privacy Policy but does not apply to those Gainonaffiliates’ websites
that have their own privacy policy. Any capitalised terms not defined hereunder shall hold the same definition as provided under the
Terms and Conditions.
              </p>
              <p>
              From time to time, we may supplement this Privacy Policy with additional information relating to a particular interaction that we may
have with you.
              </p>
              <p>
                <strong>Collection of Personal Information</strong>
              </p>
              <p>
                Information you provide us
                <br />
                pursuant to the services provided by us, we will ask you to provide certain information as a part of the
registration process, and in the course of the interface of Gainon. We will collect this information through various means and in
various places through the Website/App Services, including account registration forms, contact us forms, or when you otherwise
interact with us. We will ask you to provide only such information lawfully and operationally required for the Website/App Services
and necessary to be collected by us for effectively providing the same.
              </p>
              <p>
              Personal information we collect may be in the nature of, inter alia,
              </p>
              <ol start="">
                <li>Mobile number</li>
                <li>Email Name</li>
                <li>ID</li>
                <li>Date of Birth</li>
                <li>Address and Pin Code</li>
                <li>Gender</li>
                <li>
                  Financial information such as credit card or debit card or
                  other payment instrument details;
                </li>
              </ol>
              <p>
              All payments will be obtained and processed by our online payment partners and payment gateways.
              </p>
              <p>Information we collect as you access and use our services</p>
              <p>
              We will also collect information relating to your use of our Website/App through the use of various technologies. For example, when
you visit our Website/App, we may log certain information such as your IP address, browser type, mobile operating system,
manufacturer and model of your mobile device, geolocation, preferred language, access time, and time spent. We will also collect
information about the pages you view within our sites and other actions you take while visiting our Website/App. Collecting
information in this manner allows us to collect statistics about our Website/App usage and effectiveness, personalise your
experience whilst you are on our Website/App, as well as customize our interactions with you and also assist in business
development to enhance and expand the scope of the Website/App Services.
              </p>
              <p>
              We also maintain some records of Users who contact us for support, for the purpose of responding to such queries and other
related activities. However, we do not provide this information to any third party without your permission, or utilize the same for any
purposes not set out hereunder.
              </p>
              <p>
                Information third parties provide about you
                <br />
                We may, on your consent, request certain third parties to provide information about you to further personalize your experience on
our Website/App, and provide certain services that cannot be accessed by all users of the Website/App.
              </p>
              <p>
              As part of activating an account with Gainon, we may request your consent to connect with your email account and such other
information based on your merchant preferences, to be able to:
              </p>
              <ol>
                <li>Open and transact your Gainon account</li>
                <li>
                  Manage all Gainon account information at one place, remind you
                  of the purchases{" "}
                </li>
                <li>Curate offers and rewards customised for you</li>
              </ol>
              <p>
              Please note that this giving this consent is not mandatory and is completely voluntary.
              </p>
              <p>
                <strong>Use of Personal Information</strong>
              </p>
              <p>
              The following paragraphs describe in more detail how we propose to use your personal information.
              </p>
              <p>
                <strong>Fulfilling your Transaction Request</strong>
              </p>
              <p>
              Any information provided by a User will be used solely for processing your transaction or for any other purposes for which you have
granted access to such information to us, based on our mutual agreement / discussions / communications / correspondences. All
personal information provided by you in our Website/App which has been duly received and accepted by us will be used solely for
the purpose of processing a transaction on the Website/App. To enable such process, we may share information with authorised
third-parties, for instance, our business partners, financial teams/institutions, or postal/government authorities involved in fulfilment
of said transactions, if any. In connection with a transaction, we may also contact you as part of our customer satisfaction surveys
or for market research purposes.
<br />
Your name and billing address will be collected when you make payment for the E-Vouchers/Travel Services. All payments will be
obtained and processed by our online payment partners and payment gateways.
              </p>
              <p>
                <strong>
                Personalising your Experience on our Website/App
                </strong>
              </p>
              <p>
              We may use information we collect about you to provide you with a personalised experience on our Website/App, such as providing
you with content in which you may be interested and making navigation on our sites easier.
              </p>
              <p>
              As you access and use Gainon, we may request access wherever applicable to your:
              </p>
              <table
                border="0"
                className="table table-bordered"
                cellspacing="0"
                cellpadding="0"
              >
                <tr>
                  <td width="50">
                    <p align="center">
                      <strong>Sr No</strong>
                    </p>
                  </td>
                  <td>
                    <p align="center">
                      <strong>Permission</strong>
                    </p>
                  </td>
                  <td>
                    <p align="center">
                      <strong>Purpose</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>SMS</p>
                  </td>
                  <td>
                    <p>
                      Read OTP, alerting on your purchases via Gainon, providing
                      special offers and Gainon payment reminders
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>2</p>
                  </td>
                  <td>
                    <p>Location</p>
                  </td>
                  <td>
                    <p>
                      Rewards personalization through specific offers &amp;
                      validating security of transactions on platform
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>3</p>
                  </td>
                  <td>
                    <p>Contact</p>
                  </td>
                  <td>
                    <p>Referral program and rewards personalization</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>4</p>
                  </td>
                  <td>
                    <p>Email</p>
                  </td>
                  <td>
                    <p>
                      Alerting on your purchases via Gainon, Gainon payment
                      reminders, providing special offers.
                    </p>
                  </td>
                </tr>
              </table>
              <p>
              Please note that we do not open, access or read any personal SMS.
              </p>
              <p>
                <strong>Providing Support</strong>
              </p>
              <p>
              We may use your personal information to support our activities such as notifying you of any upcoming campaigns/programmes
conducted by us.
              </p>
              <p>
              In the course of providing such support to you, we may sometimes have incidental access to data that you have provided to us or
data that is located on your system. This Privacy Policy does not apply to our access to or handling of this information; the
conditions regarding the handling and processing of that data is covered by the applicable Terms and Conditions or other
agreements between you and Gainon.
              </p>
              <p>
                <strong>Marketing</strong>
              </p>
              <p>
              Subject to your express permission to use the information as described herein, the information you provide to us, as well as the
information we have collected about you whether directly or indirectly, may be used by us for marketing purposes. You may at any
time choose not to receive marketing materials from us by following the unsubscribe instructions included in each e-mail you may
receive, by indicating so when we call you, or by contacting us directly.
              </p>
              <p>
              Some of our campaigns/programmes/related events may be co-branded, that is sponsored by both the third parties and us. If you
sign up for such campaigns/programmes/related events, please note that your information may also be collected by and shared
with those third parties. We urge you to familiarise yourself with their privacy policies to gain an understanding of the manner in
which they will handle information about you.
              </p>
              <p>
                <strong>
                Protect the Rights and Property of Swipenow Solutions Pvt Ltd. and Others
                </strong>
              </p>
              <p>
              As required by law, at times we might be required to disclose your personal information to relevant authorities. In some cases,
when we believe that such disclosure is necessary to protect our rights, or the rights of others, or to comply with a judicial
proceeding, court order, or legal process served on our Website/App we would share such information.
              </p>
              <p>
              In some cases, Gainon may use vendors or suppliers located in various countries to collect, use, analyse, and otherwise process
information on its behalf. It is Gainon’s practice to require such vendors or suppliers to handle information in a manner consistent
with Gainon policies.
              </p>
              <p>
              We may disclose information that identifies you at an individual level and which we have collected on our Website/App, to other
non-Swipenow Solutions Private Limited entities that are not acting as our suppliers, such as our business partners. Except as
described in this Privacy Policy, we will only do so with your prior consent. For the sake of clarity, Swipenow Solutions Private
Limited does not sell or lease such information.
              </p>
              <p>
              Where, pursuant to strategic or other business reasons, Gainon decides to sell, buy, merge, or otherwise reorganise businesses in
some countries. Such a transaction may involve the disclosure of personal information to prospective or actual purchasers, or the
receipt of such information from sellers. It is Gainon’s practice to seek appropriate protection for information in these types of
transactions.
              </p>
              <p>
                <strong>Opt-Out</strong>
              </p>
              <p>
              We provide all Users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications
from us on behalf of our partners, and from us in general, after setting up an account. If you want to remove your contact
information from all our lists and newsletters, please click on the unsubscribe button on the emailers or send an email request to &nbsp;
                <a href="mailto:support@gainon.in">support@gainon.in</a>
              </p>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
              We use data collection devices such as “cookies”, etc. on certain parts of the Website/App to help analyse the services, user
interaction with the Website/App, measure promotional effectiveness, and promote trust and safety. For the sake of clarity,
“cookies” are small files placed on your device hard-drive/storage that assist us in providing the Website/App Services. Please be
informed that Gainon offers certain features via the Website/App that are only available through the use of a “cookie”.
              </p>
              <p>
              We also use cookies to allow you to enter a particular password less frequently during a session. Cookies also help us obtain
information that assists in curating the Services more targeted to your interests. You are always free to decline our cookies if your
device permits, although in that case you may not be able to use certain features on the Website/App and you may be required to
provide a password more frequently during a session.
              </p>
              <p>
                <strong>Online Advertising</strong>
              </p>
              <p>
              Gainon does not deliver third party online advertisements on the Website/App but we advertise our activities and organizational
goals on other websites/apps. We may collaborate with other Website/App operators as well as network advertisers to do so. We
request you to read and understand such concerned third party privacy policies to understand their practices relating to advertising,
including what type of information they may collect about your internet usage. Gainon does not provide any information relating to
your usage of the Website/App to such website operators or network advertisers.
              </p>
              <p>
                <strong>Links to third-party websites/apps</strong>
              </p>
              <p>
              Gainon’s Website/App may contain links to third party websites/apps that are not affiliated with Gainon. Gainon is not responsible
for the privacy practices or the content of those other websites, or for any acts/ omissions by such third parties in the course of your
transaction with them.
              </p>
              <p>
                <strong>Notices</strong>
              </p>
              <p>
              Any dispute over privacy is subject to this Privacy Policy and our Terms and Conditions, including limitations on damages,
resolution of disputes, and application of the laws of India.
              </p>
              <p>
                <strong>Changes to Privacy Policy</strong>
              </p>
              <p>
                {" "}
                Gainon reserves the right to change this policy from time to time. Any changes shall be effective immediately upon the posting of
the revised Privacy Policy. We encourage you to periodically review this page for latest information on our privacy practices.
              </p>
              <p>
                <strong>Privacy Questions and Access</strong>
              </p>
              <p>
              If you have questions, concerns, or suggestions regarding our Privacy Policy, we can be reached using the contact information on
our “Contact Us” page or
                <a href="mailto:support@Gainon.in">support@Gainon.in</a>.
              </p>
              <p>
              In certain cases, you may have the ability to view or edit your personal information online. In the event your information is not
accessible online and you wish to obtain a copy of particular information you provided to Gainon, or if you become aware the
information is incorrect and you would like us to correct it, please contact us immediately.
              </p>
              <p>
              Before Gainon is able to provide you with any information or correct any inaccuracies, however, we may ask you to verify your
identity and to provide other details to help us to respond to your request. We will contact you within 30 days of your request.
              </p>
              <p>
                <strong>Security and Responsible Disclosure</strong>
              </p>
              <p>
              If you are a security enthusiast or a researcher and you have found a possible security vulnerability on Gainon products, we
encourage you to report the issue to us responsibly.
              </p>
              <p>
                You could submit a bug report to us at&nbsp;
                <a href="mailto:support@Gainon.in">support@Gainon.in</a>
                &nbsp;with detailed steps required to reproduce the
                vulnerability.
              </p>
              <p>
              We shall put best of our efforts to investigate and fix the legitimate issues in a reasonable time frame, meanwhile, requesting you
not to publicly disclose it. Additionally, if you have suggestions on how we could improve our security systems to make it more
robust and safe for all users, you can share those with us at &nbsp;
                <a href="mailto:support@Gainon.in">support@Gainon.in</a>
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Privacy;

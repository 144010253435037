import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap';
import { post } from '../utills/API';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Toast } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { loginCookie } from "../utills/function";
import PhoneInput from 'react-phone-input-2'
import logo_header from "../assets/images/gainon/Sumaria-LG-gainon_logo.png"
import "./Swiggy_style.css"
const Sumaria_integration = (props) => {
    const history = useHistory();
    const params = useParams();

    // To show toast message and resend otp
    const [showSuccessToast, setShowSuccessToast] = useState(false)
    const [showErrorToast, setShowErrorToast] = useState(false)
    const [isResendOTPClicked, setIsResendOTPClicked] = useState(false)
    const [toastForResendOTP, setToastForResendOTP] = useState(false)

    // Below states is used to take all the input from user
    const [sumariaCode, setsumariaCode] = useState("")
    const [enterMobileNo, setEnterMobileNo] = useState('')
    const [OTP, setOTP] = useState('')
    const [countryCode, setCountryCode] = useState('')

    // Below states is used to manage the fields conditionally
    const [validatesumariaField, setValidatesumariaField] = useState(false)
    const [activateMobileField, setActivateMobileField] = useState(false)
    const [activateOTPScreen, setActivateOTPScreen] = useState(false)
    const [activateSignUpScreen, setActivateSignUpScreen] = useState(false)
    const [activeAlredyRedeemedScreen, setactiveAlredyRedeemedScreen] = useState(false)

    // Below states is used for error-messages handling for all fields/screen 
    const [mobileError, setMobileError] = useState({})
    const [sumariaCodeError, setsumariaCodeError] = useState('')
    const [OTPError, setOTPError] = useState('')

    // Below States is used to activate loaders on buttons
    const [issumariaCodeVerified, setIssumariaCodeVerified] = useState(false)
    const [isOTPSend, setIsOTPSend] = useState(false)
    const [isOTPVerified, setIsOTPVerify] = useState(false)
    const [isSignUpSuccess, setIsSignUpSuccess] = useState(false)

    // States for new user sign-up
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    // Error States for sign-up
    const [signUp_Error, setSignUp_Error] = useState({})

    useEffect(() => {
        if (params && params.spc) {
            let sumaria_code = params.spc
            setsumariaCode(sumaria_code)
        }
    }, [])


    const handleInputChange = (telNumber, selectedCountry) => {
        setShowSuccessToast(false)
        setCountryCode(selectedCountry.dialCode)
        let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
        let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
        setEnterMobileNo(mobile)
    };

    const handleMobileNumberValidation = async () => {
        if (enterMobileNo !== "") {
            if (enterMobileNo.length < 10) {
                return {
                    status: true,
                    message: "*Mobile number should be 10 digits."
                };
            } else if (enterMobileNo.length > 10) {
                return {
                    status: true,
                    message: "*Mobile number should be 10 digits."
                };
            } else {
                // setIs_buttonDisabled(true);
                return {
                    status: false,
                    message: ""
                };
            }
        } else {
            return {
                status: true,
                message: "*Please enter your mobile number."
            };
        }
    }

    const handleSignUpValidation = async () => {
        if (firstName == '' && lastName == '' && email == '') {
            return {
                status: true,
                first_name: '*Please enter first name',
                last_name: '*Please enter last name',
                email_id: '*Please enter email address'
            }
        } else if (firstName == '') {
            return {
                status: true,
                first_name: '*Please enter first name'
            }
        } else if (lastName == '') {
            return {
                status: true,
                last_name: '*Please enter last name'
            }
        } else if (email == '') {
            return {
                status: true,
                email_id: '*Please enter email address'
            }
        } else {
            return {
                status: false,
                first_name: '',
                last_name: '',
                email_id: ''
            }
        }
    }

    const addGoPointsToWallet = async (id) => {
        console.log("ID: ", id)
        const goPointsData = {
            promoCode: `${sumariaCode}`,
            user_id: id
        }
        try {
            console.log("goPointsData: ", goPointsData)
            const data = await post('promo_codes/apply_swiggy_code', goPointsData)
            console.log("sumariaData: ", data)
            if (data.data.status === 200) {
                return {
                    status: 200
                }
            } else if (data.data.status === 422 && data.data.errors) {
                return {
                    status: 422,
                    data: data.data.errors
                }
            } else {
                return {
                    status: false,
                    data: "Something went wrong."
                }
            }
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const login = async (e) => {
        e.preventDefault()
        setIsOTPVerify(true)
        if (OTP !== "") {
            if (OTP.length === 4) {
                const dataForVerifyOTP = {
                    country_code: `+${countryCode}`,
                    mobile: `${enterMobileNo}`,
                    otp: `${OTP}`
                }
                try {
                    const verify_OTP_data = await post("ngp/verify-otp", dataForVerifyOTP)
                    console.log("Verify-OTP: ", verify_OTP_data)
                    if (verify_OTP_data.data.status === 200 && verify_OTP_data.data.isExistingUser === false) {
                        console.log("Header: ", verify_OTP_data.config.headers)
                        setActivateSignUpScreen(true)

                    } else if (verify_OTP_data.data.status === 200 && verify_OTP_data.data.data.user.ngp_name === "ngp_admin") {
                        // setShowSuccessToast(true)
                        setIsOTPVerify(false)
                        loginCookie(verify_OTP_data.data.data.token, verify_OTP_data.data.data.user.ngp_name)
                        history.push("/admin/dashboard")

                    } else if (verify_OTP_data.data.status === 200 && verify_OTP_data.data.data.user.isExistingUser === true && verify_OTP_data.data.data.user.ngp_name === "user") {
                        loginCookie(verify_OTP_data.data.data.token, verify_OTP_data.data.data.user.ngp_name)

                        let APPLY_swiggy_CODE = await addGoPointsToWallet(verify_OTP_data.data.data.user.id)
                        console.log("APPLY_swiggy_CODE: ", APPLY_swiggy_CODE)

                        if (APPLY_swiggy_CODE.status === 200) {
                            setIsOTPVerify(false)
                            history.push("/wallet", {
                                message: 'Balance Added To Your Wallet',
                                type: 'success'
                            })

                        } else if (APPLY_swiggy_CODE.status === 422) {
                            setIsOTPVerify(false)
                            history.push("/wallet", {
                                message: APPLY_swiggy_CODE.data,
                                type: 'danger'
                            })

                        }

                    } else if (verify_OTP_data.data.status === 400) {
                        setOTPError(verify_OTP_data.data.message)
                        setIsOTPVerify(false)
                        // setShowErrorToast(true)
                    }
                } catch (e) {
                    setIsOTPVerify(false)
                    setOTPError("There's network issue. Please try again.")
                    console.log("Error: ", e)
                }
            } else {
                setIsOTPVerify(false)
                setOTPError("*Please check your OTP")
            }
        } else {
            setIsOTPVerify(false)
            setOTPError("*Please enter OTP")
        }

    }

    const signUpForNewUser = async (e) => {
        e.preventDefault()
        try {
            let isSignUpValid = await handleSignUpValidation()
            if (!isSignUpValid.status) {
                setIsSignUpSuccess(true)
                const signUpForsumariaUser = {
                    goPointUser: true,
                    user: {
                        first_name: `${firstName}`,
                        last_name: `${lastName}`,
                        mobile: `${enterMobileNo}`,
                        email: `${email}`,
                        country_code: `+${countryCode}`,
                    }
                }
                try {
                    const data = await post('ngp/sign-up', signUpForsumariaUser)
                    if (data.data.status === 200 && data.data.message !== "Success") {
                        setIsSignUpSuccess(false)
                        setSignUp_Error({
                            status: true,
                            email_id: `*${data.data.message}`
                        })
                    } else if (data.data.status === 200 && data.data.message === "Success") {
                        loginCookie(data.data.data.token, data.data.data.user.ngp_name)
                        console.log("Data: ", data)
                        setShowSuccessToast(true)
                        setIsSignUpSuccess(false)

                        let apply_swiggy_code_after_Signup = await addGoPointsToWallet(data.data.data.user.id)
                        console.log("apply_swiggy_code_after_Signup: ", apply_swiggy_code_after_Signup)
                        if (apply_swiggy_code_after_Signup.status === 200) {
                            history.push("/wallet", {
                                message: 'Balance Added To Your Wallet',
                                type: 'success'
                            })

                        } else if (apply_swiggy_code_after_Signup.status === 422) {
                            history.push("/wallet", {
                                message: apply_swiggy_code_after_Signup.data,
                                type: 'danger'
                            })
                        }

                    } else if (data.data.status === 400) {
                        setIsSignUpSuccess(false)
                    }
                } catch (error) {
                    setIsSignUpSuccess(false)
                    console.log("Error: ", error)
                }
            } else {
                setIsSignUpSuccess(false)
                setSignUp_Error(isSignUpValid)
            }
        } catch (error) {
            console.log("Error: ", error)
            setSignUp_Error({
                status: true,
                something_went_wrong: "There's network issue or something went wrong. Please try again."
            })
        }
    }

    const mobileOTP = async (e) => {
        e.preventDefault()
        try {
            let isMobileNumbervalid = await handleMobileNumberValidation()

            if (!isMobileNumbervalid.status) {
                console.log("MobileNo: ", enterMobileNo)

                const dataForGetOTP = {
                    country_code: `+${countryCode}`,
                    mobile: `${enterMobileNo}`,
                    app_signature_id: "pb"
                };
                if (!isResendOTPClicked) {
                    setIsOTPSend(true)
                    try {
                        console.log("otpVerifyData: ", dataForGetOTP)
                        const { data } = await post("ngp/send-otp", dataForGetOTP);
                        if (data.status === 200) {
                            setActivateOTPScreen(true)
                            setActivateMobileField(false)
                            setIsOTPSend(false)
                        }
                    } catch (e) {
                        console.log("error: ", e)
                        setMobileError({
                            status: true,
                            message: "Something went wrong. Please try again.",
                        })
                        setIsOTPSend(false)
                    }
                } else {
                    try {
                        console.log("otpVerifyData: ", dataForGetOTP)
                        const { data } = await post("ngp/send-otp", dataForGetOTP);
                        if (data.status === 200) {
                            setToastForResendOTP(true)
                            setShowSuccessToast(true)
                            setIsResendOTPClicked(false)
                        }
                    } catch (e) {
                        console.log("error: ", e)
                        setOTPError("There's network issue. Please try again.")
                        setIsResendOTPClicked(false)
                        setShowSuccessToast(false)
                    }
                }
                // setActivateOTPScreen(true)
            } else {
                setIsOTPSend(false)
                setMobileError(isMobileNumbervalid)
            }

        } catch {
            setIsOTPSend(false);
            // toast.error("Failed to create parnter!");
        }
    }

    const validatesumariaCode = async (e) => {
        e.preventDefault()
        if (sumariaCode !== "") {
            setIssumariaCodeVerified(true)
            const sumariaCodeData = {
                promCode: sumariaCode,
                goPointPartner: "sumaria"
            }
            console.log(sumariaCodeData)
            try {
                const { data } = await post('ngp/Validate_swiggy_code', sumariaCodeData)
                console.log("data: ", data)
                if (data.status === 404 && data.message === "This code has expired") {
                    setIssumariaCodeVerified(false)
                    setsumariaCodeError("This code has expired.")
                } else if (data.status !== 200) {
                    setIssumariaCodeVerified(false)
                    setsumariaCodeError("*Invalid Code")
                } else {
                    setShowSuccessToast(true)
                    setValidatesumariaField(false)
                    setActivateMobileField(true)
                    setIssumariaCodeVerified(false)
                }
            } catch (e) {
                console.log("Error: ", e)
                // setShowErrorToast(true)
                setIssumariaCodeVerified(false)
                setsumariaCodeError("There's network issue. Please check and try again.")
            }
        } else {
            setsumariaCodeError('*Please enter promocode')
        }

    }
    return (
        <>
            <div className='page img-fluid background-banner'>
                {
                    showSuccessToast ?
                        <Row>
                            <Col md={9}></Col>
                            <Col md={3}>
                                <div
                                    className="position-fixed top-0 p-3 d-block justify-content-between"
                                    style={{ zIndex: "1005" }}
                                >
                                    <Toast
                                        delay={1000}
                                        onClose={() => setShowSuccessToast(false)}
                                        autohide
                                    >
                                        <Toast.Body
                                            style={{ color: "#00000", height: "55px" }}
                                            className="text-16 bg-success text-white"
                                        >
                                            <div className="d-flex d-block justify-content-between">
                                                <i
                                                    className="fa fa-check-circle pt-1"
                                                    style={{ fontSize: 22 }}
                                                >
                                                </i>
                                                <p
                                                    style={{
                                                        fontSize: 18,
                                                        fontStyle: "normal",
                                                    }}
                                                    className="mx-2"
                                                >
                                                    {
                                                        !activateSignUpScreen && !activateOTPScreen ?
                                                            "Code verified Successfully"
                                                            :
                                                            activateOTPScreen && !activateSignUpScreen && toastForResendOTP ?
                                                                "OTP sent successfully"
                                                                :
                                                                activateOTPScreen && !activateSignUpScreen ?
                                                                    "OTP verified successfully"
                                                                    :
                                                                    activateSignUpScreen ?
                                                                        "Sign Up successfully"
                                                                        : null
                                                    }
                                                </p>
                                            </div>
                                        </Toast.Body>
                                    </Toast>
                                </div>
                            </Col>
                        </Row>
                        :
                        showErrorToast ?
                            <Row>
                                <Col md={10}></Col>
                                <Col md={2}>
                                    <div
                                        className="position-fixed top-0 p-3 d-block justify-content-between"
                                        style={{ zIndex: "1005" }}
                                    >
                                        <Toast
                                            delay={2000}
                                            show={true}
                                            onClose={() => setShowErrorToast(false)}
                                            autohide
                                        >
                                            <Toast.Body
                                                style={{ color: "#00000", height: "55px" }}
                                                className="text-16 bg-primary text-white"
                                            >
                                                <div className="d-flex d-block justify-content-between">
                                                    <i
                                                        className="fa fa-times-circle pt-1"
                                                        style={{ fontSize: 22 }}
                                                    >
                                                    </i>
                                                    <p
                                                        style={{
                                                            fontSize: 18,
                                                            fontStyle: "normal",
                                                        }}
                                                        className="mx-2"
                                                    >
                                                        {
                                                            activateOTPScreen ?
                                                                "Invalid OTP"
                                                                :
                                                                "Invalid Code"
                                                        }
                                                    </p>
                                                </div>
                                            </Toast.Body>
                                        </Toast>
                                    </div>
                                </Col>
                            </Row>
                            :
                            null
                }
                <Container>
                    <Row className='navbar-header-main'>
                        <div className="navbar-header sumaria-logo">
                            <a className="logo navbar-btn pull-left my-3" href={`${sumariaCode}`}>
                                <img style={{ height: '50px' }} src={logo_header} alt="sumaria" />
                            </a>
                        </div>
                    </Row>


                    {
                        activateSignUpScreen ?
                            <div className="mt-5 sign-up-banner mobile-hide">
                                <h2 className="font-weight-bold my-2 text-white" style={{ fontSize: '38px' }}>
                                    Welcome To The Rewarding World
                                </h2>
                                <span className="my-3 text-white">
                                    Seems like you are not yet a member of gainon, just need your name & email to complete signup.
                                </span>
                            </div>
                            :
                            null
                    }
                    <Row className={`${!activateMobileField && !activateOTPScreen && !activateSignUpScreen ? "background-gradient landingPage-top" : ""}`}>
                        {
                            !activateMobileField && !activateOTPScreen && !activateSignUpScreen &&
                            <div style={{ padding: '10px' }} className="sign-up-banner">
                                <h2 className="font-weight-bold my-3 mx-3 text-white" style={{ fontSize: '38px' }}>
                                    Welcome To The Rewarding World
                                </h2>
                                <div style={{ color: 'white', marginTop: '10%' }} className="claim-banner">
                                    <p className='banner-head' style={{ fontWeight: '700' }}>
                                        Congratulations!
                                    </p>
                                    <div className='banner-text'>
                                        <span>
                                            You can use your INR 1000 as a discount on<br />
                                            {" "}over 200 brand gift vouchers.
                                        </span>

                                    </div>
                                    {/* <Button
                                    type="button"
                                    variant="primary"
                                    className='text-uppercase border border-1 border-white mt-5 text-22 py-3 px-4 my-2'
                                    style={{boxShadow:'none'}}
                                    onClick={() => {
                                        setValidatesumariaField(true)
                                    }}
                                    >
                                    Claim Now
                                </Button> */}
                                </div>
                            </div>
                        }
                        <Col md={6}>
                            {/* <Card className='brand-card container'> */}
                            <form onSubmit={!activateMobileField && !activateOTPScreen ? validatesumariaCode : activateMobileField && !activateOTPScreen || isResendOTPClicked ? mobileOTP : activateOTPScreen && !activateSignUpScreen ? login : signUpForNewUser}>
                                <Row className='p-3'>
                                    {
                                        !activateOTPScreen ?
                                            <>
                                                {/* <Col md={1}></Col> */}
                                                {
                                                    !activateMobileField ?
                                                        <Col className='' md="8" sm="8">
                                                            <div className='form-group'>
                                                                <label className='text-16 my-3 text-white'>Please enter your unique promocode</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-2"
                                                                    name="promocode"
                                                                    value={sumariaCode}
                                                                    onFocus={() => setsumariaCodeError('')}
                                                                    onChange={(e) => setsumariaCode(e.target.value)}
                                                                    placeholder="Enter your promocode"
                                                                />
                                                                {
                                                                    sumariaCodeError && <span
                                                                        style={{
                                                                            color: "#FD1C03",
                                                                            fontSize: "15px",
                                                                        }}
                                                                    >{sumariaCodeError}</span>
                                                                }
                                                                <Button
                                                                    variant="primary"
                                                                    type="submit"
                                                                    disabled={issumariaCodeVerified}
                                                                    className="border border-1 border-white col-lg-6 submit-btn text-20"
                                                                    block
                                                                >
                                                                    {issumariaCodeVerified ? (
                                                                        <Spinner animation="border" size="sm" />
                                                                    ) : "CLAIM NOW"}
                                                                </Button>
                                                            </div>

                                                        </Col>
                                                        :
                                                        activateMobileField ?
                                                            <Col className='banner-content background-gradient' md="8" sm="6">
                                                                <div className="form-group">
                                                                    <label className='text-16 my-3 text-white'>Enter Mobile Number</label>
                                                                    <PhoneInput

                                                                        // disabled={is_buttonDisabled}
                                                                        onChange={handleInputChange}
                                                                        onEnterKeyPress={mobileOTP}
                                                                        inputStyle={{
                                                                            fontWeight: 'normal'
                                                                        }}
                                                                        country={"in"}
                                                                        className="mb-2"
                                                                        // flagsImagePath="/flags.723494a4.png"
                                                                        onFocus={() => setMobileError({})}
                                                                    />
                                                                    {
                                                                        mobileError.status && <span
                                                                            style={{
                                                                                color: "#FD1C03",
                                                                                fontSize: "15px",
                                                                            }}
                                                                        >{mobileError.message}</span>
                                                                    }

                                                                    <Button
                                                                        variant="primary"
                                                                        type="submit"
                                                                        className="border border-1 border-white col-lg-6 get-otp-btn"
                                                                        block
                                                                        disabled={isOTPSend}
                                                                    // onClick={mobileOTP}
                                                                    >
                                                                        {isOTPSend ? (
                                                                            <Spinner animation="border" size="sm" />
                                                                        ) : "SEND OTP"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            :
                                                            null
                                                }
                                                {/* <Col md={1}></Col> */}
                                            </>
                                            :
                                            !activateSignUpScreen ?
                                                <Col className='banner-content background-gradient' md="8" sm="6">
                                                    <div className='form-group justify-content-center'>
                                                        <p className='text-white py-2 my-2 text-22' style={{ fontWeight: 700 }}>Enter OTP</p>
                                                        <p className='pt-2 text-white text-14'>
                                                            {`A 4 digit OTP has been sent to +${countryCode} ${enterMobileNo}`}
                                                        </p>
                                                        <p className='pb-2 text-white text-14' style={{
                                                            lineHeight: "0.4px"
                                                        }}>

                                                        </p>
                                                        <OtpInput

                                                            className="hello"
                                                            onChange={(otp) => {
                                                                setOTP(otp)
                                                                setOTPError('')
                                                            }}
                                                            focusStyle={{
                                                                borderBottom: "3px solid yellow",

                                                            }}
                                                            numInputs={4}
                                                            inputStyle={{
                                                                // width:'60px',
                                                                // height:'50px',
                                                                // margin:'5px 10px 10px 10px',
                                                                width: "60px",
                                                                textAlign: "center",
                                                                height: "50px",
                                                                margin: "5px 10px 10px",
                                                                background: "transparent",
                                                                outline: "yellow",
                                                                borderBottom: "2.5px solid white",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderTop: "white",
                                                                color: "white",
                                                                fontSize: "18px",
                                                                fontWeight: "700",

                                                            }}

                                                            value={OTP}
                                                            isInputNum={false}
                                                            // hasErrored={OTPError}
                                                            shouldAutoFocus={true}

                                                        // containerStyle="box-otp"
                                                        />
                                                        {
                                                            OTPError && <span
                                                                style={{
                                                                    color: "#FD1C03",
                                                                    fontSize: "15px",
                                                                }}
                                                            >{OTPError}</span>
                                                        }
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            className="my-2 text-center justify-content-center col-lg-6"
                                                            block
                                                            disabled={isOTPVerified}
                                                        // onClick={(e) => mobileOTP(e)}
                                                        >
                                                            {isOTPVerified ? (
                                                                <Spinner animation="border" size="sm" />
                                                            ) : "Verify OTP"}
                                                        </Button>
                                                        <button
                                                            type='submit'
                                                            className="btn btn-link text-white text-12 col-lg-6"
                                                            onClick={() => {
                                                                setIsResendOTPClicked(true)
                                                                setToastForResendOTP(false)
                                                            }}
                                                        >
                                                            Resend OTP
                                                        </button>
                                                    </div>
                                                </Col>
                                                :
                                                <Col md="8" className='background-gradient mobile-view' style={{ flexDirection: 'column' }}>
                                                    <div className="form-group my-3 text-left mobile-view" style={{ flexDirection: 'column' }}>
                                                        <label className='text-white'>
                                                            First Name <span>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onFocus={() => setSignUp_Error({})}
                                                            name="fname"
                                                            placeholder="Enter First Name"
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            className="form-control"
                                                        />

                                                        {
                                                            signUp_Error.status && <span
                                                                style={{
                                                                    color: "#FD1C03",
                                                                    fontSize: "15px",
                                                                }}
                                                            >{signUp_Error.first_name}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group my-3 text-left">
                                                        <label className='text-white'>
                                                            Last Name <span>*</span>
                                                        </label>
                                                        <input

                                                            type="text"
                                                            name="lname"
                                                            onFocus={() => setSignUp_Error({})}
                                                            placeholder="Enter Last Name"
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            className="form-control"
                                                        />
                                                        {
                                                            signUp_Error.status && <span
                                                                style={{
                                                                    color: "#FD1C03",
                                                                    fontSize: "15px",
                                                                }}
                                                            >{signUp_Error.last_name}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group my-3 text-left">
                                                        <label className='text-white'>
                                                            Email Address <span>*</span>
                                                        </label>
                                                        <input
                                                            // style={ signUp_Error.status ? {border: 0, outline:"2px solid red"} : {}}
                                                            type="email"
                                                            onFocus={() => setSignUp_Error({})}
                                                            name="email"
                                                            placeholder="Enter Email Address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            className="form-control"
                                                        />
                                                        {
                                                            signUp_Error.status && <span
                                                                style={{
                                                                    color: "#FD1C03",
                                                                    fontSize: "15px",
                                                                }}
                                                            >{signUp_Error.email_id}</span>
                                                        }
                                                        {
                                                            signUp_Error.status && signUp_Error.something_went_wrong && <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "15px",
                                                                }}
                                                            >{signUp_Error.something_went_wrong}</span>
                                                        }
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            block
                                                            className='my-4 text-center justify-content-center col-lg-6'
                                                            disabled={isSignUpSuccess}
                                                        >

                                                            {console.log(isSignUpSuccess)}
                                                            {isSignUpSuccess ? (
                                                                <Spinner animation="border" size="sm" />
                                                            ) : "Sign Up"}
                                                        </Button>
                                                    </div>
                                                </Col>
                                    }
                                </Row>
                            </form>
                            {/* </Card> */}
                        </Col>
                        {/* <Col md={3}></Col> */}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Sumaria_integration



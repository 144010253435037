import React from "react";
import DataTable from "react-data-table-component";
import "../../../assets/sass/admin/Coupon/ShowCoupon.scss";
import { get } from "../../../utills/API";
import { Link } from "react-router-dom";

// ------Zing area--------
import "./Partners.scss";
import FloatBalanceModal from "./FloatBalanceModal";


class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "S.No.",
          selector: "id",
          sortable: true
        },
        {
          name: "Partner Name",
          selector: "name",
          sortable: true
        },
        {
          name: "Cash Float",
          selector: "cash_float",
          sortable: true
        },
        {
          name: "Service Type",
          selector: "service_type",
          sortable: true
        },
        {
          name: "View/Edit",
          button: true,
          cell: row => (
            <Link to={`/admin/partners/${row.id}/edit`}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Link>
          )
        },
        {
          name: "Float Balance",
          button: true,
          cell: row => (
            // <Link to={`/admin/partners/${row.id}/edit`}>
            //   <i className="fa fa-eye" aria-hidden="true"></i>
            // </Link>
            <button
              className="zing-btn-sm btn-danger"
              onClick={() => this.ShowFloatModel(row.id)}
            >
              Add Float
            </button>
          )
        }
      ],
      filterText: "",
      resetPaginationToggle: false,
      partners: [],
      showDeleteModal: false,
      brandCouponId: "",
      isLoad: false,
      isFloatModelOpen: false,
      partnerDetails: {},
      partnerBalanceLogs: [],
      refetchBalanceLogs: false,
    };
  }

  showDeleteModal = id => {
    this.setState({
      showDeleteModal: true,
      brandCouponId: id
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  ShowFloatModel = async (PartnerId) => {
    this.setState({
      isFloatModelOpen: true,
    })
    try {
      const PartnerData = await get(`partners/${PartnerId}`);
      const PartnerBalanceData = await get(`balancelogs/${PartnerId}`);
      console.log(PartnerData)
      console.log(PartnerBalanceData)
      this.setState({
        partnerDetails: PartnerData.data.data.partner,
        partnerBalanceLogs: PartnerBalanceData.data.logs
      })
    } catch (error) {
      console.error(error)
    }



  }

  CloseFloatModel = () => {
    this.setState({
      isFloatModelOpen: false,
      partnerDetails: {},
      partnerBalanceLogs: [],
      isLoad: !this.state.isLoad,
    })

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoad !== this.state.isLoad) {
      try {
        const { data } = await get("partners");
        var partners = data.data.partners;
      } catch (e) {
        console.log("error", e);
      }

      this.setState({ partners });
    }

    if (prevState.refetchBalanceLogs !== this.state.refetchBalanceLogs) {
      try {
        const PartnerData = await get(`partners/${this.state.partnerDetails.id}`);
        const PartnerBalanceData = await get(`balancelogs/${this.state.partnerDetails.id}`);
        this.setState({
          partnerDetails: PartnerData.data.data.partner,
          partnerBalanceLogs: PartnerBalanceData.data.logs
        })
      } catch (e) {
        console.log("error", e);
      }
    }
  }

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;
    return brandName;
  };

  async componentDidMount() {
    try {
      const { data } = await get("partners");
      var partners = data.data.partners;
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ partners });
  }

  getCategoryName = async id => {
    const { data } = await get(`categories/${id}`);
    var categoryName = data.data.category.name;
    return categoryName;
  };

  getBrandName = async id => {
    const { data } = await get(`brands/${id}`);
    var brandName = data.data.brand.name;

    return brandName;
  };

  searchHeader = e => {
    console.log("event", e);
  };

  onFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  render() {

    const { partners, columns, filterText } = this.state;
    const filteredItems = partners.filter(
      item =>
        item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <div className="span9 brand-coupon">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div style={{ padding: "20px 20px" }}
                className="col col-sm-8 muted pull-left">
                Showing Partners
              </div>
              <div className="col col-sm-4 pt-0 zing-btn-container" >
                <button
                  className="zing-btn zing-btn-primary"
                  onClick={() => this.props.history.push("/admin/partners/add")}
                >Add Partner</button>
              </div>
            </div>

            <div className="block-content  in">
              <div className="span12" style={{ marginLeft: "0" }}>
                <div className="">
                  <DataTable
                    subHeader
                    subHeaderComponent={
                      <FilterComponent
                        onFilter={this.onFilter}
                        onClear={this.onClear}
                        filterText={filterText}
                      />
                    }
                    columns={columns}
                    pagination={true}
                    //persistTableHead
                    data={filteredItems}
                  />
                  <FloatBalanceModal
                    ShowModel={this.state.isFloatModelOpen}
                    CloseModel={() => this.CloseFloatModel()}
                    PartnerDetails={this.state.partnerDetails}
                    PartnerBalaceLogs={this.state.partnerBalanceLogs}
                    RefetchBalanceLogs={() => this.setState({
                      refetchBalanceLogs: !this.state.refetchBalanceLogs
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterComponent = props => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Name"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

export default Partners;
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { post, get } from "../../../utills/API";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../../assets/sass/admin/Category/AddCategory.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionEditorState: EditorState.createEmpty(),
      aboutEditorState: EditorState.createEmpty(),
      howToRedeemEditorState: EditorState.createEmpty(),
      brandName: "",
      selectCategory: "",
      img: "",
      cashback: "",
      cashbackType: "percentage",
      categoryArr: [],
      vendorsArr: [],
      rewardUsers:[],
      vendorId: null,
      rewardUserId:null,
      isWalletCashback: true,
      is_reward_brand: false,
      isSponsorBrand: false,
      isAvailableForRedeem: false,
      isPublishBrand: true,
      website: "",
      videoLink: "",
      priority: 999,
      partners: [],
      selectedPartner: null,
      partnerCashback: "",
      partnerCashbackType: "percentage",
      partnersCashbackList: [],
      coupon_quantity_limit: 5,
      partnerErrors: {
        partnerName: "",
        partnerCashback: "",
        partnerCashbackType: "",
      },
      cashbackPartnersRef: {},
      step1: '',
      step2: '',
      step3: '',
      step4: '',
      step1Image: '',
      step2Image: '',
      step3Image: '',
      step4Image: '',
      month_purchase_limit: 0
    };
  }

  async componentDidMount() {
    try {
      const { data } = await get("categories");
      var categoryArr = data.data.categories;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("vendors");
      // var categoryArr = data.data.categories;
      var vendorsArr = data.data.vendors;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("rewardUserType");
      var rewardUsers = data.data.redward_users ? data.data.redward_users: [];
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("partners");
      var partners = data.data.partners;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      categoryArr: categoryArr,
      vendorsArr: vendorsArr,
      rewardUsers: rewardUsers,
      partners: partners,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
      this.setState({ [name]: value });
  };

  handleImageChange = (e) =>{
    const { name, value } = e.target;
      this.setState({ [name]: e.target.files[0] });
  }

  cashbackWalletChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  onEditorStateChange = (value) => {
    this.setState({ instructionEditorState: value });
  };

  onAboutEditorStateChange = (value) => {
    this.setState({ aboutEditorState: value });
  };

  onHowToRedeemEditorState = (value) => {
    this.setState({ howToRedeemEditorState: value });
  };

  addBrand = async (e) => {
    e.preventDefault();

    // const addBrandData = {
    //   brand: {
    //     name: this.state.brandName,
    //     category_id: Number(this.state.selectCategory),
    //     // image: this.state.img,
    //     cash_back: Number(this.state.cashback),
    //     cash_back_type: this.state.cashbackType,
    //     instruction: draftToHtml(
    //       convertToRaw(this.state.instructionEditorState.getCurrentContent())
    //     ),
    //     about: draftToHtml(
    //       convertToRaw(this.state.aboutEditorState.getCurrentContent())
    //     )
    //   },
    //   image: this.state.img
    // };

    let formData = new FormData();

    formData.append("image", this.state.img);
    formData.append("step1Image", this.state.step1Image);
    formData.append("step2Image", this.state.step2Image);
    formData.append("step3Image", this.state.step3Image);
    formData.append("step4Image", this.state.step4Image);
    formData.set("brand[step1Text]", this.state.step1);
    formData.set("brand[step2Text]", this.state.step2);
    formData.set("brand[step3Text]", this.state.step3);
    formData.set("brand[step4Text]", this.state.step4);
    formData.set("brand[name]", this.state.brandName);
    formData.set("brand[category_id]", Number(this.state.selectCategory));
    formData.set("brand[cash_back]", Number(this.state.cashback));
    formData.set("brand[cash_back_type]", this.state.cashbackType);
    formData.set("brand[vendor_id]", this.state.vendorId);
    this.state.rewardUserId && formData.set("brand[reward_usertype_id]", this.state.rewardUserId)
    formData.set("brand[home_page_show]", this.state.isSponsorBrand);
    formData.set(
      "brand[is_available_for_redeem]",
      this.state.isAvailableForRedeem
    );
    formData.set("brand[wallet_cashback]", this.state.isWalletCashback);
    formData.set("brand[is_reward_brand]", this.state.is_reward_brand);
    formData.set("brand[is_publish]", this.state.isPublishBrand);
    formData.set(
      "brand[brand_partners]",
      JSON.stringify(this.state.partnersCashbackList)
    );
    formData.set("brand[website]", this.state.website);
    formData.set("brand[video_link]", this.state.videoLink);
    formData.set("brand[priority]", this.state.priority);
    formData.set(
      "brand[coupon_quantity_limit]",
      this.state.coupon_quantity_limit
    );
    formData.set(
      "brand[month_purchase_limit]",
      this.state.month_purchase_limit
    );
    formData.set(
      "brand[instruction]",
      draftToHtml(
        convertToRaw(this.state.instructionEditorState.getCurrentContent())
      )
    );
    formData.set(
      "brand[about]",
      draftToHtml(convertToRaw(this.state.aboutEditorState.getCurrentContent()))
    );
    formData.set(
      "brand[how_to_redeem]",
      draftToHtml(
        convertToRaw(this.state.howToRedeemEditorState.getCurrentContent())
      )
    );

    try {
      const { data } = await post("brands", formData);
      if (data.status === 200) {
        this.props.history.push("/admin/brands");
      } else {
        toast.error("Something Went Wrong!", { autoClose: 2000 });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Something Went Wrong!", { autoClose: 2000 });
    }
  };

  onCashbackPartnerSelect = (e) => {
    const { value } = e.target;
    let selectedPartner = value === "def" ? null : value;
    this.setState({ selectedPartner });
  };

  onPartnerAdd = () => {
    const {
      selectedPartner,
      partnerCashback,
      partnerCashbackType,
      partnersCashbackList,
      cashbackPartnersRef,
      partners,
    } = this.state;

    let partnerErrors = {
      partnerName: "",
      partnerCashback: "",
      partnerCashbackType: "",
    };

    // Validation Here
    if (selectedPartner === null || selectedPartner === "def") {
      partnerErrors.partnerName = "*select a valid partner";
      return this.setState({ partnerErrors });
    }
    if (partnerCashback === "" || isNaN(partnerCashback)) {
      partnerErrors.partnerCashback = "*enter a valid cashback amount";
      return this.setState({ partnerErrors });
    }
    // ----

    let partnerInfo = {};
    partnerInfo.partner_id = selectedPartner;
    partnerInfo.cash_back = partnerCashback;
    partnerInfo.cash_back_type = partnerCashbackType;
    partnersCashbackList.push(partnerInfo);
    let partner = partners.filter(
      (p) => p.id.toString() === selectedPartner.toString()
    )[0];
    cashbackPartnersRef[selectedPartner] = partner.name;
    this.setState({
      partnersCashbackList,
      selectedPartner: "def",
      partnerCashback: "",
      partnerCashbackType: "percentage",
      cashbackPartnersRef,
      partnerErrors,
    });
  };

  onPartnerEdit = (e, id) => {
    let { partnersCashbackList } = this.state;
    e.persist();
    partnersCashbackList = partnersCashbackList.map((p) => {
      if (p.partner_id === id) {
        p[e.target.name] = e.target.value;
        return p;
      } else {
        return p;
      }
    });
    this.setState({ partnersCashbackList });
  };

  onPartnerRemove = (id) => {
    let { partnersCashbackList, cashbackPartnersRef } = this.state;
    partnersCashbackList = partnersCashbackList.filter(
      (p) => p.partner_id.toString() !== id.toString()
    );
    delete cashbackPartnersRef[id];
    this.setState({ partnersCashbackList, cashbackPartnersRef });
  };

  partnerSelectDisable = () => {
    const { partners, cashbackPartnersRef } = this.state;
    let availablePartners = partners.filter((p) => !cashbackPartnersRef[p.id]);
    if (availablePartners.length) {
      return false;
    }
    return true;
  };

  render() {
    const {
      instructionEditorState,
      aboutEditorState,
      howToRedeemEditorState,
      brandName,
      selectCategory,
      cashback,
      cashbackType,
      categoryArr,
      vendorId,
      rewardUserId,
      vendorsArr,
      rewardUsers,
      isWalletCashback,
      is_reward_brand,
      isSponsorBrand,
      isAvailableForRedeem,
      isPublishBrand,
      website,
      videoLink,
      priority,
      coupon_quantity_limit,
      month_purchase_limit,
      partners,
      selectedPartner,
      partnerCashback,
      partnerCashbackType,
      partnerErrors,
      partnersCashbackList,
      cashbackPartnersRef,
      step1,
      step2,
      step3,
      step4,
      step1Image,
      step2Image,
      step3Image,
      step4Image
    } = this.state;

    return (
      <div id="content" className="span9 coupon-category-span9">
        <div className="row-fluid">
          <div className="block">
            <div className="navbar navbar-inner block-header">
              <div className="muted pull-left">Add New Brand</div>
            </div>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
            <form onSubmit={this.addBrand}>
              <div className="row add-coupon-main">
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Brand Name</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Brand Name"
                      name="brandName"
                      value={brandName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Category</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="selectCategory"
                      value={selectCategory}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Category</option>
                      {categoryArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Vendors</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="vendorId"
                      value={vendorId}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Vendors</option>
                      {vendorsArr.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Select Reward User Type</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="rewardUserId"
                      value={rewardUserId}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Reward Users</option>
                      {rewardUsers.length>0 && rewardUsers.map((item, i) => {
                        return (
                          <>
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Wallet Cashback</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isWalletCashback"
                        checked={isWalletCashback}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="cashback"
                      />
                      <label className="onoffswitch-label" for="cashback">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Is Available For Reward</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="is_reward_brand"
                        checked={is_reward_brand}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="reward"
                      />
                      <label className="onoffswitch-label" for="reward">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Top Brand</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isSponsorBrand"
                        checked={isSponsorBrand}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="sponsorbrand"
                      />
                      <label className="onoffswitch-label" for="sponsorbrand">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Is Available For Redeem</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isAvailableForRedeem"
                        checked={isAvailableForRedeem}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="isavailableforredeem"
                      />
                      <label
                        className="onoffswitch-label"
                        for="isavailableforredeem"
                      >
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Publish Brand</div>
                  <div className="col-md-3">
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="isPublishBrand"
                        checked={isPublishBrand}
                        onClick={this.cashbackWalletChange}
                        className="onoffswitch-checkbox"
                        id="publishbrand"
                      />
                      <label className="onoffswitch-label" for="publishbrand">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Image Upload</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="img"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Cash Back</div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      name="cashback"
                      value={cashback}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="cashbackType"
                      value={cashbackType}
                      onChange={this.handleChange}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="flat">Flat</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Partner Cash Back</div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      style={{ background: "#fff", padding: "5px 10px" }}
                      name="selectedPartner"
                      value={selectedPartner}
                      onChange={this.onCashbackPartnerSelect}
                      disabled={this.partnerSelectDisable()}
                    >
                      <option value="def">Select Partner</option>
                      {partners.map((p) => {
                        if (!cashbackPartnersRef[p.id]) {
                          return <option value={p.id}>{p.name}</option>;
                        }
                        return null;
                      })}
                    </select>
                    <span style={{ color: "red" }}>
                      {partnerErrors.partnerName}
                    </span>
                  </div>
                  <div className="col-md-3">
                    <input
                      placeholder="cashback amount"
                      className="form-control"
                      type="text"
                      name="partnerCashback"
                      value={partnerCashback}
                      onChange={this.handleChange}
                      disabled={this.partnerSelectDisable()}
                    />
                    <span style={{ color: "red" }}>
                      {partnerErrors.partnerCashback}
                    </span>
                  </div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="partnerCashbackType"
                      value={partnerCashbackType}
                      onChange={this.handleChange}
                      disabled={this.partnerSelectDisable()}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="flat">Flat</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {partnerErrors.partnerCashbackType}
                    </span>
                  </div>
                  <div
                    className="col-md-1"
                    style={{
                      background: "#c82e29",
                      border: "none",
                      padding: "10px 15px",
                      color: "#fff",
                      borderRadius: "5px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={
                      !this.partnerSelectDisable() ? this.onPartnerAdd : null
                    }
                  >
                    Add
                  </div>
                </div>

                {partnersCashbackList.map((p, i) => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="col-md-12 main-row"
                  >
                    <div className="col-md-2">Partner {i + 1} </div>

                    <div className="col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        name="partnerName"
                        value={cashbackPartnersRef[p.partner_id]}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        placeholder="cashback amount"
                        className="form-control"
                        type="text"
                        name="cash_back"
                        value={p.cash_back}
                        onChange={(e) => this.onPartnerEdit(e, p.partner_id)}
                      />
                      <span style={{ color: "red" }}>
                        {partnerErrors.partnerCashback}
                      </span>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        name="cash_back_type"
                        value={p.cash_back_type}
                        onChange={(e) => this.onPartnerEdit(e, p.partner_id)}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="flat">Flat</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {partnerErrors.partnerCashbackType}
                      </span>
                    </div>
                    <div
                      className="col-md-1"
                      style={{
                        background: "#c82e29",
                        border: "none",
                        padding: "10px 15px",
                        color: "#fff",
                        borderRadius: "5px",
                        textAlign: "center",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                      onClick={() => this.onPartnerRemove(p.partner_id)}
                    >
                      Remove
                    </div>
                  </div>
                ))}

                <div className="col-md-12 main-row">
                  <div className="col-md-2">Website</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="url"
                      placeholder="Enter Website Url"
                      name="website"
                      value={website}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 1</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 1"
                      name="step1"
                      value={step1}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 1 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step1Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 2</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 2"
                      name="step2"
                      value={step2}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 2 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step2Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 3</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 3"
                      name="step3"
                      value={step3}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 3 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step3Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 4</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Step 4"
                      name="step4"
                      value={step4}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Step 4 Image</div>
                  <div className="col-md-3">
                    <input
                      type="file"
                      name="step4Image"
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Coupon Quantity Purchase Limit</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Coupon Quantity"
                      name="coupon_quantity_limit"
                      value={coupon_quantity_limit}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Montly Purchase Limit</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Monthly Purchase limit"
                      name="month_purchase_limit"
                      value={month_purchase_limit}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Priority of display</div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Priority of display"
                      name="priority"
                      value={priority}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Instructions</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={instructionEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">Term & Condition</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={aboutEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onAboutEditorStateChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">How To Redeem</div>
                  <div className="col-md-9">
                    <Editor
                      editorState={howToRedeemEditorState}
                      toolbarClassName="toolbar"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={this.onHowToRedeemEditorState}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-2">How To Redeem Video Link</div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter How To Redeem Video Link"
                      name="videoLink"
                      value={videoLink}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 main-row">
                  <div className="col-md-8 offset-2 brands-btn">
                    <button className="btn btn-primary " type="submit">
                      Add Brand
                    </button>
                    <Link to={"/admin/brands"} className="btn btn-primary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddCategory);

import React from "react";
import { Nav } from "react-bootstrap";

const Footer = (props) => {
  const prevYear = new Date().getFullYear() - 1;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="go-footer">
      <div className="container">
        <div className="footer-wrap">
          <div className="mr-auto pl-0 pl-sm-3">
            &copy; {prevYear}-{currentYear} All rights reserved. GAINON
          </div>
          <Nav as="ul">
            <Nav.Item as="li">
              <Nav.Link
                eventKey="privacy"
                href="/privacy"
                className="py-3 py-sm-0 px-0 px-sm-3"
              >
                Privacy Policy
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                eventKey="tnc"
                href="/tnc"
                className="py-3 py-sm-0 px-0 px-sm-3"
              >
                Terms Of Use
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </footer>
  );
  // return (
  //   <footer className="bg-dark type-2" style={{ zIndex: 10 }}>
  //     <div className="container dis">
  //       <div className="row">
  //         <div
  //           style={{ flex: 1 }}
  //           className="col-lg-4 col-md-3 col-sm-6 col-xs-12"
  //         >
  //           <div className="footer-block">
  //             <img
  //               src={logoPng}
  //               alt=""
  //               className="logo-footer"
  //               style={{ "-webkit-filter": " brightness(0) invert(1)" }}
  //             />
  //             <div className="f_text color-grey-7">
  //               <p>
  //                 Gainon is a members-only rewards program that offers instant
  //                 loyalty cashback on every payment - earn and redeem across
  //                 100+ leading brands you love.
  //               </p>
  //               <p>Spend smart, Earn more</p>
  //             </div>
  //             {/* <div className="footer-share">
  //               <a href="https://www.facebook.com/" target="_blank">
  //                 <span className="fab fa-facebook-f"></span>
  //               </a>
  //               <a href="https://twitter.com/" target="_blank">
  //                 <span className="fab fa-twitter"></span>
  //               </a>
  //               <a href="https://www.instagram.com/" target="_blank">
  //                 <span className="fab fa-instagram"></span>
  //               </a>
  //               <a href="https://www.youtube.com/" target="_blank">
  //                 <span className="fab fa-youtube"></span>
  //               </a>
  //             </div> */}
  //           </div>
  //         </div>
  //         <div className="col-lg-1 col-md-2 col-sm-6 col-xs-12"></div>
  //         <div className="col-lg-3 col-md-4 col-sm-6 col-sm-6 no-padding">
  //           <div className="footer-block">
  //             <h6>Important Link</h6>
  //             <div className="contact-info">
  //               <div className="contact-line color-grey-3 contline">
  //                 <Link to="/about">About us</Link>
  //               </div>
  //               <div className="contact-line color-grey-3 contline">
  //                 <Link to="/contact">Contact us</Link>
  //               </div>
  //               <div className="contact-line color-grey-3 contline">
  //                 <Link to="/voucher">Pay</Link>
  //               </div>
  //               <div className="contact-line color-grey-3 contline">
  //                 <Link to="/">Wallet</Link>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-lg-1 col-md-2 col-sm-6 col-xs-12"></div>
  //         <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
  //           <div className="footer-block">
  //             <h6>Contact Info</h6>
  //             <div className="contact-info">
  //               <div className="contact-line color-grey-3">
  //                 <i className="fa fa-map-marker"></i>
  //                 <span>
  //                   B wing, 5th floor, Ackruti Trade Centre MIDC, Andheri East,
  //                   Mumbai, Maharashtra 400093
  //                 </span>
  //               </div>
  //               <div className="contact-line color-grey-3">
  //                 <i className="fa fa-envelope"></i>
  //                 <a href="mailto:support@gainon.com">support@gainon.in</a>
  //               </div>
  //               <div className="contact-line color-grey-3">
  //                 <i className="fa fa-globe"></i>
  //                 <Link to="/">gainon.in</Link>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="footer-link bg-black">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-md-12">
  //             <div className="copyright dis">
  //               <span>© 2019-2020 All rights reserved. GAINON</span>
  //             </div>
  //             <ul>
  //               <li>
  //                 <Link className="link-aqua" to="/privacy">
  //                   Privacy Policy{" "}
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link className="link-aqua" to="/tnc">
  //                   Terms Of Use
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link className="link-aqua tapnone" to="/contact">
  //                   Contact Us{" "}
  //                 </Link>
  //               </li>
  //             </ul>

  //             <div className="copyright tapnone">
  //               <span>© 2019-2020 All rights reserved. GAINON</span>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </footer>
  // );
};

export default Footer;

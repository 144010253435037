import React, { Component } from "react";
import "../../../assets/sass/admin/RegisteredUser.scss";
import { get, download, post } from "../../../utills/API";
import { httpResponses } from "../../../utills/Constants";
import { downloadXML } from "../../../utills/function";
import DownLoadButton from "../../Common/DownloadButton";
import DownloadModal from "../../Common/DownloadModal";
import DataTable from "react-data-table-component";
// import { Link } from "react-router-dom";
import VoucherModal from "../AllUsers/VoucherModal";
import { toast } from "react-toastify";
import moment from "moment";
import { debounce } from "@material-ui/core";
import Loader from "../../Loader";
import { encrypt, decrypt, getUrlParams } from "../../../utills/function";
import MessageModal from "./MessageModal";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.css';
class AllUpiOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [  
        {
          name: "Gainon Transaction ID",
          sortable: true,
          selector: "uuid",
          width: "180px",
          cell: (row) => (
            <span>
            {row.uuid}
          </span>
          ),
        },
        {
          name: "Sender Name",
          selector: "sender_name",
          sortable: true,
          width: '150px',
          cell: (row) => (
            <span>
              {row.sender_name}
            </span>
          ),
        },
        {
          name: "Receiver Name",
          selector: "receiver_name",
          sortable: true,
          width: '150px',
          cell: (row) => (
                <span>
                  {row.receiver_name}
                </span>
              )
        },  
        {
            name: "Receiver UPI ID",
            selector: "upi_id",
            sortable: true,
            width: '150px',
            cell: (row) => (
              <span>
               {row.upi_id}
              </span>
            ),
          },
        {
          name: "Total Amount",
          selector: "total_amount",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.total_amount}
            </span>
          ),
        },
        {
          name: "Pay Amount",
          selector: "pay_amount",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.pay_amount ? row.pay_amount : row.total_amount}
            </span>
          ),
        },
        {
            name: "Discount",
            selector: "discount",
            sortable: true,
            cell: (row) => (
              <span>
                <i className="fa fa-inr"></i>&nbsp;{row.discount ? row.discount : 0}
              </span>
            ),
          },
        {
          name: "Earned Gopoints ",
          selector: "earned_go_point",
          sortable: true,
          cell: (row) => (
            <span>
              {row.earned_go_point ? row.earned_go_point : 0}
            </span>
          ),
        },
        {
          name: "Order Status",
          selector: "order_status",
          sortable: true,
          width: "180px",
          cell: (row) => {
            var status;
            var color;
            if (row.order_status === "initiated") {
              color = "blue";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  ORDER INITIATED
                </span>
              );
            } else if (row.order_status === "failed") {
              color = "red";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  ORDER FAILED
                </span>
              );
            } else if (row.order_status === "completed") {
              color = "green";
              return (
                <span style={{ fontWeight: "bold", color }}>
                  ORDER COMPLETED
                </span>
              );
            } else if (row.order_status === "pending") {
              status = "ORDER PENDING";
              color = "yellow";
              return (
                <div>
                  <span style={{ fontWeight: "bold", color }}>ORDER PENDING</span>

                </div>
              );
             } else if (row.order_status === "created") {
              status = "PAYMENT COMPLETED";
              color = "blue";
              return (
                <div>
                  <span style={{ fontWeight: "bold", color }}>CREATED</span>
                  <a
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => this.sendOrderPendingMail(row.order_id)}
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                  <a
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => 
                      {this.setState({
                        restoreVoucher: {
                          order_id : row.uuid,
                          brand_id: row.brand_id
                        },
                        isShowMessage:true
                      })
                      console.log("UUID: ", row.uuid)
                      console.log("Brand Id: ", row.order)
                    }}
                    id="restoreFailedVouchers"
                  >
                    <i className="fa fa-exclamation-triangle"></i>
                  </a>
                </div>
              );
            }
          },
        },
        // {
        //   name: "Order Status",
        //   selector: "payment_status",
        //   sortable: true,
        //   width: "180px",
        //   cell: (row) => {
        //     var status;
        //     var color;
        //     if (row.payment_status !== null && row.payment_status.toLowerCase() === "success") {
        //       color = "green";
        //       return (
        //         <span style={{ fontWeight: "bold", color }}>
        //           ORDER COMPLETED
        //         </span>
        //       );
        //     } else if (row.payment_status !== null && row.payment_status.toLowerCase() === "fail") {
        //       color = "red";
        //       return (
        //         <span style={{ fontWeight: "bold", color }}>
        //           PAYMENT FAILURE
        //         </span>
        //       );
        //     } else if (row.payment_status !== null && row.payment_status.toLowerCase() === "created") {
        //       status = "PAYMENT COMPLETED";
        //       color = "blue";
        //       return (
        //         <div>
        //           <span style={{ fontWeight: "bold", color }}>CREATED</span>
        //           <a
        //             style={{ marginLeft: "10px", cursor: "pointer" }}
        //             onClick={() => this.sendOrderPendingMail(row.id)}
        //           >
        //             <i class="fa fa-envelope"></i>
        //           </a>
        //           <a
        //             style={{ marginLeft: "10px", cursor: "pointer" }}
        //             onClick={() => 
        //               this.setState({
        //                 restoreVoucher: {
        //                   order_id : row.uuid,
        //                   brand_id: row.brand_id
        //                 },
        //                 isShowMessage:true
        //               })
        //             }
        //             id="restoreFailedVouchers"
        //           >
        //             <i class="fa fa-exclamation-triangle"></i>
        //           </a>
        //         </div>
        //       );
        //     }
        //   },
        // },

        // {
        //   name: "Vouchers",
        //   button: true,
        //   cell: (row) => (
        //     <a onClick={() => this.showVoucherComponent(row.id, true)}>
        //       <i class="fa fa-gift"></i>
        //     </a>
        //   ),
        // },
        {
          name: "Created Date",
          selector: "createdAt",
          sortable: true,
          cell: (row) => (
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          ),
        },
      ],
      // columnVoucher: [
      //   {
      //     name: "Order Id",
      //     sortable: true,
      //     selector: "external_order_id",
      //     width: "200px",
      //   },
      //   {
      //     name: "Quantity",
      //     sortable: true,
      //     selector: "quantity",
      //     width: "100px",
      //   },
      //   {
      //     name: "Status",
      //     sortable: true,
      //     selector: "status",
      //     cell: (row) => {
      //       var status;
      //       var color;
      //       if (row.status === "Success") {
      //         status = "SUCCESS";
      //         color = "green";
      //       } else if (row.status === "fail") {
      //         status = "FAILURE";
      //         color = "red";
      //       } else {
      //         status = row.status.toUpperCase();
      //         color = "blue";
      //       }

      //       return <span style={{ fontWeight: "bold", color }}>{status}</span>;
      //     },
      //     width: "100px",
      //   },
      //   {
      //     name: "Amount",
      //     sortable: true,
      //     selector: "amount",
      //     cell: (row) => (
      //       <span>
      //         <i class="fa fa-inr"></i>&nbsp;{row.amount}
      //       </span>
      //     ),
      //     width: "100px",
      //   },
      //   {
      //     name: "GUID",
      //     sortable: true,
      //     selector: "guid",
      //     width: "240px",
      //   },
      //   {
      //     name: "Gift Vouchers",
      //     button: true,
      //     cell: (row) => (
      //       <a onClick={() => this.showVoucherModal(row.id)}>
      //         <i class="fa fa-gift"></i>
      //       </a>
      //     ),
      //   },
      // ],
      orderData: [],
      filterText: "",
      isShowVoucher: false,
      voucherData: [],
      isTransactionTableShow: true,
      userVoucherId: null,
      userVoucherData: [],
      partners: [],
      selectedPartner: "",
      showBackBtn: false,
      offset: 1,
      pageSize: 10,
      totalData: 100,
      isLoading: false,
      isShowMessage:false,
      restoreVoucher:null,
      startDate: "",
      endDate: ""
    };
  }

  DEBOUNCE_TIME = 300;

  toggleDownloadModal = (value) => {
    this.setState({ downLoadModalShow: value });
  };

  handleDateRange = async (e) =>{
     if(e){
      await this.setState({ startDate : e[0], endDate: e[1]}, function () {
        // console.log("Start date State : ", this.state.startDate, "End date state   : ", this.state.endDate)
        this.fetchOrders(1);
      });
     } else {
      await this.setState({ startDate : "", endDate: ""}, function () {
        // console.log("Start date State : ", this.state.startDate, "End date state   : ", this.state.endDate)
        this.fetchOrders(1);
      });
     }
  }

  showVoucherComponent = async (id) => {
    try {
      const { data } = await get(`orders/${id}/user_vouchers`);
      var userVoucherData = data.records.user_vouchers;
      console.log("userVoucherData", userVoucherData)
    } catch (e) {
      console.log("error", e);
    }
    this.setState({
      isTransactionTableShow: false,
      userVoucherId: id,
      userVoucherData,
      showBackBtn: true,
    });
  };

  sendOrderPendingMail = async (id) => {
    try {
      const { data } = await get(`orders/${id}/order_pending_email`);
      if (data.status == httpResponses.HTTP_SUCCESS) {
        toast.success("Mail sent", {
          autoClose: 4000,
        });
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to send mail.!", {
        autoClose: 4000,
      });
    }
  };

  restoreFailedVouchers= async () => {
    this.setState({
      isProcess: true,
    });
    console.log(this.state?.restoreVoucher)
    if(this.state?.restoreVoucher!==null){
      try {
        let orderData = encrypt({
          order_id: this.state?.restoreVoucher?.order_id,
          brand_id: this.state?.restoreVoucher?.brand_id
        });

        console.log("order data : " , decrypt(orderData))
          const { data } = await post("razorpay/restore_failed_vouchers",{data:orderData});
          if (data.status == httpResponses.HTTP_SUCCESS) {
            this.getPaginatedData(this.state.offset);
            this.setState({
              isProcess: false,
              isShowMessage:false,
              restoreVoucher:null
            });
            toast.success(data?.message, {
              autoClose: 2000,
            });
          }else{
            this.setState({
              isProcess: false,
              isShowMessage:false,
              restoreVoucher:null
            });
            toast.error(data?.message, {
              autoClose: 2000,
            });
          }
      } catch (e) {
        console.log("error", e);
        this.setState({
          isProcess: false,
          isShowMessage:false,
          restoreVoucher:null
        });
      }
    }else{
      this.setState({
        isProcess: true,
        isShowMessage:false,
      });
    }
  };

  closeMessageModal = ()=>{
    this.setState({isShowMessage:false, restoreVoucher:null })
  }

  showVoucherModal = async (id) => {
    try {
      const { data } = await get(`vouchers/orders/user_vouchers/${id}`);
      var voucherData = data.records.vouchers;
      console.log("voucherData", voucherData)
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isShowVoucher: true, voucherData });
  };

  hideVoucherModal = () => {
    this.setState({ isShowVoucher: false });
  };
  hideVoucherComponent = () => {
    this.setState({ isTransactionTableShow: true, showBackBtn: false });
  };

 



  fetchOrders = async (pagIndex, currentRowsPerPage) => {
    // console.log("pagIndex: ", pagIndex, " currentRowsPerPage: ", currentRowsPerPage)
    this.setState({ isLoading: true });
    try {
      // console.log("this.state.startDate :" , this.state.startDate)
      // console.log("this.state.endatee :" , this.state.endDate)
      const { data } = await get(`upipaymentorder/getAll_Upi_Orders/?startDate=${this.state.startDate}&endDate=${this.state.endDate}`);
      var orderData = data.records ? data.records.map((item) => item) : [];
      var totalData = data.records.length ? data.records.length : [];
    } catch (e) {
      console.log("error", e); 
    }
    this.setState({
      orderData,
      offset: 1,
      totalData,
      pageSize: this.state.pageSize,
      isLoading: false,
    });
  };



  getPaginatedData = async (pagIndex) => {
    this.fetchOrders(pagIndex);
  };

  changeRowsPerPage = (currentRowsPerPage, currentPage) => {
    this.fetchOrders(currentPage, currentRowsPerPage);
  };



  async componentDidMount() {
    // try {
    //   const { data } = await get(`orders`, {
    //     partner_id: this.state.selectedPartner,
    //   });
    //   var orderData = data.records.orders;
    // } catch (e) {
    //   console.log("error", e);
    // }
    this.fetchOrders(1);
  }

  onGetOrdersByPartner = async (e) => {
    // e.persist();
    this.setState({ isLoading: true });
    const selectedPartner = e.target.value || null;
    // try {
    //   const { data } = await get("orders", {
    //     partner_id: e.target.value === "" ? null : e.target.value,
    //   });
    //   var orderData = data.records.orders;
    // } catch (e) {
    //   console.log("error", e);
    // }
    try {
      const { data } = await post(`orders/report/?startDate=Sun%20Jan%2001%202023%2017:19:17%20GMT+0530%20(India%20Standard%20Time)&endDate=${new Date()}&search_param=${selectedPartner}`);
      var orderData = data.records ? data.records.map((item) => item) : [];
      var totalData = data.records.length ? data.records.length : [];
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      orderData,
      offset: 1,
      totalData,
      pageSize: this.state.pageSize,
      isLoading: false,
    });

    // this.setState(
    //   {
    //     selectedPartner,
    //   },
    //   () => {
    //     this.fetchOrders(1);
    //   }
    // );
  };

  onFilter = (e) => {
    this.setState({ filterText: e.target.value })
    // console.log('e--->',e)
    // this.setState({ filterText: e.target.value },()=>{
    //   this.fetchOrders(1)
    // });
    // this.filterDebounce(e.target.value);
  };

  filterDebounce = debounce((value) => {
    this.setState({ filterText: value }, () => {
      this.fetchOrders(1);
    });
  }, this.DEBOUNCE_TIME);

  onClear = () => {
    this.setState({ filterText: "" })
    // this.setState({ filterText: "" },()=>{
    //   this.fetchOrders(1)
    // });
    // this.filterDebounce("");
  };

  downloadReport = async (startDate, endDate) => {
    this.setState({
      isProcess: true,
    });

    // console.log(this.state.startDate, this.state.endDate)
    
    try {
      if(this.state.startDate === "" || this.state.endDate === ""){
        var x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth()-1);
        this.state.endDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        this.state.startDate = moment(x).format("YYYY-MM-DD");
      }
      const { data } = await download(
        `upipaymentorder/orders_download/?startDate=${this.state.startDate}&endDate=${this.state.endDate}`
      );
      const fileName = `upi_orders_${moment(this.state.startDate).format("DD-MM-YYYY")}_${moment(this.state.endDate).format("DD-MM-YYYY")}.xlsx`; 
      downloadXML(data, fileName)
      
    } catch (e) {
      console.log("error", e);
    }
    this.toggleDownloadModal(false);
    this.setState({
      isProcess: false,
    });
  };
  // changeDate = (type, value) => {
  //   switch (type) {
  //     case "start":
  //       this.setState({startDate : value });
  //       return
  //     case "end":
  //       this.setState({endDate : value });
  //       return
  //   }
  // };
  render() {
    const {
      orderData,
      columns,
      filterText,
      // voucherData,
      userVoucherData,
      columnVoucher,
      selectedPartner,
      partners,
      downLoadModalShow,
      isProcess,
      totalData,
      isLoading,

    } = this.state;

    const filteredItems = orderData && orderData.filter((item) =>
      item.uuid.toLowerCase().includes(filterText.toLowerCase())
    );
    // console.log("filteredItems: ", filteredItems)
    const customStyles = {
      headCells: {
          style: {
            fontWeight: 'bold',
          },
      },
  }

  const today = new Date()
  const { afterToday  } = DateRangePicker;

    return (
      <>
        <div className="row-fluid admin-registered-user">
          {isLoading ? (
            <div className="loader-center">
              <Loader />
            </div>
          ) : null}
          <div id="content" className="span9">
            <div className="row-fluid">
              <div className="block">
                <div className="navbar navbar-inner block-header">
                  <div className="muted pull-left">
                    UPI Transaction History 
                  </div>
                  <DownLoadButton click={this.downloadReport} />
                </div>

                <div className="block-content  in">
                  <div className="span12">
                    {/* {this.state.isTransactionTableShow ? ( */}
                      <DataTable
                        subHeader
                        subHeaderComponent={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >

                            <FilterComponent
                              onFilter={this.onFilter}
                              onClear={this.onClear}
                              filterText={filterText}
                            />
                            
                                {/* <label style={{margin:0}}>Start Date</label>
                                <DatePicker
                                  name="startdate"
                                  selected={this.state.startDate}
                                  onChange={(e) => this.changeDate('start', e)}
                                  className="form-control"
                                  popperClassName="go-datepicker"
                                  maxDate={today}
                                    
                                />
                                <label style={{margin:0}}>End Date</label>
                                <DatePicker
                                  name="enddate"
                                  selected={this.state.endDate}
                                  onChange={(e) => this.changeDate('end', e)}
                                  className="form-control"
                                  popperClassName="go-datepicker"
                                  maxDate={today}
                                  minDate={this.state.startDate}
                                /> */}
                           


                            <DateRangePicker 
                              style={{ zIndex: 0 , width: '250px', color:'black !important' }} 
                              disabledDate={afterToday()} 
                              showOneCalendar 
                              onChange={this.handleDateRange} 
                              placeholder="Select Date Range"
                              character = "  to  "  
                              ranges = {[]}
                        
                            />

                          </div>
                        }
                        columns={columns}
                        data={filteredItems}
                        pagination={true}
                        defaultSortField
                        // paginationServer={true}
                        customStyles={customStyles}
                        // highlightOnHover
                        expandableRows
                        // paginationTotalRows={totalData}
                        paginationPerPage={10}
                        expandableRowsComponent={<SampleExpandedComponent />}
                        // onChangePage={this.getPaginatedData}
                        // onChangeRowsPerPage={this.changeRowsPerPage}
                      />

                    {/* ) : (
                      <DataTable
                        columns={columnVoucher}
                        data={userVoucherData}
                        pagination={true}
                        defaultSortField
                        highlightOnHover
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VoucherModal
            hideVoucher={this.hideVoucherModal}
            show={this.state.isShowVoucher}
            voucherData={this.state.voucherData}
          />
        </div>
        <MessageModal
          isShowMessage={this.state?.isShowMessage}
          restoreFailedVouchers={this.restoreFailedVouchers}
          closeMessageModal={this.closeMessageModal}
          isProcess={this.state?.isProcess}
        />
      </>
    );
  }
}

const FilterComponent = (props) => {
  return (
    <div className="row filterComp">
      <div className="filterComp-input">
        <input
          className="form-control"
          type="text"
          placeholder="Filter By Gainon Transition Id"
          value={props.filterText}
          onChange={props.onFilter}
        />
      </div>

      <button onClick={props.onClear}>X</button>
    </div>
  );
};

// const sampleExpandedComponent = (data) => {
//   console.log("data: ", data)
//   return (
//     <table className="table">
//           <tr>
//             <th scope="row">Transaction Id</th><td>{data.txn_id}</td>
//             <th scope="row">Voucher Value</th><td><i class="fa fa-inr mx-1"></i>{data.value}</td>
//             <th scope="row">Bank Transaction Id</th><td>{data.bank_txn_id}</td>
//           </tr>
//           <tr>
//             <th scope="row">Quantity</th><td>{data.quantity}</td>
//             <th scope="row">GUID</th><td>{data.guid}</td>
//             <th scope="row">Amount</th><td><i class="fa fa-inr mx-1"></i>{data.amount}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Brand Name</th><td>{data.brand_name}</td>
//             <th scope="row">Status</th>
//             {
//                 data.status === "success" ?
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'green', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//                 :
//                 data.status === "fail" ?
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'red', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//                 :
//                 (
//                   <td style={{ 
//                     fontWeight: "bold", 
//                     color:'blue', 
//                     textTransform:'uppercase'
//                   }}>
//                     {data.status}
//                   </td>
//                 )
//             }
//             <th scope="row">Voucher No.</th><td>{data.number}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Pin</th><td>{data.pin}</td>
//             <th scope="row">Voucher Code</th><td>{data.code}</td>
//             <th scope="row">Earning</th><td><i class="fa fa-inr mx-1"></i>{data.earn}</td>
//           </tr>
          
//           <tr>
//             <th scope="row">Bank Name</th><td>{data.bank_name}</td>
//             {/* <th scope="row">Payment Status</th><td>{data.payment_status}</td> */}
//             <th scope="row">End Date</th><td>{data.end_date}</td>
//           </tr>
//     </table>
//   );
// };

const SampleExpandedComponent = (data) => {
    let expandableRow = [data]
 
  const columnVoucher = [
    {
        name: "Sender UPI ID",
        sortable: true,
        selector: "sender_upi_id",
        cell: (row) => (
          <span>
            {row.data.sender_upi_id ? row.data.sender_upi_id : ''}
          </span>
        ), 
      },
    {
      name: "PayU ID",
      sortable: true,
      selector: "payu_id",
      cell: (row) => (
        <span>
          {row.data.payu_id ? row.data.payu_id : ''}
        </span>
      ), 
    },
    {
      name: "PayU Status",
      sortable: true,
      selector: "payu_status", 
      cell: (row) => (
        <span>
          {row.data.payu_status}
        </span>
      ),
    },  
    {
      name: "Cashfree ID",
      sortable: true,
      selector: "cashfree_id", 
      cell: (row) => (
        <span>
          {row.data.cashfree_id ? row.data.cashfree_id : ''}
        </span>
      ),
    },
    {
      name: "Cashfree Status",
      sortable: true,
      selector: "cashfree_status", 
      cell: (row) => (
        <span>
          {row.data.cashfree_status}
        </span>
      ),
    },
    {
      name: "Payment Status",
      sortable: true,
      selector: "payment_status", 
      cell: (row) => {
        var status;
        var color;
        if (row.data.payment_status.toLowerCase() === "success") {
          status = "PAYMENT SUCCESS";
          color = "green";
        } else if (row.data.payment_status.toLowerCase() === "failed") {
          status = "PAYMENT FAILURE";
          color = "red";
        } else {
          status = row.data.payment_status.toUpperCase() === "pending";
          status = "PAYMENT PENDING";
          color = "yellow";
        }

        return <span 
          style={{ 
            fontWeight: "bold", 
            color, 
          }}
        >
          {status}
        </span>;
      },
    },
    {
      name: "Note",
      sortable: true,
      selector: "note", 
      cell: (row) => (
        <span>
        {row.data.note}
      </span>
      ),
    },
  ]

  const customStyles = {
    rows: {
        style: {
          backgroundColor: "#f0ecec",
          fontWeight: 'bold',
        },
    },
    headCells: {
        style: {
          backgroundColor: "#f0ecec",
          fontWeight: 'bold',
        },
    },
}

    return (
        <>
          <DataTable
            columns={columnVoucher}
            data={expandableRow}
            pagination={false}
            defaultSortField
            customStyles={customStyles}
          />
        </>
    )
}

export default AllUpiOrders;

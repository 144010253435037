import axios from "axios";
import { AUTH_TOKEN, SERVICE_URL } from "./utills/Constants";

const axiosInitializer = {
  config: () => {
    axios.defaults.baseURL = SERVICE_URL;
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN
      ? `Bearer ${AUTH_TOKEN}`
      : "";

    //Request Interceptor
    axios.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    //Response Interceptor
    axios.interceptors.response.use(
      response => {
        if (response.data && response.data.message) {
          // console.log("Props", this.props);
        }
        return response;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
};

export default axiosInitializer;

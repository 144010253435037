import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { get } from "../../../utills/API";
import "./Partners.scss"

const ShowBalanceLogs = (props) => {
    const [BalanceLogs, setBalanceLogs] = useState([]);
    const [searchText, setSearchText] = useState("");

    const columns = [
        {
            name: "Sr.No.",
            selector: "sr_no",
            sortable: true,
            width: "100px",
        },
        {
            name: "Transaction Amount",
            selector: "amount",
            sortable: true,
            center: true,
        },
        {
            name: "Transaction Date",
            selector: row => moment(row.createdAt).format("DD-MM-YYYY"),
            sortable: true,
            center: true,
        },
        {
            name: "Cashfloat Description",
            selector: "description",
            width:'180px',
            sortable: true,
            center: true,
        },
    ]

    useEffect(() => {
        const fetchBalanceLogs = async () => {
            try {
                const PartnerBalanceData = await get(`balancelogs/${props.match.params.partnerId}`);
                setBalanceLogs(PartnerBalanceData.data.logs)
            } catch (error) {
                console.log(error);
            }
        }
        fetchBalanceLogs();
    }, [])

    const BalanceTable = BalanceLogs
        .sort((a, b) => (b.id > a.id) ? 1 : -1)
        .map((logs, i) => ({ ...logs, sr_no: i + 1 }))
        .filter(b_log =>
            b_log.amount.toString().toLowerCase().includes(searchText.toLowerCase())
            ||
            moment(b_log.createdAt).format("DD-MM-YYYY").toString().toLowerCase().includes(searchText.toLowerCase())
        )
    return (
        <div className="container" style={{
            minWidth: "600px",
        }} >
            <Card>
                <Card.Header className="bg-dark text-light" >
                    <Row>
                        <Col md={8}>
                            Show Balance Logs
                        </Col>
                        <Col>
                            <button
                                className="zing-btn-sm zing-btn-primary"
                                onClick={() => props.history.goBack()}
                            >Back</button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={BalanceTable}
                        pagination={true}

                        subHeader={true}
                        subHeaderComponent={<SearchBar
                            SearchText={searchText}
                            SetSearchText={(data) => setSearchText(data)}
                        />}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}
const SearchBar = ({ SearchText, SetSearchText }) => {

    return (
        <div className="form-group pr-1 d-flex" >
            <input className="form-control"
                placeholder="Search here..."
                style={{
                    zIndex: 1,
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                }}
                value={SearchText}
                onChange={(e) => SetSearchText(e.target.value)}
            />
            <i
                className="btn btn-danger fa fa-times btn-vbc"
                style={{
                    // display: "block",
                    // fontSize: "20px",
                    marginLeft: "-4px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",

                }}
                onClick={() => SetSearchText("")}
            ></i>
        </div>
    )
}

export default ShowBalanceLogs;
import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import cookie from "react-cookies";
import { logout, get } from "../../utills/API";
import DataTable from "react-data-table-component";
import Loader from "../Loader";
import moment from "moment";
import GoNav from "../Common/GoNav";
import { Card, InputGroup, FormControl } from "react-bootstrap";
import rupee from "../../assets/images/gainon/rupee.svg";
import VoucherCoupon from "../VoucherCoupon";
import { withRouter } from "react-router-dom";

class MyVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      vouchers: [],
      allVouchers: [],
      columns: [
        {
          cell: (row) => (
            <>
              {row.is_redeem === 0 ? (
                <i className="icon-arrow-right d-inline-block up font-weight-bold" />
              ) : (
                  <i className="icon-arrow-right d-inline-block down font-weight-bold" />
                )}
            </>
          ),
          maxWidth: "40px",
          minWidth: "40px",
          textAlign: "center",
        },
        {
          name: "Transaction ID",
          selector: "number",
          sortable: true,
          cell: (row) => <span onClick={() => { this.props.history.push(`/orders/${row.uuid}/details`) }} style={{ color: "#4886FF", cursor: "pointer" }}>{row.uuid}</span>,
          minWidth: "180px",
        },
        {
          name: "Type",
          selector: "is_redeem",
          sortable: true,
          cell: (row) => <span>{row.is_redeem === 0 ? "Shop" : "Redeem"}</span>,
          minWidth: "20px",
          maxWidth: "75px",
        },
        {
          name: "Date of Purchase",
          sortable: true,
          cell: (row) => (
            <span style={{ minWidth: "160px" }}>{moment(row.createdAt).format("D MMM YYYY, H:mm")}</span>
          ),
          selector: "createdAt",
          maxWidth: "250px",
        },
        {
          name: "Brand / Mode",
          sortable: true,
          selector: "brand_name",
          maxWidth: "200px",
          cell: (row) => (
            <>
              <a onClick={() => this.showVoucher(row.id)}>{row.brand_name}</a>
            </>
          ),
          minWidth: "160px",
          // grow: 2,
        },
        {
          name: "Total Value",
          sortable: true,
          cell: (row) => (
            <span>
              <i className="fa fa-inr"></i>&nbsp;{row.total_amount}
            </span>
          ),
          selector: "value",
          maxWidth: "150px",
        },
        {
          name: "Earnings",
          sortable: true,
          cell: (row) => (
            <>
              {(row.earn && !row.is_wallet_use) ? (
                <span style={{ color: "#2AC009" }}>
                  <i class="fa fa-inr"></i>&nbsp;{row.totalEarning}
                </span>
              ) : (
                  <span style={{ color: "#ccc" }}>N/A</span>
                )}
            </>
          ),
          selector: "earn",
          maxWidth: "150px",
        },
      ],

      filterText: "",
      resetPaginationToggle: false,
      isVoucherShow: false,
      voucherId: null,
      voucher: {},
      isLoading: false,
    };
  }

  showVoucher = async (id) => {
    try {
      const { data } = await get(`vouchers/${id}`);
      var voucher = data.data.voucher[0];
    } catch (e) {
      console.log("error", e);
    }
    this.setState({ isVoucherShow: true, voucherId: id, voucher });
  };

  hideVoucher = () => {
    this.setState({ isVoucherShow: false });
  };

  isCurrentUser = () => {
    return !!cookie.load("accessToken");
  };

  logout = async () => {
    await logout();
    let isLogin = this.isCurrentUser();
    this.setState({ isLoggedIn: isLogin });
    this.props.history.push("/");
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { data } = await get("users/detail");
      var userData = data.data.user;
    } catch (e) {
      console.log("error", e);
    }

    try {
      const { data } = await get("vouchers");
      var vouchers = data.data.vouchers;
    } catch (e) {
      console.log("error", e);
    }

    this.setState({
      userData,
      vouchers,
      allVouchers: vouchers,
      isLoading: false,
    });
  }

  searchHeader = (e) => {
    console.log("event", e);
  };

  onFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };

  onClear = () => {
    this.setState({ filterText: "" });
  };

  copyToClipboard = (e, id) => {
    var text = document.querySelector(`.voucher-number-${id}`);
    text.select();
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccess: "Copied!", voucherId: id });
  };

  copyToClipboardPin = (e, id) => {
    var text = document.querySelector(`.pin-${id}`);
    text.select();
    document.execCommand("copy");

    e.target.focus();
    this.setState({ copySuccess: "Copied!", voucherId: id });
  };

  handleSearchOnChange = (e) => {
    const { value } = e.target;

    let filteredVoucher = [];
    if (value && this.state.allVouchers.length > 0) {
      filteredVoucher = this.state.allVouchers.filter((eachVoucher) => {
        return (
          eachVoucher.number.toLowerCase().includes(value.toLowerCase()) ||
          eachVoucher.brand_name.toLowerCase().includes(value.toLowerCase())
        );
      });
    } else {
      filteredVoucher = this.state.allVouchers;
    }
    this.setState({ vouchers: filteredVoucher });
  };

  render() {
    const { vouchers, columns, filterText } = this.state;

    let newFiltered = [];
    const filteredObject = {}

    const filteredItems = vouchers?.filter(
      (item) =>
        item.brand_name?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        item.number?.toLowerCase()?.includes(filterText?.toLowerCase())
    );

    filteredItems.forEach((item) => {
      if (!filteredObject[item.uuid]) {
        filteredObject[item.uuid] = { ...item, totalEarning: 0 };
      }
      filteredObject[item.uuid].totalEarning += item.earn;
    });

    newFiltered = Object.values(filteredObject)

    const customStyles = {
      table: {
        style: {
          backgroundColor: "transparent",
        },
      },

      headRow: {
        style: {
          backgroundColor: "transparent",
          borderBottom: "none",
        },
      },
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "18px",
          color: "#333333",
          opacity: "0.7",
          whiteSpace: "nowrap",
        },
        cells: {
          style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
            minWidth: "60px",
          },
        },
      },
      rows: {
        style: {
          marginLeft: "16px", // override the cell padding for data cells
          marginRight: "16px",
        },
      },
    };
    return (
      <>
        {this.state.isLoading ? <Loader /> : null}
        <Header logout={this.logout} isCurrentUser={this.isCurrentUser()} />
        <section className="page page-my-voucher pt-4">
          <div className="container">
            <div className="flex-xl-nowrap row">
              <div className="col-auto d-none d-lg-flex flex-column">
                <GoNav />
              </div>
              <div className="col">
                <h1 className="font-weight-bold text-18 mb-5">
                  My Active Vouchers
                </h1>
                <div className="coupon-wrap d-flex">
                  {this.state.allVouchers &&
                    this.state.allVouchers.length > 0 &&
                    this.state.allVouchers.map((eachVoucher, index) => {
                      if (index < 2) {
                        return (
                          <div className="coupon-img">
                            <VoucherCoupon
                              eachVoucher={eachVoucher}
                              x="0"
                              y="0"
                            />
                          </div>
                        );
                      }
                    })}

                  {/* <div className="coupon-img">
                    <VoucherCoupon1 x="0" y="0" />
                  </div> */}
                </div>

                <div className="transtation-wrap">
                  <p className="font-weight-bold mb-3">Transaction History</p>
                  <div className="search-brand">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="basic-addon1"
                          className="border-right-0"
                        >
                          <i
                            className="icon-search"
                            style={{ color: "#989998" }}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="Search for Transaction ID or brands"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => this.handleSearchOnChange(e)}
                      />
                    </InputGroup>
                  </div>
                </div>
                {newFiltered &&
                  newFiltered.length > 0 &&
                  newFiltered.map((item) => {
                    return (
                      <Card className="card-active-voucher d-lg-none d-md-none d-block mb-3">
                        <Card.Body>
                          <div className="d-flex justify-content-between mb-8">
                            <span className="transaction-id" onClick={() => { this.props.history.push(`/orders/${item.uuid}/details`) }}>
                              {item.uuid}
                            </span>
                            <div>
                              {item.is_redeem === 0 ? (
                                <i className="icon-arrow-right shop-arrow" />
                              ) : (
                                  <i className="icon-arrow-right redeem-arrow" />
                                )}{" "}
                              <span>
                                {item.is_redeem === 0 ? "Shop" : "Redeem"}
                              </span>
                            </div>
                          </div>
                          <p className="mb-2 font-weight-bold d-flex">
                            {/* <div className="company-logo mr-2">
                              <img
                                src={item.image ? item.image : ''}
                                alt="Company Logo"
                              />
                            </div> */}
                            {item.brand_name}
                          </p>
                          <p className="date-time-txt mb-3">
                            {moment(item.createdAt).format(
                              "D MMM YYYY, h:mm a"
                            )}
                          </p>
                          <div className="d-flex justify-content-between">
                            <div>
                              <p className="title mb-1">Total Value</p>
                              <p className="mb-0">
                                <img src={rupee} alt="Rupee Logo" />
                                {item.total_amount}
                              </p>
                            </div>
                            <div>
                              <p className="title mb-1">Earnings</p>
                              <p
                                className="text-right mb-0"
                                style={{ color: "#2AC009" }}
                              >
                                <svg
                                  viewBox="0 0 14 14"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlinkHref="http://www.w3.org/1999/xlink"
                                  width="12"
                                  height="12"
                                >
                                  <g
                                    id="Final"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <g
                                      id="1.2.1"
                                      transform="translate(-1173.000000, -24.000000)"
                                    >
                                      <g
                                        id="Group-8"
                                        transform="translate(1147.000000, 12.000000)"
                                      >
                                        <g
                                          id="Group-26"
                                          transform="translate(0.000000, 9.000000)"
                                        >
                                          <g
                                            id="Group-25"
                                            transform="translate(26.000000, 1.000000)"
                                          >
                                            <g
                                              id="Group-2-Copy"
                                              transform="translate(0.000000, 2.000000)"
                                            >
                                              <rect
                                                id="Rectangle"
                                                x="0"
                                                y="0"
                                                width="14"
                                                height="14"
                                              ></rect>
                                              <path
                                                d="M9.21474359,12.8333333 L9.21474359,12.7371795 L5.26442308,8.15384615 C6.43429487,8.15384615 7.34775641,7.91346154 8.00480769,7.43269231 C8.66185897,6.95192308 9.03846154,6.23611111 9.13461538,5.28525641 L9.13461538,5.28525641 L10.3525641,5.28525641 L10.7211538,4.01923077 L9.11057692,4.01923077 C9.01976496,3.39423077 8.8034188,2.86538462 8.46153846,2.43269231 L8.46153846,2.43269231 L10.3525641,2.43269231 L10.7211538,1.16666667 L3.30929487,1.16666667 L2.93269231,2.43269231 L5.18429487,2.43269231 C6.49839744,2.47542735 7.28365385,3.0042735 7.5400641,4.01923077 L7.5400641,4.01923077 L3.28525641,4.01923077 L2.91666667,5.28525641 L7.55608974,5.28525641 C7.44391026,5.7767094 7.17948718,6.1653312 6.76282051,6.45112179 C6.34615385,6.73691239 5.80395299,6.88247863 5.13621795,6.88782051 L5.13621795,6.88782051 L3.14102564,6.88782051 L3.14903846,7.88942308 L7.39583333,12.8333333 L9.21474359,12.8333333 Z"
                                                id="₹"
                                                fill="#2AC009"
                                                fill-rule="nonzero"
                                              ></path>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                {item.totalEarning}
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                {/* <Card className="card-active-voucher d-lg-none d-md-none d-block mb-3">
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-8">
                      <span className="transaction-id">1231GHJG212312</span>
                      <div>
                        <i className="icon-arrow-right redeem-arrow" />{" "}
                        <span>Redeem</span>
                      </div>
                    </div>
                    <p className="mb-2 font-weight-bold d-flex">
                      <div className="company-logo mr-2">
                        <img
                          src="https://dummyimage.com/16x16/9e299e/b83fb8&text=LOGO"
                          alt="Company Logo"
                        />
                      </div>
                      Bank Transfer
                    </p>
                    <p className="date-time-txt mb-3">3 Jul 2020, 12:34 PM</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="title mb-1">Total Value</p>
                        <p className="mb-0">
                          <img src={rupee} alt="Rupee Logo" />
                          1500
                        </p>
                      </div>
                      <div>
                        <p className="title mb-1">Earnings</p>
                        <p
                          className="text-right mb-0"
                          style={{ color: "#2AC009" }}
                        >
                          N/A
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card> */}
                <DataTable
                  columns={columns}
                  data={newFiltered}
                  responsive={true}
                  className="go-table d-lg-block d-md-block d-none"
                  fixedHeader
                  noHeader
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

// const FilterComponent = (props) => {
//   return (
//     <div className="row filterComp">
//       <div className="filterComp-input" style={{ width: "100%" }}>
//         <input
//           className="form-control"
//           type="text"
//           placeholder="Filter By Name"
//           value={props.filterText}
//           onChange={props.onFilter}
//         />
//       </div>
//     </div>
//   );
// };
export default withRouter(MyVoucher);
